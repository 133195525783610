import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { BaseType } from '../base';
import { ObjectValue } from './ObjectValue';
import { ObjectValueType } from './ObjectValueType';
import { OrFunction, setDebugVariable } from '@f2w/utils';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import useStableMemo from '@restart/hooks/useStableMemo';
import { ObjectField } from '../../../theme';
function _useValue(getType, props, deps = []) {
    const dispatchUpdate = useForceUpdate();
    const { value, type } = useStableMemo(() => {
        const { skipEmpty, initialValue, options, ...rest } = { ...OrFunction(props) };
        if (!initialValue && skipEmpty) {
            return { type: null, value: null };
        }
        const type = getType(options);
        const valueType = type.init({ initialValue, ...rest, context: { dispatchUpdate } });
        return { type, value: valueType };
    }, deps);
    setDebugVariable({ fw_value: value, fw_type: type });
    return value;
}
export class BaseObjectType extends BaseType {
    constructor(options) {
        super(options);
    }
    _createValue(props) {
        const objValue = new ObjectValue(this, { ...props });
        this.specs.shape?.forEach((child, key) => objValue.register(key, child));
        return objValue;
    }
    useValue(props, deps = []) {
        return _useValue((options) => this, props, deps);
    }
    static useValue(props, deps = []) {
        return _useValue((options) => new this(options), props, deps);
    }
    _renderWidget(props) {
        return props.field.renderChildren(null, {});
    }
    _renderForm(valueType, props) {
        if (valueType.specs.inheritView || valueType.isRoot) {
            return _jsx(_Fragment, { children: this._renderWidget(props) });
        }
        return _jsx(ObjectField, { children: this._renderWidget(props), ...props });
    }
}
export class BaseFormType extends BaseObjectType {
    _createValue(props) {
        return super._createValue(props);
    }
    _getSpecs() {
        return ObjectValueType.define({
            shape: () => this.buildFields(),
            defaultOptions: () => {
                return {
                    getResolvedValue: (v, t) => this.getResolvedValue(v, t),
                    ...this.getDefaultOptions(),
                };
            },
        });
    }
    getDefaultOptions() {
        return {};
    }
    getResolvedValue(value, valueType) {
        return value;
    }
}
export class BaseFormTypeOld extends BaseObjectType {
    _createValue(props) {
        return super._createValue(props);
    }
    _getSpecs() {
        const prev = this.__getSpecsProps();
        return ObjectValueType.define({
            shape: () => this.buildFields(),
            defaultOptions: () => {
                const opts = prev?.defaultOptions?.();
                return {
                    getResolvedValue: (v, t) => this.getResolvedValue(v, t),
                    ...opts,
                };
            },
        });
    }
    __getSpecsProps(props) {
        return { ...props };
    }
    getResolvedValue(value, valueType) {
        return value;
    }
    _renderWidget(props) {
        return super._renderWidget(props);
    }
}
