import { jsx as _jsx } from "react/jsx-runtime";
import { useViewType } from '@f2w/view';
import * as DebitorServices from 'Pages/Debitor/services/debitorServices';
import { DebitorType } from 'Pages/Debitor/components/DebitorView/forms/DebitorType';
import { translate } from 'Services/Translator';
import { GeneralTab } from 'Pages/Debitor/components/DebitorView/components/GeneralTab';
import { FormError } from '@f2w/form';
import { DebitorState } from 'Pages/Debitor/enums';
import { DebitorTemplate } from '../components/DebitorTemplate';
import moment from 'moment/moment';
import { DocumentsTab } from 'Pages/Debitor/components/DebitorView/components/DocumentsTab';
import { PagePreview } from "Pages/DocumentTemplates/EditV2/PagePreview";
import { renderTemplate } from "Services/HtmlTemplateRenderer";
export const useDebitorView = ({ getDebitorInvoiceDetails: loadData = DebitorServices.getDebitorInvoiceDetails, updateDebitorInvoiceGeneralData: updateDebitorInvoiceGeneralData = DebitorServices.updateDebitorInvoiceGeneralData, }) => useViewType(() => new DebitorType(), {
    id: 'debitor-view',
    defaultState: {
        leftActiveTab: 'general',
        rightActiveTab: 'documents'
    },
    DefaultTemplate: props => _jsx(DebitorTemplate, { ...props }),
    load: async (id) => {
        const data = await loadData(id);
        return {
            id,
            payment: {
                label: data.label,
                amount: data.amountRemaining,
                paidAt: moment().format('YYYY-MM-DD')
            },
            ...data,
            ...data.conditions,
            recipientBankAccountId: data.conditions?.recipientBankAccount?.id,
            rentalObjectId: data.rentalObject?.id
        };
    },
    saveError: (error) => {
        if (!('response' in error)) {
            return new Error(error);
        }
        const { response: { data, status } } = error;
        if (status === 422 || data?.message) {
            return new FormError(data.message);
        }
        return new FormError(data);
    },
    save: async () => { },
}, () => {
    const sections = {
        general: {
            name: translate('debitor.details.general'),
            Content: (props) => _jsx(GeneralTab, { ...props }),
            Form: ({ type }) => type.renderChild('general'),
            hasForm: ({ data }) => data.state === DebitorState.DRAFT,
            save: async (values, view) => {
                const data = view.type.getChild('general').getResolvedValue();
                data.positions = data.positions
                    .filter(position => !!position && position.amount !== undefined)
                    .map(position => ({
                    ...position,
                    amount: Number(position.amount)
                }));
                return updateDebitorInvoiceGeneralData(values.id, data)
                    .then(response => {
                    view.updateState({ rightActiveTab: 'documents' });
                    view.updateData({
                        ...data,
                        persons: [...response.persons],
                        rentalObject: { ...response.rentalObject },
                        label: response.label,
                        amount: response.amount,
                        amountRemaining: response.amountRemaining,
                    });
                });
            }
        },
        documents: {
            name: translate('debitor.details.documents'),
            Content: (props) => _jsx(DocumentsTab, { ...props }),
        },
        preview: {
            name: translate('debitor.details.preview'),
            Content: (props) => {
                const preview = props.view.data.documentPreview;
                const html = renderTemplate(preview.content, preview.vars);
                return _jsx(PagePreview, { html: html, stylesheets: props.view.data.previewStylesheets });
            }
        },
    };
    return Object.fromEntries(Object.entries(sections).filter(([key, value]) => value !== undefined));
});
