import DocumentTemplateServices from "Pages/DocumentTemplates/services/documentTemplateServices";
export function downloadFile(blob, fileName) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName ? decodeURI(fileName) : '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}
export async function downloadFileFromUrl(props) {
    const { onSuccess = undefined, onError = undefined, onStart, onFinish, ...options } = props;
    let { url, fileName } = options;
    onStart?.(options);
    return fetch(url, {
        method: 'GET',
    })
        .then((res) => {
        if (!res.ok)
            return Promise.reject('Something Went Wrong');
        const disposition = res.headers.get('content-disposition');
        fileName = fileName
            ? fileName
            : DocumentTemplateServices.getFileName(disposition);
        return res.blob();
    })
        .then((blob) => {
        downloadFile(blob, fileName);
        onSuccess?.(options);
    })
        .catch((err) => {
        onError?.(err, options);
    })
        .finally(() => {
        setTimeout(() => onFinish?.(options), 300);
    });
}
