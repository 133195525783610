import { setDebugVariable } from '@f2w/utils';
import client from 'Utils/client';
import { generate } from 'Services/Router';
import { ChatStore } from './ChatStore';
import { ChatView } from 'Components/ChatBot/renderer';
export class ChatApi {
    constructor(props) {
        const _store = new ChatStore(props);
        Object.defineProperties(this, {
            _props: {
                value: props,
                writable: false,
                enumerable: false,
            },
            _service: {
                value: props?.service ?? ChatApiServices,
                writable: false,
                enumerable: false,
            },
            history: {
                value: _store,
                writable: false,
                enumerable: false,
            },
            chat: {
                get: () => this._core,
                enumerable: false,
            },
            sessionId: {
                get: () => _store.data.sessionId,
                enumerable: true,
            },
            isExpired: {
                get: () => _store.isExpired,
                enumerable: false,
            },
            storeData: {
                get: () => _store.data,
                enumerable: true,
            },
        });
        setDebugVariable({ chatApi: this });
    }
    static create(props) {
        return new this({ ...props });
    }
    ref = (v) => {
        this._core = v;
    };
    getMessages() {
        return this.chat?.getMessages() ?? [];
    }
    addMessage(message, isUpdate) {
        this.chat?.addMessage(message, isUpdate);
        return this;
    }
    getHistoryMessages() {
        return this.history?.getMessages();
    }
    addHistoryMessage(message) {
        this.history.addMessage(message);
        return this;
    }
    async sendMessage(message) {
        const response = await this._service.sendMessage(this.sessionId, message);
        this.history.update({ updateTimestamp: Date.now() });
        return { role: 'api', ...this._renderMessages(response) };
    }
    _renderMessages(response) {
        return this._props.renderMessage
            ? this._props.renderMessage?.(response, this)
            : { text: response.message };
    }
    addFeedbackMessage(message) {
        return this.addMessage({
            role: 'feedback',
            html: message,
            overwrite: true
        });
    }
    isFeedbackVisible = false;
    showFeedback() {
        if (this.isFeedbackVisible)
            return this;
        this.isFeedbackVisible = true;
        this.addFeedbackMessage(ChatView.renderFeedbackMessage(true));
        return this;
    }
    saveFeedback(score) {
        const data = this.history.read();
        if (!data?.sessionId || !data?.updateTimestamp) {
            console.debug('-- FEEDBACK ERROR -- no message sent');
            return;
        }
        if (!(score in FeedbackScore)) {
            console.debug('-- FEEDBACK ERROR -- score not valid');
            return;
        }
        this.addFeedbackMessage(ChatView.renderFeedbackMessage(false));
        return this._service.sendFeedback(data.sessionId, score)
            .then(() => {
            this.history.update({ feedback: score });
        })
            .catch(error => {
            console.error(error);
        })
            .finally(() => {
            this.isFeedbackVisible = false;
        });
    }
}
export var FeedbackScore;
(function (FeedbackScore) {
    FeedbackScore[FeedbackScore["GOOD"] = 2] = "GOOD";
    FeedbackScore[FeedbackScore["BAD"] = 0] = "BAD";
})(FeedbackScore || (FeedbackScore = {}));
export const ChatApiServices = {
    async sendFeedback(sessionId, score) {
        return client
            .post(generate('api.chat.feedback'), { sessionId, score })
            .then(response => response.data);
    },
    async sendMessage(sessionId, message) {
        return !message
            ? undefined
            : client
                .post(generate('api.chat.send'), { sessionId, message })
                .then(response => response.data);
    },
};
