import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Header, Section } from 'Atoms/Layout/components';
import { Badge, Button, Div, FlexCol, FlexRow, Title } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { AccountingRecordTable } from './DebitorAccountingRecordsTable';
import { PaymentTable } from './DebitorPaymentsTable';
import * as DebitorServices from 'Pages/Debitor/services/debitorServices';
import { ManualPaymentFormContentBlock, } from 'Pages/Debitor/components/DebitorView/components/ManualPaymentFormContentBlock';
import { Card } from 'Atoms/Card';
export const GeneralTab = ({ group: { data: debitorInvoice }, view, }) => {
    const [formOpen, setFormOpen] = React.useState(false);
    const refreshDetails = React.useCallback(async () => {
        await setFormOpen(false);
        await view.load(debitorInvoice.id);
    }, []);
    if (formOpen) {
        return (_jsx(Section, { children: _jsx(ManualPaymentFormContentBlock, { createManualPayment: DebitorServices.createManualPayment, debitorId: debitorInvoice.id, payerBankAccountId: debitorInvoice.payerBankAccountId, debitorLabel: debitorInvoice.label, debitorAmountRemaining: debitorInvoice.amountRemaining, financialAccountId: debitorInvoice.payerFinancialAccountId, closeForm: refreshDetails }) }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(DebitorInfoSection, { data: debitorInvoice }), _jsx(Header.Sticky, { children: _jsx(Title, { h6: true, as: 'h3', children: translate('debitor.accounting') }) }), _jsx(Section.DataTable, { filter: "inline", size: 'xs', use: () => AccountingRecordTable.useCreateTable({
                    data: debitorInvoice.accountingRecords,
                }) }), _jsxs(Header.Sticky, { children: [_jsx(Title, { h6: true, as: 'h3', children: translate('debitor.payments') }), _jsx(Div, { children: _jsx(Button.Edit, { icon: 'plus', label: translate('payment.add'), onClick: () => setFormOpen(true) }) })] }), _jsx(Section.DataTable, { filter: "inline", size: 'xs', use: () => PaymentTable.useCreateTable({
                    data: debitorInvoice.payments,
                    onPaymentDelete: () => refreshDetails?.(),
                    deletePayment: async (id) => {
                        return DebitorServices.deleteDebitorPayment?.(debitorInvoice.id, id);
                    },
                }) })] }));
};
export const DebitorInfoSection = ({ data: debitorInvoice, }) => {
    return (_jsx(Section, { sticky: true, children: _jsxs(FlexCol, { gap: 16, children: [_jsx(Card, { children: _jsxs(Card.Value, { title: translate('debitor.details.rentalObject'), children: [debitorInvoice.rentalObject.name, "\u00A0 (", translate(`debitor.details.rentalObject.${debitorInvoice.rentalObject.occupancyStatus.toLowerCase()}`), ")"] }) }), _jsx(Card, { children: _jsx(Card.BankInfo, { title: translate('debitor.details.recipientBankAccount'), label: debitorInvoice.conditions.recipientBankAccount?.label, iban: debitorInvoice.conditions.recipientBankAccount?.iban }) }), _jsxs(FlexRow, { gap: 16, children: [_jsx(Card, { children: _jsx(Card.Content, { title: translate('debitor.details.persons'), children: debitorInvoice.persons.map(person => (_jsxs(FlexRow, { gap: 10, alignCenter: true, children: [person.name, person.isPrimary && _jsx(Badge, { "$color": "primary", "$size": 'xs', children: translate('debitor.details.primaryContact') }), person.useInAddress && _jsx(Badge, { "$color": "secondary", "$size": 'xs', children: translate('contract.party.useInAddress.tag') })] }, person.id))) }) }), _jsx(Card, { children: _jsx(Card.Address, { title: translate('debitor.details.primaryPersonAddress'), street: debitorInvoice.primaryContactAddress?.street, streetNr: debitorInvoice.primaryContactAddress?.houseNumber, city: debitorInvoice.primaryContactAddress?.city, zip: debitorInvoice.primaryContactAddress?.zip, countryCode: debitorInvoice.primaryContactAddress?.country }) })] })] }) }));
};
