import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { DocumentTemplateDataTable } from 'Pages/DocumentTemplates/List/table/DocumentTemplateDataTable';
import DocumentTemplateServices from 'Pages/DocumentTemplates/services/documentTemplateServices';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import { CloneTemplateModal } from 'Pages/DocumentTemplates/View/Components/CloneTemplateModal';
import { useModalDispatcherConfirm, useToastDispatcherApi } from 'Components/Dispatcher';
import { getGlobalContext } from 'Services/GlobalContext';
import { Tier } from 'Pages/Administration/Customers/enums';
import { LockScreen } from 'Atoms/LockScreen';
export const DocumentTemplatesListPage = ({ documentTemplateServices = DocumentTemplateServices }) => {
    const [templateToClone, setTemplateToClone] = React.useState();
    const openCloneModal = React.useCallback((templateToClone) => setTemplateToClone(templateToClone), []);
    const { error } = useToastDispatcherApi();
    const onDeleteConfirm = useModalDispatcherConfirm({
        onConfirm: async (id) => {
            try {
                DocumentTemplateServices
                    .deleteMandateDocumentTemplate(id)
                    .then(() => {
                    table.refresh();
                });
            }
            catch (e) {
                const message = e.response.status === 422 ? e.response.data.message : 'form.response.error';
                error({
                    message: translate(message),
                    timeout: 5,
                    showRemove: true
                });
            }
            finally {
                table.refresh();
            }
        },
        message: translate('documentTemplate.delete.message'),
        help: translate('documentTemplate.delete.help'),
    });
    const onResetToDefaultConfirm = useModalDispatcherConfirm({
        onConfirm: async (id) => {
            try {
                DocumentTemplateServices
                    .deleteMandateDocumentTemplate(id)
                    .then(() => {
                    table.refresh();
                });
            }
            catch (e) {
                const message = e.response.status === 422 ? e.response.data.message : 'form.response.error';
                error({
                    message: translate(message),
                    timeout: 5,
                    showRemove: true
                });
            }
            finally {
                table.refresh();
            }
        },
        message: translate('documentTemplate.resetToDefault.message'),
        help: translate('documentTemplate.resetToDefault.help'),
        btnConfirm: translate('documentTemplate.resetToDefault.confirm')
    });
    const table = DocumentTemplateDataTable.use({
        onRefresh() { },
        createNewDocument(type) {
            documentTemplateServices.createDocumentTemplate(type)
                .then(({ id }) => {
                window.location.href = generateRoute('documentTemplate.editv2', { id });
            });
        },
        openSettingsForm() {
            window.location.href = generateRoute('documentTemplate.globalSettings');
        },
        openDocumentView: (instanceKey, id) => {
            window.location.href = generateRoute('documentTemplate.editv2', { id: id || instanceKey });
        },
        openCloneModal: (instanceKey, id, caption) => {
            openCloneModal({ id, templateInstanceKey: instanceKey, caption });
        },
        onDelete: onDeleteConfirm,
        onResetToDefault: onResetToDefaultConfirm,
        documentTemplateServices: documentTemplateServices
    });
    const onModalClose = React.useCallback(() => setTemplateToClone(undefined), []);
    const onCloneSuccess = React.useCallback((newTemplate) => {
        table.refresh();
        onModalClose();
    }, []);
    const locked = [Tier.PRIVATE].includes(getGlobalContext()?.customer?.currentSubscriptionTier) && translate('featureLocked.templateManagement.text');
    return (_jsxs(_Fragment, { children: [locked && _jsx(LockScreen.Page, { message: translate('featureLocked.templateManagement.text') }), _jsx(PageOverview, { instance: table, title: translate('templates'), inlineFilters: true }), _jsx(CloneTemplateModal, { onClose: onModalClose, onCloneSuccess: onCloneSuccess, templateToClone: templateToClone })] }));
};
