import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import { useTheme } from '@f2w/theme';
import { IconRoot } from './BaseIcon';
import getFirstLetters from 'Utils/getFirstLetters';
export const AvatarIcon = forwardRef(({ size = 24, color = '#666', bg, text, initials = text, ...props }, ref) => {
    let children = useMemo(() => getFirstLetters(initials), [initials]);
    const theme = useTheme();
    return _jsx(IconRoot, { ...props, viewBox: "0 0 24 24", style: { fontSize: size, borderRadius: '.5em', width: '1em', height: '1em' }, children: _jsx("text", { fill: color, fontSize: "12px", textAnchor: "middle", fontFamily: theme.typography.fontFamily, x: "12", y: "16", children: children }) });
});
