import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BaseFormTypeOld, BooleanType, DateType, DateValueType, MoneyType, NumberType, SelectType, StringType, TextType, DecimalType } from '@f2w/form-new';
import Portfolio from 'Models/Portfolio';
import { translate } from 'Services/App';
import { useMemo } from 'react';
import { Div, FlexCol } from 'Atoms/Layout';
import { roundToNearestPointX } from 'Utils/number';
import moment from 'moment/moment';
import { RentChangeTemplate, RentStepTemplate, SelectionStepTemplate, SummaryStepTemplate, } from '../components/ContractModificationWizardTemplate';
import { toNum } from '@f2w/utils';
import contractServices from '../services/contractServices';
export var ContractModificationWizardType;
(function (ContractModificationWizardType) {
    ContractModificationWizardType.roundDifferenceOptions = [
        { label: '5.00', value: 5.00 },
        { label: '1.00', value: 1.00 },
        { label: '0.05', value: 0.05 },
        { label: '0.01', value: 0.01 },
    ];
    const getRoot = (typeValue) => typeValue.root()?.children;
    ContractModificationWizardType.getSelectionStep = (typeValue) => getRoot(typeValue)?.selectionStep;
    ContractModificationWizardType.getSelectionStepData = (typeValue) => ContractModificationWizardType.getSelectionStep(typeValue).value;
    ContractModificationWizardType.getRentStep = (typeValue) => getRoot(typeValue)?.rentStep;
    ContractModificationWizardType.getRentStepData = (typeValue) => ContractModificationWizardType.getRentStep(typeValue).value;
    class SelectionStep extends BaseFormTypeOld {
        async loadData(contractId) {
            try {
                return (contractId && await contractServices.getRentModificationStepDate(contractId)) || undefined;
            }
            catch (error) {
                console.error('getRentModificationStepDate', error);
            }
        }
        buildFields() {
            const portfolio = Portfolio.instance();
            return {
                propertyId: SelectType.number({
                    label: translate('property'),
                    required: true,
                    choices: () => {
                        return portfolio.getProperties().map(property => ({
                            label: property.name,
                            value: property.id,
                        }));
                    },
                    onChange: (v) => {
                        ContractModificationWizardType.getSelectionStep(v).children.buildingId.updateValue(null, true);
                    },
                }),
                buildingId: SelectType.number({
                    label: translate('building.title'),
                    required: true,
                    choices: (v) => {
                        const id = ContractModificationWizardType.getSelectionStepData(v).propertyId;
                        return useMemo(() => portfolio.getBuildingsByPropertyId(id).map(data => ({
                            value: data.id, label: data.name,
                        })), [id]);
                    },
                    onChange: (v) => {
                        ContractModificationWizardType.getSelectionStep(v).children.rentalObjectId.updateValue(null, true);
                    },
                }),
                rentalObjectId: SelectType.number({
                    label: translate('rentalObject'),
                    required: true,
                    choices: (v) => {
                        const id = ContractModificationWizardType.getSelectionStepData(v).buildingId;
                        return useMemo(() => portfolio.getRentalObjectsByBuildingId(id).map(data => ({
                            value: data.id, label: data.name,
                        })), [id]);
                    },
                    onChange: async (v, { initial }) => {
                        ContractModificationWizardType.getSelectionStep(v).children.contractId.updateValue(null, true);
                    },
                }),
                contractId: SelectType.number({
                    label: translate('contract'),
                    required: true,
                    choices: (v) => {
                        const id = ContractModificationWizardType.getSelectionStepData(v).rentalObjectId;
                        return useMemo(() => portfolio.getAllContractsByRentalObjectId(id).map(data => ({
                            value: data.id, label: data.label,
                        })), [id]);
                    },
                    onChange: (v) => {
                        this.loadData(v.value)
                            .then(settings => {
                            const _step = ContractModificationWizardType.getSelectionStep(v);
                            const _validFrom = _step.children.validFrom;
                            _step.extra.settings = settings;
                            _step.extra.selectedContract = portfolio.getContractById(v.value);
                            _validFrom.resetOptions({ disabled: !settings, min: settings?.minimumDate, max: settings?.maximumDate }, true);
                            _validFrom.updateValue(DateValueType.parse(settings?.suggestedDate) || null, true);
                        });
                    },
                }),
                validFrom: new DateType({
                    label: translate('contract.validFrom'),
                    required: true,
                    help: translate('contract.validFrom.help'),
                    helperText: (v) => {
                        const data = ContractModificationWizardType.getSelectionStepData(v).settings;
                        const nextTerminationDate = DateValueType.parse(data?.nextTerminationDate);
                        const latestRentChangeDate = DateValueType.parse(data?.latestRentChangeDate);
                        return [
                            nextTerminationDate && translate('contract.validFrom.helperText', { date: DateValueType.format(nextTerminationDate) }),
                            latestRentChangeDate && translate('contract.validFrom.helperText2', { date: DateValueType.format(latestRentChangeDate) }),
                        ].filter(v => !!v).join(' ');
                    },
                }),
                netRentChanges: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.rentChanges'),
                    help: translate('contract.rentChanges.help'),
                    required: false,
                    defaultValue: true,
                    onChange: (v) => {
                        if (!v.value) {
                            const { ancillaryExpenseChanges, otherChanges } = ContractModificationWizardType.getSelectionStepData(v);
                            if (!ancillaryExpenseChanges && !otherChanges)
                                v.updateValue(true, true);
                        }
                    },
                }),
                ancillaryExpenseChanges: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.ancillaryExpenseChanges'),
                    required: false,
                    disabled: true,
                    onChange: (v) => {
                        if (!v.value) {
                            const { netRentChanges, otherChanges } = ContractModificationWizardType.getSelectionStepData(v);
                            if (!netRentChanges && !otherChanges)
                                v.updateValue(true, true);
                        }
                    },
                }),
                otherChanges: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.otherChanges'),
                    required: false,
                    disabled: true,
                    onChange: (v) => {
                        if (!v.value) {
                            const { netRentChanges, ancillaryExpenseChanges } = ContractModificationWizardType.getSelectionStepData(v);
                            if (!netRentChanges && !ancillaryExpenseChanges)
                                v.updateValue(true, true, false);
                        }
                    },
                }),
            };
        }
        _renderWidget(props) {
            return _jsx(SelectionStepTemplate, { ...props });
        }
    }
    ContractModificationWizardType.SelectionStep = SelectionStep;
    class RentStep extends BaseFormTypeOld {
        async loadData(contractId, date) {
            if (contractId) {
                const handler = this.specs.options.loadRentPotential ?? contractServices.getRentModificationStepRent;
                const data = await handler(contractId, date);
                if (data)
                    return data;
            }
        }
        async onLoad(v, contractId) {
            const _data = ContractModificationWizardType.getRentStepData(v);
            if (_data.rentPotential?.contractId && _data.rentPotential?.contractId == contractId) {
                return;
            }
            return this.loadData(contractId)
                .then((settings) => {
                this._onLoad(v, settings);
            })
                .catch(error => {
                console.error('RentStep', error);
            });
        }
        _onLoad(v, settings) {
            const _step = ContractModificationWizardType.getRentStep(v);
            const _useRentPotential = _step.children.useRentPotential;
            const _rentPotentialDate = _step.children.rentPotentialDate;
            Object.defineProperties(_step.extra, {
                settings: {
                    value: settings,
                    enumerable: false,
                    configurable: true,
                },
                rentPotential: {
                    get: () => (_step.extra.settings?.rentPotential) || undefined,
                    enumerable: true,
                    configurable: true,
                },
                currentRent: {
                    get: () => _step.extra.settings?.currentRent,
                    enumerable: true,
                    configurable: true
                },
            });
            _rentPotentialDate.resetOptions({
                min: settings?.minRateDate,
                max: settings?.maxRateDate,
            }, true);
            const rentPotentialDate = moment(), maxDate = _rentPotentialDate.specs.maxDate;
            const useRentPotential = !!settings.rentPotential;
            _useRentPotential.resetOptions({ disabled: !useRentPotential }, true);
            const intitialData = {
                rentPotentialDate: rentPotentialDate.isBefore(maxDate) ? rentPotentialDate.toDate() : maxDate,
                useRentPotential: useRentPotential,
                adjustmentAmount: RentStep.getAdjustmentAmount(_step),
                reason: RentStep.getReasonText(_step),
            };
            _step.reset(intitialData);
        }
        buildFields() {
            const isRentPotentialEnabled = (v) => {
                const useRentPotential = ContractModificationWizardType.getRentStep(v).children.useRentPotential;
                return useRentPotential.value && !useRentPotential.isDisabled;
            };
            return {
                useRentPotential: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.modification.useRentPotential'),
                    required: false,
                    defaultValue: false,
                    onChange: (v) => {
                        RentStep.updateAdjustment(v, !v.value);
                    },
                    help: (v) => {
                        const rentPotential = ContractModificationWizardType.getRentStepData(v).rentPotential;
                        if (rentPotential)
                            return translate('contract.modification.useRentPotential.help');
                        return (_jsxs(FlexCol, { justifyFlexStart: true, children: [_jsx(Div, { style: { textAlign: 'start' }, children: translate('contract.modification.useRentPotential.dataMissing.help') }), _jsxs("ul", { children: [_jsx("li", { style: { textAlign: 'start' }, children: translate('contract.modification.useRentPotential.help.lastReferenceInterestRate') }), _jsx("li", { style: { textAlign: 'start' }, children: translate('contract.modification.useRentPotential.help.consumerPriceIndexBase') }), _jsx("li", { style: { textAlign: 'start' }, children: translate('contract.modification.useRentPotential.help.finalCostCalculation') })] })] }));
                    },
                }),
                rentPotentialDate: new DateType({
                    label: translate('contract.modification.rentPotentialDate'),
                    required: false,
                    type: 'month',
                    format: 'MM.yyyy',
                    jsonFormat: 'YYYY-MM-DD',
                    isVisible: isRentPotentialEnabled,
                    onChange: async (v, { initial }) => {
                        const _rent = ContractModificationWizardType.getRentStep(v);
                        if (!initial && _rent.extra.rentPotential) {
                            const contractId = ContractModificationWizardType.getSelectionStepData(v).contractId;
                            const value = v.formattedValue;
                            this.loadData(contractId, value)
                                .then((settings) => {
                                _rent.extra.settings = settings;
                                RentStep.updateAdjustment(v);
                            })
                                .catch((e) => {
                                if (e.isAxiosError && e.response?.status === 400) {
                                    v.setError(e.response.data.message);
                                    return;
                                }
                                console.error(e);
                            });
                        }
                    },
                }),
                useChangeOfReferenceInterest: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.rent.changeOfReferenceInterest'),
                    defaultValue: true,
                    isVisible: isRentPotentialEnabled,
                    onChange: v => RentStep.updateAdjustment(v),
                    Widget: ({ typeValue, children }) => {
                        const potential = ContractModificationWizardType.getRentStepData(typeValue).rentPotential;
                        return _jsx(RentChangeTemplate, { isActive: !!typeValue.value, current: _jsxs(_Fragment, { children: [moment(potential.currentRateDate)
                                        .format('MM.YYYY'), ": ", potential.currentReferenceInterest, "%"] }), potential: _jsxs(_Fragment, { children: [moment(potential.potentialRateDate)
                                        .format('MM.YYYY'), ": ", potential.possibleReferenceInterest, "%"] }), possible: {
                                percent: potential.possibleReferenceInterestPercent,
                                amount: potential.possibleReferenceInterestAmount,
                            }, children: children });
                    },
                }),
                useInflationIndexChange: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.rent.inflationIndexChange'),
                    defaultValue: true,
                    isVisible: isRentPotentialEnabled,
                    onChange: v => RentStep.updateAdjustment(v),
                    Widget: ({ typeValue, children }) => {
                        const potential = ContractModificationWizardType.getRentStepData(typeValue).rentPotential;
                        return _jsx(RentChangeTemplate, { isActive: !!typeValue.value, current: _jsxs(_Fragment, { children: [moment(potential.currentInflationRateDate)
                                        .format('MM.YYYY'), ": ", potential.currentInflationPoints, "%"] }), potential: _jsxs(_Fragment, { children: [moment(potential.potentialInflationRateDate)
                                        .format('MM.YYYY'), ": ", potential.possibleInflationPoints, "%"] }), possible: {
                                percent: potential.possibleInflationPercent,
                                amount: potential.possibleInflationAmount,
                            }, children: children });
                    },
                }),
                useCostIncreaseChange: new BooleanType({
                    hideLabel: true,
                    content: (typeValue) => {
                        const label = DecimalType.format(ContractModificationWizardType.getRentStepData(typeValue).rentPotential.yearlyFlatRatePercent);
                        return (_jsxs(_Fragment, { children: [translate('rent-potential-calculator.cost-increase'), ": ", label, "% ", translate('perYear')] }));
                    },
                    defaultValue: true,
                    isVisible: isRentPotentialEnabled,
                    onChange: v => RentStep.updateAdjustment(v),
                    Widget: ({ typeValue, children }) => {
                        const potential = ContractModificationWizardType.getRentStepData(typeValue).rentPotential;
                        return _jsx(RentChangeTemplate, { isActive: !!typeValue.value, current: moment(potential.currentCostSettlementDate).format('MM.YYYY'), potential: moment(potential.potentialCostSettlementDate).format('MM.YYYY'), possible: {
                                percent: potential.costIncreasePercent,
                                amount: potential.costIncreaseAmount,
                            }, children: children });
                    },
                }),
                adjustmentAmount: new MoneyType({
                    hideLabel: true,
                    required: true,
                    defaultValue: 0,
                }),
                roundDifference: new NumberType({
                    required: true,
                    defaultValue: 5.00,
                }),
                reason: new TextType({
                    label: translate('rentModification.explanation'),
                    required: true,
                }),
                reservation: new StringType({
                    label: translate('rent.reservation'),
                    required: false,
                }),
                hasSubsidies: new BooleanType({
                    required: false,
                    defaultValue: false,
                }),
            };
        }
        _renderWidget(props) {
            return _jsx(RentStepTemplate, { ...props });
        }
    }
    ContractModificationWizardType.RentStep = RentStep;
    (function (RentStep) {
        function updateAdjustment(v, reset) {
            const _rent = ContractModificationWizardType.getRentStep(v);
            _rent.updateValue(reset ? {
                adjustmentAmount: 0,
                reason: null,
            } : {
                adjustmentAmount: getAdjustmentAmount(_rent),
                reason: getReasonText(_rent),
            }, true);
        }
        RentStep.updateAdjustment = updateAdjustment;
        function getSubtotal(v) {
            const data = ContractModificationWizardType.getRentStepData(v);
            const potential = data.rentPotential;
            const subtotal = { amount: 0, percent: 0 };
            if (potential) {
                [
                    {
                        active: data.useChangeOfReferenceInterest,
                        amount: potential.possibleReferenceInterestAmount,
                        percent: potential.possibleReferenceInterestPercent,
                    },
                    {
                        active: data.useInflationIndexChange,
                        amount: potential.possibleInflationAmount,
                        percent: potential.possibleInflationPercent,
                    },
                    {
                        active: data.useCostIncreaseChange,
                        amount: potential.costIncreaseAmount,
                        percent: potential.costIncreasePercent,
                    },
                ].forEach(option => {
                    if (option.active) {
                        subtotal.amount += toNum(option.amount);
                        subtotal.percent += toNum(option.percent);
                    }
                });
            }
            return subtotal;
        }
        RentStep.getSubtotal = getSubtotal;
        function getAdjustmentAmount(v) {
            const data = ContractModificationWizardType.getRentStepData(v);
            const potential = data.rentPotential;
            if (potential) {
                const { useChangeOfReferenceInterest, useInflationIndexChange, useCostIncreaseChange } = data;
                const amount = (useChangeOfReferenceInterest && useInflationIndexChange && useCostIncreaseChange)
                    ? potential.totalAmount
                    : RentStep.getSubtotal(v)?.amount;
                return roundToNearestPointX(amount, data.roundDifference);
            }
            return 0;
        }
        RentStep.getAdjustmentAmount = getAdjustmentAmount;
        function getReasonText(v) {
            const data = ContractModificationWizardType.getRentStepData(v);
            const potential = data.rentPotential;
            if (potential) {
                const reasons = [
                    {
                        active: data.useChangeOfReferenceInterest,
                        text: translate('contractModification.reason.referenceIndexRate', {
                            oldReferenceInterest: `${potential.currentReferenceInterest}%`,
                            newReferenceInterest: `${potential.possibleReferenceInterest}%`,
                            interestChangeInPercent: `${DecimalType.format(potential.possibleReferenceInterestPercent)}%`,
                        }),
                    },
                    {
                        active: data.useInflationIndexChange,
                        text: translate('contractModification.reason.consumerPriceIndex', {
                            oldIndex: potential.currentInflationPoints,
                            newIndex: potential.possibleInflationPoints,
                            indexChangeInPercent: `${DecimalType.format(potential.possibleInflationPercent)}%`,
                        }),
                    },
                    {
                        active: data.useCostIncreaseChange,
                        text: translate('contractModification.reason.costIncrease', {
                            oldLastSettlement: DateValueType.format(potential.currentRateDate),
                            newLastSettlement: DateValueType.format(potential.potentialRateDate),
                            flatRate: potential.yearlyFlatRatePercent,
                            costIncreasePercent: `${DecimalType.format(potential.costIncreasePercent)}%`,
                        }),
                    },
                ]
                    .filter(option => option.active)
                    .map((option) => option.text);
                if (reasons.length === 1)
                    return translate('contractModification.oneReason', { reason: reasons[0] });
                if (reasons.length === 2)
                    return translate('contractModification.twoReasons', { reasonOne: reasons[0], reasonTwo: reasons[1] });
                if (reasons.length === 3)
                    return translate('contractModification.allReasons', { reasonOne: reasons[0], reasonTwo: reasons[1], reasonThree: reasons[2] });
            }
            return '';
        }
        RentStep.getReasonText = getReasonText;
    })(RentStep = ContractModificationWizardType.RentStep || (ContractModificationWizardType.RentStep = {}));
    class SummaryStep extends BaseFormTypeOld {
        buildFields() {
            return {};
        }
        _renderWidget(props) {
            return _jsx(SummaryStepTemplate, { ...props });
        }
    }
    ContractModificationWizardType.SummaryStep = SummaryStep;
})(ContractModificationWizardType || (ContractModificationWizardType = {}));
