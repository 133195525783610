import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { useAncillaryExpenseCalculationsTable } from '../../table';
import { CalculationServices } from '../../index';
import { FormModal } from "@f2w/form-new";
import { CreateDraftCalculationType } from "Pages/AncillaryExpense/types/CreateDraftCalculationType";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { getGlobalContext } from "Services/GlobalContext";
import { translate } from "Services/App";
import { generate } from "Services/Router";
import moment from "moment";
export const CalculationsTab = ({ calculationServices = CalculationServices }) => {
    const { openViewModal } = useObjectViewModalContext();
    const { activeView } = useObjectViewModalContext();
    const initialLoad = React.useRef(true);
    React.useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        if (!activeView) {
            instance.refresh();
        }
    }, [activeView]);
    const modalRef = FormModal.useRef();
    const { instance } = useAncillaryExpenseCalculationsTable({
        onPropertyClick: openViewModal(ActiveView.PROPERTY),
        onCreateCalculation: () => modalRef.current.open({}),
        calculationServices,
    });
    const portfolio = usePortfolio();
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, inlineFilters: true }), _jsx(FormModal, { apiRef: modalRef, use: () => {
                    const { scope } = getGlobalContext();
                    let propertyId;
                    switch (scope?.level) {
                        case "property": {
                            propertyId = scope.id;
                            break;
                        }
                        case "rentalObject": {
                            propertyId = portfolio.getPropertyByRentalObjectId(scope.id).id;
                        }
                    }
                    const valueType = CreateDraftCalculationType.useValue({
                        initialValue: {
                            propertyId: propertyId
                        }
                    });
                    return {
                        title: translate('ancillaryExpenseCalculation.add'),
                        valueType,
                        onSave: async () => {
                            const values = valueType.getResolvedValue();
                            const data = {
                                propertyId: values.propertyId,
                                period: {
                                    start: moment(values.start).startOf('month').format('YYYY-MM-DD'),
                                    end: moment(values.end).endOf('month').format('YYYY-MM-DD')
                                }
                            };
                            return calculationServices.createCalculationDraft(data)
                                .then(({ id }) => {
                                window.location.href = generate('ancillaryExpenses.calculation.wizard', {
                                    calculation: id
                                });
                            });
                        }
                    };
                } })] }));
};
