import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useRef, useState } from 'react';
import { translate } from 'Services/Translator';
import styled from 'styled-components';
import { isString, OrFunction, pick, toObject } from '@f2w/utils';
import { useDispatcherApi } from 'Components/Dispatcher';
import { Modal, ModalBody, ModalHeader } from 'Components/Modal';
import { Button, Div, Spinner } from 'Components/Atoms';
import { FormError, ValidationError } from 'Utils/client';
function useAssignedRef(value, parentRef) {
    const valueRef = parentRef ?? useRef(value);
    valueRef.current = value;
    return valueRef;
}
const ModalViewContext = React.createContext(Object.create(null));
export const useModalViewContext = () => {
    return React.useContext(ModalViewContext);
};
function ModalView({ apiRef, size, render, children, ...props }) {
    const api = ModalView.useApi(props);
    useAssignedRef(api, apiRef);
    return _jsx(_Fragment, { children: _jsx(ModalViewContext.Provider, { value: api, children: _jsx(Modal, { show: api.show || api.loading, onHide: () => {
                    api.close();
                }, size: size ?? { maxWidth: '700px', maxHeight: '90vh' }, children: api.loading
                    ? _jsx(Modal.Body, { paddingY: 80, children: _jsx(Spinner, { align: 'center' }) })
                    : _jsx(ContentRenderer, { api: api, render: render, children: children }) }) }) });
}
const ContentRenderer = ({ children, render, api = useModalViewContext(), }) => (_jsx(_Fragment, { children: render ? render({ ...api.props }, api) : children }));
(function (ModalView) {
    function _useApi({ initialShow, confirm: _confirmProps }) {
        const dispatcherApi = useDispatcherApi();
        const [show, setShow] = useState(initialShow ?? false);
        const [loading, setLoading] = useState(false);
        const [activeProps, setActiveProps] = useState({});
        const _doConfirm = useCallback(async () => {
            const props = _confirmProps && OrFunction(_confirmProps, api);
            return !props ? true : await dispatcherApi.modal.confirmAsync({
                message: translate('formModal.confirm.message'),
                help: translate('formModal.confirm.help'),
                btnConfirm: translate('formModal.confirm.btn.confirm'),
                btnCancel: translate('formModal.confirm.btn.cancel'),
                ...(isString(props) ? { message: props } : toObject(props)),
            });
        }, [_confirmProps, activeProps]);
        const api = {
            show,
            loading,
            setLoading,
            open(props) {
                if (!loading) {
                    setActiveProps(props);
                    show || setShow(true);
                }
            },
            async close(confirm = !!_confirmProps) {
                if (show && !loading && (!confirm || (await _doConfirm()))) {
                    setShow(false);
                }
            },
            components,
            props: activeProps,
        };
        return api;
    }
    function useRef() {
        return React.useRef();
    }
    ModalView.useRef = useRef;
    function useApi(props) {
        return _useApi(props);
    }
    ModalView.useApi = useApi;
    ModalView.Header = ({ children, title, ...props }) => (_jsxs(ModalHeader, { ...props, children: [title && _jsx(Modal.Title, { children: OrFunction(title) }), children] }));
    ModalView.Body = (props) => (_jsx(ModalBody, { ...props }));
    ModalView.Footer = (props) => (_jsx(Modal.Footer, { ...props }));
    const components = {
        Header: ModalView.Header,
        Body: ModalView.Body,
        Footer: ModalView.Footer,
        ...pick(Modal, ['Title', 'Divider'])
    };
})(ModalView || (ModalView = {}));
export function FormModal({ use, successMessage, errorMessage, ...options }) {
    return (_jsx(ModalView, { ...options, render: (p, api) => {
            const props = use(p, api);
            const { valueType, title, } = props;
            const { toast } = useDispatcherApi();
            const handleSave = async () => {
                return valueType.submit()
                    .then(async () => {
                    return props?.onSave?.(valueType)
                        .then((onClose) => {
                        successMessage && toast?.success({ message: OrFunction(successMessage, valueType) });
                        setTimeout(() => {
                            onClose && api.close().then(() => {
                                OrFunction(onClose);
                            });
                        }, 300);
                    })
                        .catch(error => {
                        FormError.handleError({
                            error,
                            dispatcher: toast,
                            setError: (e) => valueType.setError(e),
                            setStatus: (e) => toast.error({
                                message: e.error,
                                timeout: 10
                            }),
                            defaultMessage: OrFunction(errorMessage, valueType),
                        });
                        props?.onError?.(valueType);
                    });
                }).catch(error => {
                    if (!(error instanceof ValidationError)) {
                        throw error;
                    }
                });
            };
            return _jsxs(_Fragment, { children: [_jsx(ModalView.Header, { title: title, closeButton: true }), _jsx(ModalView.Body, { children: valueType.render() }), _jsxs(ModalView.Footer, { style: { justifyContent: 'stretch', flexWrap: 'nowrap' }, children: [_jsx(Div, { flex: '1 1 auto' }), _jsxs(Button.Group, { stacked: false, children: [_jsx(Button, { variant: 'link', type: "button", onClick: () => api.close(valueType.meta.dirty), children: translate('btn.close') }), _jsx(Button, { label: translate('btn.save'), onClick: handleSave })] })] })] });
        } }));
}
(function (FormModal) {
    function useRef() {
        return React.useRef();
    }
    FormModal.useRef = useRef;
    FormModal.Actions = ({ children, actions: _actions, ...props }) => {
        const actions = OrFunction(_actions, useModalViewContext());
        return (_jsxs(Modal.Footer, { ...props, children: [actions && _jsx(Button.Group, { children: actions.map((btnProps, i) => (_jsx(Button, { ...btnProps }, `action-${i}`))) }), children] }));
    };
})(FormModal || (FormModal = {}));
const ServerError = styled.div `
  color: var(--state-color, ${p => p.theme.palette.error.$500});
  //margin: 6px 0 0;
`;
