import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, DateType } from '@f2w/form';
import { useEffect } from "react";
import { Col, Row } from "Atoms/Grid";
import moment from "moment";
import { translate } from "Services/Translator";
import { orFunction } from "@f2w/utils";
export class DateRangeType extends AbstractFormType {
    __getDefaultOptions() {
        return {
            type: 'month',
            ...super.__getDefaultOptions(),
        };
    }
    _useField(props) {
        super._useField(props);
        const startDate = this.$.start;
        const endDate = this.$.end;
        useEffect(() => {
            const start = moment(startDate.field.value);
            const end = moment(endDate.field.value);
            if (start.diff(end) > 0) {
                endDate.field.setValue(startDate.field.value);
            }
        }, [startDate.field.value]);
        useEffect(() => {
            const start = moment(startDate.field.value);
            const end = moment(endDate.field.value);
            if (start.diff(end) > 0) {
                startDate.field.setValue(endDate.field.value);
            }
        }, [endDate.field.value]);
    }
    _renderWidget() {
        return _jsxs(Row, { children: [_jsx(Col, { sm: 6, children: this.renderChild('start') }), _jsx(Col, { sm: 6, children: this.renderChild('end') })] });
    }
    buildFields() {
        const endDate = moment().subtract(1, 'month');
        const startDate = moment(endDate).subtract(1, 'year');
        return {
            start: new DateType({
                label: orFunction(this.options.fromDateLabel) ?? translate('fromDate'),
                type: this.options.type,
                required: this.options.required ?? true,
                selectsStart: true,
                defaultValue: () => startDate.toDate(),
            }),
            end: new DateType({
                label: orFunction(this.options.toDateLabel) ?? translate('untilDate'),
                type: this.options.type,
                required: this.options.required ?? true,
                selectsEnd: true,
                defaultValue: () => endDate.toDate(),
            }),
        };
    }
}
