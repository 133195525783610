import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { translate } from 'Services/App';
import { BaseDataServices } from './services';
import { PropertyDataTableType } from './table/PropertyDataTableType';
import { useOpenRentalObject } from './hooks/useOpenRentalObject';
import { useObjectDeleteApi, useObjectViewModalContext } from "Components/ObjectViewModal";
export const PropertyPage = (props) => {
    useOpenRentalObject(props);
    const { deleteProperty, deleteBuilding } = useObjectDeleteApi({
        onSuccess: () => table.refresh()
    });
    const { activeView } = useObjectViewModalContext();
    const initialLoad = React.useRef(true);
    React.useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        if (!activeView) {
            table.refresh();
        }
    }, [activeView]);
    const table = PropertyDataTableType.useTable({
        onDeleteProperty: deleteProperty,
        onDeleteBuilding: deleteBuilding,
    }, () => ({
        manualControl: true,
        getData: ({ request }) => BaseDataServices.getBaseDataPropertiesList(request),
    }));
    return (_jsx(PageOverview, { instance: table, variant: 'content', title: translate("propertyPage.title"), inlineFilters: true }));
};
