import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FlexCol, FlexRow } from "Atoms/Layout";
import { Card } from "Atoms/Card";
import { translateAdmin } from "Services/App";
import { MoneyType } from "@f2w/form";
import { Section } from "Atoms/Layout/components";
import { formatBytes } from "@f2w/utils";
export const SubscriptionInfoTab = ({ view }) => {
    const data = view.data;
    const monthlyAmount = MoneyType.useFormat(data.price / 12);
    const totalAmount = MoneyType.useFormat(data.price - data.oneTimeDiscount);
    return (_jsx(Section, { children: _jsxs(FlexRow, { gap: 24, children: [_jsx(FlexCol, { gap: 24, flex: '50%', children: _jsxs(Card, { children: [_jsx(Card.Value, { title: translateAdmin('customer.subscription.tier'), children: data.tierId }), _jsx(Card.Value, { title: translateAdmin('customer.subscription.limitUsers'), children: data.limitUsers }), _jsx(Card.Value, { title: translateAdmin('customer.subscription.limitObjects'), children: data.limitObjects }), _jsx(Card.Value, { title: translateAdmin('customer.subscription.limitSpace'), children: formatBytes(data.limitSpace) }), _jsx(Card.Value, { title: translateAdmin('customer.subscription.pricePerMonth'), children: monthlyAmount }), _jsx(Card.Value, { title: translateAdmin('customer.subscription.pricePerYear'), children: MoneyType.useFormat(data.price) })] }) }), _jsxs(FlexCol, { gap: 24, flex: '50%', children: [_jsxs(Card, { children: [_jsx(Card.Value, { title: translateAdmin('customer.subscription.oneTimeDiscount'), children: MoneyType.useFormat(data.oneTimeDiscount) }), _jsx(Card.Value, { title: translateAdmin('customer.subscription.totalAmount'), children: totalAmount })] }), _jsx(Card, { children: _jsx(Card.Value, { title: translateAdmin('customer.subscription.notes'), children: data.notes }) })] })] }) }));
};
