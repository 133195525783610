import useEventCallback from '@restart/hooks/useEventCallback';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import useCommittedRef from '@restart/hooks/useCommittedRef';
import useTimeout from '@restart/hooks/useTimeout';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useUncontrolled } from 'uncontrollable';
import { triggerBrowserReflow } from 'Components/Base';
import { isBool } from '@f2w/utils';
import useDebouncedCallback from '@restart/hooks/useDebouncedCallback';
const SWIPE_THRESHOLD = 40;
export function useCarousel(props, ref = undefined) {
    const { fade = false, loop = true, pause = 'hover', autoFocus, interval: _interval, controls = true, indicators = true, renderActions, children: _children, activeIndex: _activeIndex, onSelect, onKeyDown, onMouseOver, onMouseOut, onTouchStart, onTouchMove, onTouchEnd, disableKeyboard, disableTouch, onSlide, onSlid, ...rest } = useUncontrolled(props, {
        activeIndex: 'onSelect',
    });
    const children = getValidChildren(_children);
    const lastIndex = Math.max(children.length - 1, 0);
    const activeIndex = Math.min(_activeIndex || 0, lastIndex);
    const activeChild = children?.[activeIndex]?.props;
    const [renderedActiveIndex, setRenderedActiveIndex] = useState(activeIndex || 0);
    const [isSliding, setIsSliding] = useState(false);
    const [direction, setDirection] = useState('in');
    const nextDirectionRef = useRef(null);
    if (!isSliding && activeIndex !== renderedActiveIndex) {
        setDirection(nextDirectionRef.current || (activeIndex > renderedActiveIndex ? 'in' : 'out'));
        !fade && setIsSliding(true);
        setRenderedActiveIndex(activeIndex);
    }
    const [paused, setPaused] = useState(false);
    const interval = (_interval && (isBool(_interval) ? 5000 : _interval || null));
    const activeChildIntervalRef = useCommittedRef(activeChild?.interval);
    const elementRef = useRef();
    const next = useEventCallback((event) => {
        if (isSliding)
            return;
        let nextActiveIndex = renderedActiveIndex + 1;
        if (nextActiveIndex > lastIndex) {
            if (!loop)
                return;
            nextActiveIndex = 0;
        }
        nextDirectionRef.current = 'in';
        onSelect?.(nextActiveIndex, event);
    });
    const prev = useCallback((event) => {
        if (isSliding)
            return;
        let nextActiveIndex = renderedActiveIndex - 1;
        if (nextActiveIndex < 0) {
            if (!loop)
                return;
            nextActiveIndex = lastIndex;
        }
        nextDirectionRef.current = 'out';
        onSelect?.(nextActiveIndex, event);
    }, [isSliding, renderedActiveIndex, onSelect, loop, lastIndex]);
    useImperativeHandle(ref, () => ({ element: elementRef.current, prev, next, manager }));
    useEffect(() => {
        if (nextDirectionRef.current)
            nextDirectionRef.current = null;
    });
    useEffect(() => {
        if (autoFocus && elementRef.current)
            elementRef.current.focus();
    }, []);
    const slideDirection = direction === 'in' ? 'left' : 'right';
    const slideArgs = [renderedActiveIndex, slideDirection];
    const handleEnter = useCallback((node) => {
        triggerBrowserReflow(node);
        onSlide?.(...slideArgs);
    }, [onSlide, ...slideArgs]);
    const handleEntered = useCallback(() => {
        setIsSliding(false);
        onSlid?.(...slideArgs);
    }, [onSlid, ...slideArgs]);
    const slide = fade ? undefined : {
        onEnter: handleEnter,
        onEntered: handleEntered,
        direction,
        slideDirection,
    };
    useUpdateEffect(() => {
        if (!fade)
            return;
        onSlide?.(renderedActiveIndex, slideDirection);
        onSlid?.(renderedActiveIndex, slideDirection);
    }, [renderedActiveIndex]);
    const handlePaused = useDebouncedCallback((next) => {
        if (paused === next)
            return;
        setPaused(next);
    }, 100);
    const handleKeyDown = useCallback((event) => {
        if (!disableKeyboard && !/input|textarea/i.test(event.target.tagName)) {
            switch (event.key) {
                case 'ArrowLeft':
                    event.preventDefault();
                    prev(event);
                    return;
                case 'ArrowRight':
                    event.preventDefault();
                    next(event);
                    return;
                default:
            }
        }
        onKeyDown?.(event);
    }, [disableKeyboard, onKeyDown, prev, next]);
    const handleMouseOver = useCallback((event) => {
        if (pause === 'hover')
            handlePaused(true);
        onMouseOver?.(event);
    }, [pause, paused, onMouseOver]);
    const handleMouseOut = useCallback((event) => {
        handlePaused(false);
        onMouseOut?.(event);
    }, [onMouseOut]);
    const touchStartXRef = useRef(0);
    const touchDeltaXRef = useRef(0);
    const touchUnpauseTimeout = useTimeout();
    const handleTouchStart = useCallback((event) => {
        touchStartXRef.current = event.touches[0].clientX;
        touchDeltaXRef.current = 0;
        if (pause === 'hover')
            handlePaused(true);
        onTouchStart?.(event);
    }, [pause, onTouchStart]);
    const handleTouchMove = useCallback((event) => {
        if (event.touches && event.touches.length > 1)
            touchDeltaXRef.current = 0;
        else
            touchDeltaXRef.current = event.touches[0].clientX - touchStartXRef.current;
        onTouchMove?.(event);
    }, [onTouchMove]);
    const handleTouchEnd = useCallback((event) => {
        if (!disableTouch) {
            const touchDeltaX = touchDeltaXRef.current;
            if (Math.abs(touchDeltaX) > SWIPE_THRESHOLD) {
                if (touchDeltaX > 0)
                    prev(event);
                else
                    next(event);
            }
        }
        if (pause === 'hover') {
            touchUnpauseTimeout.set(() => {
                handlePaused(false);
            }, interval || undefined);
        }
        onTouchEnd?.(event);
    }, [disableTouch, pause, prev, next, touchUnpauseTimeout, interval, onTouchEnd]);
    const rootProps = {
        ref: elementRef,
        onKeyDown: handleKeyDown,
        onMouseOver: handleMouseOver,
        onMouseOut: handleMouseOut,
        onTouchStart: handleTouchStart,
        onTouchMove: handleTouchMove,
        onTouchEnd: handleTouchEnd,
        ...rest
    };
    const shouldPlay = interval != null && !paused && !isSliding;
    const intervalHandleRef = useRef();
    const nextWhenVisible = useEventCallback(() => {
        if (!document.hidden && isVisible(elementRef.current))
            next();
    });
    useEffect(() => {
        if (!shouldPlay)
            return undefined;
        intervalHandleRef.current = window.setInterval(document.visibilityState ? nextWhenVisible : next, activeChildIntervalRef.current ?? interval ?? undefined);
        return () => {
            if (intervalHandleRef.current !== null)
                clearInterval(intervalHandleRef.current);
        };
    }, [shouldPlay, next, activeChildIntervalRef, interval, nextWhenVisible]);
    const canPrev = loop || activeIndex !== 0;
    const canNext = loop || activeIndex !== lastIndex;
    const showNavigation = children.length > 1;
    const hasControls = showNavigation && controls && (canPrev || canNext);
    const hasIndicators = showNavigation && indicators;
    const api = {
        children,
        activeIndex,
        lastIndex,
        prev,
        next,
        select: onSelect,
        canPrev,
        canNext,
        showNavigation,
        hasControls,
        hasIndicators,
        interval,
        renderActions,
    };
    const manager = {
        ...api,
        slide,
        rootProps,
        onSelect: onSelect,
    };
    return manager;
}
function getValidChildren(children) {
    const _children = [];
    React.Children.forEach(children, (child) => {
        React.isValidElement(child) && _children.push(child);
    });
    return _children;
}
function isVisible(element) {
    if (!element ||
        !element.style ||
        !element.parentNode ||
        !element.parentNode.style) {
        return false;
    }
    const elementStyle = getComputedStyle(element);
    return (elementStyle.display !== 'none' &&
        elementStyle.visibility !== 'hidden' &&
        getComputedStyle(element.parentNode).display !== 'none');
}
