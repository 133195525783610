import { isDevEnv } from '@f2w/utils';
export class BaseType {
    constructor(options) {
        Object.defineProperties(this, {
            _props: {
                value: {
                    initialOptions: { ...options }
                },
                writable: false,
                configurable: false,
                enumerable: false,
            },
            specs: {
                get: () => this._props.specs ?? this.__construct(),
                configurable: false,
                enumerable: false,
            },
        });
    }
    __getDefaultOptions() {
        return {};
    }
    get key() {
        return this.specs.key;
    }
    createValue(props) {
        const valueType = this._createValue(props);
        return valueType;
    }
    init({ parent, initialValue, ...props }) {
        this.__construct();
        const valueType = this.createValue(props);
        valueType.reset(initialValue);
        return valueType;
    }
    __construct() {
        if (!this._props.specs) {
            const { initialOptions } = this._props;
            const specs = this._getSpecs();
            this._props.specs = specs;
            const defaultOptions = this.__getDefaultOptions();
            const options = {
                ...defaultOptions,
                ...initialOptions,
                constraints: {
                    ...defaultOptions.constraints,
                    ...initialOptions.constraints
                }
            };
            this._props.initialOptions = null;
            if (isDevEnv())
                console.debug('initialize %o with %o', this.constructor.name, options);
            specs.init(this, options);
        }
        return this._props.specs;
    }
    renderForm(typeValue, props) {
        return this._renderForm(typeValue, props);
    }
}
BaseType.prototype.__is_fw_type = true;
