import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
import RentServices from "Pages/Rent/services";
import { RenterMirrorTenantListExportFormType } from "Pages/Rent/forms/RenterMirror/RenterMirrorDateExportForm/RenterMirrorTenantListExportFormType";
import { useToastDispatcherApi } from "Components/Dispatcher";
export const useRenterMirrorTenantBalanceListExportForm = () => {
    const toastApi = useToastDispatcherApi();
    return useFormModal({
        name: 'tenant-balance-list-export-form',
        type: () => new RenterMirrorTenantListExportFormType({
            hideRentalObject: () => true
        }),
        onLoad: async (presetValues) => {
            return {
                date: presetValues.date ?? new Date(),
                mandateId: presetValues.mandateId ?? null,
                propertyId: presetValues.propertyId ?? null,
                buildingId: presetValues.buildingId ?? null
            };
        },
        title: () => translate('rent.exportTenantBalanceListTitle'),
        Footer: ({ saveForm, closeForm, form: { loading } }) => {
            return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.cancel') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('btn.download') })] });
        },
        onSave: async (data) => {
            try {
                await RentServices.downloadRenterMirrorTenantBalanceListReport(data);
            }
            catch ({ response }) {
                await RentServices.processDownloadFileResponse(response, toastApi);
            }
        },
        ignoreConfirm: true,
        successMessage: null,
    });
};
