import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
import { CreateAutomaticInvoicesType } from "Pages/AutoInvoicing/types/CreateAutomaticInvoicesType";
export const useAncillaryCalculationInvoicesDownloadForm = ({ calculationId, generateCalculationInvoices }) => useFormModal({
    name: 'ae-download-calculation-invoices-form',
    type: () => new CreateAutomaticInvoicesType({
        invoices: null,
    }),
    title: translate('ancillaryExpenseCalculation.downloadInvoices'),
    onSave: (settings) => {
        return generateCalculationInvoices(calculationId, settings);
    },
    successMessage: translate('ancillaryExpenseCalculation.downloadScheduled'),
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('btn.download') })] });
    },
    ignoreConfirm: true
});
