import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import classes from './styles';
import useDropdown from 'Hooks/useDropdown';
import DropdownModal from 'Components/DropdownModal/DropdownModal';
import { translate } from 'Services/Translator';
import Tooltip from 'Components/Tooltip';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { FocusSelectorMenu } from './components/Menu';
import { isStorybookEnv } from '@f2w/utils';
import { useEventCallback } from '@restart/hooks';
import { FlexCol, FlexRow } from 'Atoms/Layout';
import classNames from 'classnames';
import { Icon } from 'Atoms/Icon';
import { Text } from 'Atoms/Text';
const FocusSelector = ({ short: isShort, }) => {
    const dropdown = useDropdown("startRight");
    const portfolio = usePortfolio();
    const [scope, setScope] = useState(portfolio.getScope());
    const onItemSelected = (item) => {
        setScope(item);
        dropdown.hideDropdown();
        if (!isStorybookEnv())
            portfolio.updateScope(item);
    };
    const onClickClose = useEventCallback((e) => {
        e.stopPropagation();
        onItemSelected(null);
    });
    const selectedItem = useMemo(() => {
        switch (scope?.level) {
            case 'property':
                return {
                    property: portfolio.getPropertyById(scope.id),
                };
            case 'rentalObject':
                const rentalObject = portfolio.getRentalObjectById(scope.id);
                const building = portfolio.getBuildingById(rentalObject?.buildingId);
                const property = portfolio.getPropertyById(building?.propertyId);
                const tenants = portfolio.getActiveContractsByRentalObjectId(rentalObject?.id)?.[0]?.tenants;
                const tenant = tenants?.filter(Boolean).join(' & ');
                return { rentalObject, building, property, tenant };
        }
        return null;
    }, [scope?.level, scope?.id]);
    useEffect(() => {
        dropdown.refreshPosition();
    }, [isShort]);
    const triggerContent = _jsx(FlexRow, { justifySpaceBetween: true, alignCenter: true, gap: 16, minh: 72, px: 32, py: 12, role: "combobox", "aria-controls": "global-focus-selector-listbox", onClick: () => dropdown.onTrigger(), className: classNames(classes.trigger, {
            [`${classes.triggerShort}`]: isShort,
            [`${classes.itemSelected}`]: selectedItem,
        }), children: selectedItem ? (_jsxs(_Fragment, { children: [!isShort && (_jsx(Text, { as: FlexCol, success: 500, flex: 1, gap: 2, children: selectedItem?.rentalObject?.id ? (_jsxs(_Fragment, { children: [_jsx(Text, { ellipsis: true, maxw: 170, medium: 500, children: selectedItem?.tenant || translate('rentalObject.state.vacancy') }), _jsx(Text, { ellipsis: true, maxw: 170, small: 500, children: selectedItem?.rentalObject?.name }), _jsx(Text, { ellipsis: true, maxw: 170, small: 400, children: selectedItem?.building?.name })] })) : (_jsx(_Fragment, { children: _jsx(Text, { ellipsis: true, maxw: 170, body: 400, children: selectedItem?.property?.name }) })) })), _jsx(Text, { success: 400, onClick: onClickClose, children: _jsx(Icon, { size: 24, icon: 'x' }) })] })) : (_jsx(_Fragment, { children: isShort ? (_jsx(Icon, { size: 30, icon: 'focusSelector' })) : (_jsxs(_Fragment, { children: [_jsxs(FlexCol, { flex: 1, pr: 10, children: [_jsx(Text, { neutral: 50, children: translate('focusSelector.globalFilter') }), _jsx(Text, { small: true, neutral: 300, children: translate('focusSelector.selectProperty') })] }), _jsx(Text, { children: _jsx(Icon, { size: 24, icon: 'changeSmall' }) })] })) })) });
    return (_jsxs("div", { id: "global-focus-selector", className: classes.focusSelector, children: [_jsx("div", { ref: dropdown.triggerRef, children: isShort ? (_jsx(Tooltip, { placement: 'right', overlay: getItemTitle(selectedItem), children: triggerContent })) : (triggerContent) }), _jsx(DropdownModal, { id: "global-focus-selector-listbox", dropdown: dropdown, modalTheme: "none", onClickOutside: (e) => {
                    const target = e.target;
                    if (!target || !!target.closest('#modal-root') || !!target.closest('#kt_aside_toggler'))
                        return;
                    dropdown.hideDropdown();
                }, children: !dropdown.isPreparing && _jsx(FocusSelectorMenu, { onSelected: onItemSelected }) })] }));
};
export { FocusSelector };
function getItemTitle(selectedItem) {
    if (!selectedItem) {
        return translate('focusSelector.entirePortfolio');
    }
    if (selectedItem.rentalObject?.id) {
        return selectedItem.tenant + " :: " + selectedItem.building.name;
    }
    return selectedItem.property.name;
}
