import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from 'Atoms/Layout/components';
import { useDebitorInvoiceActions } from '../../../hooks/useDebitorInvoiceActions';
import styled from 'styled-components';
import { RenderMode } from "Atoms/Dropdown/helpers";
import { hasFeature } from "Services/FeatureFlagManager";
import DebitorInvoiceDocumentArea from "Pages/Debitor/components/DebitorView/components/DebitorInvoiceDocumentArea";
export const DocumentsTab = ({ view: { data: debitorInvoice } }) => {
    const menu = useDebitorInvoiceActions({
        invoiceId: debitorInvoice.id,
        mandateName: debitorInvoice.mandateName,
        allParties: debitorInvoice.hasMultipleParties,
        allPersons: debitorInvoice.hasMultiplePersons,
        withQr: debitorInvoice.supportsQr,
        reminder: debitorInvoice.reminderState,
        download: true,
    });
    return (_jsxs(Section, { children: [hasFeature('TEMPLATES_V2') && _jsx(DebitorInvoiceDocumentArea, { debitorInvoiceId: debitorInvoice.id, documents: debitorInvoice.documentsV2, hasMultipleParties: debitorInvoice.hasMultipleParties, supportsQr: debitorInvoice.supportsQr }), hasFeature('TEMPLATES_V1') && _jsx(MenuWrapper, { className: 'fw-menu--light', children: menu.render({ renderMode: RenderMode.Flat }) })] }));
};
const MenuWrapper = styled.div `
    && {
        padding: 0;
        --menu-divider-color: ${p => p.theme.palette.neutral.$100};

        --menu-color: ${p => p.theme.palette.neutral.$700};
        --menu-background-color: ${p => p.theme.palette.white};

        --menu-link-color: ${p => p.theme.palette.neutral.$500};

        --menu-hover-color: ${p => p.theme.palette.primary.$500};
        --menu-hover-background-color: ${p => p.theme.palette.primary.$50};

        --menu-disabled-color: ${p => p.theme.palette.neutral.$300};

        --menu-header-color: ${p => p.theme.palette.neutral.$600};

        * > &:not(:first-child) {
            padding-top: 1.5rem;
            margin-top: 1.5em;
            border-top: 2px solid silver
        }
    }

    .fw-dropdown-header {
      margin-top: 10px;
      ${p => p.theme.typography.body.css({ fontWeight: 500 })};
      color: ${p => p.theme.palette.neutral.$700};
      text-transform: none;
    }
    .fw-dropdown-item {
    }
`;
