import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'Atoms/Button';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
import { useMemo } from 'react';
import { hasRole } from 'Services/UserManager';
import { FormModal } from '@f2w/form';
import { useQuickschildOrderForm } from 'Pages/Quickschild/hooks/useQuickschildOrderForm';
import { usePortfolio } from 'Components/Base/PortfolioContext';
const QuickschildButtonWrapper = ({ tooltipText, buttonAction, onOpenForm }) => {
    const displayTooltip = buttonAction === 'disabled';
    const buttonProps = {
        outline: true,
        label: translate('contract.details.actions.nameplate'),
        disabled: buttonAction === 'disabled',
    };
    if (buttonAction === 'redirect') {
        buttonProps.href = generateRoute('quickschild.integration.overview');
    }
    if (buttonAction === 'form') {
        buttonProps.onClick = () => {
            onOpenForm();
        };
    }
    if (displayTooltip) {
        return (_jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: 'quickschild-button-tooltip', children: tooltipText }), children: _jsx(Button, { ...buttonProps }) }));
    }
    return (_jsx(Button, { ...buttonProps }));
};
const QuickschildBlock = ({ contract }) => {
    const portfolio = usePortfolio();
    const property = portfolio.getPropertyByRentalObjectId(contract.rentalObject.id);
    const orderForm = useQuickschildOrderForm();
    const isAdmin = hasRole('ROLE_ADMINISTRATOR');
    const integration = contract.quickschild;
    const { tooltipText, buttonAction } = useMemo(() => {
        let tooltipText = '', buttonAction = 'disabled';
        if (integration) {
            if (integration.active === true) {
                if (integration.properties.includes(property.id)) {
                    buttonAction = 'form';
                }
                else {
                    tooltipText = translate('quickschild.integrationView.contractView.tooltip.propertyNotActivated');
                }
            }
            else {
                if (isAdmin) {
                    buttonAction = 'redirect';
                }
                else {
                    tooltipText = translate('quickschild.integrationView.contractView.tooltip.integrationNotActivated');
                }
            }
        }
        return { tooltipText, buttonAction };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(QuickschildButtonWrapper, { buttonAction: buttonAction, tooltipText: tooltipText, onOpenForm: () => {
                    orderForm.openForm(contract.id);
                } }), _jsx(FormModal, { modal: orderForm })] }));
};
export default QuickschildBlock;
