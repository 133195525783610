import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Title } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { generateDocument } from 'Services/DocumentGenerator';
import { Header, Section } from 'Atoms/Layout/components';
import moment from 'moment';
import ViewModal from 'Components/ObjectViewModal/components/ViewModalStyled';
import ContractDocumentArea from "./ContractDocumentArea";
import { hasFeature } from "Services/FeatureFlagManager";
import { useDispatcherApi } from 'Components/Dispatcher';
export const GenerateDocumentLink = ({ document, id, sourceClass, ...props }) => {
    const dispatcher = useDispatcherApi();
    return (_jsxs(_Fragment, { children: [_jsx(ViewModal.DocumentLink, { ...props, label: document.label, onDownload: () => {
                    generateDocument(document.label, document.template, sourceClass, id.toString(), { mandated: false }, { dispatcher });
                } }), document.isMandated && _jsx(ViewModal.DocumentLink, { ...props, label: document.mandatedDocumentName, onDownload: () => {
                    generateDocument(document.label, document.template, sourceClass, id.toString(), { mandated: true }, { dispatcher });
                } })] }));
};
export const DocumentsTab = ({ group: { title, data: contract, data: { documentsV2, documents = [], modifications = [], }, } }) => {
    return (_jsxs(_Fragment, { children: [hasFeature('TEMPLATES_V2') &&
                _jsx(ContractDocumentArea, { documentSections: documentsV2.sections || [] }), hasFeature('TEMPLATES_V1') && _jsxs(_Fragment, { children: [_jsx(Header, { children: _jsx(Title, { h6: true, as: 'h3', children: title }) }), _jsx(Section, { children: documents.map((document, index) => (_jsx(GenerateDocumentLink, { document: document, id: contract.id, sourceClass: 'App\\Entity\\Contract\\Contract' }, `document-${index}`))) }), modifications.map(modification => (_jsxs(React.Fragment, { children: [_jsx(Header, { children: _jsx(Title, { large: 500, as: 'h4', children: translate('contract.details.modification.documents', {
                                        validFrom: moment(modification.validFrom).format('DD.MM.YYYY'),
                                    }) }) }), _jsx(Section, { pt: 0, children: modification.documents.map(document => (_jsx(GenerateDocumentLink, { document: document, id: modification.id, sourceClass: 'App\\Domain\\Rent\\Domain\\Entity\\ContractRent' }, document.template))) })] }, modification.id)))] })] }));
};
