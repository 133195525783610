import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { SpinnerNew } from "Components/Atoms";
import { CalculationWizardStyled } from "../CalculationWizardStyled";
import { CalculationView } from "./CalculationView";
import { CalculationServices } from "../../index";
export const AncillaryExpenseCalculationPage = ({ calculationId, calculationServices = CalculationServices, isViewMode = false }) => {
    const [basicDetails, setBasicDetails] = React.useState();
    const hasBasicDetails = !!basicDetails;
    React.useEffect(() => {
        loadCalculation(calculationId);
    }, []);
    const loadCalculation = async (calculationId) => {
        const basicDetails = await calculationServices.getCalculation(calculationId);
        setBasicDetails(basicDetails);
    };
    if (!hasBasicDetails) {
        return _jsx(SpinnerNew, {});
    }
    return (_jsx(CalculationWizardStyled, { children: _jsx(CalculationView, { calculationId: calculationId, overviewData: basicDetails, calculationServices: calculationServices, isViewMode: isViewMode }) }));
};
