import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { useAncillaryDistributionKeyForm } from '../../hooks';
import { DistributionKeysTable } from '../../table';
import { DistributionKeyServices } from '../../index';
import { FormModal } from '@f2w/form';
import { getScopePropertyId } from "Services/ScopeManager";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { CalculationDistributionSection } from "Pages/AncillaryExpense/components/OverviewPage/components/ChoosePresetDistributionKeysModal";
import { PresetDistributionKeyServices } from "Pages/AncillaryExpense/services/presetDistributionKeyServices";
export const DistributionKeysTab = ({ distributionKeyServices = DistributionKeyServices, presetDistributionKeyServices = PresetDistributionKeyServices }) => {
    const [openPresetModal, setOpenPresetModal] = useState(false);
    const { openViewModal } = useObjectViewModalContext();
    const portfolio = usePortfolio();
    const distributionKeyForm = useAncillaryDistributionKeyForm({
        onSaved: () => instance.refresh(),
        distributionKeyServices,
    });
    const instance = DistributionKeysTable.use({
        onPropertyClick: openViewModal(ActiveView.PROPERTY),
        distributionKeyServices,
        onCreatePresetDistributionKeys: () => setOpenPresetModal(true),
        onCreateDistributionKey: () => distributionKeyForm.openNewForm({ propertyId: getScopePropertyId(portfolio) }),
        onEditDistributionKey: (id) => distributionKeyForm.openForm(id)
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { variant: 'content', instance: instance }), _jsx(FormModal, { size: {
                    width: '80vw',
                    maxWidth: '1200px',
                    maxHeight: '90vh',
                    minHeight: '50vh'
                }, modal: distributionKeyForm }), openPresetModal && (_jsx(CalculationDistributionSection, { onClose: () => {
                    setOpenPresetModal(false);
                    instance.refresh();
                }, createUsingPresetDistributionKeys: presetDistributionKeyServices.createUsingPresetDistributionKeys, getAllPresetDistributionKeys: presetDistributionKeyServices.getAllPresetDistributionKeys }))] }));
};
