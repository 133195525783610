import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SelectType } from 'fairwalter/packages/form-new/src';
import Portfolio from "Models/Portfolio";
import { CreatePropertyType } from "Pages/BaseData/types/CreatePropertyType";
import { useMemo } from "react";
import { translate } from 'Services/Translator';
export class CreateStandaloneRentalObjectType extends CreatePropertyType.CreateRentalObject {
    buildFields() {
        const fields = super.buildFields();
        const portfolio = Portfolio.instance();
        return {
            propertyId: SelectType.number({
                label: translate('property'),
                nullable: false,
                required: true,
                choices: () => {
                    return useMemo(() => {
                        return portfolio.getProperties().map(property => ({
                            label: property.name,
                            value: property.id
                        }));
                    }, [portfolio.getProperties()]);
                }
            }),
            buildingId: SelectType.number({
                label: translate('building.title'),
                nullable: false,
                required: true,
                choices: (v) => {
                    const buildings = v.parent.value.propertyId
                        ? portfolio.getBuildingsByPropertyId(v.parent.value.propertyId)
                        : [];
                    return useMemo(() => {
                        return buildings.map(building => ({
                            label: building.name,
                            value: building.id
                        }));
                    }, [buildings]);
                }
            }),
            ...fields
        };
    }
    _renderWidget({ field }) {
        const c = field.children;
        return _jsxs(_Fragment, { children: [c.propertyId.render(), c.buildingId.render(), super._renderWidget({ field })] });
    }
}
