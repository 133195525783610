import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { Button } from 'Atoms/Button/Button';
import { translate } from 'Services/Translator';
import { BaseTheme } from '../base';
export function ArrayField({ Label: labelProps, Error: errorProps, field: arrayValue, children, ...props }) {
    const commonProps = { field: arrayValue };
    return (_jsxs(ArrayField.Row, { id: `${arrayValue.id}--row`, ...commonProps, ...props, children: [_jsxs(ArrayField.Label, { ...commonProps, ...labelProps, children: [_jsx(BaseTheme.Label, { ...commonProps }), arrayValue.specs.showAdd && _jsx(_Fragment, { children: _jsxs(ArrayField.ActionsRoot, { children: [_jsx(Button, { link: true, color: 'primary', "$size": 'md', onClick: () => arrayValue.push(), label: translate('form.collection.add'), icon: 'plus-circle' }), _jsx(Button, { color: 'error', "$size": 'md', label: 'Test array', icon: 'alert-triangle' })] }) })] }), children] }));
}
(function (ArrayField) {
    ArrayField.ActionsRoot = styled.div `
        display: flex;
    `;
    ArrayField.Row = styled(BaseTheme.Row) ``;
    ArrayField.Label = styled.div `
        position: relative;
        //display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: flex-start;
        text-align: left;
        margin: 20px 0;
        padding: 5px 0;
        border-bottom: 1px solid ${p => p.theme.palette.dark.$100};

        ${ArrayField.ActionsRoot} {
            position: absolute;
            z-index: 10;
            margin: 0 0 auto auto;
            top: 0;
            right: 0;
            justify-content: flex-end;

            .fw-btn {
                padding: 0 3px;
            }
        }

        ${BaseTheme.LabelRoot} {
            ${p => p.theme.typography.h6.fontSizeStyles};
            margin: 1em 0 .75em;
        }
    `;
    ArrayField.Error = styled(BaseTheme.Error) ``;
})(ArrayField || (ArrayField = {}));
