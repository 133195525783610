import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CalculationServices } from "Pages/AncillaryExpense";
import { translate } from "Services/Translator";
import { getContractContributionBillings } from "Pages/AncillaryExpense/services/calculationServices";
export const useDistributionSection = ({ calculationId, calculationServices = CalculationServices }) => {
    const [_activeAccountId, _setActiveAccountId] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [distributionMap, _setDistributionMap] = React.useState(new Map());
    const [distributionMapFiltered, _setDistributionMapFiltered] = React.useState(new Map());
    const [contractContributionBillings, setContractContributionBillings] = useState([]);
    const accountDistributions = distributionMapFiltered.get(_activeAccountId);
    const setAccount = React.useCallback((accountId) => {
        if (distributionMap.has(accountId)) {
            _setActiveAccountId(accountId);
        }
        else {
            _setActiveAccountId(0);
        }
    }, [distributionMap]);
    const loadContractContributionBillings = useCallback(() => {
        getContractContributionBillings(calculationId)
            .then(results => setContractContributionBillings(results))
            .catch(error => console.error(error));
    }, [calculationId]);
    const loadData = React.useCallback(async () => {
        setIsLoading(true);
        return calculationServices.listDistributions(calculationId)
            .then((results) => {
            const distributionMap = new Map();
            results?.forEach(result => {
                if (distributionMap.has(result.account.id)) {
                    distributionMap.set(result.account.id, {
                        ...(distributionMap.get(result.account.id)),
                        [result.type]: convertItem(result)
                    });
                }
                else {
                    distributionMap.set(result.account.id, {
                        accountId: result.account.id,
                        accountName: result.account.name,
                        [result.type]: convertItem(result)
                    });
                }
            });
            _setDistributionMapFiltered(distributionMap);
            _setDistributionMap(distributionMap);
        })
            .finally(() => {
            setIsLoading(false);
        });
    }, [calculationId]);
    const rentalObjectTypeOptions = useMemo(() => {
        const availableRentalObjectTypes = [
            ...(new Set(Array.from(distributionMap.entries())
                .map(([_key, value]) => {
                const accountTypes = {
                    allocation_formula: value.allocation_formula,
                    fixed_cost: value.fixed_cost,
                    consumption: value.consumption,
                    common_area: value.common_area
                };
                return Object
                    .values(accountTypes)
                    .filter(type => type !== undefined)
                    .map(type => type.positions.map(pos => pos.rentalObjectType))
                    .flat();
            })
                .flat()
                .filter(type => type !== null && type !== undefined)))
        ];
        return availableRentalObjectTypes.map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }));
    }, [distributionMap]);
    useEffect(() => {
        void loadData();
        loadContractContributionBillings();
    }, []);
    return {
        refreshData: loadData,
        distributionMap: distributionMap,
        activeAccountId: _activeAccountId,
        setAccount,
        accountDistributions,
        setIsLoading,
        isLoading,
        rentalObjectTypeOptions,
        contractContributionBillings,
        loadContractContributionBillings
    };
};
const convertItem = ({ account, type, ...props }) => {
    return { ...props };
};
