import { ViewType } from '../core';
import { useObjectViewModal } from 'Components/ObjectViewModal';
import { useDispatcherApi } from 'Components/Dispatcher';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import useStableMemo from '@restart/hooks/useStableMemo';
import { setDebugVariable } from '@f2w/utils';
import { useMounted } from '@restart/hooks';
export function useViewType(type, options, groups) {
    const objectViewModalContext = useObjectViewModal();
    const dispatcher = useDispatcherApi();
    const dispatch = useForceUpdate();
    const isMounted = useMounted();
    const view = useStableMemo(() => {
        return new ViewType(type(), options, groups(), () => isMounted() && dispatch(), dispatcher);
    }, []);
    setDebugVariable('viewType', view);
    if (objectViewModalContext?.setActiveView) {
        objectViewModalContext.setActiveView(view);
    }
    return view;
}
