import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from 'Services/App';
import { FlexCol, FlexRow } from 'Atoms/Layout';
import { Text } from 'Atoms/Text';
import { ContractNoticeWizardType } from './ContractNoticeWizardType';
import { Button } from 'Atoms/Button';
import { useWizardContext } from '@f2w/view';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { Callout } from 'Atoms/Callout';
import { TerminatedBy } from 'Pages/Contract/enums';
export const NoticeStepTemplate = ({ field }) => {
    const data = ContractNoticeWizardType.getNoticeStepData(field);
    const isFutureRent = ContractNoticeWizardType.isFutureRentChange(data.noticePer, data.settings?.futureRentChangeDate);
    return (_jsxs(FlexCol, { gap: 20, children: [_jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('contractNotice.noticeStep.title') }), _jsx(Text, { medium: 400, neutral: 500, children: translate('contractNotice.noticeStep.description') })] }), _jsxs(FlexCol, { children: [field.children.propertyId.render(), field.children.buildingId.render(), field.children.rentalObjectId.render(), field.children.contractId.render(), field.children.noticeBy.render(), field.children.dateOfNotice.render(), field.children.noticePer.render(), isFutureRent && (_jsx(Callout, { type: 'warning', children: translate('contractNotice.noticeStep.futureRentInfo') }))] })] }));
};
export const TerminationStepTemplate = ({ field }) => {
    const _step = ContractNoticeWizardType.getTerminationStep(field);
    const _data = ContractNoticeWizardType.getNoticeStepData(field);
    const _contracts = _step.children.contracts;
    if (_contracts.isEmpty) {
        return (_jsx(FlexCol, { gap: 20, children: _jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('contractNotice.terminationStep.title') }), _jsx(Text, { medium: 400, neutral: 500, children: translate('contractNotice.terminationStep.noSubcontracts') })] }) }));
    }
    return (_jsxs(FlexCol, { gap: 20, children: [_jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('contractNotice.terminationStep.title') }), _jsx(Text, { medium: 400, neutral: 500, children: translate('contractNotice.terminationStep.description') })] }), _jsxs(FlexCol, { gap: 20, children: [_data.noticeBy === TerminatedBy.TENANT && (_jsx(Callout, { type: 'warning', message: translate('contractNotice.terminationStep.tenantWarning') })), field.renderChildren()] })] }));
};
export const SummaryStepTemplate = ({ field }) => {
    const { selectedContract, noticeBy } = ContractNoticeWizardType.getNoticeStepData(field);
    const _step = ContractNoticeWizardType.getNoticeStep(field);
    const { stepApi } = useWizardContext();
    const portfolio = usePortfolio();
    const rentalObjectName = portfolio.getRentalObjectById(selectedContract.rentalObjectId).name;
    const buildingName = portfolio.getBuildingById(selectedContract.buildingId).name;
    const propertyName = portfolio.getPropertyById(selectedContract.propertyId).name;
    const noticeByLabel = {
        [TerminatedBy.TENANT]: translate('contractNotice.noticeBy.tenant'),
        [TerminatedBy.OWNER]: translate('contractNotice.noticeBy.owner'),
    }[noticeBy];
    const _step2 = ContractNoticeWizardType.getTerminationStep(field);
    const _contracts = _step2.children.contracts;
    return (_jsxs(FlexCol, { gap: 40, children: [_jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('contractNotice.summaryStep.title') }), _jsx(Text, { medium: 400, neutral: 900, children: translate('contractNotice.summaryStep.description') })] }), _jsxs(FlexCol, { gap: 16, children: [_jsxs(FlexCol, { gap: 24, p: 24, b: '1px solid #D7D8DB', brd: 8, children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { large: true, neutral: 900, children: translate('contractNotice.noticeStep.title') }), _jsx(Button.Edit, { "$size": 'sm', onClick: () => {
                                            stepApi.goToStep("noticeStep");
                                        } })] }), _jsxs(FlexCol, { gap: 16, children: [_jsx(SummaryBlockItem, { label: translate('property'), value: propertyName }), _jsx(SummaryBlockItem, { label: translate('building.title'), value: buildingName }), _jsx(SummaryBlockItem, { label: translate('rentalObject'), value: rentalObjectName }), _jsx(SummaryBlockItem, { label: translate('contract'), value: selectedContract.label }), _jsx(SummaryBlockItem, { label: translate('contractNotice.noticeBy'), value: noticeByLabel }), _jsx(SummaryBlockItem, { label: translate('contractNotice.dateOfNotice'), value: _step.children.dateOfNotice.humanValue }), _jsx(SummaryBlockItem, { label: translate('contractNotice.noticePer'), value: _step.children.noticePer.humanValue })] })] }), _contracts.isSomeSelected() && _jsxs(FlexCol, { gap: 24, p: 24, b: "1px solid #D7D8DB", brd: 8, children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { large: true, neutral: 900, children: translate('contractNotice.terminationStep.title') }), _jsx(Button.Edit, { "$size": 'sm', onClick: () => {
                                            stepApi.goToStep('terminationStep');
                                        } })] }), _jsx(FlexCol, { gap: 16, children: _contracts.mapSelected((c) => {
                                    return _jsx(SummaryBlockItem, { label: translate('contractNotice.noticeStep.title'), value: c.humanValue.name }, c.key);
                                }) })] })] })] }));
};
const SummaryBlockItem = ({ label, value }) => (_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { medium: 500, neutral: 900, children: label }), _jsx(Text, { medium: 400, neutral: 900, children: value })] }));
