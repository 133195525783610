import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCustomerView } from "Pages/Administration/Customers/hooks/useCustomerView";
import { useActiveViewContext } from "Pages/Administration/context/ActiveViewContext";
import { useEffect } from "react";
import { useEditCustomerForm } from "Pages/Administration/Customers/hooks/useEditCustomerForm";
import { FormModal } from "@f2w/form";
import { useCreateSubscriptionForm } from "Pages/Administration/Customers/hooks/useCreateSubscriptionForm";
import { ActiveView } from "Pages/Administration/enums";
import { SubscriptionCreation } from "Pages/Administration/Customers/enums";
import { useDispatcherApi } from "Components/Dispatcher";
export const CustomerView = ({ getCustomerDetails, editCustomer, createSubscription, getCreateSubscriptionSuggestion, getUpgradeSubscriptionSuggestion, getDowngradeSubscriptionSuggestion, }) => {
    const { modal: modalApi, toast: toastApi } = useDispatcherApi();
    const { id, setDetailsActiveView } = useActiveViewContext();
    const customerView = useCustomerView({
        getCustomerDetails,
        openEdit: () => editCustomerForm.openForm(customerView.data.id),
        openCreateSubscription: () => createSubscriptionForm.openForm(SubscriptionCreation.REGULAR),
        openUpgradeSubscription: () => createSubscriptionForm.openForm(SubscriptionCreation.UPGRADE),
        openDowngradeSubscription: () => createSubscriptionForm.openForm(SubscriptionCreation.DOWNGRADE),
        openSubscriptionDetails: (id) => setDetailsActiveView(id, ActiveView.CUSTOMER_SUBSCRIPTION_DETAILS)
    });
    useEffect(() => {
        if (id) {
            customerView.load({ id });
        }
    }, [id]);
    const editCustomerForm = useEditCustomerForm({
        editCustomer,
        onSave: (data) => customerView.updateData({ ...data }),
        getData: () => customerView.data
    });
    const createSubscriptionForm = useCreateSubscriptionForm({
        customerId: id,
        createSubscription,
        getCreateSubscriptionSuggestion,
        getUpgradeSubscriptionSuggestion,
        getDowngradeSubscriptionSuggestion,
        toastApi,
        modalApi,
        onSave: () => customerView.load({ id })
    });
    return (_jsxs(_Fragment, { children: [customerView.render(), _jsx(FormModal, { modal: editCustomerForm }), _jsx(FormModal, { modal: createSubscriptionForm })] }));
};
