import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import ViewModal from 'Components/ObjectViewModal/components/ViewModalStyled';
import { generateDocument, generateDocumentsBulk } from 'Services/DocumentGenerator';
import { Button, Div, FlexCol, FlexRow, Title } from 'Components/Atoms';
import { Header, Section } from 'Atoms/Layout/components';
import { Checkbox } from 'Atoms/Input/controls';
import { translate } from 'Services/App';
import { useDispatcherApi } from 'Components/Dispatcher';
import Translator from 'bazinga-translator';
import { useEventCallback } from '@restart/hooks';
const SectionsBlock = (props) => {
    const { documentSections, selectedTemplates, onSelectSection, onSelectTemplate, } = props;
    const dispatcher = useDispatcherApi();
    return (_jsx(_Fragment, { children: documentSections.map((section, i) => {
            const sectionTemplates = section.documents;
            const hasSomeSectionTemplatesSelected = sectionTemplates.some(item => selectedTemplates.includes(item));
            const isEntireSectionSelected = hasSomeSectionTemplatesSelected && sectionTemplates.every(item => selectedTemplates.includes(item));
            const indeterminate = !isEntireSectionSelected && hasSomeSectionTemplatesSelected;
            return _jsxs(React.Fragment, { children: [_jsxs(FlexRow, { gap: 20, children: [_jsx(Checkbox, { checked: isEntireSectionSelected, indeterminate: indeterminate, onChange: () => onSelectSection(section, !isEntireSectionSelected) }), _jsx(FlexCol, { flex: "1", children: section.title })] }), _jsx(Div, { style: { margin: '.5rem 0 1rem 3rem' }, children: section.documents.map((document, i) => {
                            const isSelected = selectedTemplates.includes(document);
                            return _jsx(React.Fragment, { children: _jsxs(FlexRow, { gap: 20, children: [_jsx(Checkbox, { checked: isSelected, onChange: () => onSelectTemplate(document, !isSelected) }), _jsx(FlexCol, { flex: "1", children: _jsx(ViewModal.DocumentLink, { icon: "download", downloadIcon: null, label: document.title, tooltip: document.isPropertySpecific
                                                    ? { content: translate('document.isPropertySpecific.help') }
                                                    : undefined, onDownload: () => {
                                                    generateDocument(document.title, document.template, document.sourceName, document.sourceId, {
                                                        instanceKey: document.templateInstanceKey,
                                                    }, { dispatcher });
                                                } }) })] }) }, `doc-link-${i}`);
                        }) })] }, `doc-section-${i}`);
        }) }));
};
const ContractDocumentArea = ({ documentSections }) => {
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const dispatcher = useDispatcherApi();
    const onSelectSection = useEventCallback(({ documents }, active) => {
        setSelectedTemplates(prev => active
            ? Array.from(new Set([...prev, ...documents]))
            : prev.filter(t => !documents.includes(t)));
    });
    const onSelectTemplate = useEventCallback((template, active) => {
        setSelectedTemplates(prev => active ? [...prev, template] : prev.filter(t => t !== template));
    });
    const onBatchExport = async () => {
        if (selectedTemplates.length === 0) {
            return;
        }
        try {
            await generateDocumentsBulk({
                documents: selectedTemplates,
            });
            dispatcher?.toast?.success({
                message: translate('contract.export.successMessage'),
                timeout: 5,
                showRemove: true
            });
        }
        catch (e) {
            dispatcher?.toast?.error?.({
                message: Translator.trans("popup.documents.error.couldNotBeGenerated", {}, "messages"),
            });
            console.error(e);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Header, { children: _jsxs(FlexRow, { justifySpaceBetween: true, children: [_jsx(Title, { children: translate('documents') }), _jsx(Button, { outline: true, icon: "download", label: translate('documents_export'), disabled: selectedTemplates.length === 0, onClick: () => onBatchExport() })] }) }), _jsx(Section, { children: _jsx(SectionsBlock, { documentSections: documentSections, selectedTemplates: selectedTemplates, onSelectSection: onSelectSection, onSelectTemplate: onSelectTemplate }) })] }));
};
export default ContractDocumentArea;
