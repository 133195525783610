import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { JsonCode, Title } from 'Atoms/Text';
import { Nav, navSlot } from 'Atoms/Nav';
import { WizardLayout } from 'Components/Layout';
import { Button } from 'Atoms/Button';
import { translate } from 'Services/App';
import { isDevEnv, OrFunction } from '@f2w/utils';
import { Modal } from 'Components/Modal';
import { defaultTemplate } from './defaultTemplate';
import { useWizardContext } from '@f2w/view';
import styled, { css } from 'styled-components';
import { Icon } from 'Atoms/Icon';
import { SpinnerNew } from 'Atoms/Spinner';
import { ariaAttr } from '@f2w/theme';
import { TabsNav } from '@f2w/form-new';
const WizardModalTemplate = (props) => {
    const go = useNavigate();
    return _jsx(Modal, { fullScreen: true, backdrop: false, show: true, size: "fullscreen", onHide: () => go('/'), children: _jsx(Outlet, {}) });
};
export const modalTemplate = {
    ...defaultTemplate,
    Root: (props) => {
        const { config, wizard } = props;
        return _jsx(_Fragment, { children: _jsxs(WizardLayout.Root, { children: [_jsx(WizardLayout.Head, { onHide: () => wizard?.close(), children: _jsx(Title.H6, { neutral: 500, children: OrFunction(config.title) ?? 'Default view title' }) }), wizard ? _jsx(StepBody, { ...props }) : _jsx(WizardLayout.Body, { children: _jsx(Fallback, {}) })] }) });
    },
};
const isDebug = isDevEnv();
const StepBody = ({ children, ...props }) => {
    const { wizard } = props;
    return _jsx(WizardLayout.BodyWizard, { side: _jsx(WizardLayout.Side, { children: _jsx(SideNavigation, {}) }), footer: _jsx(WizardLayout.Footer, { children: _jsx(FooterNavigation, {}) }), preview: _jsx(Preview, { ...props }), children: _jsx(WizardLayout.Main, { children: wizard.loading ? _jsx(Fallback, {}) : children }) });
};
const WizardLink = (props) => {
    return _jsx(NavLink, { ...props, className: ({ isActive, isPending, isTransitioning }) => [
            props.className ?? '',
            isPending ? 'pending' : '',
            isActive ? 'active' : '',
            isTransitioning ? 'transitioning' : '',
        ].join(' ') });
};
const NavRoot = styled(Nav)(({ theme: { typography, palette } }) => {
    const textSize = typography.small;
    return css `
        ${navSlot.css('inner')} {
            gap: 40px;
        }
        ${navSlot.css('item')} {
        }
        ${navSlot.css('link')} {
            justify-content: start;
            align-content: start;
            padding: 0;
            ${textSize.fontStyles};

            display: flex;
            align-items: center;
            text-decoration: none;
            flex-wrap: nowrap;
            transition-duration: 250ms;
            transition-timing-function: ease-in;
            transition-property: background-color, border-color, opacity, color;
            color: ${palette.dark.$500};
            gap: 8px;

            [data-slot="label"] {
                flex: 1;
            }

            [data-slot="avatar"] {
                flex-shrink: 0;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                font-size: inherit;
                line-height: inherit;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 4px;
                background: ${palette.success.$500};
                color: ${palette.white};
            }
            &[aria-selected=true] {
                [data-slot="avatar"] {
                    background: ${palette.primary.$500};
                }
            }
            &[aria-disabled=true] {
                color: ${palette.neutral.$200};
                [data-slot="avatar"] {
                    color: ${palette.neutral.$200};
                    border: 1px dashed ${palette.neutral.$200};
                    background: transparent;
                }
            }
        }
    `;
});
const SideNavigation = () => {
    const { stepApi } = useWizardContext();
    return _jsx(_Fragment, { children: _jsx(NavRoot, { position: 'left', children: stepApi.stepList.map((step) => {
                const meta = step.valueType.meta;
                const disabled = !step.isEnabled;
                const success = !disabled && step.isCompleted && !step.isCurrent;
                const hadError = !disabled && !step.isCurrent && meta.hasError;
                return (_jsx(React.Fragment, { children: _jsx(Nav.Item, { children: _jsxs(Nav.Link, { "aria-disabled": ariaAttr(disabled), "aria-selected": ariaAttr(step.isCurrent), as: NavLink, to: step.id, children: [_jsx("span", { "data-slot": 'avatar', children: success ? _jsx(Icon, { icon: 'check' }) : (step.index + 1) }), _jsx("span", { "data-slot": 'label', children: step.title() }), hadError && _jsx(Icon, { icon: 'alert-circle' })] }) }) }, `step-${step.id}}`));
            }) }) });
};
const FooterNavigation = () => {
    const { wizard, stepApi, valueType } = useWizardContext();
    return _jsx(_Fragment, { children: _jsxs(Button.Toolbar, { style: { marginLeft: 'auto' }, children: [_jsx(Button, { icon: 'arrow-left', variant: 'outlineFill', label: translate('contractModification.backAction'), onClick: () => stepApi.gotoPrevStep(), disabled: stepApi.isFirst }), wizard.config?.showSaveDraft && _jsx(Button, { variant: 'link', label: translate('contractModification.saveDraftAction'), onClick: () => stepApi.handleSaveDraft() }), stepApi.isLast ? (_jsx(Button, { variant: 'fill', label: translate('contractModification.saveAction'), reverse: true, onClick: () => stepApi.handleNext() })) : (_jsx(Button, { variant: 'fill', icon: 'arrow-right', label: translate('contractModification.nextAction'), reverse: true, onClick: () => stepApi.handleNext() }))] }) });
};
const Preview = ({}) => {
    let { stepApi } = useWizardContext();
    const valueType = stepApi.valueType;
    return isDebug ? (_jsx(_Fragment, { children: _jsx(WizardLayout.Preview, { style: { padding: 0 }, children: _jsx(TabsNav, { style: {}, tabs: [
                    {
                        name: 'Value',
                        isDefault: true,
                        content: () => _jsxs(_Fragment, { children: [_jsx(JsonCode, { indent: true, title: "Error", value: valueType.error }), _jsx(JsonCode, { indent: true, title: "Value", value: valueType.getResolvedValue() })] }),
                    },
                    {
                        name: 'Raw Value',
                        content: () => _jsxs(_Fragment, { children: [_jsx(JsonCode, { indent: true, title: "Value", value: valueType.getResolvedValue() }), _jsx(JsonCode, { indent: true, title: "Initial value", value: valueType.initialValue })] }),
                    },
                    {
                        name: 'Props',
                        content: () => _jsx(_Fragment, { children: _jsx(JsonCode, { indent: 2, value: valueType.__dumpProps() }) }),
                    },
                    {
                        name: 'Meta',
                        content: () => _jsx(_Fragment, { children: _jsx(JsonCode, { indent: true, title: "Touched", value: valueType.touched }) }),
                    },
                ] }) }) })) : (_jsx(_Fragment, { children: _jsx(WizardLayout.Preview, {}) }));
};
const Fallback = ({ children = undefined }) => {
    return _jsx(SpinnerNew, { alignItems: "center", justifyContent: "center" });
};
