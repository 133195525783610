import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Div, FlexCol, Nav } from "Components/Atoms";
import React, { useState } from "react";
import { CalculationServices } from "Pages/AncillaryExpense";
import { FixedDistributionTable } from "Pages/AncillaryExpense/components/CalculationDistributionSection/components/FixedDistributionTable";
import { ConsumptionDistributionTable } from "Pages/AncillaryExpense/components/CalculationDistributionSection/components/ConsumptionDistributionTable";
import Spinner from "./Spinner";
import { translate } from "Services/App";
import { DataTable } from "@f2w/data-table";
import { ContractContributionBillingTable } from "Pages/AncillaryExpense/table/ContractContributionBillingTable";
import { Callout } from "Atoms/Callout";
export const DistributionsSection = ({ isViewMode, distributionSection, overviewData, calculationId, isLoading: baseIsLoading, calculationServices = CalculationServices, setRefreshData, }) => {
    const [editMode, setEditMode] = React.useState(false);
    const [showBillings, setShowBillings] = useState(true);
    const { accountDistributions, refreshData, activeAccountId, setAccount, distributionMap, isLoading, setIsLoading, contractContributionBillings } = distributionSection;
    const instance = ContractContributionBillingTable.use({
        billings: contractContributionBillings,
        overviewData: overviewData,
    });
    React.useEffect(() => {
        setRefreshData(() => refreshData);
    }, [refreshData]);
    React.useEffect(() => {
        if (!isLoading && baseIsLoading) {
            setIsLoading(true);
        }
    }, [baseIsLoading]);
    return (_jsxs(Div, { style: { position: "relative" }, children: [_jsx(Spinner, { isVisible: isLoading }), _jsxs(Nav, { id: "distributions-nav", variant: 'tabs', position: 'top', onSelect: (eventKey) => {
                    if (eventKey === 'billings') {
                        setAccount(0);
                        setShowBillings(true);
                    }
                    else {
                        setAccount(Number(eventKey));
                        setShowBillings(false);
                    }
                }, activeKey: activeAccountId !== 0 ? activeAccountId : "billings", children: [_jsx(Nav.Link, { eventKey: "billings", label: translate('ancillaryExpenseCalculation.overview') }), Array.from(distributionMap.values()).map(({ accountId, accountName }) => (_jsx(Nav.Link, { eventKey: accountId, label: accountName, disabled: editMode && accountDistributions.accountId !== accountId }, `distributions-nav-${accountId}`)))] }), _jsxs(FlexCol, { gap: 20, children: [showBillings && (_jsxs(FlexCol, { pt: 20, gap: 10, children: [_jsx(Callout, { icon: undefined, message: translate('ancillaryExpenseCalculation.overview.info') }), _jsx(DataTable.SimpleTable, { instance: instance })] })), accountDistributions?.allocation_formula && (_jsx(FixedDistributionTable, { distributionAccount: accountDistributions.allocation_formula, overviewData: overviewData, loadData: () => refreshData() })), accountDistributions?.consumption && (_jsx(ConsumptionDistributionTable, { isViewMode: isViewMode, accountDistributions: accountDistributions, overviewData: overviewData, loadData: () => refreshData(), setEditMode: setEditMode, calculationId: calculationId, updateFixCostDistributionAmount: calculationServices.updateFixCostDistributionAmount, saveMeters: calculationServices.saveMeters }))] })] }));
};
