import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from "@f2w/data-table/table-types";
import { HandoverInvoicedStatus } from "Pages/Administration/Customers/enums";
import { ProtocolPersonDirection } from "Pages/Handover/enums/ProtocolPersonDirection";
import { useCallback } from "react";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { getGlobalContext, translate } from "Services/App";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { formatPersonName } from "Pages/Handover/services/HandoverFormatter";
import { HandoverDirection } from "Pages/Handover/enums/HandoverDirection";
import { Icon } from "Atoms/Icon";
import { Badge } from "Atoms/Badge";
import { Text } from "Atoms/Text";
import { DateType } from "@f2w/form";
import { Select } from "Atoms/Select";
import { DropdownButton } from "Atoms/Button";
import { Dropdown } from "Atoms/Dropdown";
import { UserType } from "Types/User";
const TenantRow = ({ tenants, direction }) => {
    const tenant = tenants
        .find((v) => v.direction === direction);
    if (!tenant) {
        return (_jsx("div", { style: { color: 'var(--fw-color-neutral-100)' }, children: translate('handovers.listView.vacancy') }));
    }
    const tenantStyle = !tenant.attended ? { color: 'var(--fw-color-neutral-100)' } : {};
    return (_jsx("div", { children: _jsx("span", { style: tenantStyle, children: formatPersonName(tenant) }) }));
};
const MoveIcon = ({ direction }) => {
    const icon = direction === HandoverDirection.IN
        ? 'move-in'
        : direction === HandoverDirection.OUT
            ? 'move-out'
            : 'move-in-and-out';
    return (_jsx(Icon, { icon: icon, size: 24, style: { display: 'block', margin: 'auto 0' } }));
};
const BaseTable = Table.factory()(() => ({
    manualControl: true,
    tableId: 'handover-cost-overview-table',
    usePagination: {},
    useRowSelect: {},
    BulkActions: ({ selectedFlatRows, invoice, dropInvoice, refresh }) => {
        const { user: { userType } } = getGlobalContext();
        return (_jsxs(DropdownButton, { label: 'Set as', disabled: selectedFlatRows.length === 0 || userType !== UserType.FAIRWALTER, children: [_jsx(Dropdown.Item, { onClick: () => invoice(selectedFlatRows.map(row => row.original.id), refresh), children: "Invoiced" }), _jsx(Dropdown.Item, { onClick: () => dropInvoice(selectedFlatRows.map(row => row.original.id), refresh), children: "Open" })] }));
    },
    columns: {
        $rowSelect: {},
        customer: {
            hide: true
        },
        tenants: {
            Header: translate('handovers.listView.columns.parties'),
            Cell: ({ row: { original: { tenants, type } } }) => {
                return (_jsxs(FlexRow, { gap: 16, children: [_jsx(FlexCol, { alignItems: 'center', children: _jsx(MoveIcon, { direction: type }) }), _jsxs(FlexCol, { gap: 7, children: [_jsx(TenantRow, { tenants: tenants, direction: ProtocolPersonDirection.OUT }), _jsx(TenantRow, { tenants: tenants, direction: ProtocolPersonDirection.IN })] })] }));
            },
        },
        rentalObjectId: {
            Header: translate('rentalObject'),
            Cell: ({ row: { original } }) => {
                if (original.rentalObjectId === null) {
                    return null;
                }
                return (_jsxs(FlexCol, { gap: 5, children: [_jsx(Div, { children: original.rentalObjectName }), _jsx(Div, { children: _jsx(Text, { small: 400, neutral: 300, children: original.buildingName }) })] }));
            }
        },
        status: {
            Header: translate('handovers.listView.columns.status'),
            Cell: () => (_jsx(Badge, { "$color": 'primary', children: "Abgeschlossen" })),
        },
        datum: {
            Header: translate('handovers.listView.columns.date'),
            accessor: v => DateType.format(v.handoverDate)
        },
        invoiceStatus: {
            Header: 'Invoice status',
            Cell: ({ value }) => value === HandoverInvoicedStatus.OPEN
                ? _jsx(Badge, { "$color": 'success', children: "Open" })
                : _jsx(Badge, { "$color": 'primary', children: "Invoiced" }),
            useFilters: {
                Filter: ({ column: { filterValue, setFilter } }) => (_jsx(Select, { placeholder: 'Invoice status', options: [
                        {
                            label: 'Open',
                            value: HandoverInvoicedStatus.OPEN
                        },
                        {
                            label: 'Invoiced',
                            value: HandoverInvoicedStatus.INVOICED
                        }
                    ], value: filterValue || '', isClearable: true, onChange: (newValue) => setFilter(newValue?.value) }))
            }
        },
        invoicedAt: {
            Header: 'Invoiced at',
            accessor: v => DateType.format(v.invoicedAt)
        },
    }
}));
export const HandoverCostOverviewDataTable = Object.assign(BaseTable, {
    use({ listHandoverCostOverview, invoiceHandoverProtocols, dropInvoiceHandoverProtocols, customerId }) {
        const { success } = useToastDispatcherApi();
        const invoice = useCallback((ids, refresh) => {
            invoiceHandoverProtocols(ids)
                .then(() => {
                success({
                    message: 'Selected handovers invoiced successfully',
                });
                refresh();
            });
        }, []);
        const dropInvoice = useCallback((ids, refresh) => {
            dropInvoiceHandoverProtocols(ids)
                .then(() => {
                success({
                    message: 'Invoices from selected handovers dropped successfully',
                });
                refresh();
            });
        }, []);
        return HandoverCostOverviewDataTable.useTable({
            invoice,
            dropInvoice
        }, {
            initialState: customerId ? {
                filters: [
                    {
                        id: 'customer',
                        value: customerId
                    }
                ]
            } : {},
            getData: async ({ request }) => listHandoverCostOverview(request)
        });
    }
});
