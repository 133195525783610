import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Await, Outlet, useAsyncError, useLoaderData, useParams, useRouteError, } from 'react-router-dom';
import { Div } from 'Atoms/Layout';
import { Text } from 'Atoms/Text';
import { useWizardContext, WizardType } from './core';
import { NavigateWithParams } from '@f2w/utils';
function render(Template, props) {
    const context = {
        ...props,
        ...useWizardContext()
    };
    return _jsx(Template, { ...context });
}
export function BaseController(props) {
    const config = props.config;
    useEffect(() => {
        console.debug('-- MOUNT - Base Controller');
        return () => console.debug('-- UN-MOUNT - Base Controller');
    }, []);
    return render(config.layout.Base, {
        key: `skeleton--${config.id}`,
        children: _jsx(Outlet, { context: { config } }),
        config
    });
}
export function RootController(props) {
    const { config } = useWizardContext();
    const { data } = useLoaderData();
    useEffect(() => {
        console.debug('-- MOUNT - Root Controller');
        return () => console.debug('-- UN-MOUNT - Root Controller');
    }, []);
    return _jsx(_Fragment, { children: _jsx(React.Suspense, { fallback: render(config.layout.Root, { loading: true, config }), children: _jsx(Await, { resolve: data, errorElement: _jsx(ErrorController, {}), children: (data) => {
                    const wizard = WizardType.use({ config, data });
                    return _jsx(Outlet, { context: { wizard, config: config } }, wizard.id);
                } }) }) });
}
export function StepController() {
    const context = useWizardContext();
    const { wizard } = context;
    const { '*': stepId } = useParams();
    const redirectTo = wizard.setStep(stepId);
    if (redirectTo) {
        return _jsx(NavigateWithParams, { to: redirectTo.path, replace: true });
    }
    return wizard.render();
}
export function ErrorController() {
    const context = useWizardContext();
    const routeError = useRouteError();
    const asyncError = useAsyncError();
    const error = routeError || asyncError;
    console.debug('-- Error Controller --');
    if (context?.config) {
        const { config } = context;
        return render(config.layout.Error, {
            children: error?.message ?? 'Unknown error happened',
            error
        });
    }
    return _jsx(Div, { bg: '#fff', children: _jsx(Text, { h6: true, error: 600, children: error?.message || 'Missing wizard configuration.' }) });
}
