export var DocumentArea;
(function (DocumentArea) {
    DocumentArea["ContractCommercial"] = "contract.commercial";
    DocumentArea["ContractResidential"] = "contract.residential";
    DocumentArea["ContractParking"] = "contract.parking";
    DocumentArea["ContractModification"] = "contract.modification";
    DocumentArea["DebitorInvoiceRent"] = "debitorInvoice.rent";
    DocumentArea["DebitorInvoiceManual"] = "debitorInvoice.manual";
    DocumentArea["DebitorInvoiceDunning1"] = "debitorInvoice.dunning1";
    DocumentArea["DebitorInvoiceDunning2"] = "debitorInvoice.dunning2";
    DocumentArea["DebitorInvoiceDunning3"] = "debitorInvoice.dunning3";
    DocumentArea["Termination"] = "termination";
    DocumentArea["AncillaryExpense"] = "ancillary_expense";
    DocumentArea["CoverLetter"] = "cover_letter";
    DocumentArea["AdditionalInfo"] = "additional_info";
})(DocumentArea || (DocumentArea = {}));
