import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FeatureFlagDataTable } from "Pages/Administration/FeatureFlag/table/FeatureFlagDataTable";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import { translateAdmin } from "Services/App";
import { useUpsertFeatureFlagForm } from "Pages/Administration/FeatureFlag/hooks/useUpsertFeatureFlagForm";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { FormModal } from "@f2w/form";
export const FeatureFlagPage = ({ featureFlagServices }) => {
    const { success } = useToastDispatcherApi();
    const instance = FeatureFlagDataTable.use({
        listFeatureFlags: featureFlagServices.listFeatureFlags,
        getAvailableFeatures: featureFlagServices.getAvailableFeatures,
        createFeatureFlag: () => form.openForm(),
        updateFeatureFlag: row => form.openForm(row),
        deleteFeatureFlag: id => {
            featureFlagServices.deleteFeatureFlag(id)
                .then(() => {
                instance.refresh();
                success({
                    message: translateAdmin('featureFlag.delete.success')
                });
            });
        }
    });
    const form = useUpsertFeatureFlagForm({
        createFeatureFlag: featureFlagServices.createFeatureFlag,
        updateFeatureFlag: featureFlagServices.updateFeatureFlag,
        onSave: () => instance.refresh()
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, inlineFilters: true, title: translateAdmin('featureFlag.title') }), _jsx(FormModal, { modal: form })] }));
};
