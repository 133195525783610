import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { ContractState } from 'Enums/ContractState';
import generateRoute, { generate } from 'Services/Router';
import { Badge, Button, ButtonGroup, FlexRow } from 'Components/Atoms';
import { ContentTemplateNew, SplitViewTemplate } from '@f2w/view';
import { Header } from 'Atoms/Layout/components';
import { RightSide } from 'Pages/Contract/components/DetailView/components/RightSide';
import QuickschildBlock from 'Pages/Quickschild/components/QuickschildBlock';
import { withParams } from '@f2w/utils';
import { hasFeature } from 'Services/FeatureFlagManager';
const getStateBadge = (state) => {
    const stateColors = {
        [ContractState.ACTIVE]: 'success',
        [ContractState.UPCOMING]: 'primary',
        [ContractState.TERMINATED]: 'danger',
        [ContractState.NOTICED]: 'warning',
        [ContractState.LIMITED]: 'primary',
    };
    const color = stateColors[state] || 'primary';
    return _jsx(Badge, { "$color": color, children: translate(`contract.state.${state}`) });
};
const HeaderTemplate = ({ view: { data: contract }, }) => {
    const rentalObject = contract.rentalObject;
    return _jsxs(_Fragment, { children: [_jsx(Header.Avatar, { pretitle: `${translate('contract')} #${contract?.id}`, title: rentalObject.buildingName, subtitle: rentalObject.name, actions: getStateBadge(contract?.state) }), _jsxs(FlexRow, { justifyFlexEnd: true, mt: 20, gap: 10, children: [contract.isVacancy === false && _jsx(QuickschildBlock, { contract: contract }), _jsx(ButtonGroup, { condense: true, children: hasFeature('NEW_NOTICE_WIZARD') ? (_jsx(Button, { outline: true, color: "error", label: translate('contract.details.actions.terminate'), disabled: contract.isVacancy
                                || contract.state === ContractState.TERMINATED
                                || contract.state === ContractState.NOTICED
                                || contract.isNoticed, href: withParams(`/app/wizard/contract-notice/${contract.id}`, {
                                referer: generate('contracts.overview', { contractId: contract.id })
                            }) })) : (_jsx(Button, { outline: true, color: "error", label: translate('contract.details.actions.terminate'), disabled: contract.isVacancy
                                || contract.state === ContractState.TERMINATED
                                || contract.state === ContractState.NOTICED
                                || contract.isNoticed, onClick: () => {
                                window.open(generateRoute('offboard', {
                                    contract: contract.id
                                }), '_blank').focus();
                            }, confirm: {
                                message: translate('contract.details.actions.terminate.areYouSure'),
                                help: translate('contract.details.actions.terminate.confirmationText'),
                            } })) })] })] });
};
export const ContractTemplate = (props) => {
    return _jsx(SplitViewTemplate, { ...props, Template: ContentTemplateNew, leftSide: {
            groups: props.view.data.isVacancy === true
                ? ['general']
                : ['general', 'rent', 'ancillaryExpenses', 'persons', 'deposit'],
            Header: HeaderTemplate,
        }, rightSide: {
            groups: props.view.data.isVacancy === true
                ? ['uploads']
                : ['documents', 'uploads'],
            Template: RightSide,
        } });
};
