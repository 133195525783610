import { jsx as _jsx } from "react/jsx-runtime";
import { ContentTemplateNew, SplitViewTemplate } from '@f2w/view';
import { Header } from 'Atoms/Layout/components';
const HeaderTemplate = ({ view: { data: handover }, }) => (_jsx(Header.Avatar, { title: handover.building.name, subtitle: handover.rentalObject.name }));
export const HandoverTemplate = (props) => {
    const protocol = props.view.data;
    const leftSideGroups = ['general'];
    if (protocol.outAttendance) {
        leftSideGroups.push('moveOut');
    }
    if (protocol.inAttendance) {
        leftSideGroups.push('moveIn');
    }
    const rightSideGroups = [];
    if (protocol.document !== null) {
        rightSideGroups.push('filePreview');
    }
    return _jsx(SplitViewTemplate, { ...props, Template: ContentTemplateNew, leftSide: {
            groups: leftSideGroups,
            Header: HeaderTemplate,
        }, rightSide: {
            groups: rightSideGroups,
        } });
};
