import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from "styled-components";
import { Icon } from "Components/Atoms/Icon";
import { IconMap, NotificationState } from "Components/Notifications/types";
import classnames from "classnames";
import { Button, StyledButton } from 'Atoms/Button/Button';
import { translate } from "Services/Translator";
import moment from "moment/moment";
export const Message = Object.assign(({ data, api }) => {
    const timeAgo = moment(data.createdAt).fromNow();
    const iconName = React.useMemo(() => IconMap[data.topic] || 'user', [data.topic]);
    const buttonTitle = data.topic === 'handovers_protocol'
        ? translate('notificationAction.show')
        : translate('notificationAction.download');
    return (_jsxs(Message.Base, { className: classnames(data.isUnread ? 'state-is-unread' : 'state-is-read'), onMouseLeave: () => {
            api.markAsRead && data.isUnread && api.markAsRead(data.id);
        }, children: [_jsx(Message.Icon, { "aria-busy": data.state === NotificationState.IN_PROGRESS, children: _jsx(Icon, { icon: iconName }) }), _jsx(Message.Content, { children: _jsx("p", { children: data.message }) }), data.url
                && _jsx("div", { children: _jsx(Button, { href: data.url, "$size": 'sm', children: buttonTitle }) }), _jsx(Message.Time, { children: timeAgo })] }));
}, {
    Time: styled.span `
      ${p => p.theme.typography.pretitle.css({ textTransform: undefined })};
      color: ${p => p.theme.palette.neutral.$300};
      top: 8px;
      right: 18px;
      position: absolute;
    `,
    Icon: styled.div `
      text-align: center;
      position: relative;
      font-size: 20px;
      margin: 0;
      width: 60px;
      flex: 0 0 auto;
      :is([aria-busy=true]):before {
        display: block;
        content: "";
        position: absolute;
        left: 10px;
        top: -10px;
        width: 40px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 2px solid transparent;
        border-right-color: var(--fw-color-primary-400);
        animation: l2 1s infinite linear;
      }

      @keyframes l2 {to{transform: rotate(1turn)}}
    `,
    Content: styled.div `
      display: flex;
      flex-grow: 1;
      //width: 100%;
      align-items: start;
      margin: 0 10px 0 0;
      ${p => p.theme.typography.medium.css({ textTransform: undefined })};
      color: ${p => p.theme.palette.neutral.$500};

      p {
        margin: 0;
      }
    `,
    Base: styled.div `
      display: flex;
      flex: 1;
      position: relative;
      width: 100%;
      align-items: start;
      ${p => p.theme.typography.small.css({ textTransform: undefined })};
      color: ${p => p.theme.palette.neutral.$500};
      background: ${p => p.theme.palette.white};
      text-align: left;
      border-left: transparent 1px solid;
      border-top: ${p => p.theme.palette.neutral.$50} 1px solid;
      margin: 0;
      padding: 30px 10px 30px 0;
      &.state-is-unread {
        border-left-color: ${p => p.theme.palette.primary.$400};
        background-color: ${p => p.theme.palette.primary.$50};
      }
      &.state-is-unread + &.state-is-unread {
        border-top-color: ${p => p.theme.palette.white};
      }
      ${StyledButton} {
        align-self: end;
      }
    `,
});
