import { generate } from "Services/Router";
import client from "Utils/client";
export var PresetDistributionKeyServices;
(function (PresetDistributionKeyServices) {
    PresetDistributionKeyServices.getAllPresetDistributionKeys = async () => {
        const route = generate('api.ancillaryExpense.getPresetDistributionKeys');
        return client
            .get(route)
            .then(response => response.data);
    };
    PresetDistributionKeyServices.createUsingPresetDistributionKeys = (data) => {
        const route = generate('api.ancillaryExpense.createUsingPresetDistributionKeys');
        return client.post(route, data);
    };
})(PresetDistributionKeyServices || (PresetDistributionKeyServices = {}));
