import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { CreateRemindersType } from "Pages/Dunning/forms/CreateRemindersType";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
export const useCreateRemindersForm = ({ onClose, selectedRows, createReminders }) => useFormModal({
    name: 'create-reminders-form',
    type: () => new CreateRemindersType(),
    title: translate('reminderProcess.sendReminders'),
    onSave: (settings) => {
        const mappedRows = selectedRows.map(row => ({
            ...row,
            id: Number(row.id)
        }));
        return createReminders(mappedRows, settings)
            .then(onClose);
    },
    successMessage: translate('reminderProcess.successMessage', {
        count: selectedRows.filter(row => !isNaN(Number(row.id))).length
    }),
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.cancel') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('reminderProcess.sendReminders') })] });
    },
    ignoreConfirm: true
});
