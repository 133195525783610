import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SlotFactory } from '@f2w/theme';
import { SafeAnchor } from 'Atoms/SafeAnchor';
import { Icon } from 'Atoms/Icon';
import { inObject } from '@f2w/utils';
import '../Carousel.styles.scss';
import { Box } from 'Atoms/Box';
import { Text } from 'Atoms/Text';
export const carouselSlot = new SlotFactory('carousel');
export const Controls = ({ prevIcon, nextIcon, prevLabel, nextLabel, manager: { canNext, canPrev, next, prev }, }) => (_jsxs("div", { ...carouselSlot.attr('controls'), children: [canPrev && _jsx(BaseTrigger, { name: 'prev', icon: prevIcon ?? 'chevron-left', label: prevLabel ?? 'Previous', onClick: prev }), canNext && _jsx(BaseTrigger, { name: 'next', icon: nextIcon ?? 'chevron-right', label: nextLabel ?? 'Next', onClick: next })] }));
const BaseTrigger = (({ name, label, icon, ...props }) => {
    return _jsxs(SafeAnchor, { ...carouselSlot.attr(`trigger-${name}`), ...props, tabIndex: -1, children: [_jsx(Icon, { ...inObject(icon, 'icon') ? icon : { icon } }), _jsx("span", { className: "sr-only", children: label ?? name })] });
});
export const FullscreenTrigger = (props) => (_jsx(BaseTrigger, { ...props, name: 'fullscreen', icon: props.icon ?? 'maximize', label: props.label ?? 'Fullscreen' }));
export const Indicators = ({ manager: { activeIndex, children } }) => {
    return _jsx("div", { ...carouselSlot.attr('indicator-group'), children: _jsxs(Box, { as: Text, alignSelf: 'stretch', alignContent: 'center', bg: 'white', py: 5, px: 10, children: [activeIndex + 1, " / ", children.length] }) });
};
export const Pagination = ({ children, activeIndex, select, }) => {
    return _jsx("ol", { ...carouselSlot.attr('pagination'), children: children.map((_child, index) => (_jsx("li", { "aria-current": index === activeIndex, className: index === activeIndex ? 'active' : undefined, onClick: (event) => {
                select(index, event);
            } }, index))) });
};
