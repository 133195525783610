import { AbstractFormType, SelectType } from "@f2w/form";
import React, { useState } from "react";
import { usePortfolio } from "Components/Base/PortfolioContext";
export class PortfolioSelectType extends AbstractFormType {
    mandates = [];
    properties = [];
    buildings = [];
    rentalObjects = [];
    _useField(props) {
        super._useField(props);
        const [mandates, setMandates] = useState([]);
        const [properties, setProperties] = useState([]);
        const [buildings, setBuildings] = useState([]);
        const [rentalObjects, setRentalObjects] = useState([]);
        this.mandates = mandates;
        this.properties = properties;
        this.buildings = buildings;
        this.rentalObjects = rentalObjects;
        const portfolio = usePortfolio();
        React.useEffect(() => {
            setProperties(portfolio.getProperties());
            setMandates(portfolio.getMandates());
        }, []);
        React.useEffect(() => {
            let { mandateId, propertyId, buildingId, rentalObjectId } = this.field.value;
            const properties = mandateId ? portfolio.getPropertiesByMandateId(mandateId) : [];
            if (propertyId && !properties.find(({ id }) => id === propertyId)) {
                propertyId = null;
            }
            const buildings = propertyId ? portfolio.getBuildingsByPropertyId(propertyId) : [];
            if (buildingId && !buildings.find(({ id }) => id === buildingId)) {
                buildingId = null;
            }
            const rentalObjects = buildingId ? portfolio.getRentalObjectsByBuildingId(buildingId) : [];
            if (rentalObjectId && !rentalObjects.find(({ id }) => id === rentalObjectId)) {
                rentalObjectId = null;
            }
            this.field.value.propertyId = propertyId;
            this.field.value.buildingId = buildingId;
            this.field.value.rentalObjectId = rentalObjectId;
            setProperties(properties);
            setBuildings(buildings);
            setRentalObjects(rentalObjects);
        }, [this.field.value.mandateId, this.field.value.propertyId, this.field.value.buildingId]);
    }
    buildFields() {
        return {
            mandateId: new SelectType({
                label: 'Mandate',
                searchable: true,
                required: false,
                nullable: true,
                choiceOptions: () => this.mandates.map(mandate => ({
                    label: mandate.name,
                    value: mandate.id,
                })),
            }),
            propertyId: new SelectType({
                label: 'Liegenschaft',
                searchable: true,
                required: false,
                nullable: true,
                choiceOptions: () => this.properties.map(property => ({
                    label: property.name,
                    value: property.id,
                })),
                isVisible: () => this.field.value.mandateId !== null,
            }),
            buildingId: new SelectType({
                label: 'Gebäude',
                searchable: true,
                required: false,
                nullable: true,
                choiceOptions: () => this.buildings.map(building => ({
                    label: building.name,
                    value: building.id,
                })),
                isVisible: () => this.field.value.propertyId !== null,
            }),
            rentalObjectId: new SelectType({
                label: 'Mietobjekt',
                searchable: true,
                required: false,
                nullable: true,
                choiceOptions: () => this.rentalObjects.map(rentalObject => ({
                    label: rentalObject.name,
                    value: rentalObject.id,
                })),
                isVisible: () => this.field.value.buildingId !== null && this.options.hideRentalObject?.() === false,
            }),
        };
    }
}
