import { OrFunction } from '@f2w/utils';
import defineOptionsModifiers from './options/OptionsModifiers';
import defineConstraintsModifiers from './options/ConstraintsModifiers';
import { translate } from 'Services/Translator';
export function createSpecsApi(props) {
    const mutate = (fn) => {
        const _s = Schema.getSchema();
        Schema._schema = fn(_s) ?? _s;
    };
    const Validator = defineConstraintsModifiers(p => props.getConstraints({ ...p, mutate }));
    const optionsApi = defineOptionsModifiers(p => props.getOptionsModifiers?.({ ...p, mutate }), Validator);
    const Schema = {
        _schema: null,
        clear() {
            Schema._schema = null;
        },
        getSchema() {
            if (!Schema._schema) {
                Schema._schema = props.schemaType()
                    .label('This')
                    .typeError(translate('form.validation.fieldIsRequired'));
                if (props.getSchema)
                    Schema._schema = props.getSchema(Schema._schema);
            }
            return Schema._schema;
        },
    };
    const _this = {
        _initialOptions: null,
        getInitialOptions(opts) {
            if (!_this._initialOptions || opts) {
                const defaultOptions = OrFunction(props.defaultOptions);
                _this._initialOptions = {
                    ...defaultOptions,
                    ...opts,
                    constraints: {
                        ...defaultOptions?.constraints,
                        ...opts?.constraints,
                    },
                };
            }
            return _this._initialOptions;
        },
    };
    return {
        store: Object.create(null),
        _dump: () => ({
            initialOptions: _this._initialOptions,
            privateOptions: {
                ...optionsApi._own,
            },
            options: { ...optionsApi.options },
        }),
        validator: Validator,
        get schema() {
            return Schema.getSchema();
        },
        get initialOptions() {
            return _this.getInitialOptions();
        },
        get options() {
            return optionsApi.options;
        },
        init(opts) {
            Schema.clear();
            optionsApi.clear();
            optionsApi.update(_this.getInitialOptions(opts));
        },
        update(opts) {
            optionsApi.update(opts);
        },
        reset() {
            Schema.clear();
            optionsApi.clear();
            optionsApi.update(_this.getInitialOptions());
        },
        resetSchema() {
            Schema.clear();
            optionsApi.update(optionsApi.options);
        },
    };
}
