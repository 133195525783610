import { inObject, isNumOrStr, mapObject, objectKeys } from '@f2w/utils';
export var KeyOrChild;
(function (KeyOrChild) {
    function key(childOrKey) {
        return inObject(childOrKey, 'key') ? childOrKey.key : (isNumOrStr(childOrKey) ? String(childOrKey) : '');
    }
    KeyOrChild.key = key;
})(KeyOrChild || (KeyOrChild = {}));
export class IndexDescriptor {
    name;
    options;
    constructor(name, config, options) {
        this.name = name;
        this.options = options;
        Object.defineProperties(this, {
            config: {
                value: { name, ...config },
                enumerable: true,
                writable: false,
                configurable: false
            },
            _set: {
                value: new Set(),
                enumerable: false,
                writable: false,
                configurable: false
            },
        });
    }
    static create(key, config, options) {
        return new IndexDescriptor(key, config, options);
    }
    extend(config) {
        Object.assign(this.config, config(this.config));
        return this;
    }
    get size() {
        return this._set.size;
    }
    has(key) {
        return this._set.has(KeyOrChild.key(key));
    }
    values() {
        return [...this._set.values()];
    }
    get() {
        return this.config.getMetaValue(this._set);
    }
    add(key, ignoreUpdate) {
        key = KeyOrChild.key(key);
        if (this._set.has(key))
            return false;
        this._set.add(key);
        ignoreUpdate || this._handleUpdate([key], true);
        return true;
    }
    remove(key, ignoreUpdate) {
        key = KeyOrChild.key(key);
        if (!this._set.delete(key))
            return false;
        ignoreUpdate || this._handleUpdate([key], false);
        return true;
    }
    _update(child) {
        let add;
        if (this.config.resolveValue) {
            add = !!this.config.resolveValue(child);
        }
        else if (inObject(child['_state'].meta, this.name)) {
            add = !!child['_state'].meta[this.name];
        }
        return add == null ? false : this.update(child.key, add);
    }
    update(key, add, ignoreUpdate) {
        return (add ? this.add(key, ignoreUpdate) : this.remove(key, ignoreUpdate));
    }
    updateAll(keys, add, ignoreUpdate) {
        const updatedKeys = keys
            .map(k => KeyOrChild.key(k))
            .filter(k => this.update(k, add, true));
        if (updatedKeys.length && !ignoreUpdate)
            this._handleUpdate(updatedKeys, add);
        return updatedKeys.length > 0;
    }
    removeAll(keys, ignoreUpdate) {
        return this.updateAll(keys, false, ignoreUpdate);
    }
    toggle(key) {
        return this.update(key, !this.has(key));
    }
    clear(ignoreUpdate) {
        const updatedKeys = this.values();
        this._set.clear();
        if (!ignoreUpdate && updatedKeys.length)
            this._handleUpdate(updatedKeys, false);
        return updatedKeys.length > 0;
    }
    forEach(cb) {
        this._set.forEach(cb);
    }
    map(cb) {
        const items = [];
        this._set.forEach((key, index) => {
            const resolvedChild = cb(key, index);
            resolvedChild && items.push(resolvedChild);
        });
        return items;
    }
    _handleUpdate(keys, add) {
        const event = {
            propName: this.name,
            keys,
            add,
            index: this
        };
        if (add) {
            this.config.onAdd?.(keys, this);
            this.options?.onAdd?.(event);
        }
        else {
            this.config.onRemove?.(keys, this);
            this.options?.onRemove?.(event);
        }
        this.config.onChange?.(keys, add, this);
        this.options?.onChange?.(event);
    }
}
export class IndexManager {
    options;
    data = Object.create(null);
    constructor(options) {
        this.options = options;
    }
    static create(options) {
        return new IndexManager(options ?? {});
    }
    updateOptions(options) {
        Object.assign(this.options, options);
        return this;
    }
    create(key, config) {
        const data = this.data;
        if (!data[key])
            return data[key] = IndexDescriptor.create(key, config, this.options);
        return data[key].extend((prev) => ({ ...prev, ...config, }));
    }
    get(key) {
        return this.data[key];
    }
    add(name, config) {
        this.create(name, config);
        return this;
    }
    remove(name) {
        delete this.data[name];
        return this;
    }
    forEach(cb) {
        Object.keys(this.data).forEach(key => {
            cb(this.data[key], key);
        });
    }
    map(cb) {
        return mapObject(this.data, (key, index) => {
            return cb(index, key);
        });
    }
    update(cb, keys) {
        const updatedKeys = (keys ?? objectKeys(this.data))
            .filter((k) => (this.data[k] && cb(this.data[k], k)));
        return updatedKeys.length > 0 ? updatedKeys : null;
    }
}
