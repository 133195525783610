import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { AuditLogDataTable } from "Pages/Administration/AuditLog/table/AuditLogTableDataTable";
import PageOverview from "@f2w/data-table/layout/PageOverview";
export const AuditLogPage = ({ auditLogServices, getActiveCustomers }) => {
    const instance = AuditLogDataTable.use({
        listAuditLog: auditLogServices.listAuditLog,
        getActiveCustomers
    });
    return (_jsx(_Fragment, { children: _jsx(PageOverview, { instance: instance, title: "Audit Log", showFilters: true }) }));
};
