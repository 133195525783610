import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { Icon } from "Atoms/Icon";
const Actions = ({ cell: { value: rent }, row, onViewRent, onDeleteRent }) => {
    return (_jsx(ActionsDropdown, { id: row.id, quickActions: [
            {
                icon: 'eye',
                title: translate('rent.view'),
                onClick: () => {
                    onViewRent(rent);
                },
            },
        ], children: _jsxs(ActionsDropdown.Link, { confirm: translate('rent.delete.help'), onClick: () => onDeleteRent(row.id), children: [_jsx(Icon, { icon: 'trash' }), " ", translate('btn.delete')] }) }));
};
export default Actions;
