import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { ArrayValue, ArrayValueType, BaseType, } from '../../core';
import * as ArrayWidget from '../../theme';
export class BaseArrayType extends BaseType {
    constructor(options) {
        super(options);
    }
    _createValue(props) {
        return new ArrayValue(this, props);
    }
}
export class ArrayType extends BaseArrayType {
    static Widget = {
        list: ArrayWidget.CollectionList,
        table: ArrayWidget.CollectionTable,
        accordion: ArrayWidget.AccordionTable,
    };
    _getSpecs() {
        return new ArrayValueType({});
    }
    _createValue(props) {
        return super._createValue(props);
    }
    _renderWidget(props) {
        const valueType = props.field;
        const opts = valueType.options;
        let Widget = opts.Widget ?? ArrayType.Widgets[opts.widget];
        return _jsx(_Fragment, { children: Widget ? Widget(valueType) : valueType.renderChildren() });
    }
    _renderForm(valueType, props) {
        return _jsx(ArrayWidget.ArrayField, { children: this._renderWidget(props), ...props });
    }
    static Widgets = {
        list: (field) => _jsx(ArrayWidget.CollectionList, { field: field }),
        table: (field) => _jsx(ArrayWidget.CollectionTable, { field: field }),
    };
}
