import humanizeString from 'Utils/humanizeString';
import { lowerFirst, toString } from 'lodash';
import { translate } from 'Services/Translator';
import { isBool, isFunction, isString, orFunction, OrFunction, ValidationError, } from '@f2w/utils';
import { renderContent } from '../../renderContent';
export * from "../general";
export class BaseValueType {
    _type;
    own = Object.create(null);
    constructor(props) {
        const desc = { configurable: false, enumerable: false };
        Object.defineProperties(this, {
            own: {
                ...desc,
                writable: false,
                value: {
                    props: { ...props }
                },
            },
            props: {
                ...desc,
                get: () => this.own.props,
            },
            _type: {
                ...desc,
                value: Object.create(null),
                writable: true,
                configurable: true
            },
        });
        this.Renderer = this.Renderer.bind(this);
    }
    init(type, initialOptions) {
        this._type = type;
        this._specs.init({ ...initialOptions });
        return this;
    }
    $$bindParent(parent, key) {
        Object.assign(this.own.props, (parent && (key = toString(key))) ? { key, parent } : { key: null, parent: null });
        return this;
    }
    _dump() {
        return this._specs._dump();
    }
    get options() {
        return this._specs.options;
    }
    update(options) {
        this._specs.update(options);
    }
    reset(options) {
        this._specs.reset();
        this._specs.update(options);
    }
    getOptionsModifiers(props) {
        const { self: _this, own: _own, mutate: _mutate } = props;
        return {
            key: {
                readonly: true,
                get: () => (this.props.key ?? ''),
            },
            parent: {
                readonly: true,
                get: () => this.props.parent,
            },
            name: {
                getDefault: () => lowerFirst(this.constructor.name?.replace?.(/(Form|Type)$/, '')),
                set: value => value && isString(value) ? value : undefined,
            },
            hideLabel: {
                get: (value) => value ?? (this.isRoot || (!!value || !isString(_this.label))),
            },
            label: {
                set: (value) => {
                    if (isBool(value))
                        _this.hideLabel = !value;
                    else
                        return value;
                },
                get: (value) => OrFunction(value) ?? humanizeString(_this.key),
                update: () => _mutate(s => s.label(_this.errorLabel)),
            },
            errorLabel: {
                get: (value) => (value ?? _this.label ?? 'This'),
                update: () => _mutate(s => s.label(_this.errorLabel)),
            },
            nullable: {
                get: () => !!_own.nullable,
                update: (value) => _mutate(s => s.nullable(!!value)),
            },
            required: {
                get: () => !!_own.required,
                update: (value) => _mutate(s => value
                    ? s.required(isString(value) ? value : translate('form.validation.fieldIsRequired'))
                    : s.notRequired()),
            },
            defaultValue: {
                get: () => _own.defaultValue,
                update: (value) => _mutate(s => s.default(value)),
            },
        };
    }
    get key() {
        return (this.props.key ?? '');
    }
    get parent() {
        return this.props.parent;
    }
    get isRoot() {
        return (!this.parent);
    }
    get isOptional() {
        return !!this.options.optional ?? true;
    }
    get isRequired() {
        return !!this.options.required;
    }
    get isNullable() {
        return this.options.nullable ?? (!this.isRequired && (orFunction(this.options.defaultValue) == null));
    }
    get schema() {
        return this._specs.schema;
    }
    getDefault() {
        return this.parse(orFunction(this.options.defaultValue));
    }
    isType(v) {
        if (v == null) {
            if (this.isNullable && v === null)
                return true;
            if (this.isOptional && v === undefined)
                return true;
            return false;
        }
        return this._typeCheck(v);
    }
    cast(value, options) {
        if (typeof value === 'undefined' && !options?.ignoreDefaults)
            value = this.getDefault();
        value = this.parse(value);
        if (!this.isType(value) && !options?.ignoreDefaults)
            value = this.getDefault();
        if (this.props.cast)
            value = this.props.cast(value);
        return value;
    }
    parse(value) {
        if (this.props.parse)
            value = this.props.parse(value);
        return this.isType(value) ? value : this._cast(value);
    }
    async validate(value, options) {
        try {
            await this._specs.schema.validate(value, { abortEarly: true, ...options });
            ValidationError.throwError(this.props.validate?.(value, ValidationError));
            ValidationError.throwError(await this.props.validateAsync?.(value, ValidationError));
        }
        catch (e) {
            throw e;
        }
    }
    validateSync(value, options) {
        try {
            this._specs.schema.validateSync(value, { abortEarly: true, ...options });
            ValidationError.throwError(this.props.validate?.(value, ValidationError));
        }
        catch (e) {
            if (!ValidationError.isError(e))
                throw e;
            throw e;
        }
    }
    render({ field: valueType, options, as: Component, ...rest }) {
        try {
            options && valueType.specs.update(options);
            Component ??= valueType.isVisible ? this.Renderer : null;
            return !Component ? null : renderContent(Component, {
                key: `${valueType.id}--valueType-template`,
                ...rest,
                field: valueType,
            });
        }
        catch (error) {
            console.error('Render error', { type: this, error });
        }
        return null;
    }
    Renderer(props) {
        const { field: valueType } = props;
        try {
            const render = props.render || valueType.options.render;
            valueType.use({});
            return isFunction(render)
                ? render.call(this, valueType)
                : this._type.renderForm(valueType, {
                    key: `${valueType.id}--form`,
                    ...props,
                    field: valueType,
                });
        }
        catch (error) {
            console.error('Render error', { type: this, error });
        }
        return null;
    }
}
BaseValueType.prototype.__is_value_type = true;
