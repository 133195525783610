import client from 'Utils/client';
import { generate } from 'Services/Router';
import { isObject, pick, stripHtml } from '@f2w/utils';
import axios from 'axios';
export var CreatePropertyApi;
(function (CreatePropertyApi) {
    CreatePropertyApi.getSettings = async () => {
        const route = generate('portfolio.references');
        return client
            .get(route)
            .then(response => response.data);
    };
    CreatePropertyApi.getLocationData = async (id) => {
        const data = await LocationApi.getDetails(id);
        const buildings = await MaddApi.searchBuildings(data)
            .catch((error) => {
            console.error(error);
            return [];
        });
        return Promise.resolve({ ...data, buildings });
    };
    CreatePropertyApi.create = (propertyData) => {
        const route = generate('api.property.createExtended');
        return client
            .post(route, propertyData);
    };
    CreatePropertyApi.createBuilding = (propertyId, data) => {
        const url = generate('api.building.create');
        return client.post(url, {
            propertyId,
            ...data,
        });
    };
    CreatePropertyApi.createRentalObject = (buildingId, data) => {
        const url = generate('rentalObject.create');
        return client.post(url, {
            buildingId,
            ...data
        });
    };
})(CreatePropertyApi || (CreatePropertyApi = {}));
export var LocationApi;
(function (LocationApi) {
    const baseUrl = 'https://api3.geo.admin.ch';
    LocationApi.getType = (value) => {
        if (LocationApi.isEgid(value))
            return 'EGID';
        if (LocationApi.isEgrid(value))
            return 'EGRID';
        return null;
    };
    LocationApi.isEgrid = (value) => (value && /^CH[0-9]+$/i.test(value));
    LocationApi.isEgid = (value) => (value && /^[0-9]{5,}$/.test(value));
    LocationApi.search = async (query) => {
        let params = {
            returnGeometry: false,
        };
        const field = LocationApi.isEgid(query) ? 'egid' : LocationApi.isEgrid(query) ? 'egrid' : null;
        let results = [];
        if (field) {
            results = (await _searchByField(field, query, params))
                .sort((a, b) => a.value.localeCompare(b.value));
        }
        else {
            results = (await _searchAddress(query, params));
        }
        return results.filter((data) => data.featureId);
    };
    const _searchAddress = async (query, params) => {
        return axios
            .get(`${baseUrl}/rest/services/api/SearchServer`, {
            params: {
                type: 'locations',
                features: 'ch.bfs.gebaeude_wohnungs_register',
                origins: 'address',
                searchText: query,
                ...params,
            },
        })
            .then((response) => response.data.results)
            .then(results => results
            .map(({ id, attrs, ...data }) => {
            const label = `${attrs?.label ?? id}`;
            return {
                id,
                label,
                value: stripHtml(label),
                featureId: attrs.featureId ?? null,
                attrs: {
                    weight: data.weight,
                    ...pick(attrs, [
                        'origin',
                        'detail',
                        'zoomlevel',
                        'lat',
                        'lon',
                        'x',
                        'y',
                    ]),
                },
            };
        })).catch(error => {
            console.error('API address search failed', error);
            return [];
        });
    };
    const _searchByField = async (field, query, params) => {
        return axios
            .get(`${baseUrl}/rest/services/api/MapServer/find`, {
            params: {
                layer: 'ch.bfs.gebaeude_wohnungs_register',
                ...params,
                searchField: field,
                searchText: query,
                contains: false,
            },
        })
            .then(response => response.data.results)
            .then(results => results
            .map(({ attributes: attrs, ...data }) => {
            const label = `${attrs.strname_deinr ?? ''} <b>${attrs.dplz4 ?? ''} ${attrs.dplzname ?? ''}</b>`;
            return {
                id: data.id,
                label,
                value: label ? stripHtml(label) : data.id.toString(),
                featureId: data.featureId ?? null,
                attrs: pick({ ...data.geometry, ...attrs }, [
                    'egrid',
                    'egid',
                    'strname_deinr',
                    'dplz4',
                    'gbauj',
                    'dplzname',
                    'gdekt',
                    'deinr',
                    'strname',
                    'strnamk',
                    'x',
                    'y',
                ]),
            };
        }));
    };
    const _getDetailsRequest = async ({ featureId, ...fields }, params) => {
        params = {
            returnGeometry: true,
            ...params,
        };
        if (featureId)
            return axios
                .get(`${baseUrl}/rest/services/api/MapServer/ch.bfs.gebaeude_wohnungs_register/${featureId}`, {
                params,
            })
                .then(response => response.data.feature);
        const field = ['egrid', 'egid', Object.keys(fields)].flat(2).find(key => fields[key]);
        if (field) {
            return axios
                .get(`${baseUrl}/rest/services/api/MapServer/find`, {
                params: {
                    layer: 'ch.bfs.gebaeude_wohnungs_register',
                    ...params,
                    searchField: field,
                    searchText: fields[field],
                    contains: false,
                },
            })
                .then(response => response.data?.results?.[0]);
        }
    };
    LocationApi.getDetails = async (id) => {
        let params = isObject(id) ? { ...id } : {
            [LocationApi.isEgid(id) ? 'egid' : LocationApi.isEgrid(id) ? 'egrid' : 'featureId']: id,
        };
        return _getDetailsRequest(params, { returnGeometry: true })
            .then(({ attributes: attrs, ...data }) => ({
            id: data.id,
            label: attrs.label?.trim() ?? '',
            egid: attrs.egid?.trim() ?? '',
            egrid: attrs.egrid?.trim() ?? '',
            address: {
                street: attrs.strname[0]?.trim() ?? '',
                houseNumber: attrs.deinr ?? '',
                city: attrs.dplzname?.trim() ?? '',
                zip: attrs.dplz4 ?? '',
            },
            featureId: data.featureId,
            pin: {
                lon: data.geometry.x,
                lat: data.geometry.y,
            },
            attrs: pick(attrs, [
                'strname_deinr',
                'dplz4',
                'dplzname',
                'gbauj',
                'gdekt',
                'deinr',
                'strname',
                'strnamk',
            ]),
        }));
    };
})(LocationApi || (LocationApi = {}));
export var MaddApi;
(function (MaddApi) {
    MaddApi.searchBuildings = async (params) => {
        const route = generate('madd.buildings.list');
        const { address, egid, egrid, } = params;
        let filter;
        if (egid)
            filter = { egid };
        else if (egrid)
            filter = { egrid };
        else if (address?.street)
            filter = {
                address: {
                    street: address.street,
                    building: address.houseNumber,
                    zip: address.zip,
                },
            };
        return client
            .get(route, { params: { filter } })
            .then(response => response.data.buildings);
    };
})(MaddApi || (MaddApi = {}));
