import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown } from 'Components/Atoms/Dropdown';
import { Icon } from 'Components/Atoms/Icon';
import NotificationList from 'Components/Notifications/NotificationList';
import cls from './NotificationDropdown.module.scss';
import { ariaAttr, cssVars, useTheme } from '@f2w/theme';
import styled, { css } from 'styled-components';
import useNotifications from 'Components/Notifications/useNotifications';
const NotificationCount = styled('span')(p => {
    const { rem, palette, typography } = useTheme();
    return css `
      position: absolute;
      right: ${rem(2)};
      top: ${rem(2)};
      color: ${palette.white};
      box-sizing: content-box;
      border: ${palette.white} 2px solid;
      background: ${palette.primary.$500};
      font-size: ${rem(10)};
      font-weight: ${typography.fontWeight.$600};
      line-height: 1em;
      display: inline-flex !important;
      justify-content: center;
      align-items: center;
      width: ${rem(16)};
      height: ${rem(16)};
      text-align: center;
      border-radius: 50%;
    `;
});
const NotificationDropdown = ({ loading, ...props }) => {
    const { palette, rem } = useTheme();
    const api = useNotifications(props);
    return (_jsxs(Dropdown, { variant: 'custom', className: cls.root, scrollable: false, "aria-busy": ariaAttr(api.inProgressCount > 0), onToggle: (isOpen) => {
            api.observerRef.current.isOpen = isOpen;
        }, children: [_jsxs(Dropdown.Toggle, { className: cls.btn, style: cssVars({
                    '--notification-color': palette.dark.$500
                }), link: true, nocaret: true, caret: false, id: "user-notification-list", children: [_jsx(Icon, { icon: "bell", size: rem(20), color: "var(--notification-icon-color)", fill: "var(--notification-icon-fill-color)" }), api.unreadCount > 0 ? _jsx(NotificationCount, { children: _jsx("span", { children: api.unreadCount }) }) : null] }), _jsx(Dropdown.Menu, { className: cls.menu, alignRight: true, children: _jsx(NotificationList, { api: api }) })] }));
};
export default NotificationDropdown;
