import { AbstractFormType, AddressType, EmailType, PhoneType, SelectType, StringType, TextType, UrlType, } from '@f2w/form';
import { translate } from 'Services/Translator';
import React from 'react';
import { getGlobalContext } from 'Services/GlobalContext';
import { getServiceProviderDomains } from '../ServiceProviderManager';
import { usePortfolio } from 'Components/Base/PortfolioContext';
export class ServiceProviderType extends AbstractFormType {
    domains = [];
    properties = [];
    _useField(props) {
        super._useField(props);
        const [domains, setDomains] = React.useState([]);
        this.domains = domains;
        const [properties, setProperties] = React.useState([]);
        this.properties = properties;
        const portfolio = usePortfolio();
        const { scope } = getGlobalContext();
        React.useEffect(() => {
            setProperties(portfolio.getProperties().map(property => ({
                value: property.id,
                label: property.name
            })));
            getServiceProviderDomains()
                .then((domainsList) => setDomains(domainsList.map(domain => ({
                label: domain.name,
                value: domain.id
            }))));
            if (!this.field.value.id) {
                switch (scope?.level) {
                    case 'property': {
                        this.children.properties.field.setInternalValue([scope.id]);
                    }
                }
            }
        }, []);
    }
    buildFields() {
        return {
            name: new StringType({
                label: translate('bank.label'),
            }),
            address: new AddressType({
                label: false,
                zipKeyName: 'postalCode',
            }),
            contactPersonName: new StringType({
                label: translate('serviceProviders.contactPersonName'),
                required: false,
                nullable: true,
            }),
            phoneNumber: new PhoneType({
                label: translate('serviceProvider.phoneNumber'),
                required: false,
                nullable: true,
            }),
            emailAddress: new EmailType({
                label: translate('serviceProviders.emailAddress'),
                required: false,
                nullable: true,
            }),
            secondContactPersonName: new StringType({
                label: translate('serviceProviders.secondContactPersonName'),
                required: false,
                nullable: true,
            }),
            secondPhoneNumber: new PhoneType({
                label: translate('serviceProvider.secondPhoneNumber'),
                required: false,
                nullable: true,
            }),
            secondEmailAddress: new EmailType({
                label: translate('serviceProviders.secondEmailAddress'),
                required: false,
                nullable: true,
            }),
            website: new UrlType({
                label: translate('serviceProviders.website'),
                required: false,
                nullable: true,
            }),
            properties: new SelectType({
                label: translate('serviceProviders.properties'),
                choiceOptions: () => this.properties,
                searchable: true,
                multiple: true,
                nullable: true,
                default: [],
            }),
            domains: new SelectType({
                label: translate('serviceProviders.domains'),
                choiceOptions: () => this.domains,
                searchable: true,
                required: false,
                multiple: true,
            }),
            notes: new TextType({
                label: translate('baseData.rentalObject.notes'),
                required: false,
                nullable: false,
            }),
        };
    }
}
