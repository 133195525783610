import { isNumLike, toNum } from '@f2w/utils';
import { translate } from 'Services/Translator';
import { SimpleValueType } from './base';
import { Yup } from '../base';
export class NumberValueType extends SimpleValueType {
    __inner_type_name = 'number';
    constructor(props) {
        super(props);
    }
    static define(props) {
        return new NumberValueType(props);
    }
    static cast(value, options) {
        if (value != null) {
            if (isNumLike(value))
                return toNum(value);
        }
        if (options?.nullable)
            return null;
    }
    get schema() {
        return super.schema;
    }
    _createSchema() {
        return (new Yup.NumberSchema()).strict();
    }
    _cast(value) {
        return NumberValueType.cast(value, { nullable: this.isNullable });
    }
    _typeCheck(value) {
        if (value instanceof Number)
            value = value.valueOf();
        return typeof value === 'number' && !isNaN(value);
    }
    getOptionsModifiers(props) {
        const { self: _this, own: _own, mutate: _mutate, } = props;
        return {
            ...super.getOptionsModifiers(props),
            min: {
                get: () => _own.min,
                update: (value) => _mutate(s => s.min(value)),
            },
            max: {
                get: () => _own.max,
                update: (value) => _mutate(s => s.max(value)),
            },
        };
    }
    getConstraints(props) {
        const { mutate } = props;
        return {
            ...super.getConstraints(props),
            min(value, message) {
                mutate(s => s.min(value, message ?? translate('form.validation.noLessThan', { length: value })));
                return value;
            },
            max(value, message) {
                mutate(s => s.max(value, message ?? translate('form.validation.noLongerThan', { length: value })));
                return value;
            },
            moreThan(value, message) {
                mutate((s) => s.moreThan(value, message));
                return value;
            },
            lessThan(value, message) {
                mutate((s) => s.lessThan(value, message));
                return value;
            },
            positive(value, message) {
                mutate((s) => s.positive(message));
                return value;
            },
            negative(value, message) {
                mutate((s) => s.negative(message));
                return value;
            },
            integer(value, message) {
                mutate((s) => s.integer(message));
                return value;
            },
        };
    }
}
