import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, BooleanType, CollectionType, DateType, fieldsFactory, MoneyType, SelectType, StringType } from "@f2w/form";
import { translate } from "Services/Translator";
import { Icon } from "Atoms/Icon";
import React from "react";
import { usePortfolio } from "Components/Base/PortfolioContext";
import TaxRateServices from "Services/TaxRateServices";
import BankAccountServices from "Pages/BankAccount/services/bankAccountServices";
import { BankAccountType } from "Pages/BankAccount/enums/BankAccountType";
import moment from "moment";
import { getScopePropertyId } from "Services/ScopeManager";
import ContractServices from "Pages/Contract/services/contractServices";
import { Div } from "Atoms/Layout";
import { Text } from "Atoms/Text";
import { useFinancialAccountsHook } from "Hooks/useFinancialAccountsHook";
export class DebitorType extends AbstractFormType {
    buildFields() {
        return {
            general: new DebitorType.General({
                inheritData: true
            }),
            payment: new DebitorType.PaymentType({
                inheritData: false
            })
        };
    }
}
(function (DebitorType) {
    class General extends AbstractFormType {
        bankAccounts = [];
        rentalObjects;
        contracts;
        _useField(props) {
            super._useField(props);
            const [taxRates, setTaxRates] = React.useState([]);
            const [taxRateToUse, setTaxRateToUse] = React.useState();
            const [contracts, setContracts] = React.useState([]);
            const [rentalObjects, setRentalObjects] = React.useState([]);
            const [bankAccounts, setBankAccounts] = React.useState([]);
            const [allBankAccounts, setAllBankAccounts] = React.useState([]);
            this.contracts = contracts;
            this.bankAccounts = bankAccounts;
            this.rentalObjects = rentalObjects;
            const portfolio = usePortfolio();
            const currRentalObjectId = this.field.value.rentalObjectId;
            const selectedBankAccountId = this.field.value.recipientBankAccountId;
            const selectedProperty = portfolio.getPropertyByRentalObjectId(currRentalObjectId);
            React.useEffect(() => {
                if (!selectedProperty || allBankAccounts.length === 0) {
                    setBankAccounts([]);
                    return;
                }
                const rentBankAccountForProperty = allBankAccounts.filter(bankAccount => bankAccount.properties.map(p => p.id).includes(selectedProperty.id));
                setBankAccounts(rentBankAccountForProperty.map(bankAccount => ({
                    label: bankAccount.name,
                    value: bankAccount.id,
                })));
                if (!rentBankAccountForProperty.map(b => b.id).includes(selectedBankAccountId)) {
                    this.field.value.recipientBankAccountId = null;
                }
            }, [selectedProperty, allBankAccounts, selectedBankAccountId]);
            React.useEffect(() => {
                const promises = [
                    TaxRateServices.listTaxRates(),
                    BankAccountServices.getBankAccounts(BankAccountType.RENT),
                ];
                Promise
                    .all(promises)
                    .then(responses => {
                    setTaxRates(responses[0]);
                    setRentalObjects(this.getGroupedRentalObjects(portfolio));
                    setAllBankAccounts(responses[1]);
                });
            }, []);
            React.useEffect(() => {
                void this.getContractsByObjectId(currRentalObjectId)
                    .then(data => {
                    setContracts(data);
                });
            }, [currRentalObjectId]);
            const positions = this.field.value.positions ?? [];
            React.useEffect(() => {
                let amount = 0;
                let vat = 0;
                positions.forEach((position) => {
                    amount += position.amount ? Number(position.amount) : 0;
                    vat += (position.amount ? Number(position.amount) : 0) * (taxRateToUse && position.usesTax ? taxRateToUse.rate : 0);
                });
                this.children.vat.field.setInternalValue(Math.round((vat + Number.EPSILON) * 100) / 100);
                this.children.amount.field.setInternalValue(Math.round((amount + Number.EPSILON) * 100) / 100);
            }, [positions, taxRateToUse]);
            const { receiptDate } = this.field.value;
            React.useEffect(() => {
                if (!receiptDate) {
                    return;
                }
                const momentReceiptDate = moment(receiptDate);
                const foundTaxRate = taxRates
                    .find(taxRate => moment(taxRate.validFrom).isSameOrBefore(momentReceiptDate)
                    && (!taxRate.validTo || moment(taxRate.validTo).isSameOrAfter(momentReceiptDate)));
                setTaxRateToUse(foundTaxRate);
                this.children.vat.field.label = foundTaxRate
                    ? `${translate('debitor.vat')} (${(foundTaxRate.rate * 100).toFixed(2)}%)`
                    : translate('debitor.vat');
            }, [receiptDate, taxRates]);
        }
        getGroupedRentalObjects(portfolio) {
            const scopePropertyId = getScopePropertyId(portfolio);
            let rentalObjects = portfolio.getRentalObjects();
            if (scopePropertyId) {
                rentalObjects = portfolio.getRentalObjectsByPropertyId(scopePropertyId);
            }
            const groupedRentalObjects = new Map();
            rentalObjects.forEach(rentalObject => {
                const building = portfolio.getBuildingById(rentalObject.buildingId);
                const property = portfolio.getPropertyById(building.propertyId);
                const contracts = portfolio.getActiveContractsByRentalObjectId(rentalObject.id);
                const tenants = contracts.length > 0
                    ? contracts
                        .reduce((tenants, contract) => [...tenants, ...contract.tenants], [])
                        .join(', ')
                    : translate('rentalObject.state.vacancy');
                const key = `${property.name} : ${building.address}`;
                if (groupedRentalObjects.has(key)) {
                    const value = groupedRentalObjects.get(key);
                    groupedRentalObjects.set(key, {
                        ...value,
                        options: [...value.options, {
                                label: `${rentalObject.name} (${tenants})`,
                                value: rentalObject.id
                            }]
                    });
                }
                else {
                    groupedRentalObjects.set(key, {
                        label: key,
                        options: [{
                                label: `${rentalObject.name} (${tenants})`,
                                value: rentalObject.id
                            }]
                    });
                }
            });
            return Array.from(groupedRentalObjects.values());
        }
        async getContractsByObjectId(rentalObjectId) {
            if (!rentalObjectId) {
                return [];
            }
            const contracts = await ContractServices.loadByRentalObject(rentalObjectId);
            let selectedContractExists = false;
            const selectedContractId = this.field.value.contractId;
            const mappedContracts = [
                {
                    label: translate('contracts'),
                    options: contracts?.map(contract => {
                        if (!selectedContractExists && selectedContractId === contract.id) {
                            selectedContractExists = true;
                        }
                        return {
                            label: `
                                ${moment(contract.starts).format('DD.MM.YYYY')}
                                 - ${contract.ends ? moment(contract.ends).format('DD.MM.YYYY') : translate('contract.ends.notLimited')}
                                 - ${contract.contractParties.map(contractParty => contractParty.lastName ?? contractParty.name).join(', ')}
                            `,
                            value: contract.id
                        };
                    })
                }
            ];
            if (!selectedContractExists) {
                this.children.contractId.field.setValue(undefined, false);
            }
            return mappedContracts;
        }
        buildFields() {
            return {
                rentalObjectId: new SelectType({
                    label: translate('debitor.rentalObject'),
                    choiceOptions: () => this.rentalObjects,
                    required: true,
                    searchable: true,
                    getProps: () => ({
                        prefix: _jsx(Icon, { icon: 'search' })
                    })
                }),
                label: new StringType({
                    label: translate('debitor.label'),
                    required: true
                }),
                contractId: new SelectType({
                    label: translate('contract'),
                    choiceOptions: () => this.contracts,
                    searchable: true
                }),
                receiptDate: new DateType({
                    label: translate('invoice.date'),
                    required: true,
                    constraints: {
                        typeError: translate('form.validation.notValidDate')
                    }
                }),
                amount: new MoneyType({
                    label: translate('debitor.amountNet'),
                    required: true,
                    disabled: true
                }),
                vat: new MoneyType({
                    label: translate('debitor.vat'),
                    required: false,
                    disabled: true
                }),
                positions: new CollectionType({
                    label: translate('debitor.positions'),
                    actions: { add: true, delete: true },
                    widget: 'table',
                    tableColumnOptions: {
                        usesTax: {
                            label: translate("invoice.position.taxRate")
                        }
                    },
                    defaultValue: () => [{
                            taxRateId: undefined,
                            amount: undefined,
                            label: undefined
                        }],
                    prototype: () => new PositionType({
                        label: false,
                    })
                }),
                accountingDate: new DateType({
                    label: translate('invoice.accountingDate'),
                    required: false,
                    constraints: {
                        typeError: translate('form.validation.notValidDate')
                    }
                }),
                dueDate: new DateType({
                    label: translate('invoice.dueTo'),
                    required: true,
                    constraints: {
                        typeError: translate('form.validation.notValidDate')
                    }
                }),
                recipientBankAccountId: new SelectType({
                    label: translate('invoice.recipientBankAccount'),
                    choiceOptions: () => this.bankAccounts,
                    menuPlacement: 'top',
                    required: true
                })
            };
        }
    }
    DebitorType.General = General;
    class GeneralForm extends General {
        _render() {
            return (_jsxs("div", { children: [this.renderChild('rentalObjectId'), this.renderChild('label'), this.renderChild('contractId'), _jsx(Div, { pt: 8, pb: 8, children: _jsx(Text, { large: 500, dark: 900, children: _jsx("strong", { children: translate('debitor.positions') }) }) }), this.renderChild('receiptDate'), this.renderChild('amount'), this.renderChild('vat'), this.renderChild('positions'), _jsx(Div, { pt: 8, pb: 8, children: _jsx(Text, { large: 500, dark: 900, children: _jsx("strong", { children: translate('debitor.termsOfPayment') }) }) }), this.renderChild('accountingDate'), this.renderChild('dueDate'), this.renderChild('recipientBankAccountId')] }));
        }
    }
    DebitorType.GeneralForm = GeneralForm;
    class PositionType extends AbstractFormType {
        buildFields() {
            return fieldsFactory()({
                label: new StringType({
                    label: translate('invoice.position.label'),
                    required: true
                }),
                amount: new MoneyType({
                    label: translate('invoice.position.netAmount'),
                    required: true,
                    constraints: {
                        typeError: translate('form.validation.notValidNumber'),
                    }
                }),
                usesTax: new BooleanType({
                    label: translate('invoice.position.useTax'),
                    required: false,
                    nullable: false,
                    defaultValue: false,
                })
            });
        }
    }
    class PaymentType extends AbstractFormType {
        financialAccounts = [];
        DEFAULT_POSITION = 1020;
        DEFAULT_MANUAL_PAYMENT_POSITION = 1000;
        _useField(props) {
            super._useField(props);
            const { financialAccounts, groupedFinancialAccountOptions } = useFinancialAccountsHook({
                propertyId: this.field.value.propertyId
            });
            this.financialAccounts = groupedFinancialAccountOptions;
            React.useEffect(() => {
                if (!this.field.value.debitAccountId && financialAccounts.length > 0) {
                    const defaultPosition = this.field.value.payerBankAccountId
                        ? this.DEFAULT_POSITION
                        : this.DEFAULT_MANUAL_PAYMENT_POSITION;
                    const defaultSelectedAccount = financialAccounts
                        .find(financialAccount => financialAccount.position === defaultPosition);
                    this.children.debitAccountId.field.setValue(defaultSelectedAccount?.id);
                }
            }, [financialAccounts]);
        }
        buildFields() {
            return {
                label: new StringType({
                    label: translate('debitor.label'),
                    required: true
                }),
                amount: new MoneyType({
                    label: translate('payment.amount'),
                    required: true
                }),
                paidAt: new DateType({
                    label: translate('payment.paidAt'),
                    required: true,
                    defaultValue: () => moment().format('YYYY-MM-DD')
                }),
                debitAccountId: new SelectType({
                    label: translate('payment.debitorPosition'),
                    required: true,
                    choiceOptions: () => this.financialAccounts
                })
            };
        }
    }
    DebitorType.PaymentType = PaymentType;
})(DebitorType || (DebitorType = {}));
