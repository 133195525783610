import { generate as generateRoute } from 'Services/Router';
import client from 'Utils/client';
export var ContractServices;
(function (ContractServices) {
    ContractServices.getMainContractsByProperty = (propertyId, billingCycle) => {
        const route = generateRoute('property.mainContracts', {
            propertyId: propertyId,
            billingCycle: billingCycle,
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    ContractServices.getContractList = (params) => {
        const route = generateRoute('api.contracts.list');
        return client
            .get(route, { params })
            .then(response => response.data);
    };
    ContractServices.getContractsForDropdown = async () => {
        const route = generateRoute('api.contracts.dropdown');
        return client
            .get(route)
            .then(response => response.data);
    };
    ContractServices.getRentPotentialForRentId = async (rentId) => {
        const route = generateRoute('api.rents.rentPotential', {
            rent: rentId
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    ContractServices.deleteContract = (contractId) => {
        const route = generateRoute('api.contracts.delete', { id: contractId });
        return client
            .delete(route);
    };
    ContractServices.getContractStats = async (contractId) => {
        const route = generateRoute('api.contract.stats', { contract: contractId });
        const { data } = await client.get(route);
        return data;
    };
    ContractServices.loadByRentalObject = async (rentalObjectId) => {
        const route = generateRoute('api.contracts.byRentalObject', {
            rentalObject: rentalObjectId
        });
        const { data } = await client.get(route);
        return data;
    };
    ContractServices.getRentModificationStepDate = (contractId) => client
        .get(generateRoute('api.contracts.rentModification.stepDate', { contract: contractId }))
        .then((response) => response.data);
    ContractServices.getRentModificationStepRent = (contractId, date) => client
        .get(generateRoute('api.contracts.rentModification.stepRent', { contract: contractId, date }))
        .then((response) => response.data);
    ContractServices.createRentModification = (contractId, data) => client
        .post(generateRoute('api.contracts.createModification', { contract: contractId }), data)
        .then((response) => { });
    ContractServices.getModifiableContracts = async (rentalObjectId) => {
        const route = generateRoute('api.contracts.modifiable', { rentalObjectId });
        const { data } = await client.get(route);
        return data;
    };
})(ContractServices || (ContractServices = {}));
export default ContractServices;
