import { jsx as _jsx } from "react/jsx-runtime";
import { BaseType } from '../../base';
import { SimpleValue } from './SimpleValue';
import { MaskedValue } from './MaskedValue';
import { InputField } from '../../../../theme';
import { FormControl } from 'Atoms/Input';
import classnames from 'classnames';
export class BaseSimpleType extends BaseType {
    constructor(options) {
        super(options);
    }
    static format(value, formatter) {
        const type = new this({});
        return type.format(value, formatter);
    }
    format(value, formatter) {
        const val = this.specs.format(value, formatter);
        return (val == null ? '' : val.toString());
    }
    _renderForm(valueType, props) {
        return _jsx(InputField, { ...props, Widget: p => this._renderWidget(p) });
    }
    _renderWidget({ field: valueType, ...props }) {
        const controlProps = {
            type: 'text',
            key: `${valueType.id}--simple-widget`,
            ...props,
            className: classnames('fw-form_control', 'fw-form_widget', props?.['className']),
        };
        return _jsx(FormControl.Input, { ...controlProps });
    }
}
export class SimpleType extends BaseSimpleType {
    _createValue(props) {
        return new SimpleValue(this, props);
    }
}
export class MaskedType extends BaseSimpleType {
    _createValue(props) {
        return new MaskedValue(this, props);
    }
    format(value) {
        const _mask = MaskedValue.createMask(this.getMask());
        _mask.value = value == null ? '' : value.toString();
        return _mask.value;
    }
    _renderWidget(props) {
        const { field: valueType, onChange, value, ...rest } = props;
        valueType.use2();
        return super._renderWidget({
            type: 'text',
            ...rest,
            field: valueType,
            defaultValue: valueType.value ?? '',
            ref: valueType.maskRef,
        });
    }
}
