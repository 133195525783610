import { isBool } from 'Utils';
import { isArray, isString } from 'lodash';
import { parseJson } from 'Utils/parseJson';
import { BaseValueType, createSpecsApi, Yup, } from '../base';
export class ArrayValueType extends BaseValueType {
    __inner_type_name = 'array';
    _specs;
    constructor(props) {
        super(props);
        this._specs = createSpecsApi({
            schemaType: () => (new Yup.ArraySchema()),
            defaultOptions: () => ({}),
            ...props,
            getOptionsModifiers: (p) => {
                const own = p.own;
                return {
                    ...super.getOptionsModifiers(p),
                    prototype: {
                        update: (value) => own.proto = this.createProtoType(),
                        getters: {
                            proto: () => own.proto,
                        }
                    },
                    actions: {
                        getDefault: () => ({}),
                        set: (value, prev) => {
                            return isBool(value)
                                ? { add: value, delete: value, sort: value }
                                : { ...prev, ...value };
                        },
                        getters: {
                            showActions: (own) => (own?.add || own?.sort || own?.delete),
                            showAdd: (own) => own?.add ?? false,
                            showDelete: (own) => own?.delete ?? false,
                            showSort: (own) => own?.sort ?? false,
                        },
                    },
                };
            },
            getConstraints: (props) => {
                const { mutate } = props;
                return {
                    min(value, message) {
                        mutate(s => s.min(value, message));
                        return value;
                    },
                    max(value, message) {
                        mutate(s => s.max(value, message));
                        return value;
                    },
                };
            },
        });
    }
    get hasActions() {
        return this.options.showActions;
    }
    get showAdd() {
        return this.options.showAdd;
    }
    get showDelete() {
        return this.options.showDelete;
    }
    get showSort() {
        return this.options.showSort;
    }
    get proto() {
        return this.options.proto;
    }
    createProtoType(key) {
        const proto = this.options.prototype();
        proto.specs.$$bindParent(this._type, key);
        return proto;
    }
    createProtoTypeValue(key, props) {
        const proto = this.createProtoType(key);
        return proto.createValue({ name: key, ...props });
    }
    _typeCheck(v) {
        return isArray(v);
    }
    _cast(value) {
        return ArrayValueType.parse(value, { nullable: this.isNullable });
    }
    static parse(value, options) {
        if (value) {
            if (isString(value))
                value = parseJson(value);
            if (isArray(value))
                return value;
        }
        if (options?.nullable)
            return null;
        return [];
    }
}
