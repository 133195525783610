import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalNew } from "Components/Modal";
import { translate } from "Services/Translator";
import { Box, Button, Div, FlexCol, FlexRow } from "Components/Atoms";
import { useFixCostForm } from "Pages/AncillaryExpense/components/CalculationDistributionSection/hooks/useFixCostForm";
import { Form, FormProvider, ServerError } from "@f2w/form";
import parse from "html-react-parser";
import { formatMoney } from "Services/NumberHelper";
export const FixCostModal = ({ accountId, calculationId, previousAmount = 0, distributionAmount, onClose, updateFixCostDistributionAmount }) => {
    const form = useFixCostForm(accountId, calculationId, distributionAmount ?? 0, previousAmount, onClose, updateFixCostDistributionAmount);
    return (_jsxs(ModalNew, { show: true, size: { maxWidth: '40%', maxHeight: '50%' }, children: [_jsx(ModalNew.Header, { children: _jsx(ModalNew.Title, { children: translate('calculation.distributions.createFixCost') }) }), _jsx(ModalNew.Body, { children: _jsxs(FlexCol, { children: [_jsxs(Div, { style: { background: "#f7f7f7", padding: 15, marginBottom: 20, borderRadius: 8 }, children: [_jsx("p", { children: translate('calculation.distributions.createFixCost.help', { totalAmountInCHF: formatMoney(distributionAmount) }) }), _jsx("p", { children: translate('calculation.distributions.createFixCost.help2', { totalAmountInCHF: formatMoney(distributionAmount) }) })] }), _jsx(Div, { children: _jsx(FormProvider, { form: form, children: _jsx(Form, { noValidate: true, children: form.render() }) }) })] }) }), _jsx(ModalNew.Footer, { children: _jsxs(FlexRow, { justifySpaceBetween: true, children: [_jsx(Box, { flex: '1 1 auto', children: _jsx(ServerError, { children: form.status?.error ? parse(form.status?.error) : undefined }) }), _jsxs(FlexRow, { gap: 5, children: [_jsx(Button, { link: true, color: 'dark', onClick: () => onClose(), children: translate('btn.cancel') }), _jsx(Button, { onClick: () => form.submitForm(), children: translate('btn.create') })] })] }) })] }));
};
