import { toStr } from 'Utils/types';
import moment from 'moment';
import { Yup } from '../base';
import { SimpleValueType } from './base';
import { DateManager } from 'Atoms/Input/controls/DatePicker';
export var FormatType;
(function (FormatType) {
    FormatType["json"] = "json";
    FormatType["locale"] = "locale";
})(FormatType || (FormatType = {}));
export var DateFormatType;
(function (DateFormatType) {
    DateFormatType["year"] = "year";
    DateFormatType["month"] = "month";
    DateFormatType["day"] = "day";
    DateFormatType["quarter"] = "quarter";
})(DateFormatType || (DateFormatType = {}));
const DateFormats = {
    [FormatType.json]: {
        [DateFormatType.year]: 'yyyy',
        [DateFormatType.month]: 'yyyy-MM',
        [DateFormatType.day]: 'yyyy-MM-DD',
        [DateFormatType.quarter]: 'yyyy-MM-DD',
    },
    [FormatType.locale]: {
        [DateFormatType.year]: 'yyyy',
        [DateFormatType.month]: 'MMMM yyyy',
        [DateFormatType.day]: 'DD.MM.yyyy',
        [DateFormatType.quarter]: 'DD.MM.yyyy',
    },
};
export function getLocaleFormat(type, fallback = DateFormatType.day) {
    const f = DateFormats[FormatType.locale];
    return f[type] ?? f[fallback];
}
export function getJsonFormat(type, fallback = DateFormatType.day) {
    const f = DateFormats[FormatType.json];
    return f[type] ?? f[fallback];
}
export class DateValueType extends SimpleValueType {
    __inner_type_name = 'date';
    static DateFormatType = DateFormatType;
    static FormatType = FormatType;
    static formats = DateFormats;
    constructor(props) {
        super(props);
    }
    get schema() {
        return super.schema;
    }
    _createSchema() {
        return (new Yup.DateSchema()).strict();
    }
    formatDate(value, format = this.options.jsonFormat) {
        return DateValueType.format(value, format);
    }
    _format(value, formatter) {
        switch (formatter) {
            case 'view':
                return this.formatDate(value, this.options.localeFormat);
            default:
                return this.formatDate(value, this.options.jsonFormat);
        }
    }
    _cast(value) {
        return DateValueType.cast(value, { nullable: this.isNullable, format: this.options.jsonFormat });
    }
    _typeCheck(v) {
        return DateValueType.isDate(v) && !isNaN(v.getTime());
    }
    static isDate(obj) {
        return Object.prototype.toString.call(obj) === '[object Date]';
    }
    static cast(value, options) {
        if ((value = DateValueType.format(value, options?.format ?? 'L')))
            return value;
        if (options?.nullable)
            return null;
    }
    static formatJson(value) {
        return this.format(value, getJsonFormat(DateFormatType.day));
    }
    static format(value, format = 'L') {
        return DateValueType.parseMoment(value)?.format(format) || '';
    }
    static parseMoment(value, defaultValue) {
        if (value && (value = moment(value)) && value.isValid())
            return value;
        if (defaultValue)
            return DateValueType.parseMoment(defaultValue);
    }
    static parse(value, defaultValue) {
        return DateValueType.parseMoment(value, defaultValue)?.toDate?.();
    }
    getOptionsModifiers(props) {
        const getMinMax = ({ minDate, maxDate }) => DateManager({
            minDate: minDate || props.self.min,
            maxDate: maxDate || props.self.max,
        });
        return {
            ...super.getOptionsModifiers(props),
            min: {
                getDefault: () => new Date(DateManager.MIN_DATE),
                set: (value) => getMinMax({ minDate: value }).minDate,
            },
            max: {
                getDefault: () => new Date(DateManager.MAX_DATE),
                set: (value) => getMinMax({ maxDate: value }).maxDate,
            },
            type: {
                set: v => toStr(v).toLowerCase(),
            },
            jsonFormat: {
                get: (val) => val ?? getJsonFormat(props.own?.type, DateFormatType.day),
                getDefault: () => getJsonFormat(props.own?.type, DateFormatType.day),
            },
            localeFormat: {
                get: (val) => val ?? getLocaleFormat(props.own?.type, DateFormatType.day),
                getDefault: () => getLocaleFormat(props.own?.type, DateFormatType.day),
            }
        };
    }
    get minDate() {
        return this.options.min;
    }
    get maxDate() {
        return this.options.max;
    }
    getConstraints(props) {
        const { mutate } = props;
        return {
            ...super.getConstraints(props),
            min(value, message) {
                mutate(s => s.min(value, message));
                return value;
            },
            max(value, message) {
                mutate(s => s.max(value, message));
                return value;
            },
        };
    }
}
