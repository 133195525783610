import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'Atoms/index';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import { trans, translate } from 'Services/Translator';
import { tryFunction } from 'Utils';
import { UploadArea } from 'Atoms/UploadArea';
import { ImageCarousel } from 'Components//ImageCarousel';
export function UploadImages({ data: images, onDelete: _onDelete, imageFit, imagePosition, ...uploadProps }) {
    const toastApi = useToastDispatcherApi();
    const onRemove = async (image) => tryFunction(_onDelete, [image])
        ?.then(() => toastApi?.success({ timeout: 4, message: translate('imageUploadManager.success.delete') }))
        .catch((e) => {
        console.error(e);
        toastApi?.error({ timeout: 10, message: translate('imageUploadManager.error.delete') });
    });
    const carouselProps = {
        fit: imageFit,
        position: imagePosition,
        images: images,
    };
    return (_jsx(UploadArea, { multiple: true, maxSize: '2MiB', accept: ['image/*'], isEmpty: !images.length, placeholderText: translate('imageUploadManager.placeholderText'), progressTitle: ({ files }) => translate('imageUploadManager.progressTitle', { count: files.length }), ...uploadProps, messages: {
            fileType: () => translate('imageUploadManager.error.fileType'),
            server: () => translate('imageUploadManager.error.server'),
            ...uploadProps.messages,
        }, children: ({ openDialog }) => (_jsx(ImageCarousel, { ...carouselProps, renderActions: (index, props) => (_jsxs(_Fragment, { children: [_jsx(Button, { icon: 'upload', onClick: openDialog, title: trans('btn.upload') }), images[index] && _jsx(Button, { color: 'error', icon: 'trash', onClickAsync: () => onRemove(images[index]), title: trans('btn.delete') })] })) })) }));
}
