import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, BooleanType } from "@f2w/form";
import { translate } from "Services/Translator";
import { Div } from 'Components/Atoms';
export class CreateAutomaticInvoicesType extends AbstractFormType {
    buildFields() {
        return {
            includeQR: new BooleanType({
                label: translate('automaticInvoices.export.includeQR.caption'),
                help: translate('automaticInvoices.export.includeQR.help'),
                required: false,
                defaultValue: true,
            }),
            useReceiptDate: new BooleanType({
                label: translate('automaticInvoices.export.useReceiptDate.caption'),
                help: translate('automaticInvoices.export.useReceiptDate.help'),
                required: false,
                defaultValue: true,
            }),
        };
    }
    _renderWidget(props) {
        return (_jsxs(_Fragment, { children: [_jsx(Div, { style: { marginBottom: 20 }, children: this.options.invoices &&
                        this.options.invoices.map((invoice) => _jsx("div", { children: _jsx("strong", { children: invoice.label }) }, invoice.invoiceId)) }), this.renderChild('includeQR'), this.renderChild('useReceiptDate')] }));
    }
}
