import { jsx as _jsx } from "react/jsx-runtime";
import { useViewType } from '@f2w/view';
import AssetServices from "Pages/Asset/services/assetServices";
import { AssetTemplate } from "../AssetTemplate";
import { GeneralTab } from "../GeneralTab";
import { AssetViewForm } from "../AssetViewForm";
import { CreditorInvoicesTab } from "../CreditorInvoicesTab";
import { translate } from "Services/Translator";
export const useAssetView = () => useViewType(() => new AssetViewForm(), {
    id: 'asset-view',
    defaultState: {
        leftActiveTab: 'general',
        rightActiveTab: 'creditorInvoices'
    },
    DefaultTemplate: props => _jsx(AssetTemplate, { ...props }),
    load: async (id) => {
        const data = await AssetServices.getAssetById(id);
        return {
            id,
            ...data,
            general: {
                ...data,
                buildYear: data.buildYear
            }
        };
    }
}, () => ({
    general: {
        showHeader: false,
        title: translate('assets.detailView.generalTab'),
        Content: (props) => _jsx(GeneralTab, { ...props }),
        Form: ({ type }) => type.renderChild('general'),
        save: async (values, view) => {
            return AssetServices.updateAsset(values.id, {
                ...values.general,
                buildYear: values.general.buildYear
            })
                .then(response => {
                view.updateData({
                    ...values,
                    buildYear: values.general.buildYear
                });
            });
        }
    },
    creditorInvoices: {
        showHeader: false,
        Content: (props) => _jsx(CreditorInvoicesTab, { ...props }),
        Form: ({ type }) => type.renderChild('creditorInvoices'),
        save: async (values, view) => {
            return AssetServices.updateAsset(values.id, values)
                .then(response => {
                view.updateData({
                    ...values
                });
            });
        }
    }
}));
