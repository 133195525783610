import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import * as Tabl from 'Atoms/Table';
import { Tbody, Td, TdActions, Th, Thead, Tr } from 'Atoms/Table';
import { translate } from 'Services/Translator';
import { FormControl } from 'Atoms/Input';
import { Tooltip2 } from 'Atoms/Overlay';
import { Button } from 'Atoms/Button';
import { FlexRow } from 'Atoms/Layout';
export function TableWidget(props) {
    const { arrayValue, columns: _columns, canSelect: canSelect, getRowStatus, ...rest } = props;
    const hasActions = arrayValue.specs.hasActions || null;
    const id = arrayValue.id;
    const columns = useMemo(() => {
        return Object.keys(_columns).map((id) => {
            let { label, value, ...rest } = _columns[id];
            return {
                id,
                ...rest,
                label: () => label(arrayValue),
                value: (row) => value(row, arrayValue)
            };
        });
    }, []);
    let colSpan = columns.length;
    if (canSelect)
        colSpan++;
    if (hasActions)
        colSpan++;
    const isAllSelected = arrayValue.isAllSelected();
    const isSomeSelected = arrayValue.isSomeSelected();
    return _jsx(_Fragment, { children: _jsxs(Tabl.Table, { variant: 'content', colSpan: colSpan, ...rest, children: [_jsxs(Thead, { children: [canSelect && _jsx(Th, { min: true, sticky: 'left', children: _jsx(FormControl.Checkbox, { checked: isSomeSelected, indeterminate: isSomeSelected && !isAllSelected, onChange: (value, e) => {
                                    e.stopPropagation();
                                    arrayValue.updateAllSelected(!isAllSelected);
                                } }) }, `table-select--${id}`), columns.map((col) => (_jsx(Th, { children: col.label() }, `table-column--${col.id}`))), hasActions && _jsx(Th, { sticky: 'right', children: "##" }, `table-actions--${id}`)] }), _jsx(Tbody, { children: arrayValue.map((row) => {
                        const isSelected = arrayValue.isSelected(row);
                        const isPartialSelected = arrayValue.isPartialSelected(row);
                        const statusProps = getRowStatus?.(row, arrayValue);
                        return _jsxs(Tr, { status: statusProps?.status, children: [canSelect && _jsx(Td, { min: true, sticky: 'left', style: { paddingRight: 0 }, children: _jsxs(FlexRow, { alignCenter: true, children: [_jsx(FormControl.Checkbox, { checked: isSelected, indeterminate: isPartialSelected, onChange: (v, e) => {
                                                    e.stopPropagation();
                                                    arrayValue.updateSelected(row);
                                                } }), statusProps?.statusMessage && _jsx(Tooltip2, { placement: 'bottom-start', delay: { hide: 500, show: 0 }, flip: true, rootClose: true, content: statusProps?.statusMessage, children: _jsx(Button, { link: true, color: statusProps?.status || 'primary', icon: 'alert-circle' }) })] }) }, `table-row-select--${row.id}`), columns.map((col) => (_jsx(Td, { style: col?.style, children: col.value(row) }, `table-row-column--${col.id}--${row.id}`))), hasActions && _jsx(TdActions, { sticky: 'right', id: `${row.id}--table-actions`, quickActions: [
                                        arrayValue.specs.showDelete && {
                                            color: 'error',
                                            icon: 'trash-2',
                                            onClick: () => arrayValue.remove(row.key),
                                            confirm: translate('table.collection.deleteConfirm'),
                                        },
                                    ] }, `table-row-actions--${row.id}`)] }, `table-row--${row.id}`);
                    }) })] }) });
}
TableWidget.Td = Tabl.Td;
TableWidget.Th = Tabl.Th;
TableWidget.TdActions = Tabl.TdActions;
TableWidget.ThActions = Tabl.ThActions;
