import { parseJson } from 'Utils/parseJson';
import { isBool } from '@f2w/utils';
import { isPlainObject, isString } from 'lodash';
import { BaseValueType, createSpecsApi, Yup, } from '../base';
import { ObjectMap } from './ObjectMap';
export class ObjectValueType extends BaseValueType {
    __inner_type_name = 'object';
    _shape;
    constructor(props) {
        super(props);
        this._specs = createSpecsApi({
            schemaType: () => (new Yup.ObjectSchema()),
            defaultOptions: () => ({ ...props?.defaultOptions?.() }),
            getOptionsModifiers: (p) => {
                const own = p.own;
                return {
                    ...super.getOptionsModifiers(p),
                    inherit: {
                        getDefault: () => ({}),
                        set: (value, prev) => {
                            return isBool(value) ? { view: value, data: value } : { ...prev, ...value };
                        },
                        getters: {
                            inheritData: () => own.inherit?.data ?? false,
                            inheritView: () => own.inherit?.view ?? false,
                            inheritView2: (own) => own?.view ?? false,
                        },
                    },
                };
            },
            getConstraints: (props) => {
                const { mutate } = props;
                return {};
            },
        });
        this._shape = ObjectMap.create({
            onAdd: (key, child) => {
                console.debug('bind parent', { key, child });
                if (!child?.specs?.$$bindParent) {
                    return null;
                }
                return child?.specs?.$$bindParent(this._type, key) && child;
            }
        });
    }
    static define(props) {
        const t = new ObjectValueType(props);
        return t.setShape(props.shape());
    }
    setInherit(inherit) {
        this.options.inherit = inherit;
    }
    get inherit() {
        return this.inheritView && this.inheritData;
    }
    get inheritView() {
        return !!this.options.inherit.view;
    }
    get inheritData() {
        return !!this.options.inherit.data;
    }
    get shape() {
        return this._shape;
    }
    get fields() {
        return this._shape.fields;
    }
    setShape(shape) {
        this._shape.reset(shape);
        return this;
    }
    _typeCheck(v) {
        return isPlainObject(v) || typeof v === 'function';
    }
    _cast(value) {
        if (value) {
            if (isString(value))
                value = parseJson(value);
            if (this.isType(value))
                return value;
        }
        if (this.isNullable)
            return null;
        return {};
    }
    static parse(value, options) {
        if (value) {
            if (isString(value))
                value = parseJson(value);
            if (isPlainObject(value))
                return value;
        }
        if (options?.nullable)
            return null;
        return {};
    }
}
