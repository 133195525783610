import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { Select } from "Atoms/Select";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { getGlobalContext } from "Services/GlobalContext";
import { ModalNew } from "Components/Modal";
import { translate } from "Services/App";
import { Button, ButtonToolbar, Div, FlexCol } from "Atoms/index";
import { DataTable } from "@f2w/data-table";
import { AncillaryExpensePresetDistributionKeysDataTable } from "Pages/AncillaryExpense/table/AncillaryExpensePresetDistributionKeysDataTable";
import { useToastDispatcherApi } from "Components/Dispatcher";
export const CalculationDistributionSection = ({ onClose, getAllPresetDistributionKeys, createUsingPresetDistributionKeys }) => {
    const [presetDistributionKeys, setPresetDistributionKeys] = useState([]);
    const [propertyOptions, setPropertyOptions] = useState([]);
    const [selectedPropertyId, setSelectedPropertyId] = useState();
    const [submitting, setSubmitting] = useState(false);
    const portfolio = usePortfolio();
    const instance = AncillaryExpensePresetDistributionKeysDataTable.use({
        data: presetDistributionKeys
    });
    React.useEffect(() => {
        void getAllPresetDistributionKeys()
            .then(presetDistributionKeys => {
            const { scope } = getGlobalContext();
            const properties = portfolio.getProperties();
            setPropertyOptions(properties.map(property => ({
                label: property.name,
                value: property.id
            })));
            switch (scope?.level) {
                case "property": {
                    setSelectedPropertyId(scope.id);
                    break;
                }
                case "rentalObject": {
                    setSelectedPropertyId(portfolio.getPropertyByRentalObjectId(scope.id).id);
                }
            }
            setPresetDistributionKeys(presetDistributionKeys);
        });
    }, []);
    const { success } = useToastDispatcherApi();
    const onCreateClicked = useCallback(async () => {
        await createUsingPresetDistributionKeys({
            propertyId: selectedPropertyId,
            presetDistributionKeyIds: instance.selectedFlatRows.map(row => row.original.id)
        })
            .then(() => {
            const property = portfolio.getPropertyById(selectedPropertyId);
            success({
                message: translate('ancillaryExpense.presetDistributionKeys.create.success', {
                    propertyName: property.name
                }),
            });
            onClose();
        })
            .catch(() => setSubmitting(false));
    }, [selectedPropertyId, instance.selectedFlatRows.length]);
    return (_jsxs(ModalNew, { show: true, size: { maxWidth: '700px', maxHeight: '80%', minHeight: '600px' }, onHide: () => !submitting && onClose(), children: [_jsx(ModalNew.Header, { closeButton: true, onHide: () => !submitting && onClose(), children: _jsx(ModalNew.Title, { children: translate('ancillaryExpense.presetDistributionKeys.header') }) }), _jsx(ModalNew.Body, { children: _jsxs(FlexCol, { gap: 24, h: '100%', children: [_jsxs(Div, { children: [_jsx("label", { htmlFor: "presetDistributionKeyPropertyId", style: { fontWeight: 500 }, children: translate('property') }), _jsx(Select, { id: "presetDistributionKeyPropertyId", value: selectedPropertyId, options: propertyOptions, isSearchable: true, container: true, onChange: (selectedOption) => {
                                        setSelectedPropertyId(selectedOption.value);
                                    } })] }), _jsx(Div, { h: '80%', children: _jsx(DataTable.SimpleTable, { instance: instance, scroll: 'fit' }) })] }) }), _jsx(ModalNew.Footer, { children: _jsxs(ButtonToolbar, { children: [_jsx(Button, { disabled: submitting, variant: 'link', color: 'neutral', onClick: onClose, children: translate('btn.close') }), _jsx(Button, { disabled: submitting || !selectedPropertyId || !instance.selectedFlatRows.length, onClick: onCreateClicked, children: translate('ancillaryExpense.presetDistributionKeys.btn.createSelected', {
                                numDistributionKeys: instance.selectedFlatRows.length
                            }) })] }) })] }));
};
