import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { ChatApi, ChatApiServices } from './ChatApi';
import useEventCallback from '@restart/hooks/useEventCallback';
import useStableMemo from '@restart/hooks/useStableMemo';
import { getCurrentUser } from 'Services/UserServices';
import { AvatarIcon } from 'Atoms/Icon/AvatarIcon';
import { Icon } from 'Atoms/Icon';
import { useTheme } from '@f2w/theme';
import { css } from 'styled-components';
import useTimeout from '@restart/hooks/useTimeout';
import { ChatView } from 'Components/ChatBot/renderer';
import { translate } from 'Services/App';
export function useChatModal({ service = ChatApiServices, ...props }) {
    const [show, setShow] = useState(props.defaultShow ?? false);
    const toggle = useEventCallback(() => setShow(prev => !prev));
    const showChat = useCallback(() => (!show && setShow(true)), [show]);
    const hideChat = useCallback(() => (show && setShow(false)), [show]);
    return {
        show,
        showChat,
        hideChat,
        toggle,
    };
}
export function useChatApi({ ...options }) {
    const theme = useTheme();
    const { set, clear } = useTimeout();
    const chatApi = useStableMemo(() => {
        return ChatApi.create({
            ...options,
            renderMessage: (props, api) => ChatView.apiMessage({ response: props }),
        });
    }, []);
    const handleUpdate = useCallback((body, signals) => {
        const userMessage = body.messages?.shift();
        userMessage && chatApi.sendMessage(userMessage.text)
            .then(message => {
            if (!message)
                return;
            signals.onResponse(message);
            set(() => chatApi.showFeedback(), 10 * 1000);
        })
            .catch(error => {
            console.error('ChatApi error', { error });
            signals.onResponse({ error: translate('chat.error') });
        });
    }, [chatApi]);
    const chatProps = useMemo(() => {
        const props = ChatProvider.props({
            theme,
            options: options,
            api: chatApi
        });
        return {
            ...props,
            ref: chatApi.ref,
            history: props?.history ?? chatApi.getHistoryMessages(),
            connect: { handler: handleUpdate },
            onMessage: ({ message, isHistory }) => {
                if (isHistory)
                    return;
                chatApi.addHistoryMessage(message);
            },
        };
    }, [chatApi]);
    return [
        chatProps,
        chatApi,
    ];
}
export var ChatProvider;
(function (ChatProvider) {
    function props({ theme, api, options }) {
        const _chat = {};
        const props = { _chat, options, api, theme: _createThemeProps(theme) };
        withAvatar(props);
        withInput(props);
        withMessageStyles(props);
        return _chat;
    }
    ChatProvider.props = props;
    const withMessageStyles = ({ _chat, theme, api, options }) => {
        _chat.style = {
            border: 'none',
            width: '100%',
            height: '620px',
            fontSize: '14px',
            lineHeight: '20px',
        };
        _chat.auxiliaryStyle = css `
            .fw-icon {
                max-width: 100%;
                max-height: 100%;
                stroke-linecap: round;
                stroke-linejoin: round;
                flex-shrink: 0 !important;
            }

            button {
                display: inline-flex;
                justify-content: space-evenly;
                align-items: center;
                text-align: center;
                vertical-align: middle;
                flex-wrap: nowrap;
                white-space: nowrap;
                user-select: none;
                cursor: pointer;
                box-sizing: content-box;

                gap: .5em;
                padding: 0;
                margin: 0;
                outline: none;
                border: 0 solid transparent;

                min-height: auto;
                min-width: auto;
                font-size: inherit;
                line-height: 1.25em;

                color: inherit;
                background-color: transparent;

                &:is(:hover, :focus, .focus) {
                    outline: 0;
                    text-decoration: none;
                }

                *:is([aria-disabled=true], [aria-busy=true]) &,
                &:is(.disabled, :disabled, [aria-disabled=true], [aria-busy=true]) {
                    user-select: none;
                    cursor: default;
                    opacity: .7;
                }
            }

            #messages {
                &:after {
                    content: "";
                    display: block;
                    height: 100px;
                    width: 100%;
                    min-width: 1px;
                }
            }

            .message-bubble {
                a {
                    color: inherit;
                }

                p {
                    margin: 1em 0 .6em;
                }

                ul {
                    padding-left: 10px;
                    margin: .6em 0 1em 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                }

                :is(p):last-child {
                    margin-bottom: 0;
                }

                :is(p, ul):first-child {
                    margin-top: 0;
                }
            }
        `.toString();
        _chat.htmlClassUtilities = {
            'message': {
                styles: {
                    default: {
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 'var(--chat-min-height, auto)',
                    },
                },
            },
            'message-body': {
                styles: {
                    default: {
                        flex: '1',
                    },
                },
            },
            'message-feedback': {
                styles: {
                    default: {
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        top: 'calc(100% - 0px)',
                        right: '5px',
                        gap: '.5em',
                        padding: '1px',
                        borderRadius: '8px',
                        backgroundColor: theme._neutral.backgroundColor,
                        color: theme._neutral.color,
                        boxShadow: '0 -3px 10px 1px #888',
                    },
                },
            },
        };
        const vars = {
            size: '25px',
        };
        _chat.htmlClassUtilities = {
            ..._chat.htmlClassUtilities,
            'chat-feedback': {
                styles: {
                    default: {
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        padding: '0',
                        marginRight: '-10px',
                        minHeight: '50px'
                    },
                },
            },
            'feedback-icon': {
                styles: {
                    default: {
                        cursor: 'pointer',
                        margin: '0',
                        width: vars.size,
                        height: vars.size,
                        fontSize: vars.size,
                        lineHeight: '1',
                        padding: '10px',
                        borderRadius: '8px',
                    },
                    hover: {
                        backgroundColor: 'rgb(from var(--fw-color-primary-10) r g b/.50)',
                    },
                },
                events: {
                    click(e) {
                        const ele = (e.currentTarget ?? e.target);
                        if (ele) {
                            const score = ele.dataset?.['score'];
                            api.saveFeedback(score);
                        }
                    },
                },
            }
        };
        _chat.messageStyles = {
            default: {
                shared: {
                    outerContainer: {
                        margin: '15px 0',
                    },
                    bubble: {
                        borderRadius: 'var(--fw_chat-borderRadius)',
                        padding: '10px',
                        marginTop: '0',
                        maxWidth: '60%',
                        lineHeight: '20px',
                        ...theme._neutral,
                    },
                },
                ai: {
                    bubble: {
                        ...theme._primary,
                        padding: '12px 12px 11px',
                    },
                },
                info: {
                    bubble: {
                        backgroundColor: 'transparent',
                        maxWidth: '100%',
                        color: theme._neutral.color,
                    },
                },
            },
            html: {
                shared: {
                    bubble: {
                        maxWidth: '70%',
                    },
                },
                feedback: {
                    innerContainer: {
                        width: '100%'
                    },
                    outerContainer: {
                        position: 'absolute',
                        zIndex: '10',
                        margin: '0',
                        padding: '15px',
                        bottom: '57px',
                        left: '0',
                        right: '10px',
                        width: 'auto',
                        boxSizing: 'border-box',
                        backgroundColor: 'var(--fw-color-white)',
                        color: 'var(--fw-color-primary-500)',
                    },
                    bubble: {
                        backgroundColor: 'var(--fw-color-primary-60)',
                        maxWidth: '100%',
                        color: 'inherit',
                        padding: '10px 20px',
                        boxShadow: '0 -5px 20px 0px rgba(0,0,0,0.15)',
                        border: '1px solid rgb(from var(--fw-color-primary-500) r g b/.35)',
                        ...theme.typography.body.css({ fontWeight: '400' }),
                        flex: '1',
                    },
                },
            },
            intro: {
                bubble: {
                    ...theme._primary,
                    padding: '12px 12px 11px',
                },
            },
            loading: {
                message: {
                    styles: {
                        bubble: {
                            padding: 'revert-layer',
                            ...theme._primary,
                        },
                    },
                },
            },
        };
    };
    const withAvatar = ({ _chat, theme, options: { userImage, userName } }) => {
        const user = getCurrentUser();
        const _userImage = userImage ?? user?.profileImage;
        const _userName = userName ?? (user?.firstName && `${user.firstName} ${user.lastName}`);
        const avatarSize = '22px';
        const avatarIconSrc = () => {
            let styles = {
                backgroundColor: theme._neutral.color,
                margin: '0',
                width: '24px',
                height: '24px',
                padding: '2px',
                borderRadius: '16px',
            };
            if (_userImage)
                return {
                    src: _userImage,
                    styles: {
                        avatar: {
                            ...styles,
                            width: '28px',
                            height: '28px',
                            padding: '0',
                        },
                    },
                };
            if (_userName)
                return {
                    src: ChatView.iconSrc(_jsx(AvatarIcon, { text: _userName, size: avatarSize, color: theme._neutral.backgroundColor })),
                    styles: { avatar: styles },
                };
            return {
                src: ChatView.iconSrc(_jsx(Icon, { icon: 'user', size: avatarSize, color: theme._neutral.backgroundColor })),
                styles: { avatar: styles },
            };
        };
        _chat.avatars = {
            default: {
                styles: {
                    container: {
                        marginTop: '0',
                    },
                    avatar: { height: avatarSize, width: avatarSize },
                },
            },
            ai: {
                src: ChatView.iconSrc(_jsx(Icon, { icon: 'bot', size: avatarSize, color: theme._primary.backgroundColor })),
            },
            feedback: {
                styles: {
                    container: { display: 'none' }
                }
            },
            user: avatarIconSrc(),
        };
        return _chat;
    };
    const withInput = ({ _chat, theme, options }) => {
        const searchHeight = '32px';
        _chat.inputAreaStyle = {
            borderTop: '1px solid var(--fw-color-neutral-100)',
            padding: '0 16px',
            boxSizing: 'border-box',
            scale: '1',
        };
        _chat.textInput = {
            placeholder: {
                text: options.placeholder,
                style: {
                    color: 'var(--fw-color-neutral-100)',
                },
            },
            styles: {
                container: {
                    ...theme.typography.medium.fontSizeStyles,
                    boxShadow: 'none',
                    border: 'none',
                    margin: '12px 0',
                    width: 'auto',
                    flex: '1',
                    scale: '1',
                    height: searchHeight,
                },
            },
        };
        _chat.submitButtonStyles = {
            position: 'inside-right',
            alwaysEnabled: false,
            submit: {
                container: {
                    default: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '6px',
                        margin: '0',
                        borderRadius: '16px',
                        fontSize: '20px',
                        bottom: 'auto',
                        top: '12px',
                        boxSizing: 'border-box',
                        scale: '1',
                        ...theme._primary,
                    },
                },
                svg: {
                    styles: {
                        default: {
                            display: 'block',
                            width: '1em',
                            height: '1em',
                            filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7%) hue-rotate(240deg) brightness(102%) contrast(102%)',
                        },
                    },
                },
            },
            loading: {
                container: {
                    default: { justifyContent: 'start' },
                },
                svg: {
                    styles: {
                        default: {
                            filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7%) hue-rotate(240deg) brightness(102%) contrast(102%)',
                            width: '.25em',
                            height: '.25em',
                            bottom: 'auto',
                            left: 'calc(-9990px)',
                        },
                    },
                },
            },
            disabled: {
                container: {
                    default: { ...theme._neutral },
                    hover: { cursor: 'default' },
                },
                svg: {
                    styles: {
                        default: { filter: 'none', ...theme._neutral },
                    },
                },
            },
        };
        return _chat;
    };
    const _createThemeProps = (theme) => ({
        ...theme,
        _primary: {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.$500,
        },
        _neutral: {
            color: theme.palette.neutral.$500,
            backgroundColor: theme.palette.neutral.$50,
        },
    });
})(ChatProvider || (ChatProvider = {}));
