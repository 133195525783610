import { AbstractFormType, BooleanType, MoneyType, SelectType, StringType } from "@f2w/form";
import { translate } from "Services/Translator";
import { BillingCycle } from "Components/ObjectViewModal";
import React, { useState } from "react";
import { usePortfolio } from "Components/Base/PortfolioContext";
import IndexServices from "Services/IndexServices";
import RentServices from "Services/RentServices";
import { formatAmount, formatPercentage } from "Services/NumberHelper";
export class RentType extends AbstractFormType {
    indexBases = [];
    costIncreases = [];
    inflationIndexes = [];
    referenceInterestRates = [];
    _useField(props) {
        super._useField(props);
        const [indexBases, setIndexBases] = useState([]);
        const [costIncreases, setCostIncreases] = useState([]);
        const [inflationIndexes, setInflationIndexes] = useState([]);
        const [referenceInterestRates, setReferenceInterestRates] = useState([]);
        this.indexBases = indexBases;
        this.costIncreases = costIncreases;
        this.inflationIndexes = inflationIndexes;
        this.referenceInterestRates = referenceInterestRates;
        const portfolio = usePortfolio();
        React.useEffect(() => {
            const promises = [
                IndexServices.getIndexBases(),
                RentServices.getRentCostIncreaseOptions(),
                IndexServices.getReferenceInterestRates()
            ];
            const property = portfolio.getPropertyByRentalObjectId(this.value.rentalObject.id);
            Promise.all(promises)
                .then(response => {
                setIndexBases(response[0]?.map?.(indexBase => ({
                    label: `${indexBase.month}/${indexBase.year}`,
                    value: indexBase.id,
                })));
                setCostIncreases(Object.keys(response[1]).map(key => ({
                    label: key,
                    value: response[1][key],
                })));
                setReferenceInterestRates(response[2]?.map?.(referenceInterestRate => ({
                    label: `${referenceInterestRate.month}/${referenceInterestRate.year}: ${formatPercentage(referenceInterestRate.rate, "0.00%")}`,
                    value: referenceInterestRate.id,
                })));
            });
        }, []);
        const indexBaseId = this.field.value.indexBaseId;
        React.useEffect(() => {
            if (indexBaseId) {
                IndexServices.getInflationIndexesByIndexBase(indexBaseId)
                    .then(response => setInflationIndexes(response?.map?.(inflationIndex => ({
                    value: inflationIndex.id,
                    label: `${inflationIndex.month}/${inflationIndex.year}: ${formatAmount(inflationIndex.amount)}`,
                }))));
            }
            else {
                setInflationIndexes([]);
            }
        }, [indexBaseId]);
    }
    buildFields() {
        return {
            netAmount: new MoneyType({
                label: translate('rent.netAmount'),
                constraints: {
                    typeError: translate('form.validation.notValidNumber'),
                },
                required: true,
            }),
            grossAmount: new MoneyType({
                label: translate('rent.grossAmount'),
                constraints: {
                    typeError: translate('form.validation.notValidNumber'),
                },
                required: true,
            }),
            billingCycle: new SelectType({
                label: translate('rent.billingCycle'),
                required: true,
                choices: {
                    [BillingCycle.MONTHLY]: translate('monthly'),
                    [BillingCycle.QUARTERLY]: translate('quarterly'),
                    [BillingCycle.HALF_ANNUALLY]: translate('half-yearly'),
                    [BillingCycle.ANNUALLY]: translate('yearly'),
                }
            }),
            indexBaseId: new SelectType({
                label: translate('rent.indexBase'),
                required: false,
                choiceOptions: () => this.indexBases,
                clearable: true,
            }),
            inflationIndexId: new SelectType({
                label: translate('rent.inflationIndex'),
                required: false,
                choiceOptions: () => this.inflationIndexes,
                clearable: true,
            }),
            referenceInterestRateId: new SelectType({
                label: translate('rent.lastReferenceInterestRateChange'),
                required: false,
                choiceOptions: () => this.referenceInterestRates,
                clearable: true,
            }),
            lastCostSettlement: new SelectType({
                label: translate('rent.lastCostSettlement'),
                required: false,
                choiceOptions: () => this.costIncreases,
                searchable: true,
                clearable: true,
            }),
            flatRate: new SelectType({
                label: translate('rent.flatRate'),
                choices: {
                    0: '0%',
                    0.0025: '0.25%',
                    0.005: '0.5%',
                    0.0075: '0.75%',
                    0.01: '1%',
                    0.015: '1.5%',
                    0.1: '10%',
                },
                required: true,
            }),
            hasVat: new BooleanType({
                label: translate('rent.hasVat'),
                defaultValue: false,
                required: false,
                disabled: () => this.field.value.isVacancy,
            }),
            reservation: new StringType({
                label: translate('rent.reservation'),
                required: false,
            }),
        };
    }
}
