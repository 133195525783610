import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ButtonGroup } from 'Atoms/ButtonGroup';
import { Carousel } from 'Components/Carousel';
import { BaseButton, Button } from 'Atoms/Button';
import { trans } from 'Services/App';
import { CarouselPreview } from 'Components/Carousel/CarouselPreview';
import { Media2 } from './ImageCarousel';
export const ImagePreview = ({ images, renderActions: _renderActions, }) => {
    const [preview, setPreview] = useState();
    const showPreview = useCallback((index = 0) => {
        if (!images.length)
            return;
        const activeIndex = images[index] ? index : 0;
        return setPreview({ activeIndex });
    }, [!images.length]);
    const renderActions = (index, props) => _jsx(_Fragment, { children: _jsxs(ButtonGroup, { size: 'sm', variant: 'outline', children: [_jsx(Button, { href: images[index].path, title: trans('btn.download'), download: images[index].name ?? '', icon: 'download' }), _renderActions?.(index, props)] }) });
    return _jsxs(_Fragment, { children: [_jsx(PreviewButton, { onClick: () => showPreview(), label: _jsx(_Fragment, { children: images.length }) }), _jsx(CarouselPreview, { show: preview != null, onHide: () => setPreview(null), defaultActiveIndex: preview?.activeIndex ?? 0, renderActions: renderActions, children: images.map((image, key) => (_jsx(Carousel.Item, { children: _jsx(Media2, { children: _jsx("img", { src: image.path, alt: image.name ?? '' }) }) }, key))) })] });
};
export const PreviewButton = styled(BaseButton).attrs(({ variant, icon }) => ({
    variant: variant ?? 'link',
    icon: icon ?? 'image',
})) `
    position: relative;
    > span {
        --fw-bubble-size: 17px;
        position: absolute;
        display: inline-flex !important;
        justify-content: center;
        align-items: center;
        text-align: center;

        color: var(--fw-color-white);
        background: var(--fw-color-primary-500);
        border: var(--fw-color-white) 2px solid;

        right: 0;
        top: 0;

        box-sizing: content-box;
        border-radius: 50%;
        width: var(--fw-bubble-size);
        height: var(--fw-bubble-size);
        font-size: 10px;
        line-height: 1em;

        font-weight: 600;
    }
`;
