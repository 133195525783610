import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DataTable } from "@f2w/data-table";
import { FlexCol, FlexRow, Icon } from "Components/Atoms";
import moment from "moment/moment";
import { ProtocolPersonDirection } from "Pages/Handover/enums/ProtocolPersonDirection";
import { formatPersonName } from "Pages/Handover/services/HandoverFormatter";
import { HandoverDirection } from "Pages/Handover/enums/HandoverDirection";
import generateRoute from "Services/Router";
import { translate } from "Services/Translator";
import { useMounted } from '@restart/hooks';
import HandoverStatusBadge from "Pages/Handover/components/HandoverStatusBadge";
const TenantRow = ({ tenants, direction }) => {
    const tenant = tenants.find((v) => v.direction === direction);
    if (!tenant) {
        return (_jsx("div", { style: { color: 'var(--fw-color-neutral-100)' }, children: translate('handovers.listView.vacancy') }));
    }
    const tenantStyle = !tenant.attended ? { color: 'var(--fw-color-neutral-100)' } : {};
    return (_jsx("div", { children: tenant.personId !== null
            ? _jsx("a", { style: tenantStyle, href: generateRoute('person.list', { personId: tenant.personId }), target: '_blank', children: formatPersonName(tenant) })
            : _jsx("span", { style: tenantStyle, children: formatPersonName(tenant) }) }));
};
const MoveIcon = ({ direction }) => {
    const icon = direction === HandoverDirection.IN
        ? 'move-in'
        : direction === HandoverDirection.OUT
            ? 'move-out'
            : 'move-in-and-out';
    return (_jsx(Icon, { icon: icon, size: 24, style: { display: 'block', margin: 'auto 0' } }));
};
const BaseTable = DataTable.factory()(() => {
    return {
        manualControl: false,
        useGlobalFilter: {},
        columns: {
            tenants: {
                Header: translate('handovers.listView.columns.parties'),
                Cell: ({ row: { original: { tenants, type } } }) => {
                    return (_jsxs(FlexRow, { gap: 16, children: [_jsx(FlexCol, { alignItems: 'center', children: _jsx(MoveIcon, { direction: type }) }), _jsxs(FlexCol, { gap: 7, children: [_jsx(TenantRow, { tenants: tenants, direction: ProtocolPersonDirection.OUT }), _jsx(TenantRow, { tenants: tenants, direction: ProtocolPersonDirection.IN })] })] }));
                },
            },
            status: {
                Header: translate('handovers.listView.columns.status'),
                Cell: ({ row: { original: { state } } }) => (_jsx(HandoverStatusBadge, { status: state })),
            },
            datum: {
                Header: translate('handovers.listView.columns.date'),
                accessor: v => moment(v.handoverDate).format('DD.MM.YYYY')
            },
            view: {
                Header: '',
                Cell: ({ row: { original: { id, state } }, onViewHandoverClicked }) => (_jsx(_Fragment, { children: state >= 6 && _jsx("a", { href: '#', onClick: (event) => {
                            event.preventDefault();
                            onViewHandoverClicked(id);
                        }, children: "Anzeigen" }) }))
            }
        }
    };
});
export const HandoverProtocolsTable = Object.assign(BaseTable, {
    use: ({ handoverServices, onViewHandoverClicked, onEmptyResult, rentalObjectId }) => {
        const isMounted = useMounted();
        return BaseTable.useTable({
            onViewHandoverClicked
        }, {
            getData: async () => {
                const results = await handoverServices.getProtocols(rentalObjectId);
                if (!results || results.length === 0) {
                    isMounted() && onEmptyResult(false);
                }
                return {
                    results: results ?? [],
                };
            }
        });
    }
});
