import { isObject, isUndefined } from '@f2w/utils';
import { CompoundState, CompoundValue, } from '../base';
export class ObjectState extends CompoundState {
    _extraData = Object.create(null);
    get initialValue() {
        return { ...this._extraData, ...this.data.initialValue };
    }
    get value() {
        return { ...this._extraData, ...this.data.value };
    }
    get children() {
        return this.data.fields;
    }
    mapEntries(cb) {
        const items = {};
        this.childrenMap.forEach((child, key) => {
            const value = cb(child, key);
            if (!isUndefined(value))
                items[key] = value;
        });
        return items;
    }
}
export class ObjectValue extends CompoundValue {
    _state;
    children;
    constructor(type, props) {
        super(type, props);
        this._state = new ObjectState({
            getSelf: () => this,
        });
        Object.defineProperties(this, {
            extra: {
                enumerable: true,
                get: () => this._state._extraData,
            },
            children: {
                enumerable: true,
                get: () => this._state.data.fields,
            },
        });
    }
    register(key, type) {
        if (!this._state.has(key)) {
            const child = type.createValue({ key });
            this._state.register(key, child);
        }
    }
    get initialValue() {
        return this._state.initialValue;
    }
    get value() {
        return this._state.value;
    }
    map(cb) {
        return this._state.map(cb);
    }
    mapEntries(cb) {
        return this._state.mapEntries(cb);
    }
    getResolvedValue(options) {
        const value = this._state.mapEntries((child) => {
            if (child.isActive)
                return child.getResolvedValue(options);
        });
        return (this.options.getResolvedValue ? this.options.getResolvedValue({
            ...this._state._extraData,
            ...value,
        }, this) : value);
    }
    reset(value, shouldValidate, skipRender) {
        value = this._state._cast(value, value !== undefined);
        value && this._forValue(value, (key, value, child) => {
            if (child)
                child.reset(value, false, true);
            else
                this._state._extraData[key] = value;
        });
        this.forEach((child, key) => {
            (key in value) || child.reset(undefined, false, true);
        });
        return this._handleUpdate(skipRender, shouldValidate);
    }
    setValue(value, shouldValidate, skipRender) {
        value = this._state._cast(value, value !== undefined);
        value && this._forValue(value, (key, value, child) => {
            if (child)
                child.setValue(value, false, true);
            else
                this._state._extraData[key] = value;
        });
        return this._handleUpdate(skipRender, shouldValidate);
    }
    updateValue(value, shouldValidate, skipRender) {
        isObject(value)
            ? this._forValue(value, (key, value, child) => {
                if (child)
                    child.updateValue(value, false, true);
                else
                    this._state._extraData[key] = value;
            })
            : this.forEach(child => child.updateValue(undefined, false, true));
        return this._handleUpdate(skipRender, shouldValidate);
    }
    setTouched(value, shouldValidate, skipRender) {
        if (isObject(value)) {
            Object.keys(value).forEach((key) => this.get(key)?.setTouched(value[key], false, true));
        }
        else {
            this.forEach(child => child.setTouched(!!value, false, true));
        }
        return this._handleUpdate(skipRender, shouldValidate);
    }
    setError(value, skipRender) {
        if (isObject(value)) {
            Object.keys(value).forEach((key) => this.get(key)?.setError(value[key], true));
        }
        else {
            this.forEach(child => child.setError(!!value, true));
        }
        return this._handleUpdate(skipRender);
    }
    _forValue(value, onUpdate) {
        Object.keys(value)
            .forEach((key) => {
            this.has(key) ? onUpdate(key, value[key], this.get(key)) : onUpdate(key, value[key]);
        });
    }
}
