import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { UseStyled } from "@fw/theme";
import { FlexBox } from "Atoms/Layout/Flex";
import { css } from "styled-components";
import { Text } from "Atoms/Text";
const BaseBox = UseStyled.styled(FlexBox, ({ theme: { palette } }) => (css `
      border: 1px solid ${palette.neutral.$100};
      border-radius: 8px;
    `));
export const Box = ({ children, preTitle, body, ...rest }) => (_jsx(BaseBox, { direction: 'column', justifySpaceBetween: true, gap: 8, flexGrow: 1, p: 24, ...rest, children: children
        ? children
        : (_jsxs(_Fragment, { children: [_jsx(Text, { pretitle: 500, neutral: 300, children: preTitle }), _jsx(Text, { body: 500, neutral: 900, children: body })] })) }));
