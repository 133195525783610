import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from "Services/App";
import { Select } from "Atoms/Select";
import { useCallback, useEffect, useState } from "react";
import BankAccountServices from "Pages/BankAccount/services/bankAccountServices";
import { Div } from "Atoms/Layout";
import { BankingFileService } from "Pages/BankingReconciliation/services";
import moment from "moment";
import { BankAccountType } from "Pages/BankAccount/enums/BankAccountType";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
import { DeleteIcon } from "Components/App/Icons";
const BaseTable = Table.factory()(() => ({
    tableId: 'banking-uploads-table',
    manualControl: true,
    useSortBy: {
        initialState: {
            dragAndDropCaption: translate('bankingReconciliation.dragAndDrop'),
            sortBy: [
                {
                    id: 'uploadTime',
                    desc: true
                }
            ]
        }
    },
    RowActions: ({ row: { original: { totalTransactions, nbAssignedTransactions, id } }, deleteBankingUpload, goToTransactionTab }) => (_jsx(ActionsDropdown, { id: id, quickActions: totalTransactions > 0 ? [{
                icon: 'eye',
                title: translate('bankingReconciliation.assignTransactions.title'),
                onClick: () => goToTransactionTab(id)
            }] : [], children: _jsxs(ActionsDropdown.Link, { disabled: nbAssignedTransactions > 0, confirm: translate('bankingReconciliation.uploadFiles.deleteUpload.confirm'), onClick: () => deleteBankingUpload(id), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('btn.delete') })] }) })),
    usePagination: {},
    useDropzone: {
        onDropAccepted: (files, { addFiles }) => addFiles(files),
        onDropRejected: (files, instance) => { },
        onDrop: () => { },
        dropzoneOptions: {
            multiple: true,
            accept: [
                'text/xml',
                'application/zip',
                'application/x-zip',
                'application/x-zip-compressed',
            ]
        }
    },
    columns: {
        uploadTime: {
            Header: translate('date'),
            Cell: ({ value }) => moment(value).format('DD.MM.YYYY HH:mm:ss'),
            useSortBy: {}
        },
        bankAccount: {
            Header: translate('bankAccount.caption'),
            Cell: ({ value, row: { original: { totalTransactions } } }) => value ? value.name
                : (totalTransactions > 0 ? translate('bankingReconciliation.multipleBankAccounts') : ''),
            useSortBy: {},
            useFilters: {
                Filter: ({ column: { filterValue, setFilter }, getBankAccounts }) => {
                    const [bankAccounts, setBankAccounts] = useState([]);
                    useEffect(() => {
                        getBankAccounts(BankAccountType.RENT)
                            .then(bankAccounts => setBankAccounts(bankAccounts.map(ba => ({
                            label: ba.name,
                            value: ba.id
                        }))));
                    }, []);
                    return _jsx(Div, { w: 300, children: _jsx(Select, { options: bankAccounts, value: filterValue, isSearchable: true, isClearable: true, onChange: (newValue) => setFilter(newValue?.value) }) });
                }
            }
        },
        totalTransactions: {
            Header: translate('total'),
        },
        nbAssignedTransactions: {
            Header: translate('bankingReconciliation.nbAssigned'),
        },
        nbNotAssignedTransactions: {
            Header: translate('bankingReconciliation.nbNotAssigned'),
            useSortBy: {}
        }
    }
}));
export const BankingFileDataTable = Object.assign(BaseTable, {
    use({ getUploads = BankingFileService.getUploads, deleteUpload, getBankAccounts = BankAccountServices.getBankAccounts, goToTransactionTab, openUploadModal, }) {
        const addFiles = useCallback(async (files) => {
            openUploadModal?.(files);
        }, []);
        return BankingFileDataTable.useTable({
            addFiles,
            getBankAccounts,
            goToTransactionTab,
            deleteBankingUpload: deleteUpload
        }, {
            getData: async ({ request }) => getUploads(request)
        });
    }
});
