import { jsx as _jsx } from "react/jsx-runtime";
import { BaseSimpleType, MaskedType, NumberValueType, SimpleValue, } from '../../core';
import { FormControl } from 'Atoms/Input';
import { FloorsRange } from 'Pages/BaseData/types';
import { formatMoney } from 'Services/NumberHelper';
export class NumberType extends BaseSimpleType {
    _createValue(props) {
        return new SimpleValue(this, props);
    }
    _getSpecs() {
        return new NumberValueType({
            defaultOptions: () => ({})
        });
    }
    _renderWidget({ field: valueType, ...props }) {
        const controlProps = {
            step: valueType.options.step,
            min: valueType.options.min,
            max: valueType.options.max,
        };
        if (controlProps.step > 0) {
            return _jsx(FormControl.Number, { ...props, ...controlProps });
        }
        return _jsx(FormControl.Input, { type: 'number', ...props, ...controlProps });
    }
}
export class MoneyType extends MaskedType {
    _getSpecs() {
        return NumberValueType.define({
            defaultOptions: () => ({
                currency: 'CHF',
            }),
        });
    }
    getMask() {
        return ({
            mask: Number,
            autofix: false,
            thousandsSeparator: "'",
            scale: 2,
            padFractionalZeros: true,
            normalizeZeros: true,
            radix: '.',
            mapToRadix: [','],
        });
    }
    format(value, formatter) {
        return formatMoney(value) ?? '';
    }
    _renderWidget(props) {
        return super._renderWidget({
            ...props,
            prefix: this.specs.options.currency,
        });
    }
}
export class DecimalType extends MaskedType {
    getMask() {
        return ({
            mask: Number,
            thousandsSeparator: "'",
            scale: this.specs.options.scale ?? 2,
            padFractionalZeros: true,
            normalizeZeros: true,
            radix: '.',
            mapToRadix: [','],
        });
    }
    _getSpecs() {
        return NumberValueType.define({
            defaultOptions: () => ({
                scale: 2,
            }),
        });
    }
    _renderWidget(props) {
        return super._renderWidget({
            prefix: this.specs.options.prefix ?? '.00',
            ...props,
        });
    }
}
export class FloorType extends NumberType {
    constructor(options) {
        super({
            ...options,
            min: -99,
            max: 99,
            step: 1,
        });
    }
    _getSpecs() {
        const specs = super._getSpecs();
        specs.options.min = FloorsRange.MIN;
        specs.options.max = FloorsRange.MAX;
        specs.options.step = 1;
        return specs;
    }
}
