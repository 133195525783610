import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FeedbackScore } from 'Components/ChatBot/ChatApi';
import { renderToString } from 'react-dom/server';
import classNames from 'classnames';
import { Icon } from 'Atoms/Icon';
import { translate } from "Services/App";
export var ChatView;
(function (ChatView) {
    const Message = ({ response: data, ...props }) => (_jsx(StyledMessage, { ...props, children: _jsxs(StyledMessageBody, { children: [data?.message && _jsx("p", { children: data?.message }), data?.references?.length && _jsx(ReferenceList, { references: data?.references }), props.children] }) }));
    ChatView.apiMessage = ({ overwrite, ...props }) => {
        return {
            role: 'api',
            html: renderToString(_jsx(Message, { ...props })),
            overwrite
        };
    };
    ChatView.renderFeedbackMessage = (isAsking) => isAsking ? renderToString(_jsxs(StyledMessage, { children: [_jsx(StyledMessageBody, { children: _jsx("p", { children: translate('chat.feedback.ask') }) }), _jsx("div", { className: "feedback chat-feedback", children: _jsx(FeedbackActions, {}) })] })) : `<div class="deep-chat-temporary-message">${translate('chat.feedback.thankyou')}</div>`;
    const StyledMessageBody = ({ className, ...props }) => (_jsx("div", { className: classNames('message-body', className), ...props }));
    const StyledMessage = ({ className, loading, flash, ...props }) => (_jsx("div", { "aria-busy": loading, className: classNames('message', className, {
            'deep-chat-temporary-message': flash
        }), ...props }));
    const ReferenceList = ({ references }) => (!references?.length ? null : _jsxs(_Fragment, { children: [_jsx("p", { children: "Weitere Informationen dazu finden Sie unter:" }), _jsx("ul", { children: references.map(ref => (_jsx("li", { children: _jsx("a", { href: ref.url, target: "_blank", children: ref.title }) }, ref.url))) })] }));
    const FeedbackActions = () => (_jsxs(_Fragment, { children: [_jsx("button", { className: "feedback-icon", "data-score": FeedbackScore.GOOD, children: _jsx(Icon, { icon: 'thumbs-up' }) }), _jsx("button", { className: "feedback-icon", "data-score": FeedbackScore.BAD, children: _jsx(Icon, { icon: 'thumbs-down' }) })] }));
    function iconSrc(component) {
        return `data:image/svg+xml;utf8,${encodeURIComponent(renderToString(component))}`;
    }
    ChatView.iconSrc = iconSrc;
})(ChatView || (ChatView = {}));
