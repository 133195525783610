import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, BooleanType, DateType, SelectType } from "@f2w/form";
import { translate } from "Services/App";
import { useEffect, useState } from "react";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { Checkbox } from "Atoms/Input/controls";
import { FlexCol, FlexRow } from "Atoms/Layout";
export class FinancialBalanceSheetExport extends AbstractFormType {
    properties = [];
    _useField(props) {
        super._useField(props);
        const [properties, setProperties] = useState([]);
        this.properties = properties;
        const portfolio = usePortfolio();
        useEffect(() => {
            setProperties(portfolio.getProperties().map(property => ({
                label: property.name,
                value: property.id
            })));
        }, []);
    }
    buildFields() {
        return {
            propertyId: new SelectType({
                label: translate('property'),
                choiceOptions: () => this.properties,
                disabled: true
            }),
            reportingDate: new DateType({
                label: translate('accounting.reportingDate'),
                min: this.options?.min?.(),
                max: this.options?.max?.(),
            }),
            showAllFinancialAccounts: new BooleanType({
                hidden: true,
                defaultValue: false
            })
        };
    }
    _renderWidget(iProps) {
        return (_jsxs(FlexCol, { children: [this.renderChild('propertyId'), this.renderChild('reportingDate'), _jsxs(FlexRow, { gap: 10, children: [_jsx(Checkbox, { name: 'financial-accounts', radio: true, onChange: () => this.children.showAllFinancialAccounts.field.setValue(true), children: translate('accounting.select.allRecord.label') }), _jsx(Checkbox, { name: 'financial-accounts', radio: true, onChange: () => this.children.showAllFinancialAccounts.field.setValue(false), defaultChecked: true, children: translate('accounting.select.onlyActiveAccounts.label') })] })] }));
    }
}
