import { isNumOrStr, isObjectLike } from 'Utils';
import { CompoundState, CompoundValue, } from '../base';
export class ArrayState extends CompoundState {
    selected = this.indexManager
        .create('selected', {
        getMetaValue: (set) => set.size === this.getChildrenSize(),
        onAdd: (keys) => {
            this.updateIndexes({
                childrenOrKeys: keys,
                ignoredKeys: ['selected']
            });
        },
        onRemove: (keys) => {
            this.updateParent();
        },
        onChange: (keys, add, indexType) => {
            this._updateParentSelected(this._self().isAllSelected());
        },
    });
    onAddChild(child) {
        this._nextIndexKey++;
        this.indexMap.selected.add(child);
    }
    onRemoveChild(child) {
        this.indexMap.selected.remove(child);
    }
    _updateParentSelected(add, child = this._parent) {
        if (child?.['_parent'] instanceof ArrayState)
            return child['_parent'].indexMap.selected.update(child.key, add);
        if (child?.['_parent'])
            return this._updateParentSelected(add, child?.['_parent']);
    }
    get initialValue() {
        return Object.values(this.data.initialValue);
    }
    get value() {
        return Object.values(this.data.value);
    }
    get children() {
        return [...this.childrenMap.values()];
    }
    _nextIndexKey = 0;
    getNextIndexKey() {
        return this._nextIndexKey.toString();
    }
    _getOrCreate(key) {
        if (isNumOrStr(key) && this.has(key)) {
            return this.get(key);
        }
        key = this.getNextIndexKey();
        const proto = this._type().createProtoType(key);
        const child = proto.createValue({ key });
        this.register(key, child);
        return child;
    }
}
export class ArrayValue extends CompoundValue {
    _state;
    constructor(type, props) {
        super(type, props);
        this._state = new ArrayState({
            getSelf: () => this,
            onIndexChange: ({ propName, ...props }) => {
                switch (propName) {
                    case 'selected':
                        this.options.selectable?.onChange?.(this, props);
                        break;
                }
            },
        });
    }
    get protoType() {
        return this.specs.proto;
    }
    get isDisabled() {
        return super.isDisabled;
    }
    getActiveChildrenSize() {
        return this.children.filter(c => (this.isPartialSelected(c) || this.isSelected(c))).length;
    }
    get indexes() {
        return this._state.indexMap;
    }
    isEnabled(keyOrChild) {
        return !this.options.selectable || (this.isSelected(keyOrChild));
    }
    _selectableChild(keyOrChild) {
        return this.options.selectable?.getChild?.(this._state.get(keyOrChild));
    }
    isSelected(keyOrChild) {
        const child = this._state.get(keyOrChild);
        const childArray = this._selectableChild(child);
        if (false === childArray?.isEmpty) {
            return childArray.isSomeSelected();
        }
        else {
            return this._state.indexMap.selected.has(keyOrChild);
        }
    }
    isPartialSelected(keyOrChild) {
        const childArray = this._selectableChild(keyOrChild);
        if (childArray) {
            return childArray.isSomeSelected() && !childArray.isAllSelected();
        }
    }
    isAllSelected() {
        return this._state.indexMap.selected.get();
    }
    isSomeSelected() {
        return this._state.indexMap.selected.size > 0;
    }
    updateSelected(keyOrChild, add = !this.isSelected(keyOrChild), skipRender) {
        let updated;
        const childArray = this._selectableChild(keyOrChild);
        if (childArray) {
            if (childArray?.hasChildren) {
                childArray.forEach(c => {
                    childArray.updateSelected(c.key, add, true);
                });
            }
        }
        updated = this._state.indexMap.selected.update(keyOrChild, add);
        if (!skipRender) {
            this._dispatchUpdate();
        }
    }
    updateAllSelected(add, skipRender) {
        this.forEach(c => this.updateSelected(c, add, true));
        if (!skipRender) {
            this._dispatchUpdate();
        }
    }
    get children() {
        return this._state.children;
    }
    mapSelected(cb) {
        return this._state.indexMap.selected.map(key => cb(this._state.get(key), key));
    }
    getResolvedValue(options) {
        return this._state.map(child => {
            if (this.isEnabled(child)) {
                return child.getResolvedValue(options);
            }
        });
    }
    push(value) {
        const child = this._state._getOrCreate();
        child.reset(value, false, true);
        this._handleUpdate();
    }
    pushAll(values) {
        values.forEach(value => {
            const child = this._state._getOrCreate();
            child.reset(value, false, true);
        });
        this._handleUpdate();
    }
    remove(key) {
        if (this._state.remove(key)) {
            this._handleUpdate();
        }
    }
    get(key) {
        return this._state.get(key);
    }
    forEach(cb) {
        return this._state.forEach(cb);
    }
    map(cb) {
        return this._state.map(cb);
    }
    reset(initialValue, shouldValidate, skipRender) {
        const value = isObjectLike(initialValue) ? initialValue : this.specs.getDefault();
        if (Array.isArray(value)) {
            value.forEach((val, key) => {
                const child = this._state._getOrCreate(key);
                child.reset(val, false, true);
            });
        }
        return this._handleUpdate(skipRender, shouldValidate);
    }
    setValue(value, shouldValidate, skipRender) {
        value = (isObjectLike(value) ? value : this.specs.getDefault());
        if (Array.isArray(value)) {
            value.forEach((val, key) => {
                let child = this._state._getOrCreate(key);
                child.setValue(val, false, true);
            });
        }
        return this._handleUpdate(skipRender, shouldValidate);
    }
    updateValue(value, shouldValidate, skipRender) {
        return this;
        if (Array.isArray(value)) {
            value.forEach(key => {
                if (this.has(key)) {
                    this.get(key).updateValue(value[key], shouldValidate, true);
                }
                else {
                    this.push(value[key]);
                }
            });
        }
        else {
            this.forEach(child => child.updateValue(undefined, shouldValidate, true));
        }
        return this._handleUpdate(skipRender, shouldValidate);
    }
    setTouched(value, shouldValidate, skipRender) {
        if (Array.isArray(value)) {
            value.forEach((val, key) => this.get(key)?.setTouched(val, false, true));
        }
        else {
            this.forEach(child => child.setTouched(!!value, false, true));
        }
        return this._handleUpdate(skipRender, shouldValidate);
    }
    setError(value, skipRender) {
        if (Array.isArray(value)) {
            value.forEach((val, key) => this.get(key)?.setError(val, true));
        }
        else {
            this.forEach(child => child.setError(!!value, true));
        }
        return this._handleUpdate(skipRender);
    }
}
