import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { Button, ButtonToolbar } from 'Components/Atoms/Button';
import { Icon } from 'Components/Atoms/Icon';
import { translate } from 'Services/Translator';
import { formatMoney } from 'Services/NumberHelper';
import { formatDateShort, formatYearMonthInverse } from 'Services/DateHelper';
import moment from 'moment';
import generateRoute from 'Services/Router';
const model = Table.factory()(() => ({
    tableId: 'AutomaticInvoicing',
    Actions: ({ showAddModal }) => (_jsx("div", { children: _jsxs(Button, { onClick: () => showAddModal(), "$size": 'lg', title: translate('btn.add'), children: [_jsx(Icon, { icon: 'plus' }), _jsx("span", { style: { verticalAlign: 'sub' }, children: translate('btn.add') })] }) })),
    BulkActions: ({ openAutomaticInvoicesForm, state }) => {
        const periodIds = Object.keys(state.selectedRowIds);
        const count = periodIds.length;
        return (_jsx(ButtonToolbar, { children: _jsxs(Button, { icon: 'download', variant: 'outline', disabled: count === 0, onClick: () => openAutomaticInvoicesForm(periodIds), children: [translate('autoInvoicing.actions.downloadInvoices'), " ", count ? `(${count})` : ''] }) }));
    },
    useRowSelect: {},
    useSortBy: {},
    usePagination: {},
    initialState: {
        pageSize: 25,
        sortBy: [
            { id: 'period', desc: true }
        ]
    },
    columns: {
        period: {
            Header: translate('automaticInvoices'),
            accessor: (({ period }) => {
                return formatYearMonthInverse(moment(period, "YYYYMM"));
            }),
            useSortBy: {
                sortDescFirst: true
            },
            Cell: ({ value, row }) => _jsx("a", { href: generateRoute('list_debitor_invoices', { rentPeriod: row.id }), children: value }),
        },
        year: {
            accessor: (({ period }) => period.substring(0, 4)),
            useFilters: {},
            hide: true,
            canFilter: true
        },
        month: {
            accessor: (({ period }) => period.substring(4, 6)),
            useFilters: {},
            hide: true,
            canFilter: true
        },
        net: {
            Header: translate('automaticInvoices.net'),
            accessor: (({ net }) => formatMoney(net)),
            useSortBy: {},
        },
        gross: {
            Header: translate('automaticInvoices.gross'),
            accessor: (({ gross }) => formatMoney(gross)),
            useSortBy: {},
        },
        nbInvoices: {
            Header: translate('automaticInvoices.createdInvoices'),
            accessor: 'nbInvoices',
            useSortBy: {},
            Cell: ({ value, row }) => _jsx("a", { href: generateRoute('list_debitor_invoices', { rentPeriod: row.original.period }), children: value }),
        },
        lastExecutedAt: {
            Header: translate('automaticInvoices.lastExecutedAt'),
            accessor: (({ lastExecutedAt }) => formatDateShort(moment(lastExecutedAt))),
            useSortBy: {
                sortDescFirst: true
            },
        },
    },
}));
export default model;
