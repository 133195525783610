import generateRoute from "Services/Router";
import client from "Utils/client";
import axios from "axios";
export const listBankFiles = (params) => {
    const route = generateRoute('api.creditorInvoiceBankFiles.list');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export const getDropdownDataForApproval = (creditorId) => {
    const route = generateRoute('api.creditorInvoices.dropdownDataForApproval', {
        creditorInvoice: creditorId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const downloadBankFile = (bankFileId) => {
    const route = generateRoute('api.creditorInvoiceBankFiles.download', {
        bankFile: bankFileId
    });
    return client.get(route, {
        responseType: 'blob'
    });
};
export const listCreditorInvoices = async (params) => {
    const route = generateRoute('api.creditorInvoice.list');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export const deleteCreditorInvoice = (id) => {
    const route = generateRoute('api.creditorInvoices.delete', {
        creditorInvoice: id
    });
    return client.delete(route);
};
export const exportAttachments = (data) => {
    const route = generateRoute('api.creditor-invoices.export');
    return client.post(route, data);
};
export const dropCreditorFromBankFile = (bankFileId, creditorInvoiceId) => {
    const route = generateRoute('api.creditorInvoices.dropCreditorFromBankFile', {
        bankFile: bankFileId,
        creditorInvoice: creditorInvoiceId
    });
    return client.delete(route);
};
export const bulkBankFiles = (creditorInvoiceIds, ignoreInvalidInvoices = false) => {
    const route = generateRoute('api.creditorInvoices.bulkBankFiles', {
        ignoreInvalidInvoices: ignoreInvalidInvoices ? 1 : 0
    });
    return client.post(route, { creditorInvoiceIds });
};
export const bulkManualPayments = (request) => {
    const route = generateRoute('api.creditorInvoices.bulkPayments');
    return client.post(route, request);
};
export const markBankFilesAsPaid = (bankFileIds) => {
    const route = generateRoute('api.creditorInvoices.payBankFiles');
    return client.post(route, { bankFileIds });
};
export const downloadNewBankFiles = () => {
    const route = generateRoute('api.creditorInvoiceBankFiles.downloadNew');
    return client.get(route, {
        responseType: 'blob'
    });
};
export const downloadSelectedBankFiles = (data) => {
    const route = generateRoute('api.creditorInvoiceBankFiles.downloadSelected');
    return client.post(route, data, {
        responseType: 'blob'
    });
};
export const getProcessingData = (id) => {
    const route = generateRoute('api.creditorInvoices.processingData', {
        creditorInvoice: id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const updateProcessingStepData = (id, data) => {
    const route = generateRoute('api.creditorInvoices.processingStep', {
        creditorInvoice: id
    });
    return client.put(route, data).then(res => res.data);
};
export const getInvoicePositionsData = (id) => {
    const route = generateRoute('api.creditorInvoices.invoicePositionsData', {
        creditorInvoice: id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const updateInvoicePositionsStepData = (id, data) => {
    const route = generateRoute('api.creditorInvoices.invoicePositionsStep', {
        creditorInvoice: id
    });
    return client.put(route, data).then(res => res.data);
};
export const getApprovalData = (id) => {
    const route = generateRoute('api.creditorInvoices.approvalData', {
        creditorInvoice: id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const updateApprovalStepData = async (id, data) => {
    const route = generateRoute('api.creditorInvoices.approvalStep', {
        creditorInvoice: id
    });
    await client.put(route, data);
};
export const createPayment = (id, data) => {
    const route = generateRoute('api.creditorInvoices.createPayment', {
        creditorInvoice: id
    });
    return client.post(route, data);
};
export const loadPaymentData = (id) => {
    const route = generateRoute('api.creditorInvoices.manualPaymentData', {
        creditorInvoice: id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const cancelQrReader = (id) => {
    const route = generateRoute('api.creditorInvoices.cancelQrReader', {
        creditorInvoice: id
    });
    return client.get(route);
};
export const getCreditorInvoiceDetails = (id) => {
    const route = generateRoute('api.creditorInvoices.details', {
        creditorInvoice: id
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const deleteCreditorPayment = (creditorInvoiceId, paymentId) => {
    const route = generateRoute('api.creditorInvoices.deletePayment', {
        creditorInvoice: creditorInvoiceId,
        payment: paymentId
    });
    return client.delete(route);
};
export const updateCreditorObjectData = (creditorInvoiceId, data) => {
    const route = generateRoute('api.creditorInvoices.updateObjectData', {
        creditorInvoice: creditorInvoiceId,
    });
    return client.post(route, data);
};
export const createManualPayment = (id, data) => {
    const route = generateRoute('api.creditorInvoices.createManualPayment', {
        creditorInvoice: id
    });
    return client.post(route, data);
};
export const createBlankCreditor = () => {
    const route = generateRoute('api.creditorInvoices.createBlank');
    return client.post(route);
};
const singleFileCreditorUpload = (data) => {
    const route = generateRoute('api.creditorInvoices.createUsingUploads');
    const formData = new FormData();
    formData.append('files[]', data.file, data.fileName);
    formData.append('model', new Blob([
        JSON.stringify({ propertyIds: [data.propertyId] })
    ], {
        type: 'application/json'
    }));
    return axios.post(route, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};
export const createCreditorsUsingUploads = (rows) => {
    const promises = [];
    rows.forEach((row, index) => {
        promises.push(singleFileCreditorUpload(row));
    });
    return Promise.allSettled(promises);
};
export const resetCreditorAccounting = (invoiceId) => {
    const route = generateRoute('api.creditorInvoices.resetAccounting', {
        creditorInvoice: invoiceId,
    });
    return client.put(route);
};
export const bulkDeleteCreditorInvoices = (invoiceIds) => {
    const route = generateRoute('api.creditorInvoices.bulkDelete');
    return client.delete(route, {
        data: { creditorInvoiceIds: invoiceIds }
    });
};
