import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { translate } from 'Services/Translator';
import moment from 'moment/moment';
import { Div, FlexRow, Input, Td, Text, Tr } from 'Components/Atoms';
import { formatAmount } from 'Services/NumberHelper';
import { DataTable } from '@f2w/data-table';
import { ContractProgress } from 'Pages/AncillaryExpense/ContractProgress';
const BaseTable = DataTable.factory()(() => ({
    manualControl: false,
    showEmpty: true,
    initialState: {
        pageSize: 50
    },
    useRowState: {
        initialRowStateAccessor: (...args) => {
            return {};
        },
    },
    useGlobalFilter: {},
    columns: {
        rentalObject: {
            Header: translate('rentalObject'),
            accessor: d => d.rentalObject?.name ?? translate('consumptionTable.commonArea'),
            Cell: ({ value }) => _jsx(Text, { nowrap: true, value: value }),
        },
        rentalObjectType: {
            Header: translate('baseData.rentalObject.type'),
            accessor: v => v.rentalObjectType
                ? translate(`rentalObjectType.${v.rentalObjectType}`)
                : undefined,
            Cell: ({ value }) => _jsx(_Fragment, { children: value }),
        },
        tenants: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.tenants'),
            accessor: d => {
                if (d.tenants.length === 0) {
                    return translate('rentalObject.state.vacancy');
                }
                return d.tenants?.map(v => v.name).join(', ');
            },
        },
        grp: {
            Header: '',
            minWidth: 80,
            maxWidth: 100,
            accessor: a => a.activeContractStartDate,
            Cell: ({ row: { original: { activeContractStartDate, activeContractEndDate, contract } }, overviewData }) => {
                if (!activeContractStartDate) {
                    return translate('notAvailable');
                }
                return _jsx(ContractProgress, { calculationFrom: overviewData.periodFrom, calculationTo: overviewData.periodTo, isVacancy: contract === null, contractFrom: activeContractStartDate, contractTo: activeContractEndDate });
            }
        },
        activeContractStartDate: {
            Header: translate('contract.details.period'),
            Cell: ({ row: { original: { activeContractStartDate, activeContractEndDate } } }) => {
                if (!activeContractStartDate) {
                    return translate('notAvailable');
                }
                return moment(activeContractStartDate).format('DD.MM.YYYY') + ' – '
                    + (activeContractEndDate ? moment(activeContractEndDate).format('DD.MM.YYYY') : '∞');
            }
        },
        meterStart: {
            Header: translate('meterReading.previousValue'),
            Cell: ({ value, updateApi, editMode, row: { id, original: { meterEnd: initialMeterEnd } } }) => {
                const { meterStart = value, meterEnd = initialMeterEnd } = updateApi.getData(id);
                return (_jsx(Div, { textNoWrap: true, w: 80, children: editMode
                        ? (_jsx(Input, { type: 'number', step: 0.01, "$size": 'sm', value: meterStart ?? 0, onChange: (value) => {
                                const meterDelta = (Number(meterEnd) ?? 0) - (Number(value) ?? 0);
                                updateApi.update(id, {
                                    meterStart: value,
                                    meterDelta
                                });
                            } }))
                        : value !== undefined && value !== null ? formatAmount(value) : translate('notAvailable') }));
            }
        },
        meterEnd: {
            Header: translate('meterReading.currentValue'),
            Cell: ({ value, updateApi, editMode, row: { id, original: { meterStart: initialMeterStart } } }) => {
                const { meterStart = initialMeterStart, meterEnd = value, } = updateApi.getData(id);
                return (_jsx(Div, { textNoWrap: true, w: 80, children: editMode
                        ? (_jsx(Input, { type: 'number', step: 0.01, "$size": 'sm', value: meterEnd ?? 0, onChange: (value) => {
                                const meterDelta = (Number(value) ?? 0) - (Number(meterStart) ?? 0);
                                updateApi.update(id, {
                                    meterEnd: value,
                                    meterDelta
                                });
                            } }))
                        : value !== undefined && value !== null ? formatAmount(value) : translate('notAvailable') }));
            }
        },
        meterDelta: {
            Header: translate('meterReading.value'),
            Cell: ({ value, updateApi, editMode, row: { id, original: { meterStart: initialMeterStart } } }) => {
                const { meterDelta = value, } = updateApi.getData(id);
                return (_jsx(Div, { textNoWrap: true, w: 80, children: editMode
                        ? (_jsx(Input, { type: 'number', step: 0.01, "$size": 'sm', value: meterDelta ?? 0, onChange: (value) => {
                                const meterEnd = (Number(initialMeterStart) ?? 0) + (Number(value) ?? 0);
                                updateApi.update(id, {
                                    meterDelta: value,
                                    meterEnd
                                });
                            } }))
                        : value !== undefined && value !== null ? formatAmount(value) : translate('notAvailable') }));
            }
        },
        totalSharePercent: {
            Header: translate('ancillaryExpenseCalculation.totalSharePercent'),
            Cell: ({ value }) => value !== undefined && value !== null ? `${formatAmount(value, '0,0.0')}%` : translate('notAvailable'),
        },
        amount: {
            Header: translate('amount'),
            Cell: ({ value }) => value !== undefined && value !== null
                ? _jsx(FlexRow, { justifyFlexEnd: true, children: formatAmount(value) })
                : translate('notAvailable'),
        },
    },
    Footer: ({ data }) => {
        const meterDeltaSum = data
            .map(row => row.meterDelta ?? 0.00)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0.00);
        const totalSharePercentSum = data
            .map(row => row.totalSharePercent ?? 0.00)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0.00);
        const amountSum = data
            .map(row => row.amount ?? 0.00)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0.00);
        return (_jsxs(Tr, { children: [_jsx(Td, { colSpan: 8 }), _jsx(Td, { children: _jsx("strong", { children: formatAmount(meterDeltaSum) }) }), _jsx(Td, { children: _jsxs("strong", { children: [formatAmount(totalSharePercentSum, '0,0.0'), "%"] }) }), _jsx(Td, { align: 'right', children: _jsx("strong", { children: formatAmount(amountSum) }) })] }));
    }
}));
export const CalculationDistributionConsumptionTable = Object.assign(BaseTable, {
    use: ({ data, loadData, overviewData, ancillaryAccountId, calculationId, saveMeters }) => {
        const [editMode, setEditMode] = React.useState(false);
        const onSave = React.useCallback(async ({ updateApi }) => {
            const meterData = updateApi
                .dirtyIds
                .map((id) => {
                const { meterStart, meterEnd } = updateApi.getData(id);
                return ({
                    id,
                    meterStart: Number(meterStart),
                    meterEnd: Number(meterEnd),
                });
            });
            saveMeters(calculationId, {
                accountId: ancillaryAccountId,
                distributionPositions: meterData,
            }).then(async () => {
                await loadData();
                await updateApi.resetAll();
                setEditMode(false);
            });
        }, [data]);
        const table = BaseTable.useCreateTable({
            data: data,
            overviewData,
            refresh: () => loadData(),
            editMode,
            setEditMode,
            onSave
        });
        return table;
    },
});
