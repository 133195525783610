import { AbstractFormType, SelectType } from "@f2w/form";
import { translate } from "Services/App";
import { useEffect, useMemo } from "react";
import { DateRangeType } from "Pages/Rent/forms/DateRangeType";
export class ExportCreditorDocumentsType extends AbstractFormType {
    _useField(props) {
        super._useField(props);
        const { portfolio } = this.options;
        const { mandateId, propertyId } = this.field.value;
        useEffect(() => {
            if (!mandateId) {
                this.children.propertyId.field.setValue(undefined);
            }
            else if (portfolio.getPropertyById(propertyId)?.mandate.id !== mandateId) {
                this.children.propertyId.field.setValue(undefined);
            }
        }, [mandateId]);
    }
    buildFields() {
        return {
            mandateId: new SelectType({
                label: translate('property.mandate'),
                required: false,
                searchable: true,
                choiceOptions: () => useMemo(() => {
                    return this
                        .options
                        .portfolio
                        .getMandates()
                        .map(mandate => ({
                        label: mandate.name,
                        value: mandate.id
                    }));
                }, [])
            }),
            propertyId: new SelectType({
                label: translate('property'),
                required: false,
                searchable: true,
                choiceOptions: () => useMemo(() => {
                    if (!this.field.value.mandateId) {
                        return [];
                    }
                    return this
                        .options
                        .portfolio
                        .getPropertiesByMandateId(this.field.value.mandateId)
                        .map(property => ({
                        label: property.name,
                        value: property.id
                    }));
                }, [this.field.value.mandateId])
            }),
            dateRange: new DateRangeType({
                label: false,
                required: false,
                type: 'day',
                fromDateLabel: translate('creditor.invoiceDate.from'),
                toDateLabel: translate('creditor.invoiceDate.to'),
                defaultValue: () => ({
                    start: undefined,
                    end: undefined
                })
            })
        };
    }
}
