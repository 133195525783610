import { DataTable } from "@f2w/data-table";
import { translate } from "Services/Translator";
const BaseTable = DataTable.factory()(() => ({
    manualControl: false,
    columns: {
        $rowSelect: {},
        name: {
            Header: translate('ancillaryExpenses.distributionKeys')
        },
        type: {
            Header: translate('ancillaryExpenseDistributionKey.label.keyType'),
            Cell: ({ value }) => translate(`ancillaryExpenseDistributionKey.keyType.${value}`)
        }
    }
}));
export const AncillaryExpensePresetDistributionKeysDataTable = Object.assign(BaseTable, {
    use: ({ data }) => {
        return AncillaryExpensePresetDistributionKeysDataTable.useCreateTable({
            data
        });
    }
});
