import { BaseValue, SimpleState } from '../../base';
import { TimeoutHandler, sleep, ValidationError } from '@f2w/utils';
export class SimpleValue extends BaseValue {
    _validationError;
    _state;
    timeout = new TimeoutHandler();
    constructor(type, props) {
        super(type, props);
        this._state = new SimpleState({
            getSelf: () => this,
            onChange: (event) => {
                switch (event.propName) {
                    case 'value':
                        this.options.onChange?.(this, { initial: false });
                        break;
                    case 'initialValue':
                        this.options.onChange?.(this, { initial: true });
                        break;
                }
            }
        });
    }
    get isDisabled() {
        return super.isDisabled;
    }
    get isVisible() {
        return super.isVisible;
    }
    get isActive() {
        return super.isActive;
    }
    use(props) {
        return this;
    }
    destroy() {
        this.timeout.clear();
    }
    getResolvedValue(options) {
        return this.formattedValue;
    }
    toString() {
        return this.humanValue?.toString?.() ?? '';
    }
    clear(skipRender) {
        this._state.clear(skipRender);
    }
    reset(value, shouldValidate, skipRender) {
        this._state.reset({ newValue: value, shouldValidate, skipRender });
    }
    setValue(initialValue, shouldValidate, skipRender) {
        initialValue = this.specs.cast(initialValue);
        if (this._state.set('initialValue', initialValue)) {
            this._handleUpdate(skipRender, shouldValidate);
        }
    }
    updateValue(value, shouldValidate, skipRender) {
        value = this.specs.cast(value, { ignoreDefaults: true });
        if (this._state.set('value', value)) {
            this._handleUpdate(skipRender, shouldValidate);
        }
    }
    setTouched(value, shouldValidate, skipRender) {
        if (this._state.set('touched', value)) {
            this._handleUpdate(skipRender, shouldValidate);
        }
    }
    setError(value, skipRender) {
        if (this._state.set('error', value)) {
            this._handleUpdate(skipRender);
        }
    }
    async validate(force, skipRender) {
        if (!this.isActive) {
            this._state.updateParent();
            return undefined;
        }
        await sleep(20);
        return new Promise((resolve, reject) => {
            if (this.isValidated && !force) {
                resolve(this.error);
            }
            else {
                this._validationError = undefined;
                this._state.set('validating', true);
                this.specs.validate(this.value)
                    .then((value) => {
                    this._updateError(skipRender, resolve);
                })
                    .catch(error => {
                    if (ValidationError.isError(error)) {
                        this._validationError = true;
                        this._updateError(skipRender, resolve, error);
                    }
                    else {
                        this._validationError = undefined;
                        reject(error);
                    }
                });
            }
        });
    }
    _updateError(skipRender, resolve, error) {
        if (error) {
            this._state.update({
                validating: false,
                error: error?.message || '',
            });
            resolve(error?.message);
        }
        else {
            this._state.update({
                validating: false,
                error: '',
            });
            resolve();
        }
        this._handleUpdate(skipRender);
    }
}
