import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classnames from 'classnames';
import styled, { css } from 'styled-components';
import { UseStyled } from '@fw/theme';
import { Icon } from 'Atoms/Icon';
import { Modal, useModalCloseHandler } from 'Components/Modal';
import { Carousel } from './Carousel';
import { carouselSlot } from './components/CarouselSlots';
export const CarouselPreview = ({ show, onHide, onShow, dialogAs, ...props }) => {
    return _jsx(_Fragment, { children: _jsxs(Modal, { fullScreen: true, dialogAs: dialogAs, show: show, onHide: onHide, onShow: onShow, autoFocus: false, children: [_jsx(CloseModal, {}), _jsx(Carousel, { fullscreen: true, autoFocus: true, ...props, as: FullscreenTheme, tabIndex: -1 })] }) });
};
const FullscreenTheme = styled.div `
    ${carouselSlot.css('item')} {

    }
`;
const CloseModal = UseStyled.Fn((props) => {
    const handleClick = useModalCloseHandler();
    return _jsx("button", { type: "button", onClick: handleClick, className: classnames('close', props.className), "data-dismiss": "modal", "aria-label": 'Close', children: _jsx(Icon, { strokeWidth: 2, size: 40, icon: 'x' }) });
}, css `
        &&& {
          color: white;
          position: absolute;
          display: block;
          right: 20px;
          top: 20px;
          z-index: 200;
          pointer-events: auto;
        }
    `);
