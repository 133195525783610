import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { MaintenanceSeverity } from "Pages/Administration/Maintenance/enums";
import { FlexRow } from "Atoms/Layout";
import { Icon } from "Atoms/Icon";
import { Text } from "Atoms/Text";
import styled from "styled-components";
const TextBlock = styled(Text) `
    a {
        color: inherit;
        text-decoration: underline;
    }
`;
export const MaintenanceBanner = ({ message, severity }) => {
    const { icon, bgColor, textColor } = useMemo(() => {
        switch (severity) {
            case MaintenanceSeverity.INFO: return {
                icon: _jsx(Icon, { color: '#fafafa', icon: 'alert-circle', size: 32 }),
                bgColor: 'primary.$500',
                textColor: 10
            };
            case MaintenanceSeverity.WARNING: return {
                icon: _jsx(Icon, { color: '#2c313b', icon: 'alert-triangle', size: 32 }),
                bgColor: 'warning.$500',
                textColor: 900
            };
            case MaintenanceSeverity.ERROR: return {
                icon: _jsx(Icon, { color: '#fafafa', icon: 'alert-octagon', size: 32 }),
                bgColor: 'error.$500',
                textColor: 10
            };
        }
    }, [severity]);
    return (_jsxs(FlexRow, { p: 15, gap: 20, alignCenter: true, bg: bgColor, children: [icon, _jsx(TextBlock, { medium: 400, neutral: textColor, style: { wordBreak: 'break-all' }, dangerouslySetInnerHTML: { __html: message } })] }));
};
