import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { ContractDataTableModel } from 'Pages/Contract/components/ContractDataTableModel';
import { transChoice, translate } from 'Services/Translator';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { useModalDispatcherApi, useToastDispatcherApi } from 'Components/Dispatcher';
import { Badge, FlexRow, Icon } from 'Components/Atoms';
import { useEventCallback } from '@restart/hooks';
import { ContractRentModificationFilter } from 'Pages/Contract/enums/ContractRentModificationFilter';
import { ContractState } from "Enums/ContractState";
import { useObjectViewModalContext } from "Components/ObjectViewModal";
import { PersonModal } from "Pages/Person/components/PersonModal";
const Content = ({ personData, onResetPersonData }) => _jsxs(FlexRow, { gap: 5, alignCenter: true, children: [_jsxs("label", { style: { marginBottom: 0 }, children: [translate('contracts.filters'), ":"] }), personData && _jsxs(Badge, { children: [personData.name, " ", _jsx(Icon, { style: { cursor: "pointer" }, onClick: () => onResetPersonData(), icon: 'x' })] })] });
export const ContractDataTable = ({ filters, contractServices, ...rest }) => {
    const personModalApi = useRef();
    const [personData, setPersonData] = React.useState(filters?.person);
    const { activeView } = useObjectViewModalContext();
    const initialLoad = React.useRef(true);
    React.useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        if (!activeView) {
            contractDataTable.refresh();
        }
    }, [activeView]);
    const modalApi = useModalDispatcherApi();
    const { error, success } = useToastDispatcherApi();
    const onDeleteContract = React.useCallback((id, refreshTable) => {
        contractServices
            .deleteContract(id)
            .then(() => {
            success({
                timeout: 2,
                message: translate('flash.contract.deletion.success')
            });
            refreshTable();
        })
            .catch((e) => {
            if (e.response && e.response.data && e.response.data.message) {
                error({
                    timeout: 5,
                    message: e.response.data.message,
                });
            }
            else {
                error({
                    timeout: 5,
                    message: translate('flash.contract.deletion.generalError')
                });
            }
        });
    }, []);
    const onDeleteConfirmation = useEventCallback((id, refreshTable) => {
        modalApi.show({
            asyncLoad: async () => {
                const { nbInvoices } = await contractServices.getContractStats(id);
                if (nbInvoices > 0) {
                    return {
                        severity: 4,
                        message: translate('modalDispatcher.deleteContract.message'),
                        content: transChoice('contract.actions.deleteWithInvoices.areYouSure', nbInvoices),
                        help: translate('contract.actions.delete.areYouSure.help'),
                        onConfirm: () => onDeleteContract(id, refreshTable),
                    };
                }
                else {
                    return {
                        severity: 2,
                        message: translate('modalDispatcher.deleteContract.message'),
                        content: translate('contract.actions.delete.areYouSure'),
                        help: translate('contract.actions.delete.areYouSure.help'),
                        onConfirm: () => onDeleteContract(id, refreshTable),
                    };
                }
            },
        });
    });
    const contractDataTableModel = React.useMemo(() => ContractDataTableModel.create({
        manualControl: true,
        initialState: {
            filters: [
                {
                    id: "upcomingRentModification",
                    value: ContractRentModificationFilter.ALL
                },
                {
                    id: "showVacancy",
                    value: '1',
                },
                {
                    id: "state",
                    value: [
                        ContractState.ACTIVE,
                        ContractState.UPCOMING,
                        ContractState.NOTICED,
                        ContractState.LIMITED
                    ],
                }
            ]
        },
        getData: async ({ request }) => {
            return contractServices
                .getContractList(request);
        }
    }), [personData]);
    const contractDataTable = contractDataTableModel.createRemote({
        ...rest,
        deleteContract: onDeleteConfirmation,
        openPersonInfoModal: (personId) => personModalApi.current?.openModal(personId),
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { title: translate("contract.table.title"), instance: contractDataTable, inlineFilters: true, content: personData
                    ? _jsx(Content, { personData: personData, onResetPersonData: () => {
                            contractDataTable.setFilter('personId', null);
                            setPersonData(null);
                        } })
                    : null }), _jsx(PersonModal, { api: personModalApi, onClose: () => contractDataTable.refresh() })] }));
};
