import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Badge, Button, ButtonToolbar, SafeAnchor } from 'Components/Atoms';
import { useModalDispatcherConfirmWithChangingProps, useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { DataTable } from '@f2w/data-table';
import generateRoute from 'Services/Router';
import { StatusFilter } from '../components/CalculationPage/components/StatusFilter';
import { CalculationState } from '../enums';
import { CalculationServices } from '../services';
import moment from 'moment';
export const AncillaryExpenseCalculationsTable = DataTable.factory()(() => {
    const getStatusBadge = (status) => {
        switch (status) {
            case CalculationState.DRAFT:
                return _jsx(Badge, { "$color": "secondary", children: translate('ancillaryExpense.calculation.draft') });
            case CalculationState.BILLED:
                return _jsx(Badge, { "$color": "success", children: translate('ancillaryExpense.calculation.billed') });
        }
    };
    return {
        tableId: 'Calculations',
        RowActions: ({ row: { original: { id, isDeletable, status, numInvoices } }, refresh, onViewCalculationClicked, onEditCalculationClicked, onDeleteClicked, }) => {
            const { Link } = DataTable.Actions;
            return (_jsx(DataTable.Actions, { id: id, quickActions: [
                    status === CalculationState.BILLED && {
                        icon: "eye",
                        title: translate('ancillaryExpenseCalculation.actions.view'),
                        onClick: () => {
                            onViewCalculationClicked(id);
                        }
                    },
                    status === CalculationState.DRAFT && {
                        icon: "edit",
                        title: translate('btn.edit'),
                        onClick: () => {
                            onEditCalculationClicked(id);
                        }
                    },
                ], children: isDeletable && (_jsx(Link, { icon: 'trash', label: translate('btn.delete'), onClick: () => onDeleteClicked(id, numInvoices, refresh) })) }));
        },
        Actions: ({ onCreateCalculation }) => (_jsx(ButtonToolbar, { children: _jsx(Button, { icon: 'plus', label: translate('new'), title: translate('ancillaryExpenseCalculation.actions.new'), onClick: () => onCreateCalculation() }) })),
        manualControl: true,
        useGlobalFilter: {},
        usePagination: {},
        useSortBy: {
            initialState: {
                sortBy: [
                    {
                        id: 'property',
                        desc: false
                    },
                    {
                        id: 'period',
                        desc: true
                    }
                ]
            }
        },
        columns: {
            $actions: {
                sticky: 'left',
            },
            property: {
                Header: translate('ancillaryExpenseCalculation.table.property'),
                useSortBy: {},
                Cell: ({ row: { original: { property } }, onPropertyClick }) => (_jsx("div", { children: _jsx(SafeAnchor, { onClick: (event => {
                            event.preventDefault();
                            onPropertyClick(property.id);
                        }), children: property.name }) })),
            },
            period: {
                Header: translate('ancillaryExpenseCalculation.table.period'),
                useSortBy: {},
                Cell: ({ value }) => _jsxs("div", { children: [moment(value.from).format('DD.MM.YYYY'), " \u2013 ", moment(value.to).format('DD.MM.YYYY')] }),
            },
            status: {
                Header: translate('ancillaryExpenseCalculation.table.status'),
                useSortBy: {},
                Cell: ({ value }) => getStatusBadge(value),
                useFilters: {
                    Filter: StatusFilter,
                },
            },
        },
    };
});
export const useAncillaryExpenseCalculationsTable = ({ onPropertyClick, onCreateCalculation, calculationServices = CalculationServices, }) => {
    const { success, error } = useToastDispatcherApi();
    const resolveSuccess = React.useCallback((message, refresh) => () => {
        success({ timeout: 2, message });
        refresh();
    }, []);
    const resolveError = React.useCallback((message) => () => {
        error({ timeout: 5, message });
    }, []);
    const onDeleteAncillaryExpenseCalculation = React.useCallback((calculationId, refresh) => {
        calculationServices.deleteCalculation(calculationId)
            .then(resolveSuccess(translate('ancillaryExpenseCalculation.delete.success'), refresh))
            .catch((error) => {
            if ([400, 422].includes(error.response.status)) {
                resolveError(error.response.data.message)();
            }
            else {
                resolveError(translate('ancillaryExpenseCalculation.delete.error'))();
            }
        });
    }, []);
    const onDeleteConfirmation = useModalDispatcherConfirmWithChangingProps({
        icon: 'alert-triangle',
        onConfirm: onDeleteAncillaryExpenseCalculation,
        message: translate('ancillaryExpenseCalculation.message.areYouSure'),
        help: translate('ancillaryExpenseCalculation.message.deleteConfirmation'),
        btnConfirm: translate('btn.confirm'),
    });
    const onDelete = React.useCallback((calculationId, numInvoices, refresh) => {
        if (numInvoices > 0) {
            onDeleteConfirmation([calculationId, refresh], {
                help: translate('ancillaryExpenseCalculation.message.deleteConfirmationInvoicesPresent', {
                    numInvoicesWithPayments: numInvoices,
                }),
            });
        }
        else {
            onDeleteConfirmation([calculationId, refresh]);
        }
    }, []);
    const onViewCalculationClicked = React.useCallback((calculationId) => {
        window.location.href = generateRoute('ancillaryExpenses.calculation.details', { calculation: calculationId });
    }, []);
    const onEditCalculationClicked = React.useCallback((calculationId) => {
        window.location.href = generateRoute('ancillaryExpenses.calculation.wizard', { calculation: calculationId });
    }, []);
    const instance = AncillaryExpenseCalculationsTable.useTable({
        onPropertyClick,
        onDeleteClicked: onDelete,
        onCreateCalculation,
        onViewCalculationClicked: onViewCalculationClicked,
        onEditCalculationClicked: onEditCalculationClicked,
    }, () => ({
        getData: ({ request }) => calculationServices.listCalculations(request)
    }));
    return {
        instance,
    };
};
