export var NotificationState;
(function (NotificationState) {
    NotificationState["IN_PROGRESS"] = "IN_PROGRESS";
    NotificationState["COMPLETED"] = "COMPLETED";
    NotificationState["FAILED"] = "FAILED";
})(NotificationState || (NotificationState = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["REMINDER_PROCESS"] = "debitorInvoice_reminder";
})(NotificationType || (NotificationType = {}));
export const IconMap = {
    [NotificationType.REMINDER_PROCESS]: 'user'
};
