import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { DeepChat } from 'deep-chat-react';
import styled, { css } from 'styled-components';
import { translate } from 'Services/App';
import { Button } from 'Atoms/Button';
import { Icon } from 'Atoms/Icon';
import { Text } from 'Atoms/Text';
import { FlexCol } from 'Atoms/Layout';
import { useChatApi, useChatModal } from './useChat';
export const ChatBot = (props) => {
    const api = useChatModal(props);
    return _jsxs(_Fragment, { children: [_jsxs(Button, { onClick: api.toggle, style: { alignSelf: 'center', marginLeft: 15, marginRight: 15 }, children: [_jsx(Icon, { icon: 'bot', size: 20 }), _jsx("span", { children: "Walter" })] }), _jsx(ChatPositioner, { children: api.show && _jsxs(ChatWindowRoot, { children: [_jsxs("header", { children: [_jsxs("div", { "data-slot": 'title', children: [_jsx(Icon, { icon: 'bot' }), _jsxs(FlexCol, { children: [_jsx(Text, { children: translate('chat.header.title') }), _jsx(Text, { small: true, children: translate('chat.header.subtitle') })] })] }), _jsx(Button.Toolbar, { "data-slot": 'actions', color: 'light', variant: 'link', children: _jsx(Button, { icon: 'x', onClick: api.hideChat }) })] }), _jsx(ChatWindow, { onHide: api.hideChat, ...props })] }) })] });
};
ChatBot.defaultProps = {
    placeholder: translate('chat.placeholder'),
    introMessage: translate('chat.introMessage'),
};
export const ChatWindow = (props) => {
    const [chatProps, api] = useChatApi({ ...props });
    useEffect(() => {
        setTimeout(() => api.chat.focusInput(), 500);
    }, []);
    return _jsx(DeepChat, { remarkable: { html: true }, introMessage: { text: props.introMessage }, ...chatProps });
};
const ChatWindowRoot = styled.div(({ theme }) => {
    return css `
        --fw_chat-width: 540px;
        --fw_chat-borderRadius: 6px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid var(--fw-color-neutral-100);
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
        width: var(--fw_chat-width);
        border-radius: var(--fw_chat-borderRadius);

        > header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            background: var(--fw-color-primary-500);
            color: var(--fw-color-white);
            padding: 12px 16px;

            [data-slot] {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            [data-slot=title] {
                ${theme.typography.body.fontSizeStyles};

                .fw-icon {
                    font-size: 22px;
                }
            }

            [data-slot=actions] {
                .fw-btn {
                    --btn-min-height: 20px;
                    font-size: 20px;
                    padding: 0;
                    color: var(--fw-color-white);
                }
            }
        }
    `;
});
const ChatPositioner = styled.div(({ theme, offset = 25 }) => {
    let position = {
        top: 75,
        right: offset,
    };
    return css `
        position: fixed;
        white-space: nowrap;
        z-index: 99999;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        ${position};
        gap: 20px;
    `;
});
