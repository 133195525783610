import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FlexRow, Text } from "Components/Atoms";
import { formatMoney } from "Services/NumberHelper";
import { DataTable } from "@f2w/data-table";
import { CalculationDistributionFixedTable } from "../../../table";
import { translate } from "Services/Translator";
export const FixedDistributionTable = ({ overviewData, loadData, distributionAccount }) => {
    const table = CalculationDistributionFixedTable.use({
        loadData,
        distributionAccount,
        overviewData,
        data: distributionAccount.positions ?? []
    });
    return (_jsxs(_Fragment, { children: [_jsx(FlexRow, { gap: 20, alignCenter: true, pt: 20, h: 50, children: _jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsxs(Text, { style: { margin: 0 }, children: [translate('ancillaryExpenseAccount.settlement.allocationFormula'), ": ", distributionAccount.propertyDistributionKeyName] }), _jsx(Text, { primary: true, children: formatMoney(distributionAccount.totalCost) })] }) }), _jsx(DataTable.SimpleTable, { filter: 'inline', instance: table })] }));
};
