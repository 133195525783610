import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useMemo, useRef } from 'react';
import { useEventCallback } from '@restart/hooks';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import { isBool } from '@f2w/utils';
import styled, { css } from 'styled-components';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
import { Icon } from 'Atoms/Icon';
const BaseCheckbox = forwardRef(({ onChange: _onChange, className, style, radio, indeterminate, onChangeNative, $size = "lg", ...props }, ref) => {
    const inputRef = useRef(null);
    const mergedRef = useMergedRefs(inputRef, ref);
    const onChange = useEventCallback((e) => {
        if (onChangeNative) {
            onChangeNative(e);
        }
        const target = e?.target ?? e?.currentTarget;
        if (_onChange && target) {
            if (target.type === 'radio' || target.type === 'checkbox') {
                _onChange(target.checked, e);
            }
            else {
                _onChange(target.value, e);
            }
        }
    });
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);
    return (_jsx(CheckboxTheme.Input, { ...{
            $size,
            className,
            style,
            onChange,
            ...props,
            type: radio ? 'radio' : 'checkbox',
            ref: mergedRef,
        } }));
});
export const Checkbox = forwardRef(({ variant, $variant = variant, $size = 'lg', className, style, wrapperProps, label, checked: _checked, children = label, help, ...props }, ref) => {
    const checked = useMemo(() => (_checked === undefined && isBool(props.value)) ? props.value : _checked, [_checked, props.value]);
    const { message, ...helpProps } = (help?.message ? help : help ? { message: help } : {});
    return (_jsxs(CheckboxTheme.Root, { role: props.radio ? 'radio' : 'checkbox', "aria-disabled": props?.disabled || undefined, "aria-readonly": props?.readOnly || undefined, ...{
            ...wrapperProps,
            $size,
            $variant,
            className,
            style,
        }, children: [_jsx(BaseCheckbox, { ...props, ref: ref, checked: checked }), children && _jsx("span", { children: children }), message && (_jsx(OverlayTrigger, { placement: 'top', flip: true, ...helpProps, overlay: (_jsx(Tooltip, { id: `${props.id}--help`, children: message })), children: _jsx(Icon, { className: 'fw-help-icon', "data-form-help": true, icon: 'help-circle' }) }))] }));
});
export var CheckboxTheme;
(function (CheckboxTheme) {
    CheckboxTheme.Input = styled.input(({ theme: { rem, palette }, $size = "lg", disabled, readOnly, }) => {
        const $color = (readOnly || disabled) ? "neutral" : "primary";
        return css `
      --input-height: ${rem(20)};
      //height: 28px;
        user-select: none;
        font: inherit;
        min-width: var(--input-height);
        min-height: var(--input-height);
        line-height: var(--input-height);

      &[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: var(--form-background);
        /* Not removed via appearance */
        margin: 0;
        z-index: 1;

        color: ${palette.dark.$200};
        border-radius: ${rem(4)};
        border: ${rem(2)} solid currentColor;

        display: flex;
        justify-items: stretch;

        &:not(:is([readonly],[disabled])) {
            &:hover {
              border-color: transparent;
              box-shadow: 0 0 0 4px ${palette[$color].$100};
            }

            &:focus-within {
              outline: none;
              border-color: ${palette[$color].$500};
            }
        }

        &:checked, &:indeterminate {
          background: ${palette[$color].$500};
          border-color: ${palette[$color].$500};
          padding: ${rem(2)};
        }

        &:checked::before {
          flex: 1;
          content: "";
          background: url("data:image/svg+xml,${checkIcon}") center center no-repeat;
          background-size: contain;
        }

        &:indeterminate::before {
          flex: 1;
          content: "";
          background: url("data:image/svg+xml,${indeterminateIcon}") center center no-repeat;
          background-size: contain;
        }
      }
    `;
    });
    CheckboxTheme.Root = styled.label `
      --fw-input__icon-color: var(--fw-color-neutral-400);
      --fw-input__icon-shadow-color: #fff;
      display: flex;
      align-items: center;
      gap: 16px;
      margin: 0;
      padding: 0;

      > .fw-help-icon {
        pointer-events: all;
        cursor: pointer;
      }

      &:not(:is([aria-disabled],[aria-readonly])) {
        cursor: pointer;
      }

      &:is([aria-disabled],[aria-readonly]) {
        pointer-events: none;
      }

      &[aria-disabled] {
        > span {
          color: var(--fw-color-neutral-300);
        }
        ${CheckboxTheme.Input} {
          opacity: .6;
        }
      }
    `;
})(CheckboxTheme || (CheckboxTheme = {}));
const checkIcon = encodeURIComponent(`
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6663 1.5L4.24967 7.91667L1.33301 5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`);
const indeterminateIcon = encodeURIComponent(`
  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.91699 1H10.0837" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`);
