import { jsx as _jsx } from "react/jsx-runtime";
import { OrFunction } from '@f2w/utils';
import { FormControl } from 'Atoms/Input';
import classnames from 'classnames';
import { translate } from 'Services/Translator';
import { InputField } from '../../theme';
import { BaseSimpleType, NumberValueType, SimpleValue, StringValueType, } from '../../core';
export class BaseSelectType extends BaseSimpleType {
    _createValue(props) {
        return new SimpleValue(this, props);
    }
    _renderForm(valueType, props) {
        return _jsx(InputField, { ...props, Widget: p => this._renderWidget(p) });
    }
    _renderWidget({ field: valueType, ...props }) {
        const controlProps = {
            key: `${valueType.id}--simple-widget`,
            confirm: valueType.options.confirm,
            alignMenu: valueType.options.align,
            menuPlacement: valueType.options?.placement || 'auto',
            container: true,
            ...props,
            className: classnames('fw-form_control', 'fw-form_widget', props?.['className']),
            isMulti: valueType.options.multiple,
            placeholder: valueType.options.placeholder,
            isClearable: valueType.options.clearable == null ? valueType.specs.isNullable : valueType.options.clearable,
            isSearchable: valueType.options.searchable,
            isDisabled: valueType.options.disabled,
            value: valueType.value ?? null,
            defaultValue: valueType.specs.isNullable ? null : (valueType.initialValue ?? null),
            ...this.getChoiceOptionsProps(valueType, valueType.options),
        };
        return _jsx(FormControl.Select, { ...controlProps });
    }
    getChoiceOptionsProps(valueType, { loadChoices, choices }) {
        if (loadChoices) {
            return {
                loadOptions: loadChoices,
            };
        }
        return {
            options: OrFunction(choices, valueType) ?? []
        };
    }
}
export class SelectType extends BaseSelectType {
    _getSpecs() {
        return this._props.specs();
    }
    constructor(specs, options) {
        super(options);
        this._getSpecs = specs;
    }
    _createValue(props) {
        return super._createValue(props);
    }
    static number(options) {
        return new SelectType(() => NumberValueType.define({
            parse: (val) => FormControl.UseOption.getValue(val),
            defaultOptions: () => ({
                placeholder: translate('select.placeholder'),
            }),
        }), options);
    }
    static string(options) {
        return new SelectType(() => StringValueType.define({
            parse: (val) => FormControl.UseOption.getValue(val),
            defaultOptions: () => ({
                placeholder: translate('select.placeholder'),
            }),
        }), options);
    }
}
