import { jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from 'Services/App';
import { useMemo } from 'react';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { CalculationServices } from 'Pages/AncillaryExpense';
import { FlexCol, FlexRow } from 'Atoms/Layout';
import { BaseFormTypeOld, DateType, SelectType } from '@f2w/form-new';
import { DateManager } from 'Atoms/Input/controls';
export class CreateDraftCalculationType extends BaseFormTypeOld {
    buildFields() {
        return {
            propertyId: SelectType.number({
                label: translate('property'),
                required: true,
                choices: () => {
                    const portfolio = usePortfolio();
                    return useMemo(() => {
                        return portfolio
                            .getProperties()
                            .map(p => ({
                            value: p.id,
                            label: p.name
                        }));
                    }, []);
                },
                onChange: (v) => {
                    if (v.value) {
                        CalculationServices.getCalculationPropertyDetails(v.value)
                            .then(details => {
                            if (details.calculationPeriod) {
                                v.parent.updateValue({
                                    start: DateManager.parseDate(details.calculationPeriod.from),
                                    end: DateManager.parseDate(details.calculationPeriod.to)
                                }, true);
                            }
                        });
                    }
                }
            }),
            start: DateType.start({
                type: 'month',
                label: translate('ancillaryExpenseCalculation.period.from'),
                required: true,
                end: v => v.parent.children.end,
            }),
            end: DateType.end({
                type: 'month',
                label: translate('ancillaryExpenseCalculation.period.to'),
                required: true,
                start: v => v.parent.children.start,
            }),
        };
    }
    _renderWidget({ field }) {
        return (_jsxs(FlexCol, { children: [field.children.propertyId.render(), _jsxs(FlexRow, { justifySpaceBetween: true, gap: 10, children: [field.children.start.render(), field.children.end.render()] })] }));
    }
}
