import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { DataTable } from '@f2w/data-table';
import { DistributionKeyServices } from '../services';
import { Button, ButtonToolbar, FlexRow, Table, Tbody, Td, Th, Thead, Tr } from 'Components/Atoms';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
const BaseTable = DataTable.factory()(() => ({
    tableId: 'DistributionKeys',
    manualControl: true,
    Actions: ({ onCreateDistributionKey, onCreatePresetDistributionKeys }) => (_jsxs(ButtonToolbar, { children: [_jsx(Button, { icon: "plus", variant: 'outline', label: translate('ancillaryExpense.presetDistributionKeys.btn.create'), title: translate('ancillaryExpense.presetDistributionKeys.btn.create'), onClick: onCreatePresetDistributionKeys }), _jsx(Button, { icon: "plus", label: translate('ancillaryExpenseDistributionKey.actions.new'), title: translate('ancillaryExpenseDistributionKey.actions.new'), onClick: onCreateDistributionKey })] })),
    showEmpty: true,
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    initialState: {
        pageSize: 200
    },
    columns: {
        $actions: {
            sticky: 'left',
        },
        property: {
            width: '100%',
            Header: translate('ancillaryExpenseDistributionKey.label.property'),
            Cell: ({ value, onPropertyClick }) => (_jsx("a", { href: "#", onClick: (event => {
                    event.preventDefault();
                    onPropertyClick(value.id);
                }), children: value.name })),
        },
        $rowExpand: {},
    },
    useExpanded: {
        renderSubRow: ({ row: { original: { distributionKeys } }, instance: { onEditDistributionKey, onDeleteClicked, refresh }, }) => _jsx(_Fragment, { children: _jsxs(Table, { variant: "content", scroll: { minWidth: 800 }, borderRadius: false, border: false, rowDivider: false, layout: "fixed", children: [_jsxs(Thead, { children: [_jsx(Th, { style: { width: 100 }, children: " " }), _jsx(Th, { style: { width: 140 }, children: translate('ancillaryExpenseDistributionKey.label.title') }), _jsx(Th, { style: { width: 140 }, children: translate('ancillaryExpenseDistributionKey.label.keyType') })] }), _jsx(Tbody, { children: distributionKeys.map(({ id, title, keyType }) => _jsxs(Tr, { children: [_jsx(Td, { children: _jsx(FlexRow, { children: _jsx(ActionsDropdown, { id: id, quickActions: [{
                                                    icon: 'edit',
                                                    title: translate('btn.edit'),
                                                    onClick: () => onEditDistributionKey(id),
                                                }], children: _jsx(ActionsDropdown.Link, { icon: 'trash', confirm: {
                                                    icon: 'alert-triangle',
                                                    message: translate('ancillaryExpenseDistributionKey.actions.delete.message.areYouSure'),
                                                    help: translate('ancillaryExpenseDistributionKey.actions.delete.message.deleteConfirmation'),
                                                    btnConfirm: translate('btn.confirm'),
                                                }, onClick: () => void onDeleteClicked(id, refresh), title: translate('btn.delete'), children: translate('btn.delete') }) }) }) }), _jsx(Td, { children: title }), _jsx(Td, { children: translate('ancillaryExpenseDistributionKey.keyType.' + keyType) })] }, `row-${id}`)) })] }) }),
    },
}));
export const DistributionKeysTable = Object.assign(BaseTable, {
    use: ({ onPropertyClick, onCreateDistributionKey, onEditDistributionKey, distributionKeyServices = DistributionKeyServices, onCreatePresetDistributionKeys }) => {
        const getData = React.useCallback(({ request }) => distributionKeyServices.listDistributionKeys(request), []);
        const { success, error } = useToastDispatcherApi();
        const onDeleteDistributionKey = React.useCallback((distributionKeyId, refresh) => {
            distributionKeyServices.deleteDistributionKey(distributionKeyId)
                .then(() => {
                success({
                    timeout: 2,
                    message: translate('ancillaryExpenseDistributionKey.actions.delete.message.success')
                });
                refresh();
            })
                .catch(() => {
                error({
                    timeout: 5,
                    message: translate('ancillaryExpenseDistributionKey.actions.delete.message.error')
                });
            });
        }, []);
        return DistributionKeysTable.useTable({
            getData,
            onPropertyClick,
            onDeleteClicked: onDeleteDistributionKey,
            onCreateDistributionKey,
            onEditDistributionKey,
            onCreatePresetDistributionKeys
        });
    }
});
