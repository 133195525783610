import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Input } from './controls';
import { uniqueId } from 'lodash';
import { styledModule } from '@f2w/theme';
import styled from 'styled-components';
export const InputField = Object.assign(({ label, id: _id, ...props }) => {
    const id = useMemo(() => {
        return _id ?? uniqueId('input-');
    }, [_id]);
    return (_jsxs(InputField.Root, { htmlFor: `${id}--widget`, children: [label && (_jsxs(InputField.Label, { children: [_jsx(InputField.LabelText, { children: label }), props.required && _jsx(InputField.LabelRequired, { children: "*" })] })), _jsx(Input, { id: id, ...props })] }));
}, styledModule(() => {
    const LabelRequired = styled.span `
          font-size: 22px;
          color: ${p => p.theme.palette.error.$600};
        `;
    const LabelText = styled.span `
          font-size: inherit;
          font-weight: inherit;
          color: inherit;
          line-height: inherit;
          letter-spacing: inherit;
        `;
    const Label = styled.span `
          display: flex;
          margin-bottom: 12px;
          gap: 3px;
          ${p => p.theme.typography.small.fontSizeStyles};

          ${LabelRequired} {
            font-size: 150%;
          }
        `;
    const Root = styled.label `
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0;
          ${p => p.theme.typography.body.fontStyles};
          color: ${p => p.theme.palette.neutral.$600};

        `;
    return {
        Root,
        Label,
        LabelText,
        LabelRequired,
    };
}));
