import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Box, Nav, Tab } from 'Components/Atoms';
export const TabsNav = ({ tabs, defaultTab, styles = {
    b: 'solid 1px #ddd',
    maxh: '100vh',
}, style = { overflow: 'auto', position: 'sticky', top: 0 }, }) => {
    const { ids, defaultKey } = useMemo(() => {
        const ids = tabs.map((tab, i) => `rtab-${i}`);
        return {
            ids,
            defaultKey: ids[tabs.findIndex((tab, i) => defaultTab ? (tab.key ?? i) === defaultTab : tab.isDefault) ?? 0] ?? ids[0],
        };
    }, []);
    return (_jsx(Box, { w: '100%', ...styles, style: style, children: _jsxs(Tab.Container, { id: 'form-debug', defaultActiveKey: defaultKey, children: [_jsx(Nav, { variant: "tabs", position: 'top', className: "flex-column", sticky: 'top', style: {
                        background: 'white',
                        paddingTop: '4px'
                    }, children: tabs.map((tab, i) => _jsx(Nav.Link, { eventKey: ids[i], children: tab.name }, `tab-link-${i}`)) }), _jsx(Tab.Content, { style: { padding: '20px' }, children: tabs.map((tab, i) => _jsx(Tab.Pane, { eventKey: ids[i], children: tab.content() }, `tab-content-${i}`)) })] }) }));
};
