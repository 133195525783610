import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ModalNew } from 'Components/Modal';
import { Div, FlexCol, FlexRow } from 'Atoms/Layout';
import { translate } from 'Services/App';
import { IbanType } from '@f2w/form';
import { Button } from 'Atoms/Button';
import { generate } from 'Services/Router';
import { SafeAnchor } from 'Atoms/SafeAnchor';
import { SpinnerNew } from 'Atoms/Spinner';
import { Callout } from 'Atoms/Callout';
import { SafeHtml } from 'Atoms/Text';
import { useDispatcherApi } from 'Components/Dispatcher';
export const BankingFileUploadModal = ({ filesToUpload, onClose, upload }) => {
    const [uploadResponse, setUploadResponse] = useState();
    const { toast } = useDispatcherApi();
    useEffect(() => {
        upload(filesToUpload)
            .then(setUploadResponse)
            .catch(e => {
            console.error('BankingFileUploa error', e);
            toast.error({ message: translate('bankingReconciliation.upload.error') });
            onClose();
        });
    }, []);
    return (_jsx(BankingFileUploadModalInner, { uploadResponse: uploadResponse, onClose: onClose }));
};
export const BankingFileUploadModalInner = ({ uploadResponse, onClose }) => {
    const { invalidAccountIds, missingFinancialAccounts, processingErrors } = { ...uploadResponse };
    const bankWarnings = invalidAccountIds?.length || missingFinancialAccounts?.length;
    const maxWidth = bankWarnings || processingErrors?.length ? '800px' : '500px';
    return (_jsxs(ModalNew, { show: true, onHide: onClose, size: { maxWidth }, children: [_jsx(ModalNew.Header, { closeButton: true, children: _jsx(ModalNew.Title, { ellipsis: true, children: bankWarnings > 0
                        ? translate('bankingReconciliation.upload.error.bank.title')
                        : translate('bankingReconciliation.upload.modalTitle') }) }), _jsx(ModalNew.Body, { children: uploadResponse ? _jsx(BankingUploadResponseContent, { ...uploadResponse }) : _jsx(SpinnerNew, { isVisible: true }) }), _jsx(ModalNew.Footer, { children: _jsx(Button, { disabled: !uploadResponse, onClick: onClose, children: translate('btn.close') }) })] }));
};
const BankingUploadResponseContent = (props) => {
    const { processingErrors, invalidAccountIds, missingFinancialAccounts } = props;
    if (processingErrors.length > 0)
        return _jsxs(Callout, { type: 'error', children: [_jsx(SafeHtml, { value: translate('bankingReconciliation.upload.error.invalidFile', { numberOfFiles: processingErrors.length }) }), _jsx(FlexCol, { mt: 8, gap: 4, children: processingErrors.map((data, index) => (_jsxs("div", { children: [_jsx("div", { children: data.fileName }), _jsx("code", { style: { whiteSpace: 'break-spaces' }, children: data.errorMessage })] }, index))) })] });
    if (props.isIgnored)
        return translate('bankingReconciliation.duplicatedFiles');
    if (invalidAccountIds.length || missingFinancialAccounts.length)
        return _jsxs(FlexCol, { gap: 20, children: [invalidAccountIds.length > 0 && (_jsxs(Callout, { type: 'warning', children: [_jsx(SafeHtml, { value: translate('bankingReconciliation.upload.error.invalidAccountIds') }), _jsx(FlexCol, { mt: 8, gap: 4, children: invalidAccountIds.map((data, index) => (_jsxs("div", { children: ["- ", data.fileName] }, index))) })] })), missingFinancialAccounts.length > 0 && (_jsxs(Callout, { type: 'warning', children: [_jsx(SafeHtml, { value: translate('bankingReconciliation.upload.error.missingFinancialAccounts') }), _jsx(FlexCol, { mt: 8, gap: 4, children: missingFinancialAccounts.map((data, index) => (_jsxs(FlexRow, { gap: 8, children: [_jsxs("span", { children: ["- ", IbanType.format(data.iban) || data.iban] }), _jsx(SafeAnchor, { target: "_blank", href: generate('bank_accounts_overview_page', { bankAccountId: data.id }), children: translate('bankAccount.edit.rent') })] }, data.id))) })] }))] });
    return (_jsxs(FlexCol, { gap: 10, children: [_jsxs(Div, { children: [props.transactionsAutoAssigned, " ", translate('bankingReconciliation.transactionsAutoAssigned')] }), _jsxs(Div, { children: [props.transactionsNotAssigned, " ", translate('bankingReconciliation.transactionsNotAllocated')] }), _jsxs(Div, { children: [props.transactionsIgnored, " ", translate('bankingReconciliation.transactionsIgnored')] })] }));
};
