import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { translate } from "Services/Translator";
import { Button } from "Atoms/Button";
import { CreateAutomaticInvoicesType } from "Pages/AutoInvoicing/types/CreateAutomaticInvoicesType";
import { generate } from "Services/Router";
import { downloadFileFromUrl } from "@f2w/utils";
export const useCreateAutomaticInvoicesForm = ({ onClose, selectedPeriodIds, invoices, generateInvoices }) => useFormModal({
    name: 'create-autoinvoicing-form',
    type: () => new CreateAutomaticInvoicesType({
        invoices: invoices
    }),
    title: translate('autoInvoicing.actions.downloadInvoices'),
    onSave: (settings) => {
        if (!invoices) {
            return generateInvoices(selectedPeriodIds, settings)
                .then(onClose);
        }
        const route = generate('download_debitor_invoice.v2', {
            debitorInvoice: invoices[0].invoiceId,
            ...settings
        });
        return downloadFileFromUrl({
            url: route,
        });
    },
    successMessage: !invoices ? translate('autoInvoicing.downloadScheduled') : null,
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.close') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('btn.download') })] });
    },
    ignoreConfirm: true
});
