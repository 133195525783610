import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Table, Tbody, Td, Tr } from "Components/Atoms";
import { formatAmount } from "Services/NumberHelper";
import { CostUnit } from "Pages/Handover/enums/CostUnit";
import HandoverProtocolItemLevelBadge from "Pages/Handover/components/HandoverView/component/HandoverProtocolItemLevelBadge";
import { HandoverProtocolItemType } from "Pages/Handover/enums/HandoverProtocolItemType";
import { translate } from "Services/Translator";
import { ImagePreview } from 'Components/ImageCarousel';
const formatCost = (cost) => {
    if (cost === null) {
        return '';
    }
    switch (cost.unit) {
        case CostUnit.ABSOLUTE:
            return formatAmount(cost.amount) + ' CHF';
        case CostUnit.PERCENT:
            return formatAmount(cost.amount) + '%';
    }
};
const ProtocolItemsTable = ({ items }) => {
    const badge = (item) => {
        if (item.type === HandoverProtocolItemType.KEY) {
            if (item.keysData.available < item.keysData.quantity) {
                return (_jsx(Badge, { "$color": 'danger', children: translate('handovers.detailView.keyMissing') }));
            }
            else {
                return '';
            }
        }
        return (_jsx(HandoverProtocolItemLevelBadge, { level: item.level }));
    };
    const itemGroupRows = items.map((group) => {
        return (_jsx(Tr.Expandable, { expandableRows: (_jsx(Tr, { children: _jsx(Table, { style: { width: '100%' }, children: _jsx(Tbody, { children: group.items.map((item) => {
                            if (item.type === HandoverProtocolItemType.KEY) {
                                const data = item.keysData;
                                item.notes.unshift(data.lockingSystem + ', #' + data.number + ', Soll: ' + data.quantity + ', Ist: ' + data.available);
                            }
                            const expandableContent = item.notes.length > 0 ? (_jsx(Tr, { children: _jsx(Td, { colSpan: 3, children: item.notes.map((note, index) => (_jsx("div", { children: note }, 'protocol-item-' + item.id + '-note-' + index))) }) }, 'protocol-item-expanded-' + item.id)) : null;
                            return (_jsxs(Tr.Expandable, { expandableRows: expandableContent, expanded: true, children: [_jsx(Td, { children: item.title }), _jsxs(Td, { children: [badge(item), item.documents?.length && _jsx(ImagePreview, { images: item.documents.map(d => ({
                                                    path: d.path,
                                                    name: d.id
                                                })) })] }), _jsx(Td, { noWrap: true, children: formatCost(item.cost) })] }, 'protocol-item-' + item.id));
                        }) }) }) })), children: _jsx(Td, { children: group.title }) }, 'protocol-item-group-' + group.id));
    });
    return (_jsx(Table, { variant: 'accordion', children: _jsx(Tbody, { children: itemGroupRows }) }));
};
export default ProtocolItemsTable;
