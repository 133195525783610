import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ModalNew } from "Components/Modal";
import { translate } from "Services/Translator";
import { Div, FlexCol } from "Atoms/Layout";
import { Select } from "Atoms/Select";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { AncillaryExpensePresetAccountDataTable } from "Pages/AncillaryExpense/table/AncillaryExpensePresetAccountDataTable";
import { Button, ButtonToolbar } from "Components/Atoms";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { DataTable } from "@f2w/data-table";
import { getGlobalContext } from "Services/GlobalContext";
export const ChoosePresetAccountsModal = ({ onClose, getAllPresetAccounts, createAccountsUsingPresetForProperties }) => {
    const [presetAccounts, setPresetAccounts] = React.useState([]);
    const [propertyOptions, setPropertyOptions] = React.useState([]);
    const [selectedPropertyId, setSelectedPropertyId] = React.useState(undefined);
    const [submitting, setSubmitting] = React.useState(false);
    const portfolio = usePortfolio();
    const instance = AncillaryExpensePresetAccountDataTable.use({
        data: presetAccounts
    });
    React.useEffect(() => {
        void getAllPresetAccounts()
            .then(presetAccounts => {
            const { scope } = getGlobalContext();
            const properties = portfolio.getProperties();
            setPropertyOptions(properties.map(property => ({
                label: property.name,
                value: property.id
            })));
            switch (scope?.level) {
                case "property": {
                    setSelectedPropertyId(scope.id);
                    break;
                }
                case "rentalObject": {
                    setSelectedPropertyId(portfolio.getPropertyByRentalObjectId(scope.id).id);
                }
            }
            setPresetAccounts(presetAccounts);
        });
    }, []);
    const { success } = useToastDispatcherApi();
    const onCreateClicked = React.useCallback(() => {
        setSubmitting(true);
        const presetAccountIds = instance
            .selectedFlatRows
            .map(row => Number(row.id));
        void createAccountsUsingPresetForProperties(selectedPropertyId, presetAccountIds)
            .then(() => {
            const property = portfolio.getPropertyById(selectedPropertyId);
            success({
                message: translate('ancillaryExpenseAccount.createUsingPreset.success', {
                    propertyName: property.name
                }),
            });
            onClose();
        })
            .catch(() => setSubmitting(false));
    }, [selectedPropertyId, instance.selectedFlatRows.length]);
    return (_jsxs(ModalNew, { show: true, size: { maxWidth: '80%', maxHeight: '80%', minHeight: '600px' }, onHide: () => !submitting && onClose(), children: [_jsx(ModalNew.Header, { closeButton: true, onHide: () => !submitting && onClose(), children: _jsx(ModalNew.Title, { children: translate('ancillaryExpenseAccount.setupAssistant') }) }), _jsx(ModalNew.Body, { children: _jsxs(FlexCol, { gap: 24, h: '100%', children: [_jsxs(Div, { children: [_jsx("label", { htmlFor: "setupAssistantProperties", style: { fontWeight: 500 }, children: translate('property') }), _jsx(Select, { id: "setupAssistantProperties", value: selectedPropertyId, options: propertyOptions, isSearchable: true, container: true, onChange: (selectedOption) => {
                                        setSelectedPropertyId(selectedOption.value);
                                    } })] }), _jsx(Div, { h: '80%', children: _jsx(DataTable.SimpleTable, { instance: instance, scroll: 'fit' }) })] }) }), _jsx(ModalNew.Footer, { children: _jsxs(ButtonToolbar, { children: [_jsx(Button, { disabled: submitting, variant: 'link', color: 'neutral', onClick: onClose, children: translate('btn.close') }), _jsx(Button, { disabled: submitting || !selectedPropertyId || !instance.selectedFlatRows.length, onClick: onCreateClicked, children: translate('ancillaryExpenseAccount.btn.createSelectedAccounts', {
                                numAccounts: instance.selectedFlatRows.length
                            }) })] }) })] }));
};
