import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import SelectSubscriptionPlan from "./components/SelectSubscriptionPlan/SelectSubscriptionPlan";
import client from "Utils/client";
import generateRoute from "Services/Router";
import CheckoutSubscriptionPlan from "./components/CheckoutSubscriptionPlan/CheckoutSubscriptionPlan";
import { getGlobalContext } from "Services/GlobalContext";
import CheckoutSuccess from "./components/CheckoutSuccess/CheckoutSuccess";
import { orderSubscription } from "./SubscriptionManager";
import SubscriptionServices from './services/subscriptionServices';
const getSubscriptionPlans = async () => {
    const route = generateRoute('api.subscriptionPlans.list');
    const { data } = await client.get(route);
    return data;
};
var SubscriptionStep;
(function (SubscriptionStep) {
    SubscriptionStep[SubscriptionStep["Start"] = 0] = "Start";
    SubscriptionStep[SubscriptionStep["Checkout"] = 1] = "Checkout";
    SubscriptionStep[SubscriptionStep["Success"] = 2] = "Success";
})(SubscriptionStep || (SubscriptionStep = {}));
const ChangeSubscriptionPage = ({ trialData }) => {
    const [currentStep, setCurrentStep] = useState(SubscriptionStep.Start);
    const [subscriptionPlans, setSubscriptionPlans] = useState(undefined);
    const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(undefined);
    const [selectedLimits, setSelectedLimits] = useState(undefined);
    const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState(undefined);
    const { limits } = getGlobalContext();
    useEffect(() => {
        getSubscriptionPlans()
            .then((subscriptionPlans) => setSubscriptionPlans(subscriptionPlans.map((type) => {
            return {
                id: type.id,
                optionId: type.id,
                name: type.name,
                description: type.description,
                price: type.price,
                limits: {
                    nbUsers: type.limits.users,
                    nbRentalObjects: type.limits.objects,
                    dmsLimitInBytes: type.limits.space,
                },
                isDmsLimitExpansible: type.isSpaceExpansible,
                isNbRentalObjectsExpansible: type.isObjectsExpansible,
            };
        })));
        SubscriptionServices.listSubscriptions().then(response => setCurrentSubscriptionPlan(response.length > 0 ? {
            id: response[0].id,
            optionId: response[0].id,
            name: response[0].name,
            description: '',
            price: response[0].price,
            isDmsLimitExpansible: true,
            isNbRentalObjectsExpansible: true,
            limits: {
                nbUsers: response[0].limits.users,
                nbRentalObjects: response[0].limits.objects,
                dmsLimitInBytes: response[0].limits.space,
            }
        } : null));
    }, []);
    if (subscriptionPlans === undefined || currentSubscriptionPlan === undefined) {
        return (_jsx("div", { children: "Loading..." }));
    }
    const onUpgrade = (subscriptionPlan, limits) => {
        setSelectedSubscriptionPlan(subscriptionPlan);
        setSelectedLimits(limits);
        setCurrentStep(SubscriptionStep.Checkout);
    };
    const onCheckout = async (subscriptionPlan, limits, billingDetails) => {
        await orderSubscription(subscriptionPlan.optionId, limits, billingDetails);
        setCurrentStep(SubscriptionStep.Success);
    };
    return (_jsxs(_Fragment, { children: [currentStep === SubscriptionStep.Start && _jsx(SelectSubscriptionPlan, { subscriptionPlans: subscriptionPlans, currentSubscriptionPlan: currentSubscriptionPlan, limits: limits, onUpgrade: onUpgrade, trialData: trialData, onGoBack: () => window.location.href = generateRoute('subscriptions') }), currentStep === SubscriptionStep.Checkout && _jsx(CheckoutSubscriptionPlan, { subscriptionPlan: selectedSubscriptionPlan, limits: selectedLimits, onCheckout: onCheckout, onGoBack: () => setCurrentStep(SubscriptionStep.Start) }), currentStep === SubscriptionStep.Success && _jsx(CheckoutSuccess, {})] }));
};
export default ChangeSubscriptionPage;
