import { getUuid, LocalStorage, OrFunction } from '@f2w/utils';
export class BaseSessionStorage {
    constructor(key) {
        Object.defineProperty(this, 'key', {
            value: key,
            writable: false,
        });
    }
    init(reset) {
        if (reset || !this._read())
            return this._reset();
        else if (this._isExpired()) {
            return this._reset();
        }
        return this._read();
    }
    update(value) {
        const prev = this._read();
        const next = OrFunction(value, prev);
        return this._write({ ...prev, ...next });
    }
    read(reset) {
        return this._read(reset);
    }
    _read(reset) {
        if (reset || typeof this._data === 'undefined')
            this._data = LocalStorage.get(this.key) ?? null;
        return this._data;
    }
    _write(value) {
        return this._data = LocalStorage.set(this.key, value) ?? null;
    }
}
export class ChatStore extends BaseSessionStorage {
    static DEFAULT_TIMEOUT = 4 * 60 * 1000;
    timeout;
    constructor({ sessionKey, sessionTimeout }) {
        super(sessionKey ?? 'fw_chat');
        this.timeout = sessionTimeout ?? ChatStore.DEFAULT_TIMEOUT;
    }
    get isExpired() {
        return this._isExpired();
    }
    get sessionId() {
        return this._read().sessionId;
    }
    get updateTimestamp() {
        return this._read().updateTimestamp;
    }
    get feedback() {
        return this._read()?.feedback;
    }
    get data() {
        return this.init();
    }
    getMessages() {
        return [...this.data.messages];
    }
    addMessage(message) {
        if (!message || message.role === 'info')
            return this;
        const { messages, ...rest } = this.data;
        this._write({ ...rest, messages: [...messages, message] });
        return this;
    }
    _isExpired() {
        const timestamp = super._read()?.updateTimestamp;
        if (timestamp) {
            const time = Date.now() - timestamp;
            return time > this.timeout;
        }
        return false;
    }
    _reset() {
        return this._write({ sessionId: getUuid(), messages: [] });
    }
}
