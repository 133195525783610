import { jsx as _jsx } from "react/jsx-runtime";
import { createWithScope, slotAttr, slotCss } from './utils';
export class SlotFactory {
    scope;
    constructor(scope) {
        this.scope = scope;
        this.create = this.create.bind(this);
        this.props = this.props.bind(this);
        this.attr = this.attr.bind(this);
        this.css = this.css.bind(this);
        this.partAttr = this.partAttr.bind(this);
        this.partCss = this.partCss.bind(this);
        this.Part = this.Part.bind(this);
        this.Part.displayName = 'SlotPart';
    }
    create(slot, options = Object.create(null)) {
        return createWithScope(this.scope, slot, options);
    }
    Part(props) {
        return _jsx(PartTag, { scope: this.scope, ...props });
    }
    props(slot, props) {
        const attrs = this.attr(slot);
        return {
            ...attrs,
            ...props,
            ...attrs
        };
    }
    attr(slot) {
        return slotAttr(this.scope, slot);
    }
    css(...slots) {
        return slotCss(this.scope, ...slots);
    }
    partAttr(part) {
        return slotAttr(null, part);
    }
    partCss(...slots) {
        return slotCss(null, ...slots);
    }
}
const PartTag = ({ scope, part, as: Component = 'div', ...props }) => {
    return _jsx(Component, { ...slotAttr(scope, part), ...props });
};
