import { BaseValueType, createSpecsApi, } from '../../base';
export class SimpleValueType extends BaseValueType {
    _specs;
    constructor(props) {
        super(props);
        this._specs = createSpecsApi({
            defaultOptions: props?.defaultOptions,
            getSchema: props?.getSchema,
            schemaType: () => this._createSchema(),
            getOptionsModifiers: p => this.getOptionsModifiers(p),
            getConstraints: p => this.getConstraints(p),
        });
    }
    format(value, formatter) {
        let _value = this.cast(value, { ignoreDefaults: true });
        if (_value == null)
            return _value;
        if (this.props.format)
            return this.props.format(_value, formatter);
        return this._format(_value, formatter);
    }
    _format(value, formatter) {
        return value;
    }
    getConstraints(props) {
        return {};
    }
}
