import { jsxs as _jsxs } from "react/jsx-runtime";
import { renderContent } from '@f2w/utils';
import { PropDesc } from './utils';
export class StepType {
    constructor({ config, id, parent, index, ...rest }) {
        PropDesc.create(this, { visible: true, writable: false, configurable: false })
            .values({ visible: false }, {
            _props: {},
        })
            .values({
            config: config,
            id,
            index,
            props: { ...config, ...rest },
            type: config.type?.(this),
        })
            .getters({
            parent: () => parent,
            valueType: () => {
                return parent.valueType.get(this.id);
            },
        });
    }
    get path() {
        return `${this.parent.props.base}/${this.id}`;
    }
    get loading() {
        return this._props?.loading ?? false;
    }
    get isCurrent() {
        return this.api.currentIndex === this.index;
    }
    get isFirst() {
        return this.index === 0;
    }
    get isLast() {
        return !this.nextStep;
    }
    get api() {
        return this.parent.stepApi;
    }
    get isEnabled() {
        return this.index <= this.api.lastIndex;
    }
    get isCompleted() {
        return this.valueType?.isValid ?? true;
    }
    get prevStep() {
        return this.parent.stepList[this.index - 1];
    }
    ;
    get nextStep() {
        return this.parent.stepList[this.index + 1];
    }
    ;
    get data() {
        return this.valueType?.data;
    }
    hasType() {
        return !!this.type;
    }
    title = () => {
        return this.config.title?.(this) ?? this.id;
    };
    render = (props) => {
        return renderContent(this.StepTemplate, {
            key: `wizard-step--${this.id}`,
            ...props,
        });
    };
    StepTemplate = (props) => {
        const step = this;
        const wizard = step.parent;
        const context = {
            step,
            wizard,
            config: wizard.config,
            stepApi: wizard.stepApi,
            loading: step.loading,
            valueType: step.valueType,
        };
        const templates = wizard.templates;
        return _jsxs(templates.Step, { ...context, children: [props?.children, step.valueType?.render(), step.config?.render?.(this)] });
    };
}
