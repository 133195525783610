import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useViewType } from "@f2w/view";
import { RentType } from "Pages/Rent/types/RentType";
import RentServices from "Pages/Rent/services";
import { RentTemplate } from "Pages/Rent/components/DetailView/RentTemplate";
import { trans, translate } from "Services/Translator";
import { GeneralTab } from "Pages/Rent/components/DetailView/GeneralTab";
import { DebitorInvoicesTab } from "Pages/Rent/components/DetailView/DebitorInvoicesTab";
import { UseStyled } from "@fw/theme";
import { css } from "styled-components";
import { FormError } from "Utils/client";
const WarningText = UseStyled.styled('div', ({ theme: { palette, typography, rem } }) => (css `
    background: ${palette.warning.$100};
    color: ${palette.warning.$900};
    border: 1px solid ${palette.warning.$500};
    border-radius: ${rem(8)};
    padding: ${rem(18)} ${rem(26)};
    margin-bottom: ${rem(16)};
    ${typography.medium.fontStyles}
`));
export const useRentView = () => useViewType(() => new RentType(), {
    id: 'rent-main',
    DefaultTemplate: props => _jsx(RentTemplate, { ...props }),
    load: async (id) => {
        const data = await RentServices.getRentDetails(id);
        data.indexBaseId = data.indexBase?.id;
        data.inflationIndexId = data.inflationIndex?.id;
        data.referenceInterestRateId = data.referenceInterestRate?.id;
        return data;
    },
}, () => ({
    general: {
        name: translate('contract.details.general'),
        Content: (props) => _jsx(GeneralTab, { ...props }),
        Form: ({ type, data }) => {
            return (_jsxs(_Fragment, { children: [data.invoices.length > 0 && _jsx(WarningText, { children: trans('rent.details.editWarningInvoices') }), data.contractRentsCount > 1 && _jsx(WarningText, { children: trans('rent.details.editWarningOtherRents') }), type.render()] }));
        },
        save: async (values, view) => {
            try {
                const updatedData = await RentServices.updateRent(values.id, values);
                return Promise.resolve({
                    updateValues2: {},
                    updateValues: {
                        ...values,
                        ...updatedData,
                    }
                });
            }
            catch ({ response }) {
                const { data, status } = response;
                if ([400, 422].includes(status) && data?.message) {
                    view.dispatcher.toast.error({
                        message: data.message,
                        timeout: 5,
                        showRemove: true
                    });
                    throw new FormError(data.message);
                }
                return Promise.reject();
            }
        },
    },
    debitorInvoices: {
        name: translate('rent.details.invoices.title'),
        Content: (props) => _jsx(DebitorInvoicesTab, { ...props }),
    }
}));
