import { jsx as _jsx } from "react/jsx-runtime";
import { BaseSimpleType, SimpleValue, StringValueType, } from '../../core';
import { InputField } from '../../theme';
import { TextArea, TextEditor } from 'Atoms/Input/controls';
export class TextType extends BaseSimpleType {
    _getSpecs() {
        return StringValueType.define({});
    }
    _createValue(props) {
        return new SimpleValue(this, props);
    }
    _renderWidget({ field, ...innerProps }) {
        return _jsx(TextArea, { ...innerProps });
    }
}
export class EditorType extends BaseSimpleType {
    _createValue(props) {
        return new SimpleValue(this, props);
    }
    _getSpecs() {
        return StringValueType.define({
            defaultOptions: () => ({
                type: 'BLOCK',
            }),
        });
    }
    _renderForm(valueType, props) {
        const { type: editorType = 'BLOCK', placeholder, } = valueType.options;
        return _jsx(InputField, { ...props, Widget: ({ field, $size, $variant, disabled, id, value, onChange, onBlur, }) => (_jsx(TextEditor, { id: id, disabled: disabled, placeholder: placeholder, initialValue: field.initialValue, onChange: onChange, onBlur: onBlur, editorType: editorType, "$size": $size, "$variant": $variant })) });
    }
}
