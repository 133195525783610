import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import styled from 'styled-components';
import { isStr, OrFunction } from '@f2w/utils';
import { useEventCallback } from '@restart/hooks';
import { BaseTheme } from '../base';
export function InputField({ field: valueType, Label: labelProps, Error: errorProps, Widget, children, props: innerProps, as, ...rest }) {
    const commonProps = { field: valueType };
    const inputProps = InputField.useProps(valueType, {});
    if (Widget) {
        children = Widget({
            ...innerProps,
            ...inputProps,
            ...commonProps,
        });
    }
    if (valueType.options.Widget) {
        children = valueType.options.Widget({ children, typeValue: valueType });
    }
    return (_jsxs(InputField.Row, { id: `${valueType.id}--row`, as: as, ...rest, ...commonProps, children: [_jsx(InputField.Label, { ...labelProps, ...commonProps }), children, (valueType.options.helperText) && _jsx(InputField.HelperText, { children: OrFunction(valueType.options.helperText, valueType) }), (valueType.meta.touched && !valueType.meta.valid) && _jsx(InputField.Error, { ...errorProps, ...commonProps })] }));
}
(function (InputField) {
    InputField.Row = styled(BaseTheme.Row) ``;
    InputField.HelperText = styled.div `
        display: block;
        margin-top: 4px;
        color: ${p => p.theme.palette.neutral.$500};
    `;
    InputField.Label = styled(BaseTheme.Label) `
      margin: 0 0 8px;
      ${p => p.theme.typography.small.fontStyles};
    `;
    InputField.Error = styled(BaseTheme.Error) ``;
    function useProps(valueType, props) {
        const name = valueType.path;
        const value = valueType.humanValue ?? '';
        const $size = valueType.theme?.size;
        let $variant = useVariant(valueType, props);
        const onChange = useEventCallback((value, event) => {
            valueType.updateValue(value, true);
        });
        const onBlur = useCallback((event) => {
            if (isStr(value)) {
                const next = value.trim();
                if (next !== value)
                    valueType.updateValue(next, false);
            }
            valueType.setTouched(true, true);
        }, [value]);
        return {
            id: valueType.widgetId,
            name: name,
            value: value,
            onBlur: onBlur,
            onChange: onChange,
            $size: $size,
            $variant: $variant,
            disabled: valueType.isDisabled,
            readOnly: valueType.options.readonly,
        };
    }
    InputField.useProps = useProps;
    function useVariant(valueType, props) {
        if (valueType.meta.touched) {
            if (valueType.meta.validating)
                return 'validating';
            if (valueType.meta.validated)
                return valueType.meta.hasError ? 'error' : 'success';
        }
        return props?.$variant || 'default';
    }
})(InputField || (InputField = {}));
