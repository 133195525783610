import client from 'Utils/client';
import generateRoute from 'Services/Router';
export const getAutoInvoiceDatesForContract = (contractId) => {
    const route = generateRoute('api.contracts.automaticInvoicingDates', {
        contractId: contractId,
    });
    return client
        .get(route)
        .then(response => response.data?.availableInvoicingMonths ?? []);
};
export const generateAutoInvoiceForPeriodAndContract = (month, year, contractId) => {
    const route = generateRoute('api.automaticInvoicing.createForContract', {
        contract: contractId,
    });
    return client
        .post(route, {
        month: month,
        year: year,
    })
        .then(response => response.data);
};
export const generateAutoInvoiceForPeriods = (periodIds, settings) => {
    const route = generateRoute('api.automaticInvoicing.generateInvoices');
    return client
        .post(route, {
        periodIds,
        ...settings
    })
        .then(response => response.data);
};
