import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Div, FlexCol, FlexRow, Text } from "Components/Atoms";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { UseStyled } from "@fw/theme";
import { translate } from "Services/Translator";
import { EditObjectDataSegment } from "Pages/Creditor/components/CreditorView/components/EditObjectDataSegment";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { DateType, MoneyType } from "@f2w/form";
import moment from "moment";
const VerticalLine = UseStyled.styled('div', ({ theme: { palette } }) => ({
    borderLeft: `1px solid ${palette.neutral.$100}`,
    height: '100%'
}));
export const CreditorDetails = ({ creditorInvoice, updateCreditorObjectData, getAssetsByRentalObjectForDropdown, refreshDetails }) => {
    const [isSaving, setIsSaving] = React.useState(false);
    const [editingEnabled, setEditingEnabled] = React.useState();
    const [assetId, setAssetId] = React.useState(creditorInvoice.asset?.id);
    const [rentalObjectId, setRentalObjectId] = React.useState(creditorInvoice.rentalObject?.id);
    const [assetOptions, setAssetOptions] = React.useState([]);
    React.useEffect(() => {
        if (!editingEnabled) {
            if (!rentalObjectId) {
                setAssetOptions([]);
            }
            else {
                getAssetsByRentalObjectForDropdown(rentalObjectId)
                    .then((assets) => {
                    setAssetOptions(assets.map(asset => ({
                        label: asset.name,
                        value: asset.id
                    })));
                });
            }
        }
    }, [rentalObjectId, editingEnabled]);
    const enableEditing = React.useCallback((enableFor) => () => {
        setEditingEnabled(enableFor);
    }, []);
    const { success, error } = useToastDispatcherApi();
    const saveEdit = React.useCallback(() => {
        setIsSaving(true);
        updateCreditorObjectData(creditorInvoice.id, { rentalObjectId, assetId })
            .then(async () => {
            await refreshDetails();
            await setEditingEnabled(undefined);
            success({
                timeout: 2,
                message: translate('creditor.updateObjectData.success')
            });
        })
            .catch(() => {
            error({
                timeout: 5,
                message: translate('creditor.updateObjectData.error')
            });
        })
            .finally(() => setIsSaving(false));
    }, [rentalObjectId, assetId]);
    const disregardEdit = React.useCallback((disregardFor) => async () => {
        await setEditingEnabled(undefined);
        if (disregardFor === 'rentalObject') {
            await setRentalObjectId(creditorInvoice.rentalObject?.id);
        }
        else {
            await setAssetId(creditorInvoice.asset?.id);
        }
    }, [creditorInvoice]);
    const portfolioData = usePortfolio();
    const rentalObjectOptions = React.useMemo(() => {
        if (!creditorInvoice.property?.id) {
            return [];
        }
        const rentalObjects = portfolioData.getRentalObjectsByPropertyId(creditorInvoice.property?.id);
        return rentalObjects
            .map(rentalObject => ({
            label: rentalObject.name,
            value: rentalObject.id
        }));
    }, [creditorInvoice.property?.id, portfolioData]);
    const dueDate = DateType.useFormat(creditorInvoice.dueDate) ?? translate('notAvailable');
    const amount = MoneyType.useFormat(creditorInvoice.grossAmount);
    const amountRemaining = MoneyType.useFormat(creditorInvoice.amountRemaining);
    const { name: propertyName = translate('notAvailable') } = creditorInvoice.property ?? { name: undefined };
    const { name: rentalObjectName = translate('notAvailable') } = creditorInvoice.rentalObject ?? { name: undefined };
    const { name: assetName = translate('notAvailable') } = creditorInvoice.asset ?? { name: undefined };
    return (_jsxs(FlexCol, { children: [_jsxs(FlexRow, { justifySpaceAround: true, pb: 24, children: [_jsxs(FlexCol, { p: 16, alignCenter: true, gap: 5, children: [_jsx(Text, { h6: 500, neutral: 500, children: dueDate }), _jsx(Text, { medium: 400, neutral: 200, children: translate('creditor.dueDate') })] }), _jsx(FlexCol, { pl: 10, pr: 10, children: _jsx(VerticalLine, {}) }), _jsxs(FlexCol, { p: 16, alignCenter: true, gap: 5, children: [_jsx(Text, { h6: 500, neutral: 500, children: amount }), _jsx(Text, { medium: 400, neutral: 200, children: translate('creditor.totalAmount') })] }), _jsx(FlexCol, { pl: 10, pr: 10, children: _jsx(VerticalLine, {}) }), _jsxs(FlexCol, { p: 16, alignCenter: true, gap: 5, children: [_jsx(Text, { h6: 500, neutral: 500, children: amountRemaining }), _jsx(Text, { medium: 400, neutral: 200, children: translate('creditor.amountRemaining') })] })] }), _jsxs(FlexCol, { gap: 5, mb: 15, alignFlexEnd: true, children: [_jsxs(Div, { children: [_jsxs("strong", { children: [translate('invoice.date'), ":"] }), " ", creditorInvoice.invoiceDate
                                ? moment(creditorInvoice.invoiceDate).format('DD.MM.YYYY')
                                : translate('notAvailable')] }), _jsxs(Div, { children: [_jsxs("strong", { children: [translate('creditor.comment'), ":"] }), " ", creditorInvoice.comment ?? translate('notAvailable')] }), _jsxs(Div, { children: [_jsxs("strong", { children: [translate('creditor.invoiceRelevancy'), ":"] }), " ", creditorInvoice.invoiceRelevancy ?? translate('notAvailable')] })] }), _jsxs(FlexCol, { children: [_jsxs(FlexRow, { alignCenter: true, flexWrap: true, children: [_jsx(Div, { p: 8, w: 220, children: _jsx(Text, { medium: 400, neutral: 200, children: translate('property') }) }), _jsx(Div, { p: 8, flexGrow: 1, children: propertyName })] }), _jsxs(FlexRow, { alignCenter: true, bt: '2px solid #d7d8db', flexWrap: true, children: [_jsx(Div, { p: 8, w: 220, children: _jsx(Text, { medium: 400, neutral: 200, children: translate('rentalObject') }) }), _jsx(EditObjectDataSegment, { id: rentalObjectId, options: rentalObjectOptions, editingEnabled: editingEnabled === 'rentalObject', onEnableEditing: enableEditing('rentalObject'), onSaveEdit: saveEdit, name: rentalObjectName, editButtonDisabled: !creditorInvoice.property || editingEnabled === 'asset', onDisregardEdit: disregardEdit('rentalObject'), updateId: setRentalObjectId, isSaving: isSaving })] }), _jsxs(FlexRow, { alignCenter: true, bt: '2px solid #d7d8db', flexWrap: true, children: [_jsx(Div, { p: 8, w: 220, children: _jsx(Text, { medium: 400, neutral: 200, children: translate('asset') }) }), _jsx(EditObjectDataSegment, { id: assetId, options: assetOptions, editingEnabled: editingEnabled === 'asset', onEnableEditing: enableEditing('asset'), onSaveEdit: saveEdit, name: assetName, editButtonDisabled: !creditorInvoice.property || !creditorInvoice.rentalObject || editingEnabled === 'rentalObject', onDisregardEdit: disregardEdit('asset'), updateId: setAssetId, isSaving: isSaving })] })] })] }));
};
