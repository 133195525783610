import { jsx as _jsx } from "react/jsx-runtime";
import escapeHTML from 'escape-html';
import { useMemo } from 'react';
export const SafeHtml = function ({ as: As = 'div', children, value = children, ignoreNl, escapeHtml }) {
    const __html = useMemo(() => {
        let html = escapeHtml ? escapeHTML(value) : value;
        return ignoreNl ? html : html.replace(/\n/g, '<br />');
    }, [value, ignoreNl, escapeHtml]);
    return _jsx(As, { dangerouslySetInnerHTML: { __html } });
};
