import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, DateType, SelectType } from "@f2w/form";
import { usePortfolio } from "Components/Base/PortfolioContext";
import { translate } from "Services/App";
import { useEffect, useMemo, useRef, useState } from "react";
import { UseStyled } from "@fw/theme";
import { css } from "styled-components";
import ContractServices from "Pages/Contract/services/contractServices";
const WarningBlock = UseStyled.styled('div', ({ theme: { palette } }) => css `
    display: flex;
    padding: 16px;
    margin-bottom: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: ${palette.warning.$50};
    color: ${palette.warning.$500};
`);
export class ReAssignCustomerDocumentType extends AbstractFormType {
    properties = [];
    buildings = [];
    rentalObjects = [];
    contracts = [];
    _useField(props) {
        super._useField(props);
        const portfolio = usePortfolio();
        const areContractsLoaded = useRef(false);
        const [contracts, setContracts] = useState([]);
        useEffect(() => {
            void ContractServices.getContractsForDropdown().then(data => {
                areContractsLoaded.current = true;
                setContracts(data);
            });
        }, []);
        this.properties = useMemo(() => {
            return portfolio.getProperties()
                .map(p => ({
                label: p.name,
                value: p.id
            }));
        }, []);
        this.buildings = useMemo(() => {
            if (!this.field.value.propertyId) {
                return [];
            }
            return portfolio
                .getBuildingsByPropertyId(this.field.value.propertyId)
                .map(b => ({
                label: b.name,
                value: b.id
            }));
        }, [this.field.value.propertyId]);
        this.rentalObjects = useMemo(() => {
            if (!this.field.value.buildingId) {
                return [];
            }
            return portfolio
                .getRentalObjectsByBuildingId(this.field.value.buildingId)
                .map(ro => ({
                label: ro.name,
                value: ro.id
            }));
        }, [this.field.value.buildingId]);
        this.contracts = useMemo(() => {
            if (!this.field.value.rentalObjectId) {
                return [];
            }
            return contracts
                .filter(c => c.rentalObjectId === this.field.value.rentalObjectId)
                .map(c => ({
                label: `${DateType.format(c.starts)}
                    - ${c.ends ? DateType.format(c.ends) : translate('contract.details.unlimited')}
                    - ${c.contractParties.map(cp => cp.name).join(', ')}`,
                value: c.id
            }));
        }, [this.field.value.rentalObjectId, contracts]);
        useEffect(() => {
            if (this.field.value.buildingId && portfolio.getBuildingById(this.field.value.buildingId).propertyId !== this.field.value.propertyId) {
                this.children.buildingId.field.setValue(undefined);
                this.children.rentalObjectId.field.setValue(undefined);
                this.children.contractId.field.setValue(undefined);
            }
        }, [this.field.value.propertyId]);
        useEffect(() => {
            if (this.field.value.rentalObjectId && portfolio.getRentalObjectById(this.field.value.rentalObjectId)?.buildingId !== this.field.value.buildingId) {
                this.children.rentalObjectId.field.setValue(undefined);
                this.children.contractId.field.setValue(undefined);
            }
        }, [this.field.value.buildingId]);
        useEffect(() => {
            if (!areContractsLoaded.current) {
                return;
            }
            if (this.field.value.contractId && contracts.find(c => c.id === this.field.value.contractId)?.rentalObjectId !== this.field.value.rentalObjectId) {
                this.children.contractId.field.setValue(undefined);
            }
        }, [this.field.value.rentalObjectId, contracts]);
    }
    buildFields() {
        return {
            propertyId: new SelectType({
                label: translate('property'),
                clearable: true,
                searchable: true,
                choiceOptions: () => this.properties,
                required: false
            }),
            buildingId: new SelectType({
                label: translate('building.title'),
                clearable: true,
                searchable: true,
                choiceOptions: () => this.buildings,
                isVisible: () => this.field.value.propertyId !== null,
                required: false
            }),
            rentalObjectId: new SelectType({
                label: translate('rentalObject'),
                clearable: true,
                searchable: true,
                choiceOptions: () => this.rentalObjects,
                isVisible: () => this.field.value.buildingId !== null,
                required: false
            }),
            contractId: new SelectType({
                label: translate('contract'),
                clearable: true,
                searchable: true,
                choiceOptions: () => this.contracts,
                isVisible: () => this.field.value.rentalObjectId !== null,
                required: false
            })
        };
    }
    _renderWidget(iProps) {
        return (_jsxs(_Fragment, { children: [_jsx(WarningBlock, { children: translate('customerDocuments.reAssign.warning') }), super._renderWidget(iProps)] }));
    }
}
