import { generate } from 'Services/Router';
import client from 'Utils/client';
export var ContractNotice;
(function (ContractNotice) {
    ContractNotice.getStepNotice = (contractId, date) => client
        .get(generate('api.contract.nextOfficialNoticeDate', { contract: contractId, date: date }))
        .then((response) => response.data);
    ContractNotice.save = (contractId, data) => client
        .patch(generate('api.contracts.terminate', { contract: contractId }), data)
        .then((response) => { });
})(ContractNotice || (ContractNotice = {}));
export default ContractNotice;
