import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { translate } from 'Services/Translator';
import moment from 'moment';
import { formatAmount } from 'Services/NumberHelper';
import { CalculationServices } from '../services';
import { DataTable } from '@f2w/data-table';
import { FlexRow, Input, Td, Tr } from 'Components/Atoms';
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
const BaseTable = DataTable.factory()(() => ({
    tableId: 'CalculationFees',
    manualControl: false,
    showEmpty: true,
    useGlobalFilter: {},
    useRowState: {
        initialRowStateAccessor: (...args) => {
            return {};
        },
    },
    usePagination: {},
    initialState: {
        pageSize: 50
    },
    RowActions: ({ row: { original }, openFinancialAccountStatement }) => {
        return _jsx(ActionsDropdown, { id: original.id, quickActions: [
                {
                    icon: 'eye',
                    title: translate('ancillaryExpenseCalculation.accountStatement'),
                    onClick: () => openFinancialAccountStatement(original.id)
                },
            ] });
    },
    columns: {
        rentalObject: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.rentalObject'),
            accessor: v => v.rentalObject.name,
            Cell: ({ value }) => value,
        },
        rentalObjectType: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.rentalObject.type'),
            accessor: v => translate(`rentalObjectType.${v.rentalObjectType}`),
            Cell: ({ value }) => _jsx(_Fragment, { children: value }),
        },
        tenants: {
            Header: translate('ancillaryExpenseCalculation.floatingAndFlatFees.tenants'),
            accessor: v => v.tenants.map(tenant => tenant.name).join(' & '),
            Cell: ({ value }) => _jsx("div", { children: value }),
        },
        activeContractStartDate: {
            Header: translate('contract.details.period'),
            Cell: ({ row: { original: { activeContractStartDate, activeContractEndDate } } }) => {
                if (!activeContractStartDate) {
                    return translate('notAvailable');
                }
                return moment(activeContractStartDate).format('DD.MM.YYYY') + ' – '
                    + (activeContractEndDate ? moment(activeContractEndDate).format('DD.MM.YYYY') : '∞');
            }
        },
        ancillaryExpenseFloatingAmount: {
            Header: _jsx(FlexRow, { w: '100%', justifyFlexEnd: true, children: translate('ancillaryExpenseCalculation.floatingAndFlatFees.floatingAmount') }),
            width: 80,
            Cell: ({ value, editMode, updateApi, row: { id } }) => {
                const { ancillaryExpenseFloatingAmount = value, } = updateApi.getData(id);
                if (!editMode) {
                    return _jsx(FlexRow, { justifyFlexEnd: true, children: ancillaryExpenseFloatingAmount !== undefined && ancillaryExpenseFloatingAmount !== null
                            ? formatAmount(ancillaryExpenseFloatingAmount)
                            : translate('notAvailable') });
                }
                return (_jsx(FlexRow, { w: '100%', justifyFlexEnd: true, children: _jsx(Input, { type: 'number', step: 0.01, "$size": 'sm', prefix: 'CHF', value: ancillaryExpenseFloatingAmount ?? 0, onChange: (value) => {
                            updateApi.update(id, {
                                ancillaryExpenseFloatingAmount: value
                            });
                        } }) }));
            }
        }
    },
    Footer: ({ data }) => {
        const ancillaryExpenseFloatingAmountSum = data
            .map(row => row.ancillaryExpenseFloatingAmount)
            .reduce((a, b) => a + b, 0);
        return (_jsxs(Tr, { children: [_jsx(Td, { colSpan: 5 }), _jsx(Td, { align: 'right', children: _jsx("strong", { children: formatAmount(ancillaryExpenseFloatingAmountSum) }) })] }));
    }
}));
export const CalculationFeesTable = Object.assign(BaseTable, {
    use: ({ calculationId, calculationServices = CalculationServices, reloadDistributions, setEditMode, editMode, openFinancialAccountStatement }) => {
        const [data, setData] = React.useState([]);
        const [floatingAncillaryAccountId, setFloatingAncillaryAccountId] = useState();
        const loadData = React.useCallback(() => {
            table.setLoading(true);
            calculationServices.listCalculationFees(calculationId)
                .then(response => {
                setData(response.results);
                setFloatingAncillaryAccountId(response.floatingAncillaryAccountId);
            })
                .catch(e => {
                console.error(e);
            })
                .finally(() => {
                table.setLoading(false);
            });
        }, []);
        React.useEffect(() => {
            loadData();
        }, []);
        const table = BaseTable.useCreateTable({
            data: data,
            refresh: () => loadData(),
            calculationServices,
            calculationId,
            reloadDistributions,
            openFinancialAccountStatement: (contractId) => openFinancialAccountStatement(contractId, floatingAncillaryAccountId),
            setEditMode,
            editMode
        });
        return table;
    },
});
