import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Transition from 'react-transition-group/Transition';
import React from 'react';
import { transitionEndListener } from 'Components/Transition';
import { map } from 'Components/Base/ElementChildren';
import { ariaAttr, cssVars, toPx } from '@fw/theme';
import { setDebugVariable } from '@f2w/utils';
import styled from 'styled-components';
import { CarouselItem } from './components/CarouselItem';
import { useCarousel } from './useCarousel';
import * as Slots from './components/CarouselSlots';
import { carouselSlot } from './components/CarouselSlots';
const CarouselRoot = styled.div `
  && {
    --fw-carousel-icon--opacity: 0;

    &:is(:hover, :focus, :focus-within) {
      --fw-carousel-icon--opacity: .7;
    }
  }
`;
const prefix = 'fw-carousel';
const BaseCarousel = React.forwardRef(function CarouselFunc({ as: Component = CarouselRoot, prevIcon, nextIcon, className, fullscreen, height = fullscreen ? undefined : 267, ...uncontrolledProps }, ref) {
    const manager = useCarousel(uncontrolledProps, ref);
    setDebugVariable({ carousel: manager });
    const { children, activeIndex, hasControls, hasIndicators, slide: transition, rootProps, renderActions, } = manager;
    const gProps = { manager };
    const actions = renderActions?.(activeIndex);
    return (_jsxs(Component, { ...carouselSlot.attr('root'), "data-fullscreen": fullscreen, ...rootProps, className: classNames(className, {
            [`fade`]: !transition,
            [`${prefix}-fade`]: !transition
        }), style: cssVars({
            '--fw-carousel--height': height ? toPx(height) : undefined
        }), children: [actions && (_jsx("div", { ...carouselSlot.attr('actions'), children: actions })), hasIndicators && (_jsx(Slots.Indicators, { ...gProps })), hasControls && (_jsx(Slots.Controls, { ...gProps, prevIcon: prevIcon, nextIcon: nextIcon })), _jsx("div", { ...carouselSlot.attr('inner'), children: map(children, (child, index) => {
                    const isActive = index === activeIndex;
                    if (!transition) {
                        return React.cloneElement(child, {
                            ...gProps,
                            'aria-current': ariaAttr(isActive),
                            className: classNames(child.props.className, {
                                'active': isActive,
                            }),
                        });
                    }
                    return _jsx(Transition, { in: isActive, ...(isActive ? {
                            onEnter: transition.onEnter,
                            onEntered: transition.onEntered,
                        } : undefined), addEndListener: transitionEndListener, children: (status) => React.cloneElement(child, {
                            ...gProps,
                            'aria-current': ariaAttr(status === 'entered' || status === 'exiting'),
                            'data-transition': isActive && status !== 'entered' ? transition.direction : undefined,
                            'data-slide': status === 'entering' || status === 'exiting' ? transition.slideDirection : undefined,
                        }) });
                }) })] }));
});
BaseCarousel.displayName = 'Carousel';
BaseCarousel.defaultProps = {
    defaultActiveIndex: 0,
};
const blocks = {
    Root: BaseCarousel,
    Item: CarouselItem,
    ...Slots
};
for (let [key, val] of Object.entries(blocks)) {
    BaseCarousel[key] = val;
}
export const Carousel = BaseCarousel;
