import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useNavigate, useResolvedPath, useSearchParams } from 'react-router-dom';
export const withParams = (path, options) => {
    const url = new URL(path ?? '', window.location.origin);
    const { paramsToKeep = ['_for'], searchParams: params = new URLSearchParams(window.location.search), } = { ...options };
    paramsToKeep?.forEach((param) => {
        params.has(param) && url.searchParams.set(param, params.get(param));
    });
    options?.referer && url.searchParams.set('referer', options?.referer);
    return `${url.pathname}${url.search}`;
};
export const useResolvedPathWithParams = (path, paramsToKeep) => {
    const [searchParams] = useSearchParams();
    const { pathname } = useResolvedPath(path);
    return withParams(pathname, { searchParams, paramsToKeep });
};
export const useNavigateWithParams = (paramsToKeep) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    return (path, options = {}) => {
        const url = withParams(path, { searchParams, paramsToKeep });
        navigate(url, options);
    };
};
export function NavigateWithParams({ to, ...props }) {
    const withParams = useResolvedPathWithParams(to);
    return _jsx(Navigate, { to: withParams, ...props });
}
