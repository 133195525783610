import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from 'Services/Translator';
import { Div, Td, Text, Tr } from 'Components/Atoms';
import { formatAmount } from 'Services/NumberHelper';
import { CalculationServices } from '../services';
import { DataTable } from '@f2w/data-table';
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
const GroupedTable = DataTable.factory()(() => ({
    tableId: 'CalculationAccount',
    manualControl: false,
    showEmpty: true,
    usePagination: {},
    useGroupBy: {
        initialState: { groupBy: ['name'] }
    },
    useRowState: {
        initialRowStateAccessor: (...args) => {
            return {};
        },
    },
    RowActions: ({ row: { id, original }, onViewFinancialAccount }) => {
        if (isNaN(parseInt(id))) {
            return _jsx(Div, { w: 5 });
        }
        return _jsx(Div, { w: 5, children: _jsx(ActionsDropdown, { id: id, quickActions: [
                    {
                        icon: 'eye',
                        title: translate('dunning.accountStatement'),
                        onClick: () => onViewFinancialAccount(original.financialAccountId)
                    },
                ] }) });
    },
    columns: {
        name: {
            accessor: d => d.account.name,
            Header: () => translate('ancillaryExpenseCalculation.expenses.type'),
            useSortBy: {},
            Cell: ({ value }) => {
                return _jsx(Text, { value: value });
            }
        },
        financialAccount: {
            accessor: d => d.displayName,
            Header: () => translate('ancillaryExpenseAccount.financialAccount'),
            width: 500,
            useSortBy: {},
            aggregate: 'uniqueCount',
            Aggregated: _jsx(_Fragment, {}),
        },
        amount: {
            Header: () => translate('amount'),
            style: { textAlign: 'right' },
            useSortBy: {},
            aggregate: 'sum',
            Aggregated: ({ value }) => formatAmount(value),
            Cell: ({ value }) => value !== undefined && value !== null
                ? formatAmount(value)
                : translate('notAvailable'),
        },
        $rowExpand: {},
    },
    Footer: ({ data }) => {
        const amountSum = data
            .map(row => row.amount)
            .reduce((a, b) => a + b, 0);
        return (_jsxs(Tr, { children: [_jsx(Td, { colSpan: 3 }), _jsx(Td, { align: 'right', children: _jsx("strong", { children: formatAmount(amountSum) }) }), _jsx(Td, {})] }));
    }
}));
export const CalculationAccountTable = Object.assign(GroupedTable, {
    use: ({ calculationId, calculationServices = CalculationServices, reloadDistributions, onViewFinancialAccount, editMode, setEditMode, }) => {
        return GroupedTable.useTable({
            calculationServices,
            calculationId,
            reloadDistributions,
            onViewFinancialAccount,
            editMode,
            setEditMode,
        }, {
            getData: async () => {
                const results = await calculationServices.listCalculationAccounts(calculationId) ?? [];
                return {
                    results: convertData(results)
                };
            }
        });
    }
});
const convertData = (data) => {
    const newData = [];
    data.forEach(({ id, account, financialAccounts, keyVariation, }) => {
        financialAccounts.forEach(({ id: financialAccountId, displayName, amount, }) => {
            newData.push({
                id: `${id}-${financialAccountId}`,
                expenseId: id,
                displayName,
                account,
                financialAccountId,
                keyVariation,
                amount,
            });
        });
    });
    return newData;
};
