import client from "Utils/client";
import generateRoute from "Services/Router";
export const getAllPresetAccounts = () => {
    const route = generateRoute('api.ancillaryExpenses.presetAccounts');
    return client
        .get(route)
        .then(res => res.data);
};
export const createAccountsUsingPresetForProperty = (propertyId, presetAccountIds) => {
    const route = generateRoute('api.ancillaryExpenses.createUsingPresetAccounts');
    return client
        .post(route, { propertyId, presetAccountIds });
};
