import { jsx as _jsx } from "react/jsx-runtime";
import { FormControl } from 'Atoms/Input';
import { BaseSimpleType, DateValueType, SimpleValue } from '../../core';
import { OrFunction } from '@f2w/utils';
export class DateType extends BaseSimpleType {
    constructor(options) {
        super(options);
    }
    _createValue(props) {
        return new SimpleValue(this, props);
    }
    _getSpecs() {
        return new DateValueType({
            defaultOptions: () => ({
                type: 'day',
            }),
        });
    }
    _renderForm(valueType, props) {
        return super._renderForm(valueType, {
            ...props,
        });
    }
    _renderWidget({ field: valueType, ...props }) {
        const controlProps = {
            type: valueType.options.type,
            variant: valueType.options.variant,
            minDate: valueType.options.min,
            maxDate: valueType.options.max,
            includeDates: valueType.options.includeDates,
            includeDateIntervals: valueType.options.includeDateIntervals,
            excludeDates: valueType.options.excludeDates,
            excludeDateIntervals: valueType.options.excludeDateIntervals,
            placeholder: valueType.options.placeholder,
            inline: valueType.options.inline,
            value: valueType.value,
            selectsStart: valueType.options.selectsStart,
            selectsEnd: valueType.options.selectsEnd,
            startDate: OrFunction(valueType.options.startDate, valueType),
            endDate: OrFunction(valueType.options.endDate, valueType),
            isNullable: true,
            ...OrFunction(valueType.options.props, valueType),
        };
        if (controlProps.selectsStart) {
            controlProps.startDate = controlProps.value;
        }
        if (controlProps.selectsEnd) {
            controlProps.endDate = controlProps.value;
        }
        return _jsx(FormControl.DatePicker, { ...props, ...controlProps });
    }
    static start({ end: _endFn, onChange, ...options }) {
        return new DateType({
            ...options,
            selectsStart: true,
            onChange: (_date, ...args) => {
                const date = DateValueType.parseMoment(_date.value);
                const _end = _endFn(_date);
                if ((date && _end.value) && date.isAfter(_end.value))
                    _end.reset(_date.value, true, true);
                _end.updateOptions({ startDate: _date.value });
                onChange && onChange(_date, ...args);
            }
        });
    }
    static end({ start: _startFn, onChange, ...options }) {
        return new DateType({
            ...options,
            selectsEnd: true,
            onChange: (_date, ...args) => {
                const date = DateValueType.parseMoment(_date.value);
                const _start = _startFn(_date);
                if ((date && _start.value) && date.isBefore(_start.value))
                    _start.reset(_date.value, true, true);
                _start.updateOptions({ endDate: _date.value });
                onChange && onChange(_date, ...args);
            }
        });
    }
}
