import { translate } from 'Services/Translator';
import { handleResponseError } from 'Utils/client';
import { isObject, isString, printValue, toArray } from '@f2w/utils';
import dom from 'dom-helpers';
export class FormError extends Error {
    static VALIDATION = 'validation';
    static SERVER = 'server';
    type;
    isFormError = true;
    constructor(message) {
        super();
        let props = isObject(message) ? message : { message };
        this.name = "FormError";
        this.message = `${message}`;
        this.code = props?.code;
        this.type = props?.type;
        this.errors = props?.errors;
        Object.setPrototypeOf(this, FormError.prototype);
    }
    static create(props) {
        return new FormError(props);
    }
    static server(message) {
        return new FormError({
            type: 'server',
            message,
        });
    }
    static validation(errors) {
        return new FormError({
            type: 'validation',
            errors,
        });
    }
    static handleError(props) {
        const setFormErrors = (errors) => {
            props?.setError?.(errors);
            if (errors) {
                const firstError = dom.querySelectorAll(document.body, '[aria-invalid=true]').shift();
                firstError?.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        };
        handleResponseError(props.error, {
            dispatcher: props.dispatcher,
            defaultMessage: props?.defaultMessage ?? translate('form.response.error'),
            statusMessages: {
                422: res => {
                    res.data?.message && props.setStatus?.({ error: res.data.message });
                    return '';
                },
                400: res => {
                    typeof res.data?.errors && setFormErrors(res.data.errors);
                    return translate('form.response.error.fields');
                },
            },
            onFormError: (error) => {
                error.errors && setFormErrors(error.errors);
                error.message && props.setStatus?.({ error: error.message });
            },
            onError: (err) => {
                props.dispatcher?.error?.({
                    message: translate('form.response.error'),
                });
            },
        });
        return false;
    }
}
(function (FormError) {
    const Types = [FormError.SERVER, FormError.VALIDATION];
})(FormError || (FormError = {}));
let strReg = /\$\{\s*(\w+)\s*\}/g;
export class ValidationError extends Error {
    value;
    path;
    type;
    errors;
    params;
    inner;
    static formatError(message, params) {
        const path = params.label || params.path || 'this';
        if (path !== params.path)
            params = { ...params, path };
        if (typeof message === 'string')
            return message.replace(strReg, (_, key) => printValue(params[key]));
        if (typeof message === 'function')
            return message(params);
        return message;
    }
    static isError(err) {
        return err && (err?.name === 'ValidationError' || err instanceof ValidationError);
    }
    static createError(error) {
        if (ValidationError.isError(error))
            return error;
        if (isString(error))
            return new ValidationError(error);
    }
    static throwError(error) {
        if ((error = ValidationError.createError(error)))
            throw error;
    }
    constructor(errorOrErrors, value, field, type) {
        super();
        this.name = 'ValidationError';
        this.value = value;
        this.path = field;
        this.type = type;
        this.errors = [];
        this.inner = [];
        toArray(errorOrErrors).forEach((err) => {
            if (ValidationError.isError(err)) {
                this.errors.push(...err.errors);
                this.inner = this.inner.concat(err.inner.length ? err.inner : err);
            }
            else {
                this.errors.push(err);
            }
        });
        this.message =
            this.errors.length > 1
                ? `${this.errors.length} errors occurred`
                : this.errors[0];
        if (Error.captureStackTrace)
            Error.captureStackTrace(this, ValidationError);
    }
}
