import { AbstractFormType, BooleanType, DateType } from "@f2w/form";
import { PortfolioSelectType } from "Pages/Rent/forms/PortfolioSelectType";
import { translate } from "Services/Translator";
import { hasProductFeature, ProductFeatureType } from "Services/ProductFeatureManager";
export class RenterMirrorDateExportFormType extends AbstractFormType {
    _useField(props) {
        super._useField(props);
        if (this.field.value.type !== 'excel') {
            this.children.withRentPotential.options.isVisible = () => false;
        }
    }
    buildFields() {
        const hasRentPotentialFeature = hasProductFeature(ProductFeatureType.RENT_POTENTIAL_CALCULATOR);
        return {
            date: new DateType({
                label: translate('accounting.reportingDate'),
            }),
            portfolio: new PortfolioSelectType({
                label: false,
                inheritData: true,
            }),
            anonymized: new BooleanType({
                label: translate('rent.anonymized'),
                required: false,
                defaultValue: false
            }),
            vacancyOnly: new BooleanType({
                label: translate('rent.vacancyOnly'),
                required: false,
                defaultValue: false
            }),
            withRentPotential: new BooleanType({
                label: translate('rent.withRentPotential'),
                required: false,
                defaultValue: false,
                help: !hasRentPotentialFeature ? translate('rent.withRentPotentialDisabled') : undefined,
                disabled: !hasRentPotentialFeature
            }),
        };
    }
}
