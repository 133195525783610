import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Div, FlexCol, FlexRow } from 'Atoms/Layout';
import { Title } from 'Atoms/Text';
import { Nav } from 'Atoms/Nav';
import { WizardLayout } from 'Components/Layout';
import { OrFunction } from '@f2w/utils';
import { Icon } from 'Atoms/Icon';
import { defaultTemplate } from './defaultTemplate';
export const testTemplate = {
    ...defaultTemplate,
    Base: ({ children, config }) => {
        return _jsx(_Fragment, { children: _jsx(Div, { b: '1px solid blue', m: 10, p: 10, bg: '#fff', children: children || _jsx(_Fragment, { children: "loading..." }) }) });
    },
    Step: ({ wizard, step, children }) => {
        return _jsx(_Fragment, { children: _jsxs(FlexCol, { gap: 8, children: [_jsx(Title.H2, { h6: true, neutral: 900, children: step.title() }), children] }) });
    },
    Root: ({ config, wizard, loading, children, }) => {
        const loaded = !!wizard;
        return _jsxs(Div, { b: '1px solid red', p: 40, children: [_jsx(WizardLayout.Head, { children: _jsxs(Title.H6, { neutral: 500, children: [loading && _jsx(Icon, { icon: 'spinner' }), " ", OrFunction(config.title) ?? 'Default view title'] }) }), !wizard
                    ? _jsxs("p", { children: ["Loading wizard ", _jsx("b", { children: config.id }), "..."] })
                    : (_jsx(FlexCol, { alignStretch: true, children: _jsxs(FlexRow, { children: [_jsx(Div, { w: 200, children: _jsx(Nav, { variant: 'pills', position: 'left', children: wizard.stepList.map((step) => {
                                            return (_jsx(Nav.Item, { children: _jsx(Link, { to: step.id, relative: 'route', children: step.id }) }, `step-${step.id}}`));
                                        }) }) }), _jsx(Div, { flex: 1, children: children })] }) }))] });
    },
};
