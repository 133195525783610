import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { ContractDataTable } from 'Pages/Contract/components/ContractDataTable';
import { RentPotentialModal } from 'Pages/Contract/components/RentPotentialModal';
import ContractServices from 'Pages/Contract/services/contractServices';
import { AutomaticInvoicingModal } from 'Pages/Contract/components/AutomaticInvoicingModal';
import * as AutoInvoicingServices from 'Pages/Contract/services/autoInvoicingServices';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { hasProductFeature, ProductFeatureType } from 'Services/ProductFeatureManager';
import { useToastDispatcherApi } from "Components/Dispatcher";
import { translate } from "Services/App";
import { generate } from "Services/Router";
import { useCreateAutomaticInvoicesForm } from "Pages/AutoInvoicing/hooks/useCreateAutomaticInvoicesForm";
import { generateAutoInvoiceForPeriods } from "Pages/Contract/services/autoInvoicingServices";
import { FormModal } from "@f2w/form";
export const ContractPage = ({ openDetailsForContract, filters, contractServices = ContractServices, autoInvoicingServices = AutoInvoicingServices, showAncillaryWarning }) => {
    const { openViewModalObj } = useObjectViewModalContext();
    const [rentId, setRentId] = React.useState();
    const [contractId, setContractId] = React.useState();
    const [invoices, setInvoices] = React.useState();
    const { warning } = useToastDispatcherApi();
    useEffect(() => {
        if (showAncillaryWarning) {
            warning({
                message: translate("contract.create.ancillaryCalculationWarning")
            });
        }
    }, []);
    React.useEffect(() => {
        if (openDetailsForContract) {
            openViewModalObj({
                id: openDetailsForContract.id,
                view: ActiveView.CONTRACT,
                parentRentalObjectId: openDetailsForContract.rentalObjectId
            });
        }
    }, [openDetailsForContract]);
    const downloadAutomaticInvoicesForm = useCreateAutomaticInvoicesForm({
        onClose: () => undefined,
        selectedPeriodIds: [],
        generateInvoices: generateAutoInvoiceForPeriods,
        invoices: invoices
    });
    const onInvoiceGenerated = (invoices) => {
        setInvoices(invoices);
        downloadAutomaticInvoicesForm.openForm();
    };
    return (_jsxs(_Fragment, { children: [_jsx(ContractDataTable, { filters: filters, contractServices: contractServices, setRentId: setRentId, setContractId: setContractId, displayRentPotential: hasProductFeature(ProductFeatureType.RENT_POTENTIAL_CALCULATOR) }), _jsx(RentPotentialModal, { referrer: generate('contracts.overview'), rentId: rentId, onCloseModal: () => setRentId(undefined), getRentPotentialForRentId: contractServices.getRentPotentialForRentId }), _jsx(AutomaticInvoicingModal, { contractId: contractId, onCloseModal: () => setContractId(undefined), onInvoiceGenerated: onInvoiceGenerated, autoInvoicingServices: autoInvoicingServices }), _jsx(FormModal, { modal: downloadAutomaticInvoicesForm, size: { maxWidth: 500 } })] }));
};
