import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DateValueType, DecimalType } from '@f2w/form-new';
import { translate } from 'Services/App';
import { useEffect, useMemo } from 'react';
import { Div, FlexCol, FlexRow } from 'Atoms/Layout';
import { Text } from 'Atoms/Text';
import { ContractModificationWizardType } from '../types/ContractModificationWizardType';
import { Callout } from 'Atoms/Callout';
import { Icon } from 'Atoms/Icon';
import { RadioButtonGroup } from 'Components/Molecules/RadioButtonGroup';
import { roundToNearestPointX, roundToPrecision } from 'Utils/number';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
import { Checkbox } from 'Atoms/Input/controls';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { useWizardContext } from '@f2w/view';
import { Button } from 'Atoms/Button';
import { SpinnerNew } from 'Atoms/Spinner';
export const SelectionStepTemplate = ({ field }) => {
    return (_jsxs(FlexCol, { gap: 20, children: [_jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('contract.selection') }), _jsx(Text, { medium: 400, neutral: 500, children: translate('contract.selection.help') })] }), _jsxs(FlexCol, { children: [field.children.propertyId.render(), field.children.buildingId.render(), field.children.rentalObjectId.render(), field.children.contractId.render(), field.children.validFrom.render()] }), _jsxs(FlexCol, { gap: 20, p: 24, b: "1px solid #D7D8DB", brd: 8, children: [_jsxs(FlexCol, { gap: 4, children: [_jsx(Text, { body: 500, neutral: 900, children: _jsx("strong", { children: translate('contract.modification') }) }), _jsx(Text, { medium: 400, neutral: 500, children: translate('contract.modification.help') })] }), _jsxs(FlexCol, { children: [field.children.netRentChanges.render(), field.children.ancillaryExpenseChanges.render(), field.children.otherChanges.render()] })] })] }));
};
export const RentStepTemplate = ({ field }) => {
    const _rent = ContractModificationWizardType.getRentStep(field);
    const { validFrom, contractId } = ContractModificationWizardType.getSelectionStepData(field);
    const { currentRent } = ContractModificationWizardType.getRentStepData(field);
    useEffect(() => {
        _rent._type.onLoad(_rent, contractId);
    }, []);
    if (!_rent.value.currentRent) {
        return _jsx(SpinnerNew, { alignItems: "center", justifyContent: "center" });
    }
    return (_jsxs(FlexCol, { gap: 20, children: [_jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('rentModification.title') }), _jsx(Text, { medium: 400, neutral: 500, children: translate('rentModification.title.help') })] }), !!currentRent && _jsxs(FlexCol, { gap: 16, children: [_jsx(RentPotential.Widget, { field: field }), currentRent?.reservation && (_jsx(_Fragment, { children: _jsx(Callout, { icon: 'alert-circle', type: 'neutral', message: _jsxs(_Fragment, { children: [_jsx(Text, { small: 500, neutral: 500, children: translate('contract.rent.previousReservation', {
                                            date: DateValueType.format(validFrom),
                                        }) }), _jsx("br", {}), _jsx(Text, { children: currentRent.reservation })] }) }) })), _jsxs(FlexCol, { p: 24, gap: 16, b: "1px solid #D7D8DB", brd: 8, children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { medium: 500, neutral: 900, children: translate('contract.rent.existingNetRent') }), _jsx(Text, { medium: 400, neutral: 900, children: DecimalType.format(currentRent.netAmount) })] }), _jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsxs(FlexRow, { gap: 5, children: [_jsx(Text, { medium: 500, neutral: 900, children: translate('contract.rent.adjustment') }), _jsx(OverlayTrigger, { overlay: _jsx(Tooltip, { id: "rent-adjustment-help", children: translate('contract.rent.adjustment.help') }), children: _jsx(Icon, { icon: "help-circle", size: 20 }) })] }), _jsx(FlexRow, { contentFlexEnd: true, alignCenter: true, children: field.children.adjustmentAmount.render({
                                            props: {
                                                style: { textAlign: 'right' },
                                            },
                                            style: { width: '160px' },
                                        }) })] }), _jsxs(FlexRow, { flex: '0 0 30%', alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { medium: 500, neutral: 900, children: translate('contract.rent.newNetRent') }), _jsx(Text, { medium: 400, neutral: 900, children: DecimalType.format(currentRent.netAmount + (field.value.adjustmentAmount ?? 0.0)) })] })] })] }), _jsxs("div", { children: [field.children.reason.render({
                        props: {
                            style: { height: 100 },
                        }
                    }), field.children.reservation.render(), _jsxs(FlexRow, { gap: 8, children: [_jsx(Text, { medium: 400, neutral: 900, children: translate('contract.rent.hasSubsidies') }), _jsxs(FlexRow, { gap: 16, children: [_jsxs(FlexRow, { gap: 8, alignCenter: true, children: [_jsx(Checkbox, { onChange: () => field.children.hasSubsidies.updateValue(true, true, false), checked: field.value.hasSubsidies, radio: true, name: 'has_subsidies' }), " ", translate('yes')] }), _jsxs(FlexRow, { gap: 8, alignCenter: true, children: [_jsx(Checkbox, { onChange: () => field.children.hasSubsidies.updateValue(false, true, false), checked: !field.value.hasSubsidies, radio: true, name: 'has_subsidies' }), " ", translate('no')] })] })] })] })] }));
};
export const RentChangeTemplate = ({ current, potential, possible, children, isActive }) => (_jsx(_Fragment, { children: _jsxs(FlexCol, { gap: 10, style: { opacity: isActive ? 1 : .7, transition: 'opacity .3s ease-in-out' }, children: [children, _jsxs(FlexRow, { children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, flex: '0 0 80%', children: [_jsxs(FlexRow, { gap: 16, pl: 35, alignCenter: true, children: [_jsx(Div, { children: _jsx(Text, { medium: 400, neutral: 900, children: current }) }), isActive && _jsxs(_Fragment, { children: [_jsx(Icon, { icon: "arrow-right", size: 20 }), _jsx(Text, { medium: 400, primary: 500, children: potential })] })] }), isActive && _jsxs(Text, { medium: 400, neutral: 900, children: [DecimalType.format(possible.percent), "%"] })] }), isActive && _jsx(FlexRow, { justifyEnd: true, alignCenter: true, flex: "0 0 20%", children: _jsx(Text, { medium: 400, neutral: 900, children: DecimalType.format(possible.amount) }) })] })] }) }));
var RentPotential;
(function (RentPotential) {
    function Widget({ field }) {
        const fields = field.children;
        const data = field.value;
        return (_jsx(_Fragment, { children: _jsxs(FlexCol, { b: '1px solid #D7D8DB', brd: 8, children: [_jsx(Div, { p: 24, bb: '1px solid #D7D8DB', children: fields.useRentPotential.render() }), (data.rentPotential && data.useRentPotential) && _jsxs(_Fragment, { children: [_jsxs(FlexCol, { gap: 20, p: 24, children: [_jsxs("div", { children: [fields.rentPotentialDate.render(), _jsx(NotAvailable, { ...data.rentPotential })] }), data.rentPotentialDate && (_jsxs("div", { children: [fields.useChangeOfReferenceInterest.render(), fields.useInflationIndexChange.render(), fields.useCostIncreaseChange.render()] }))] }), data.rentPotentialDate && (_jsx(Summary, { field: field, data: data.rentPotential }))] })] }) }));
    }
    RentPotential.Widget = Widget;
    function Summary({ field }) {
        const data = ContractModificationWizardType.getRentStepData(field);
        const subtotal = ContractModificationWizardType.RentStep.getSubtotal(field);
        const { roundDifference: _roundDifference, rentPotential: _potential } = data;
        const { formattedRoundDifference, formattedAdjustmentAmount } = useMemo(() => {
            return {
                formattedRoundDifference: DecimalType.format(roundToPrecision((subtotal.amount - roundToNearestPointX(subtotal.amount, _roundDifference)) * -1)),
                formattedAdjustmentAmount: DecimalType.format(roundToNearestPointX(subtotal.amount, _roundDifference)),
            };
        }, [subtotal.amount, _roundDifference]);
        return _jsxs(FlexCol, { p: 24, bt: '1px solid #D7D8DB', gap: 24, children: [_jsxs(FlexRow, { children: [_jsxs(FlexRow, { gap: 16, alignCenter: true, justifySpaceBetween: true, flex: '0 0 80%', children: [_jsx(Text, { medium: 500, neutral: 900, children: translate('contractModification.rent.subTotal') }), _jsxs(Text, { medium: 400, neutral: 900, children: [DecimalType.format(subtotal.percent), "%"] })] }), _jsx(FlexRow, { justifyFlexEnd: true, flex: '0 0 20%', children: _jsx(Text, { medium: 400, neutral: 900, children: DecimalType.format(subtotal.amount) }) })] }), _jsxs(FlexRow, { children: [_jsxs(FlexRow, { gap: 16, justifySpaceBetween: true, alignCenter: true, flex: '0 0 80%', children: [_jsx(Text, { medium: 500, neutral: 900, children: translate('contractModification.rent.roundingDifference') }), _jsx(RadioButtonGroup, { onChange: (value) => {
                                        field.updateValue({
                                            roundDifference: value,
                                            adjustmentAmount: roundToNearestPointX(subtotal.amount, value)
                                        }, true);
                                    }, radioOptions: ContractModificationWizardType.roundDifferenceOptions, selectedValue: _roundDifference })] }), _jsx(FlexRow, { justifyFlexEnd: true, flex: '0 0 20%', children: _jsx(Text, { medium: 400, neutral: 900, children: formattedRoundDifference }) })] }), _jsxs(FlexRow, { children: [_jsx(FlexRow, { gap: 16, flex: '0 0 80%', children: _jsx(Text, { medium: 500, neutral: 900, children: translate('contract.rent.total') }) }), _jsx(FlexRow, { justifyFlexEnd: true, flex: '0 0 20%', children: _jsx(Text, { medium: 400, neutral: 900, children: formattedAdjustmentAmount }) })] })] });
    }
    RentPotential.Summary = Summary;
    function NotAvailable({ potentialRateDate, potentialInflationRateDate }) {
        const showCallout = DateValueType.parseMoment(potentialRateDate)?.isAfter(potentialInflationRateDate);
        if (!showCallout)
            return null;
        return _jsx(Callout, { message: translate('rentModification.consumerPriceIndexNotAvailable') });
    }
    RentPotential.NotAvailable = NotAvailable;
})(RentPotential || (RentPotential = {}));
export const SummaryStepTemplate = ({ field }) => {
    const portfolio = usePortfolio();
    const { validFrom, selectedContract } = ContractModificationWizardType.getSelectionStepData(field);
    const { currentRent, rentPotential, adjustmentAmount, ...date } = ContractModificationWizardType.getRentStepData(field);
    const rentalObjectName = portfolio.getRentalObjectById(selectedContract.rentalObjectId).name;
    const buildingName = portfolio.getBuildingById(selectedContract.buildingId).name;
    const propertyName = portfolio.getPropertyById(selectedContract.propertyId).name;
    const { stepApi } = useWizardContext();
    return (_jsxs(FlexCol, { gap: 40, children: [_jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('rentModification.summary.title') }), _jsx(Text, { medium: 400, neutral: 900, children: translate('rentModification.summary.title.help') })] }), _jsxs(FlexCol, { gap: 16, children: [_jsxs(FlexCol, { gap: 24, p: 24, b: '1px solid #D7D8DB', brd: 8, children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { large: true, neutral: 900, children: translate('contract') }), _jsx(Button.Edit, { "$size": 'sm', onClick: () => {
                                            stepApi.goToStep("selectionStep");
                                        } })] }), _jsxs(FlexCol, { gap: 16, children: [_jsx(SummaryBlockItem, { label: translate('property'), value: propertyName }), _jsx(SummaryBlockItem, { label: translate('building.title'), value: buildingName }), _jsx(SummaryBlockItem, { label: translate('rentalObject'), value: rentalObjectName }), _jsx(SummaryBlockItem, { label: translate('contract.from'), value: DateValueType.format(selectedContract.starts) }), _jsx(SummaryBlockItem, { label: translate('contractModification.validFrom'), value: DateValueType.format(validFrom) })] })] }), _jsxs(FlexCol, { gap: 24, p: 24, b: '1px solid #D7D8DB', brd: 8, children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { large: true, neutral: 900, children: translate('contract.details.rent.netAmount') }), _jsx(Button.Edit, { "$size": 'sm', onClick: () => {
                                            stepApi.goToStep("rentStep");
                                        } })] }), _jsxs(FlexCol, { gap: 16, children: [rentPotential && (_jsxs(_Fragment, { children: [date.useChangeOfReferenceInterest && (_jsx(SummaryBlockItem, { label: translate('contract.rent.changeOfReferenceInterest'), value: DecimalType.format(rentPotential.possibleReferenceInterestAmount) })), date.useInflationIndexChange && (_jsx(SummaryBlockItem, { label: translate('contract.rent.inflationIndexChange'), value: DecimalType.format(rentPotential.possibleInflationAmount) })), date.useCostIncreaseChange && (_jsx(SummaryBlockItem, { label: `${translate('rent-potential-calculator.cost-increase')}: ${DecimalType.format(rentPotential.yearlyFlatRatePercent)}% ${translate('perYear')}`, value: DecimalType.format(rentPotential.costIncreaseAmount) })), _jsx("hr", {})] })), _jsx(SummaryBlockItem, { label: translate('contract.rent.existingNetRent'), value: DecimalType.format(currentRent.netAmount) }), _jsx(SummaryBlockItem, { label: translate('contract.rent.adjustment'), value: DecimalType.format(adjustmentAmount) }), _jsx(SummaryBlockItem, { label: translate('contract.rent.newNetRent'), value: DecimalType.format(currentRent.netAmount + adjustmentAmount) })] })] })] })] }));
};
const SummaryBlockItem = ({ label, value }) => (_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { medium: 500, neutral: 900, children: label }), _jsx(Text, { medium: 400, neutral: 900, children: value })] }));
