import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FinancialAccountStatementModal } from "Pages/Accounting/components/FinancialAccountStatementModal";
export const CalculationFinancialAccountStatement = ({ financialAccountId, calculationId, contractId, periodFrom, periodTo, onClose, getFinancialAccountStatement }) => {
    const [financialAccountStatement, setFinancialAccountStatement] = React.useState();
    React.useEffect(() => {
        if (financialAccountId) {
            getFinancialAccountStatement(financialAccountId, calculationId, contractId, periodFrom, periodTo)
                .then(res => setFinancialAccountStatement(res));
        }
        else {
            setFinancialAccountStatement(undefined);
        }
    }, [financialAccountId]);
    return _jsx(FinancialAccountStatementModal, { financialAccountStatement: financialAccountStatement, onClose: onClose });
};
