import { pick } from '@f2w/utils';
import { BaseState } from './BaseState';
export class SimpleState extends BaseState {
    _config = SimpleState.createManager(this._configureDescriptors());
    constructor(config) {
        super(config);
        Object.defineProperties(this, {
            meta: {
                value: this._config.meta,
                writable: false,
                configurable: false
            },
            data: {
                value: this._config.data,
                writable: false,
                configurable: false
            },
        });
    }
    get value() {
        return this.hasValue() ? this.data.value : this.data.initialValue;
    }
    get initialValue() {
        return this.data.initialValue;
    }
    hasInitialValue() {
        return this._hasOwn('initialValue');
    }
    hasValue() {
        return this._hasOwn('value');
    }
    clear(skipUpdate, ignoreValue) {
        this._own = pick(this._own, ['initialValue', ignoreValue ? ['value', 'humanValue', 'formattedValue', 'prevValue'] : []]);
        if (!skipUpdate) {
            this.updateParent() && this._dispatchUpdate();
        }
    }
    reset(options) {
        let initialValue = this._own.initialValue;
        this.clear(true);
        if (options?.newValue !== undefined) {
            initialValue = this._cast(options?.newValue, this.hasInitialValue());
        }
        else if (!this.hasInitialValue()) {
            initialValue = this._type().getDefault();
        }
        const updated = this.set('initialValue', initialValue);
        if (!options?.skipRender && (this.updateParent() || updated)) {
            this._dispatchUpdate();
        }
    }
    _updateData(key, value, force) {
        switch (key) {
            case 'initialValue':
                if (super._updateData(key, value)) {
                    return this.meta.touched ? ['dirty'] : [];
                }
                break;
            case 'value':
                if (super._updateData(key, value, !this.hasValue())) {
                    return ['dirty', 'touched'];
                }
                break;
            case 'error':
                if (super._updateData(key, value)) {
                    return ['validated', 'valid', 'hasError'];
                }
                break;
            default:
                if (super._updateData(key, value)) {
                    return [key];
                }
                break;
        }
    }
    _configureDescriptors() {
        const desc = this._userConfig.descriptors;
        const config = {
            ...desc,
            disabled: {
                getMetaValue: () => this._self().isDisabled,
                set: (value) => this._type().update({ disabled: value }),
                ...desc?.disabled,
            },
            visible: {
                getMetaValue: () => this._self().isVisible,
                set: (value) => this._type().update({ hidden: value }),
                ...desc?.visible,
            },
            active: {
                getMetaValue: () => this._self().isActive,
                ...desc?.active,
            },
            dirty: {
                getMetaValue: () => {
                    return this.hasValue() && this._own.value !== this._own.initialValue;
                },
                ...desc?.dirty,
            },
            error: {
                getValue: () => {
                    return this._self().isActive ? this._own.error : '';
                },
                set: (value) => this._own.error = value,
                ...desc?.error,
            },
            hasError: {
                getMetaValue: () => (this._self().error !== ''),
                ...desc?.hasError,
            },
            valid: {
                getMetaValue: () => (this._self().error === ''),
                ...desc?.valid,
            },
            validated: {
                set: (value) => this._own.validated = value,
                getMetaValue: () => (this._own.validated ?? (this._self().error != null)),
                ...desc?.validated,
            },
            validating: {
                set: (value) => this._own.validating = value,
                getMetaValue: () => (this._own.validating ?? false),
                ...desc?.validating,
            },
            touched: {
                set: (value) => this._own.touched = value,
                getMetaValue: () => (this._own.touched ?? false),
                ...desc?.touched,
            },
            initialValue: {
                getValue: () => this._own.initialValue,
                set: (value) => {
                    this._own.initialValue = value;
                    _clearFormatted();
                },
                ...desc?.initialValue,
            },
            value: {
                getValue: () => this._own.value,
                set: (value) => {
                    this._own.prevValue = this._own.value;
                    this._own.value = value;
                    _clearFormatted();
                },
                ...desc?.value,
            },
            prevValue: {
                getValue: () => this._own.prevValue,
                ...desc?.prevValue,
            },
            formattedValue: {
                getValue: () => {
                    if (!this._hasOwn('formattedValue'))
                        this._own.formattedValue = this._type().format(this.value, 'upsert');
                    return this._own.formattedValue;
                },
                ...desc?.formattedValue,
            },
            humanValue: {
                getValue: () => {
                    if (!this._hasOwn('humanValue'))
                        this._own.humanValue = this._type().format(this.value, 'view');
                    return this._own.humanValue;
                },
                ...desc?.humanValue,
            },
        };
        const _clearFormatted = () => {
            const { formattedValue, humanValue, ...own } = this._own;
            this._own = own;
        };
        return config;
    }
}
