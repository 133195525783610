import { mapObject, objectKeys } from '@f2w/utils';
import { KeyOrChild } from './IndexDescriptor';
export class ChildDescriptor {
    name;
    options;
    constructor(name, config, options) {
        this.name = name;
        this.options = options;
        Object.defineProperties(this, {
            config: {
                value: { name, ...config },
                enumerable: true,
                writable: false,
                configurable: false
            },
            data: {
                value: Object.create(null),
                enumerable: true,
                writable: false,
                configurable: false
            },
        });
    }
    static create(key, config, options) {
        return new ChildDescriptor(key, config, options);
    }
    extend(config) {
        Object.assign(this.config, config(this.config));
        return this;
    }
    add(child) {
        const config = this.config;
        let set;
        if (config.set)
            set = (value) => {
                config.set(child, value);
            };
        Object.defineProperty(this.data, child.key, {
            configurable: true,
            enumerable: true,
            get: () => config.getValue(child),
            set,
        });
    }
    remove(keyOrChild) {
        const key = KeyOrChild.key(keyOrChild);
        delete this.data[key];
    }
}
export class ChildrenManager {
    options;
    props = Object.create(null);
    constructor(options) {
        this.options = options;
    }
    static create(options) {
        return new ChildrenManager(options ?? {});
    }
    updateOptions(options) {
        Object.assign(this.options, options);
        return this;
    }
    create(key, config) {
        const data = this.props;
        if (!data[key])
            return data[key] = ChildDescriptor.create(key, config, this.options);
        return data[key].extend((prev) => ({ ...prev, ...config, }));
    }
    hasProp(key) {
        return !!this.props[key];
    }
    getProp(key) {
        return this.props[key];
    }
    getData(key) {
        return this.getProp(key).data;
    }
    add(name, config) {
        this.create(name, config);
        return this;
    }
    remove(name) {
        delete this.props[name];
        return this;
    }
    forEach(cb) {
        Object.keys(this.props).forEach(key => {
            cb(this.props[key], key);
        });
    }
    map(cb) {
        return mapObject(this.props, (key, index) => {
            return cb(index, key);
        });
    }
    update(cb, keys) {
        const updatedKeys = (keys ?? objectKeys(this.props))
            .filter((k) => (this.props[k] && cb(this.props[k], k)));
        return updatedKeys.length > 0 ? updatedKeys : null;
    }
}
