import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table as DataTable } from '@f2w/data-table/table-types';
import { Button } from 'Components/Atoms/Button';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import { ContractStatusFilter } from 'Pages/Contract/components/ContractStatusFilter';
import { Icon } from 'Components/Atoms/Icon';
import { PersonsField as ContractPartiesCell, RentalObjectCard } from 'Components/App/card';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { DateType, MoneyType } from '@f2w/form';
import { RentPotentialCalculationButton } from 'Pages/Rent/components/RentPotentialCalculationButton';
import { UpcomingRentModificationFilter } from 'Pages/Contract/components/UpcomingRentModificationFilter';
import { Div, FlexCol, FlexRow } from 'Atoms/Layout';
import { Text } from 'Atoms/Text';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
import moment from 'moment';
import { ContractVacancyFilter } from 'Pages/Contract/components/ContractVacancyFilter';
import { ContractStateBadge } from 'Pages/Contract/components/ContractStateBadge';
import { DeleteIcon } from 'Components/App/Icons';
export const ContractDataTableModel = DataTable.factory()(() => {
    return {
        tableId: 'Contract',
        Actions: () => (_jsx(Button, { href: generateRoute('create_contract'), icon: 'plus', label: translate('btn.new') })),
        RowActions: ({ row: { original: { id, objectData, isVacancy } }, row, deleteContract, setContractId, refresh }) => {
            const { openViewModalObj } = useObjectViewModalContext();
            return (_jsxs(ActionsDropdown, { id: row.id, quickActions: [
                    {
                        icon: "eye",
                        title: translate('contract.actions.view'),
                        onClick: () => {
                            openViewModalObj({
                                id: id,
                                view: ActiveView.CONTRACT,
                                parentRentalObjectId: objectData.rentalObjectId
                            });
                        }
                    },
                ], children: [_jsxs(ActionsDropdown.Link, { onClick: e => {
                            e.preventDefault();
                            deleteContract(id, refresh);
                        }, disabled: isVacancy, children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('btn.delete') })] }), _jsxs(ActionsDropdown.Link, { onClick: e => {
                            e.preventDefault();
                            setContractId(id);
                        }, disabled: isVacancy, children: [_jsx(Icon, { icon: 'file' }), " ", _jsx("span", { children: translate('contract.actions.automaticInvoicing') })] })] }));
        },
        usePagination: {},
        useGlobalFilter: {},
        columns: {
            $actions: {
                sticky: 'left'
            },
            personId: {
                useFilters: {
                    disableFilters: true
                },
                hide: true
            },
            rentalObject: {
                Header: translate('contract.table.column.rentalObject'),
                Cell: ({ row: { original: { objectData } } }) => (_jsx(RentalObjectCard, { rentalObjectName: objectData.rentalObjectName, rentalObjectId: objectData.rentalObjectId, buildingName: objectData.buildingName })),
                width: 300,
                minWidth: 160,
                maxWidth: '30%',
                useSortBy: {},
            },
            contractParties: {
                Header: translate('contract.table.column.contractParties'),
                minWidth: 120,
                width: 300,
                maxWidth: '30%',
                Cell: ({ value: contractParties, openPersonInfoModal }) => {
                    if (contractParties.length === 0) {
                        return translate('contract.overview.vacancy');
                    }
                    contractParties = contractParties.sort((a, b) => {
                        if (a.isPrimary)
                            return -1;
                        if (b.isPrimary)
                            return 1;
                        return a.name.localeCompare(b.name);
                    });
                    return _jsx(ContractPartiesCell, { openPersonInfoModal: openPersonInfoModal, persons: contractParties });
                }
            },
            contractPeriod: {
                Header: translate('contract.table.column.contractPeriod'),
                Cell: ({ value: contractPeriod }) => (_jsxs(_Fragment, { children: [contractPeriod.start ? DateType.format(contractPeriod.start) : translate('contract.ends.notLimited'), " / ", contractPeriod.end ? DateType.format(contractPeriod.end) : translate('contract.ends.notLimited')] })),
                useSortBy: {}
            },
            state: {
                Header: translate('contract.table.column.state'),
                Cell: ({ value: state, row: { original: { mainContract, isVacancy } } }) => (_jsxs(FlexRow, { gap: 15, alignCenter: true, justifySpaceBetween: true, children: [_jsx(ContractStateBadge, { state: state, isVacancy: isVacancy }), mainContract && (_jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: 'qr-tooltip', children: _jsxs(FlexCol, { inline: true, children: [_jsxs(Div, { children: [translate('contract.mainContract'), ":"] }), _jsx(Div, { children: `
                                                    ${moment(mainContract.startDate).format('DD.MM.YYYY')}
                                                     - ${mainContract.endDate ? moment(mainContract.endDate).format('DD.MM.YYYY') : translate('contract.details.unlimited')}
                                                     / ${mainContract.contractPartyLastNames.join(', ')}
                                                     / ${mainContract.rentalObjectName}
                                                ` })] }) }), children: _jsx(Icon, { size: 22, icon: 'link' }) }))] })),
                useFilters: {
                    Filter: ContractStatusFilter
                }
            },
            activeRent: {
                Header: translate('contract.table.column.netAndGrossRent'),
                width: 240,
                Cell: ({ value: activeRent, setRentId, displayRentPotential, row: { original: { upcomingRentModificationValidFrom } } }) => {
                    return (_jsx(FlexRow, { justifySpaceBetween: true, children: activeRent ? (_jsxs(_Fragment, { children: [_jsxs(FlexRow, { inline: true, gap: 5, wrap: 'wrap', children: [_jsxs(Text, { as: 'div', nowrap: true, children: [MoneyType.format(activeRent.netAmount), " /"] }), _jsx(Text, { as: 'div', nowrap: true, children: MoneyType.format(activeRent.grossAmount) })] }), _jsxs(FlexRow, { alignCenter: true, justifyCenter: true, children: [upcomingRentModificationValidFrom && (_jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: 'upcoming-rent-tooltip', children: _jsx("div", { style: { textAlign: "left" }, children: _jsxs(FlexCol, { justifyFlexStart: true, gap: 5, children: [_jsx("div", { children: translate('contract.overview.futureChange') }), _jsxs("div", { children: [translate('contract.validFrom'), ": ", moment(upcomingRentModificationValidFrom).format('DD.MM.YYYY')] }), _jsxs("div", { children: [translate('contract.modification.typeTooltip'), ": ", translate('process.contractRentModification')] })] }) }) }), children: _jsx(Icon, { icon: 'refreshRotatedCw' }) })), displayRentPotential &&
                                            _jsx(RentPotentialCalculationButton, { rent: {
                                                    id: activeRent.id,
                                                    canCalculateRentPotential: activeRent.hasPotential
                                                }, onClick: setRentId })] })] })) : translate('notAvailable') }));
                }
            },
            upcomingRentModification: {
                Header: translate('contract.overview.filter.upcomingRentModification'),
                useFilters: {
                    Filter: UpcomingRentModificationFilter
                },
                hide: true
            },
            showVacancy: {
                Header: translate('contract.overview.filter.showVacancy.label'),
                useFilters: {
                    Filter: ContractVacancyFilter
                },
                hide: true
            },
            noticePeriod: {
                Header: translate('contract.table.column.noticePeriod'),
                Cell: ({ value: noticePeriod }) => noticePeriod !== null
                    ? `${noticePeriod} ${translate('months')}`
                    : ''
            }
        }
    };
});
