import generateRoute, { generate } from "Services/Router";
import client from "Utils/client";
export var FinancialAccountServices;
(function (FinancialAccountServices) {
    FinancialAccountServices.getFinancialAccountStatement = async (financialAccountId, fiscalYearId, dateRange) => {
        const route = generate('api.financialAccounts.statement', {
            financialAccount: financialAccountId,
            fiscalYear: fiscalYearId,
            dateRange: dateRange ? {
                start: dateRange.from ?? null,
                end: dateRange.to ?? null
            } : null
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    FinancialAccountServices.downloadFinancialBalanceSheet = async (data) => {
        const response = await client.get(generateRoute('api.financialAccounts.balanceSheet', {
            ...data,
            showAllFinancialAccounts: data.showAllFinancialAccounts ? 1 : 0
        }), { responseType: 'blob' });
        FinancialAccountServices.downloadFile(response);
    };
    FinancialAccountServices.downloadFile = (response) => {
        const fileName = response.headers['X-Download-File-Name'.toLowerCase()];
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', decodeURI(fileName));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
})(FinancialAccountServices || (FinancialAccountServices = {}));
