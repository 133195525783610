import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ContentTemplateNew, SplitViewTemplate } from '@f2w/view';
import { Header } from 'Atoms/Layout/components';
import { Badge, FlexCol, FlexRow, Text } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import DebitorInvoiceState from 'Pages/Debitor/components/DebitorInvoiceState';
import { UseStyled } from '@fw/theme';
import { formatAmount } from 'Services/NumberHelper';
import { formatDateShort } from 'Services/DateHelper';
const getReminderBadge = (reminderState) => {
    switch (reminderState) {
        case 1: return _jsx(Badge, { "$color": 'primary', children: translate('debitor.reminderStateLabel', { number: reminderState }) });
        case 2: return _jsx(Badge, { "$color": 'warning', children: translate('debitor.reminderStateLabel', { number: reminderState }) });
        case 3: return _jsx(Badge, { "$color": 'danger', children: translate('debitor.reminderStateLabel', { number: reminderState }) });
    }
};
const VerticalLine = UseStyled.styled('div', ({ theme: { palette } }) => ({
    borderLeft: `1px solid ${palette.neutral.$100}`,
    alignSelf: 'stretch',
    margin: '0 10px'
}));
const InfoBox = ({ label, value }) => {
    return _jsxs(FlexCol, { p: 16, alignCenter: true, gap: 5, children: [_jsx(Text, { nowrap: true, large: 500, neutral: 800, children: value }), _jsx(Text, { pretitle: 500, neutral: 500, children: label })] });
};
const HeaderTemplate = ({ view: { data: debitorInvoice } }) => (_jsxs(FlexCol, { gap: 24, children: [_jsx(Header.Avatar, { title: translate('debitor.details.title'), actions: _jsxs(_Fragment, { children: [debitorInvoice.reminderState !== 0 && getReminderBadge(debitorInvoice.reminderState), _jsx(DebitorInvoiceState, { state: debitorInvoice.state })] }) }), _jsxs(FlexRow, { justifySpaceAround: true, alignStretch: true, children: [_jsx(InfoBox, { label: translate('debitor.date'), value: formatDateShort(debitorInvoice.receiptDate) }), _jsx(VerticalLine, {}), _jsx(InfoBox, { label: translate('debitor.dueDate'), value: formatDateShort(debitorInvoice.dueDate) }), _jsx(VerticalLine, {}), _jsx(InfoBox, { label: translate('debitor.totalAmount') + ' / ' + translate('debitor.amountRemaining') + ' in CHF', value: formatAmount(debitorInvoice.amount) + ' / ' + formatAmount(debitorInvoice.amountRemaining) })] })] }));
export const DebitorTemplate = (props) => {
    return _jsx(SplitViewTemplate, { ...props, Template: ContentTemplateNew, leftSide: {
            Header: HeaderTemplate,
            groups: ['general'],
        }, rightSide: {
            groups: ['documents', 'preview'],
            Template: props => {
                return _jsx(ContentTemplateNew, { ...props });
            },
        } });
};
