import { AbstractFormType, BooleanType } from "@f2w/form";
import { translate } from "Services/Translator";
export class CreateRemindersType extends AbstractFormType {
    buildFields() {
        return {
            isSeparatePerParty: new BooleanType({
                label: translate('automaticInvoices.export.isSeparatePerParty.caption'),
                help: translate('automaticInvoices.export.isSeparatePerParty.help'),
                required: false,
                defaultValue: true,
            }),
            includeQR: new BooleanType({
                label: translate('automaticInvoices.export.includeQR.caption'),
                help: translate('automaticInvoices.export.includeQR.help'),
                required: false,
                defaultValue: true,
            }),
            useReceiptDate: new BooleanType({
                label: translate('automaticInvoices.export.useReceiptDate.caption'),
                help: translate('automaticInvoices.export.useReceiptDate.help'),
                required: false,
                defaultValue: false,
            }),
        };
    }
}
