import { AbstractFormType, BooleanType } from "@f2w/form";
import { PortfolioSelectType } from "Pages/Rent/forms/PortfolioSelectType";
import { translate } from "Services/Translator";
import { DateRangeType } from "Pages/Rent/forms/DateRangeType";
import moment from 'moment/moment';
export class RenterMirrorDateRangeExportFormType extends AbstractFormType {
    buildFields() {
        const endDate = moment().subtract(1, 'month');
        const startDate = moment().subtract(1, 'year');
        return {
            dateRange: new DateRangeType({
                label: false,
                type: 'day',
                defaultValue: () => ({
                    start: startDate.startOf('month').toDate(),
                    end: endDate.endOf('month').toDate(),
                }),
            }),
            portfolio: new PortfolioSelectType({
                label: false,
                inheritData: true,
            }),
            anonymized: new BooleanType({
                label: translate('rent.anonymized'),
                required: false,
                defaultValue: false
            }),
        };
    }
}
