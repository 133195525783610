import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { slotAttr, slotCss } from '@f2w/theme';
import styled from 'styled-components';
import { orFunction } from 'Utils';
import { renderContent } from 'Components/Base';
import { FlexRow } from 'Atoms/Layout';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
import { Icon } from 'Atoms/Icon';
import classNames from 'classnames';
import { Button } from 'Atoms/Button';
import { Text } from 'Atoms/Text';
export var BaseTheme;
(function (BaseTheme) {
    function Label({ field, children, ...props }) {
        return _jsxs(BaseTheme.LabelRoot, { id: `${field.id}--label`, htmlFor: field.widgetId, "data-required": field.isRequired, ...props, className: classNames(`fw-form__label--${field.typeName}`, {
                'sr-only': !field.showLabel,
            }, props?.className), children: [_jsx(TooltipLabelContent, { field: field, help: field.options.help, children: renderLabelContent(field, _jsx(_Fragment, { children: _jsx("span", { ...slotAttr('label', 'content'), children: field.label }) })) }), children] });
    }
    BaseTheme.Label = Label;
    BaseTheme.LabelRoot = styled.label.attrs(() => ({
        className: 'fw-form__label',
    })) `
        width: 100%;
        margin: 0 0 8px;

        ${p => p.theme.typography.small.fontStyles};
        color: ${p => p.theme.palette.neutral.$600};

        &[data-required=true] ${slotCss('label', 'content')} {
            display: inline-block;
            vertical-align: bottom;
            &:after {
                content: '*';
                font-size: 16px;
                line-height: 1em;
                color: ${p => p.theme.palette.error.$600};
                margin-left: 3px;
                vertical-align: bottom;
            }
        }
    `;
    let debug = false;
    const renderLabelContent = (field, children) => {
        if (debug)
            return _jsx(TestLabelContent, { field: field, children: children });
        return children;
    };
    const TooltipLabelContent = ({ help: _help, field, children }) => {
        const help = orFunction(_help, field);
        const { message, ...props } = (help?.message ? help : help ? { message: help } : {});
        const messageContent = message && renderContent(message, { field }) || message || null;
        if (!messageContent) {
            return children;
        }
        return (_jsxs(FlexRow, { gap: 10, children: [children, _jsx(OverlayTrigger, { placement: 'top', flip: true, ...props, overlay: (_jsx(Tooltip, { id: `${field.id}--help`, children: messageContent })), children: _jsx(Icon, { style: { pointerEvents: 'all', cursor: 'pointer' }, "data-form-help": true, icon: 'help-circle' }) })] }));
    };
    const TestLabelContent = ({ field, children }) => (_jsx(_Fragment, { children: _jsxs(FlexRow, { alignEnd: true, justifySpaceBetween: true, children: [children, _jsxs(FlexRow, { alignEnd: true, gap: 10, children: [_jsx(ToggleValueMeta, { metaKey: 'active', label: 'Active', field: field }), _jsx(ToggleValueMeta, { metaKey: 'visible', label: 'Visible', field: field }), _jsx(ToggleValueMeta, { label: 'Disabled', metaKey: 'disabled', field: field })] })] }) }));
    const printBool = (value) => _jsx(_Fragment, { children: value ? 'Yes' : 'No' });
    const ToggleValueMeta = ({ field, label, metaKey, ...rest }) => {
        const value = field.meta[metaKey];
        const statusColor = value ? 'primary' : 'neutral';
        return _jsx(_Fragment, { children: _jsx(Button, { "$size": 'xs', variant: 'linkFill', color: 'neutral', label: _jsxs(_Fragment, { children: [label, ": ", _jsx(Text, { as: 'b', colorName: statusColor, children: printBool(value) })] }), onClick: (e) => {
                    console.log(`Updating ${field.path} ${metaKey}`, printBool(!value));
                    field.updateMeta({
                        [metaKey]: !value
                    });
                } }) });
    };
    function Error({ field, ...props }) {
        if (!field.meta.hasError || field._isCompound())
            return null;
        return _jsx(BaseTheme.ErrorRoot, { id: `${field.id}--error`, ...props, children: Array.isArray(field.error) ? field.error.map((error, key) => (_jsx("p", { children: error }, `error-${key}`))) : field.error });
    }
    BaseTheme.Error = Error;
    BaseTheme.ErrorRoot = styled.div.attrs(() => ({
        className: 'fw-form__error',
    })) `
      color: var(--state-color, ${p => p.theme.palette.error.$500});
      margin: 6px 0 0;
    `;
    BaseTheme.Row = styled.div.attrs(() => ({
        className: 'fw-form__row',
    })) `
      display: block;
      position: relative;
      margin-bottom: ${p => p.compound ? '0' : 'var(--form-row-spacing, 1.5rem)'};
        &:last-child {
            margin-bottom: 0;
        }

        *[data-form-help] {
            color: ${p => p.theme.palette.secondary.$600};
            font-size: 16px;
        }
    `;
})(BaseTheme || (BaseTheme = {}));
