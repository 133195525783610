import { jsx as _jsx } from "react/jsx-runtime";
import { ArrayType, BaseFormTypeOld, DateType, DateValueType, SelectType, StringType, TableWidget, } from '@f2w/form-new';
import { useMemo } from 'react';
import Portfolio from 'Models/Portfolio';
import { translate } from 'Services/App';
import services from './contractNoticeServices';
import { NoticeStepTemplate, SummaryStepTemplate, TerminationStepTemplate } from './ContractNoticeWizardTemplate';
import { Callout } from 'Atoms/Callout';
import { TerminatedBy } from 'Pages/Contract/enums';
export class ContractNoticeWizardType extends BaseFormTypeOld {
    buildFields() {
        return {
            noticeStep: new ContractNoticeWizardType.NoticeStep({ hideLabel: true }),
            terminationStep: new ContractNoticeWizardType.TerminationStep({ hideLabel: true }),
            summaryStep: new ContractNoticeWizardType.SummaryStep({ hideLabel: true }),
        };
    }
}
(function (ContractNoticeWizardType) {
    ContractNoticeWizardType.getRoot = (v) => v.root();
    ContractNoticeWizardType.getNoticeStep = (v) => v.root()?.children?.noticeStep;
    ContractNoticeWizardType.getNoticeStepData = (v) => (v.root()?.children?.noticeStep.value);
    class NoticeStep extends BaseFormTypeOld {
        async loadData(contractId, date) {
            try {
                if (contractId) {
                    const handler = this.specs.options.loadData ?? services.getStepNotice;
                    const data = await handler(contractId, date);
                    if (data)
                        return data;
                }
            }
            catch (error) {
                console.error('getRentModificationStepDate', error);
            }
        }
        buildFields() {
            const portfolio = Portfolio.instance();
            return {
                propertyId: SelectType.number({
                    label: translate('property'),
                    required: true,
                    choices: () => useMemo(() => portfolio.getProperties().map(property => ({
                        label: property.name,
                        value: property.id,
                    })), []),
                    onChange: (v) => {
                        ContractNoticeWizardType.getNoticeStep(v).children.buildingId.updateValue(null, true);
                    },
                }),
                buildingId: SelectType.number({
                    label: translate('building.title'),
                    required: true,
                    choices: (v) => {
                        const id = ContractNoticeWizardType.getNoticeStepData(v).propertyId;
                        return useMemo(() => portfolio.getBuildingsByPropertyId(id).map(data => ({
                            value: data.id, label: data.name,
                        })), [id]);
                    },
                    onChange: (v) => {
                        ContractNoticeWizardType.getNoticeStep(v).children.rentalObjectId.updateValue(null, true);
                    },
                }),
                rentalObjectId: SelectType.number({
                    label: translate('rentalObject'),
                    required: true,
                    choices: (v) => {
                        const id = ContractNoticeWizardType.getNoticeStepData(v).buildingId;
                        return useMemo(() => portfolio.getRentalObjectsByBuildingId(id).map(data => ({
                            value: data.id, label: data.name,
                        })), [id]);
                    },
                    onChange: async (v, { initial }) => {
                        ContractNoticeWizardType.getNoticeStep(v).children.contractId.updateValue(null, true);
                    },
                }),
                contractId: SelectType.number({
                    label: translate('contract'),
                    required: true,
                    choices: (v) => {
                        const id = ContractNoticeWizardType.getNoticeStepData(v).rentalObjectId;
                        return useMemo(() => portfolio.getUnlimitedContractsByRentalObjectId(id).map(data => ({
                            value: data.id, label: data.label,
                        })), [id]);
                    },
                    onChange: (v) => {
                        const _step = ContractNoticeWizardType.getNoticeStep(v);
                        _step.updateValue({ selectedContract: portfolio.getContractById(v.value) });
                    },
                }),
                noticeBy: SelectType.string({
                    label: translate('contractNotice.noticeBy'),
                    required: true,
                    defaultValue: TerminatedBy.TENANT,
                    isVisible: (v) => !!ContractNoticeWizardType.getNoticeStepData(v).contractId,
                    choices: [
                        { value: TerminatedBy.TENANT, label: translate('contractNotice.noticeBy.tenant') },
                        { value: TerminatedBy.OWNER, label: translate('contractNotice.noticeBy.owner') },
                    ],
                    helperText: (v) => {
                        if (TerminatedBy.OWNER == v.value) {
                            return _jsx(Callout, { style: { marginTop: 10 }, message: translate('contractNotice.noticeBy.info') });
                        }
                    },
                }),
                dateOfNotice: new DateType({
                    label: translate('contractNotice.dateOfNotice'),
                    required: true,
                    defaultValue: () => new Date(),
                    isVisible: (v) => !!ContractNoticeWizardType.getNoticeStepData(v).contractId,
                    onChange: (v) => {
                        const _root = ContractNoticeWizardType.getRoot(v);
                        const _step = _root.children.noticeStep;
                        this.loadData(_step.extra.selectedContract?.id, v.formattedValue)
                            .then(settings => {
                            _step.extra.settings = settings;
                            const _terminationStep = _root.children.terminationStep;
                            if (settings?.subContracts) {
                                _terminationStep.reset({
                                    contracts: settings.subContracts.map((p) => ({
                                        ...p,
                                        nextNoticeDate: DateValueType.parse(p.nextNoticeDate),
                                    }))
                                });
                            }
                            const _noticePer = _step.children.noticePer;
                            _noticePer.resetOptions({ min: settings?.minimumDate }, true);
                            _noticePer.updateValue(DateValueType.parse(settings?.nextNoticeDate) || null, true);
                        });
                    },
                }),
                noticePer: new DateType({
                    label: translate('contractNotice.noticePer'),
                    required: true,
                    isDisabled: (v) => !ContractNoticeWizardType.getNoticeStepData(v).selectedContract?.id,
                    isVisible: (v) => !!ContractNoticeWizardType.getNoticeStepData(v).contractId,
                    helperText: (v) => {
                        const nextNoticeDate = DateValueType.parseMoment(ContractNoticeWizardType.getNoticeStepData(v).settings?.nextNoticeDate);
                        if (nextNoticeDate) {
                            const noticePer = DateValueType.parseMoment(ContractNoticeWizardType.getNoticeStepData(v).noticePer);
                            return [
                                nextNoticeDate && translate('contractNotice.noticePer.helperText', { date: DateValueType.format(nextNoticeDate) }),
                                noticePer?.isBefore(nextNoticeDate) && translate('contractNotice.noticePer.helperTextExtraordinary'),
                            ].filter(v => !!v).join(' ');
                        }
                    },
                }),
            };
        }
        _renderWidget(props) {
            return _jsx(NoticeStepTemplate, { ...props });
        }
    }
    ContractNoticeWizardType.NoticeStep = NoticeStep;
    ContractNoticeWizardType.isFutureRentChange = (nextNotice, futureRentChange) => {
        const futureRentChangeDate = DateValueType.parseMoment(futureRentChange);
        const nextNoticeDate = DateValueType.parseMoment(nextNotice);
        return futureRentChangeDate && nextNoticeDate && nextNoticeDate.isBefore(futureRentChangeDate);
    };
    ContractNoticeWizardType.getTerminationStep = (v) => v.root()?.children?.terminationStep;
    ContractNoticeWizardType.getTerminationStepData = (v) => v.root()?.children?.terminationStep.value;
    class TerminationStep extends BaseFormTypeOld {
        buildFields() {
            const contractProto = () => new SubContractType({
                isDisabled: (v) => !v.parent?.isSelected(v),
            });
            return {
                contracts: new ArrayType({
                    prototype: contractProto,
                    selectable: {},
                    render: (v) => TerminationStep._renderTable(v),
                }),
            };
        }
        static _renderTable(v) {
            const noticePerDate = ContractNoticeWizardType.getNoticeStepData(v).noticePer;
            const _step = ContractNoticeWizardType.getTerminationStep(v);
            const arrayValue = _step.children.contracts;
            arrayValue.setTheme({ size: 'sm' });
            return _jsx(TableWidget, { arrayValue: arrayValue, canSelect: true, getRowStatus: (row, vt) => {
                    const isSelected = arrayValue.isSelected(row);
                    const isFutureRent = ContractNoticeWizardType.isFutureRentChange(noticePerDate, row.value.futureRentChangeDate);
                    if (!isSelected) {
                        return {
                            status: 'primary',
                            statusMessage: translate('contractNotice.terminationStep.partialContractsWarning'),
                        };
                    }
                    if (isFutureRent) {
                        return {
                            status: 'warning',
                            statusMessage: translate('contractNotice.noticeStep.futureRentInfo'),
                        };
                    }
                }, columns: {
                    name: {
                        label: (v) => v.protoType.specs.fields.name.specs.options.label,
                        value: (v) => v.children.name.humanValue,
                        style: { width: '60%' },
                    },
                    nextNoticeDate: {
                        label: (v) => v.protoType.specs.fields.nextNoticeDate.specs.options.label,
                        value: (v) => v.children.nextNoticeDate.humanValue,
                        style: { width: '40%' },
                    },
                } });
        }
        _renderWidget(props) {
            return _jsx(TerminationStepTemplate, { ...props });
        }
    }
    ContractNoticeWizardType.TerminationStep = TerminationStep;
    class SubContractType extends BaseFormTypeOld {
        buildFields() {
            return {
                name: new StringType({
                    required: true,
                    readonly: true,
                    label: () => translate('contract'),
                }),
                nextNoticeDate: new DateType({
                    required: true,
                    readonly: true,
                    label: () => translate('contractNotice.terminationStep.nextNoticeDate'),
                }),
            };
        }
    }
    class SummaryStep extends BaseFormTypeOld {
        buildFields() {
            return {};
        }
        _renderWidget(props) {
            return _jsx(SummaryStepTemplate, { ...props });
        }
    }
    ContractNoticeWizardType.SummaryStep = SummaryStep;
})(ContractNoticeWizardType || (ContractNoticeWizardType = {}));
