import { forEachObject, isFunction, isKeyOf, isObject, removeKeyOf } from 'Utils';
function defineOptionsModifiers(getModifiers, validator) {
    const _own = Object.create(null);
    const _options = Object.create(null);
    const _descriptors = Object.create(null);
    _descriptors['constraints'] = {
        enumerable: true,
        configurable: false,
        get: () => validator.options,
        set: (value) => {
            isObject(value) && Object.assign(validator.options, value);
        },
    };
    Object.defineProperty(_own, 'constraints', {
        writable: false,
        value: validator._own,
        enumerable: true,
        configurable: false,
    });
    const modifiers = {
        ...getModifiers({ self: _options, own: _own }),
        constraints: {}
    };
    forEachObject(modifiers, (propKey, propConfig) => {
        if (propKey === 'constraints') {
            _descriptors[propKey] = {
                configurable: propConfig.configurable ?? false,
                enumerable: propConfig.enumerable ?? true,
                get: () => validator.options,
                set: (value) => {
                    isObject(value) && Object.assign(validator.options, value);
                },
            };
            return;
        }
        _descriptors[propKey] = {
            configurable: propConfig.configurable ?? false,
            enumerable: propConfig.enumerable ?? true,
            get: () => {
                if (_own[propKey] === undefined && isFunction(propConfig.getDefault))
                    _own[propKey] = propConfig.getDefault();
                return propConfig.get?.(_own[propKey]) ?? _own[propKey];
            },
            set: propConfig.readonly ? undefined : (value) => {
                let _prevValue = _own[propKey];
                let _newValue = value;
                if (propConfig.set != null) {
                    _newValue = propConfig.set(_newValue, _prevValue);
                    if (_newValue === undefined)
                        return;
                }
                if (_newValue === _prevValue)
                    return;
                if (_newValue != null) {
                    _own[propKey] = _newValue;
                }
                else if (propConfig.keepNull && _newValue === null)
                    _own[propKey] = _newValue;
                else
                    removeKeyOf(_own, propKey);
                propConfig?.update?.(_own[propKey]);
            },
        };
        propConfig.getters && forEachObject(propConfig.getters, (k, getter) => {
            if (k === 'constraints' || k in modifiers)
                return;
            _descriptors[k] = {
                configurable: false,
                enumerable: true,
                get: () => {
                    return getter(_own[propKey]);
                }
            };
        });
    });
    return {
        _own,
        _descriptors,
        options: Object.defineProperties(_options, _descriptors),
        clear: () => {
            validator.clear();
            forEachObject(_options, (key) => {
                if (!isKeyOf(_descriptors, key))
                    removeKeyOf(_options, key);
            });
            forEachObject(_own, (key) => {
                if (key !== 'constraints')
                    removeKeyOf(_own, key);
            });
        },
        update: (opts) => {
            Object.assign(_options, opts);
        },
    };
}
export default defineOptionsModifiers;
