import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import PageOverview from "@f2w/data-table/layout/PageOverview";
import { CustomersDataTable } from "Pages/Administration/Customers/table/CustomerDataTable";
import { translate, translateAdmin } from "Services/Translator";
import { useUserEditForm } from "Pages/Administration/Customers/hooks/useUserEditForm";
import { FormModal } from "@f2w/form";
import { useActiveViewContext } from "Pages/Administration/context/ActiveViewContext";
import { ActiveView } from "Pages/Administration/enums";
import { useModalDispatcherConfirm, useToastDispatcherApi } from "Components/Dispatcher";
export const CustomersPage = ({ customerServices: { getCustomers, toggleCustomerStatus, enableCustomerUser, disableCustomerUser, getCustomerUserEditData, updateCustomerUserEditData, resendCustomerRegistrationEmail, resendCustomerUserInvitationEmail, deleteSelectedCustomers } }) => {
    const { setDetailsActiveView } = useActiveViewContext();
    const toastApi = useToastDispatcherApi();
    const resendRegistrationEmailConfirm = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: async (id, refresh) => {
            try {
                await resendCustomerRegistrationEmail(id);
                toastApi.success({ message: translate('customer.resendRegistrationEmail.success'), timeout: 5, showRemove: true });
            }
            catch (e) {
                toastApi.error({ message: translate('customer.resendRegistrationEmail.error'), timeout: 5, showRemove: true });
                console.error(e);
            }
            finally {
                refresh();
            }
        },
        message: translate('customer.resendRegistrationEmail.confirm'),
        btnConfirm: translate('btn.confirm')
    });
    const resendInvitationEmailConfirm = useModalDispatcherConfirm({
        icon: 'alert-triangle',
        onConfirm: async (id, refresh) => {
            try {
                await resendCustomerUserInvitationEmail(id);
                toastApi.success({ message: translate('customer.resendRegistrationEmail.success'), timeout: 5, showRemove: true });
            }
            catch (e) {
                toastApi.error({ message: translate('customer.resendRegistrationEmail.error'), timeout: 5, showRemove: true });
                console.error(e);
            }
            finally {
                refresh();
            }
        },
        message: translate('customer.resendRegistrationEmail.confirm'),
        btnConfirm: translate('btn.confirm')
    });
    const instance = CustomersDataTable.use({
        getCustomers,
        enableCustomers: async (ids) => {
            await toggleCustomerStatus({ ids, disable: false });
            instance.refresh();
        },
        disableCustomers: async (ids) => {
            await toggleCustomerStatus({ ids, disable: true });
            instance.refresh();
        },
        goToCustomerDetails: (id) => {
            setDetailsActiveView(id, ActiveView.CUSTOMER_DETAILS);
        },
        enableCustomer: async (id) => {
            await toggleCustomerStatus({ ids: [id], disable: false });
            instance.refresh();
        },
        disableCustomer: async (id) => {
            await toggleCustomerStatus({ ids: [id], disable: true });
            instance.refresh();
        },
        enableCustomerUser: async (id) => {
            await enableCustomerUser(id);
            instance.refresh();
        },
        disableCustomerUser: async (id) => {
            await disableCustomerUser(id);
            instance.refresh();
        },
        editCustomerUser: (id) => {
            customerUserEditForm.openForm(id);
        },
        resendRegistrationEmail: (id) => {
            resendRegistrationEmailConfirm(id, () => { instance.refresh(); });
        },
        resendCustomerUserInvitationEmail: (id) => {
            resendInvitationEmailConfirm(id, () => { instance.refresh(); });
        },
        deleteSelectedCustomers: (ids) => {
            deleteSelectedCustomers(ids).then(() => instance.refresh());
        }
    });
    const customerUserEditForm = useUserEditForm({
        getCustomerUserEditData,
        updateCustomerUserEditData,
        onSave: () => instance.refresh()
    });
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { inlineFilters: true, title: translateAdmin('customers'), instance: instance }), _jsx(FormModal, { modal: customerUserEditForm })] }));
};
