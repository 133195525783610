import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table as DataTable } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import { ActiveView, useOpenObjectViewModal } from 'Components/ObjectViewModal';
import { UseStyled } from '@f2w/theme';
import { Badge, Button, Select as DropdownSelect } from 'Atoms/index';
import { useMemo } from 'react';
import { OccupancyStatus } from 'Enums/OccupancyStatus';
import { PersonsField as ContractPartiesCell, RentalObjectCard } from 'Components/App/card';
import { FloorsFilter } from 'Components/Molecules/Floors';
import { NumberOfRoomsFilter } from 'Components/Molecules/NumberOfRooms';
import { rentalObjectTypeOptions } from 'Enums/RentalObjectType';
import { getFloorLabelFromNumber } from "Components/Molecules/Floors/services";
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
import { DeleteIcon } from "Components/App/Icons";
export const RentalObjectDataTableType = DataTable.factory()(() => ({
    tableId: 'RentalObject',
    usePagination: {},
    useGlobalFilter: {},
    RowActions: ({ row: { id }, onDeleteRentalObject }) => {
        const openView = useOpenObjectViewModal(ActiveView.RENTAL_OBJECT);
        return (_jsx(ActionsDropdown, { id: id, quickActions: [
                {
                    icon: 'eye',
                    title: translate('rentalObject'),
                    onClick: () => openView(Number(id))
                },
                {
                    icon: 'edit',
                    title: translate('btn.edit'),
                    onClick: () => openView(Number(id), true)
                }
            ], children: _jsxs(ActionsDropdown.Link, { onClick: () => onDeleteRentalObject(Number(id)), children: [_jsx(DeleteIcon, {}), " ", _jsx("span", { children: translate('btn.delete') })] }) }));
    },
    Actions: ({ openCreateObject }) => {
        return _jsx(Button.New, { variant: 'fill', onClick: openCreateObject });
    },
    columns: {
        $actions: {
            sticky: 'left',
        },
        name: {
            Header: translate('baseData.rentalObjectTable.column.name'),
            Cell: ({ row: { original: rentalObject } }) => {
                const openView = useOpenObjectViewModal(ActiveView.RENTAL_OBJECT);
                const building = rentalObject.building;
                return (_jsx(RentalObjectCard, { buildingName: building.name, rentalObjectName: rentalObject.name, onClick: () => openView(rentalObject.id) }));
            },
            useSortBy: {}
        },
        occupancyStatus: {
            Header: translate('baseData.rentalObjectTable.column.status'),
            Cell: ({ value }) => (_jsx(Cell, { children: _jsx(OccupancyStatusBadge, { state: value }) })),
        },
        contractParties: {
            Header: translate('baseData.rentalObjectTable.column.contractParties'),
            Cell: ({ value: contractParties, openPersonInfoModal }) => (_jsx(Cell, { children: _jsx(ContractPartiesCell, { openPersonInfoModal: openPersonInfoModal, persons: contractParties }) }))
        },
        type: {
            Header: translate('baseData.rentalObjectTable.column.type'),
            Cell: ({ value }) => _jsx(Cell, { children: translate(`rentalObjectType.${value}`) }),
            useFilters: {
                Filter: TypeFilter,
            },
        },
        floor: {
            Header: translate('baseData.rentalObjectTable.column.floor'),
            Cell: ({ value }) => _jsx(Cell, { children: getFloorLabelFromNumber(value) }),
            useSortBy: {},
            useFilters: {
                Filter: FloorsFilter
            },
        },
        numberOfRooms: {
            Header: translate('baseData.rentalObjectTable.column.numberOfRooms'),
            Cell: ({ value }) => _jsx(Cell, { children: value }),
            useSortBy: {},
            useFilters: {
                Filter: NumberOfRoomsFilter
            },
        },
        area: {
            Header: translate('baseData.rentalObjectTable.column.area'),
            Cell: ({ value }) => value ? _jsxs(Cell, { children: [value, "m", _jsx("sup", { children: "2" })] }) : _jsx(Cell, { children: translate('notAvailable') }),
            useSortBy: {}
        },
        isAncillaryExpenseRelevant: {
            Header: translate('baseData.rentalObjectTable.column.isAncillaryExpenseRelevant'),
            Cell: ({ value }) => _jsx(Cell, { children: translate(value ? 'yes' : 'no') }),
            useSortBy: {}
        },
    }
}));
const Cell = UseStyled.styled('div', () => ({
    height: '100%',
    paddingTop: '10px'
}));
const OccupancyStatusBadge = ({ state }) => {
    const { color = 'primary', message } = useMemo(() => {
        const map = {
            [OccupancyStatus.RENTED_OUT]: {
                color: 'success',
                message: 'baseData.rentalObject.active',
            },
            [OccupancyStatus.VACANT]: {
                color: 'danger',
                message: 'baseData.rentalObject.vacant',
            },
            [OccupancyStatus.NOTICED]: {
                color: 'warning',
                message: 'baseData.rentalObject.noticed',
            },
            [OccupancyStatus.UPCOMING]: {
                color: 'primary',
                message: 'baseData.rentalObject.upcoming',
            },
        };
        return map[state];
    }, [state]);
    return _jsx(Badge, { "$color": color, children: translate(message) });
};
const TypeFilter = ({ column: { filterValue, setFilter }, }) => {
    return (_jsx(DropdownSelect, { value: filterValue !== undefined ? filterValue : '', isSearchable: true, isClearable: true, options: rentalObjectTypeOptions, onChange: (newValue) => {
            setFilter(newValue !== null ? newValue.value : undefined);
        } }));
};
