import { FormError, isObject, useNavigateWithParams } from '@f2w/utils';
import { PropDesc } from './utils';
import { ObjectType } from '@f2w/form-new';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import { StepType } from './StepType';
import { translate } from 'Services/App';
import { useDispatcherApi } from 'Components/Dispatcher';
import { useNavigation } from 'react-router-dom';
export class StepApi {
    config;
    get toastApi() {
        return this._props.dispatcherApi.toast;
    }
    constructor(config, parent) {
        this.config = config;
        const desc = PropDesc.create(this, { visible: true, writable: false, configurable: false });
        desc
            .values({ visible: false }, {
            _props: {
                currentIndex: 0,
                lastIndex: 0,
                type: ObjectType.create({})
            }
        })
            .values({
            wizard: parent,
            steps: Object.create(null),
            stepIds: [],
            stepList: [],
        });
        desc
            .getters({
            type: () => this._props.type,
            valueType: () => {
                if (!this._props.valueType) {
                    const rootType = this._props.valueType = this.type?.init({
                        initialValue: { ...parent.data?.['stepData'] },
                        context: {
                            dispatchUpdate: () => this._props.dispatchUpdate?.(),
                        },
                    });
                }
                return this._props.valueType;
            }
        });
        Object.keys(config.steps)
            .forEach(id => this._createStep(config.steps, id, parent));
    }
    _createStep(configs, id, parent) {
        const shape = this._props.type.specs.shape;
        if (!isObject(configs[id]))
            return;
        const stepType = this.steps[id] = new StepType({
            id,
            config: configs[id],
            index: this.stepList.length,
            parent,
        });
        this.stepList.push(stepType);
        this.stepIds.push(id);
        if (stepType.type) {
            shape.add(stepType.id, stepType.type);
        }
    }
    goToStep = (idOrIndex) => {
        this._gotoStep(idOrIndex);
    };
    gotoPrevStep = () => {
        this._gotoStep(this.currentStep.prevStep);
    };
    handleNext = () => {
        const { dispatcherApi } = this._props;
        const step = this.currentStep;
        if (!step.hasType()) {
            return this._updateNext();
        }
        const valueType = step.valueType;
        return valueType.submit()
            .then(async () => {
            if (!step.isLast) {
                return this._updateNext();
            }
            else {
                return this._handleSave();
            }
        })
            .catch((error) => {
            if (step.isLast) {
                FormError.handleError({
                    error,
                    setStatus: r => {
                        dispatcherApi?.toast.error({
                            message: r.error,
                        });
                    },
                    dispatcher: this._props.dispatcherApi?.toast,
                    defaultMessage: translate('form.response.error')
                });
            }
        });
    };
    _gotoStep(stepOrId) {
        const step = this._find(stepOrId);
        if (step?.path)
            this._props.navigate(step?.path);
    }
    ;
    _updateNext() {
        const nextStep = this.currentStep.nextStep;
        if (nextStep) {
            this._props.lastIndex = Math.max(nextStep.index, this._props.lastIndex);
            this._gotoStep(nextStep);
        }
    }
    ;
    async _handleSave() {
        const valueType = this.valueType;
        const data = valueType.formattedValue;
        return this.config.save?.(data, this);
    }
    ;
    handleClose = async () => {
        const { navigation, navigate } = this._props;
        if (!this.valueType.meta.dirty || await this._props.dispatcherApi.modal.confirmAsync({
            btnConfirm: translate('wizard.confirm.btn.confirm'),
            btnCancel: translate('wizard.confirm.btn.cancel'),
            message: translate('wizard.confirm.message'),
            help: translate('wizard.confirm.help'),
        })) {
            if (this.wizard.props.referer) {
                window.location.href = this.wizard.props.referer;
                return;
            }
            return this.config.onClose?.({ navigate, navigation });
        }
    };
    handleSaveDraft = () => {
        console.log('-- handleSaveDraft -- ');
    };
    useType() {
        this._props.dispatchUpdate = useForceUpdate();
        this._props.dispatcherApi = useDispatcherApi();
        this._props.navigate = useNavigateWithParams();
        this._props.navigation = useNavigation();
        const step = this.currentStep;
        const wizard = step?.parent;
        return {
            step,
            wizard,
            config: wizard.config,
            stepApi: wizard.stepApi,
            loading: step.loading,
            valueType: step.valueType,
        };
    }
    ;
    get currentIndex() {
        return this._props.currentIndex;
    }
    get isFirst() {
        return this.currentStep.isFirst;
    }
    get isLast() {
        return this.currentStep.isLast;
    }
    get currentStep() {
        return this.stepList[this.currentIndex];
    }
    get lastIndex() {
        return this._props.lastIndex;
    }
    get lastStep() {
        return this.stepList[this.lastIndex];
    }
    setCurrent = (stepOrId) => {
        const step = this.getValid(stepOrId);
        if (step && step.index !== this.currentIndex) {
            this._props.currentIndex = step.index;
            return step;
        }
    };
    _find(stepOrId) {
        if (stepOrId != null) {
            if (stepOrId instanceof StepType)
                return stepOrId;
            return (this.steps[stepOrId] ?? this.stepList[stepOrId]);
        }
    }
    ;
    hasStep = (stepOrId) => {
        return !!this._find(stepOrId);
    };
    getValid = (stepOrId) => {
        const step = this._find(stepOrId);
        return step?.isEnabled && step;
    };
    isValid = (stepOrId) => {
        return !!this.getValid(stepOrId);
    };
}
