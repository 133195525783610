import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { BaseObjectType, ObjectValueType, } from '../../core';
import { ObjectField } from '../../theme/object/object';
export class ObjectType extends BaseObjectType {
    constructor(fields, options) {
        super(options);
        this.specs.setShape(fields);
    }
    _createValue(props) {
        return super._createValue(props);
    }
    _getSpecs() {
        return ObjectValueType.define({
            shape: () => ({})
        });
    }
    _renderForm(valueType, props) {
        if (valueType.specs.inheritView || valueType.isRoot) {
            return _jsx(_Fragment, { children: valueType.renderChildren(null, {}) });
        }
        return _jsx(ObjectField, { children: valueType.renderChildren(null, {}), ...props });
    }
    static create(fields, options) {
        const type = new ObjectType(fields, options);
        return type;
    }
}
