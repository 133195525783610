import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormModal } from "@f2w/form";
import { FinancialBalanceSheetExport } from "Pages/Accounting/types/FinancialBalanceSheetExportType";
import { translate } from "Services/App";
import { FinancialAccountServices } from "Pages/Accounting/services";
import { Button } from "Atoms/Button";
export const useFinancialBalanceSheetExportForm = ({ min, max }) => useFormModal({
    name: 'financial-balance-sheet-export',
    title: () => translate('accounting.financialExport.title'),
    type: () => new FinancialBalanceSheetExport({
        min: () => min,
        max: () => max,
    }),
    ignoreConfirm: true,
    successMessage: null,
    onSave: (values) => {
        return FinancialAccountServices
            .downloadFinancialBalanceSheet(values);
    },
    Footer: ({ saveForm, closeForm, form: { loading } }) => {
        return _jsxs(_Fragment, { children: [_jsx(Button, { link: true, onClick: () => closeForm(), children: translate('btn.cancel') }), _jsx(Button, { loading: loading, onClick: () => saveForm(true), children: translate('btn.download') })] });
    }
});
