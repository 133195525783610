import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { OrFunction } from '@f2w/utils';
import { ModalClose } from '../../Modal';
import { SlotFactory } from '@f2w/theme';
export const wizardSlot = new SlotFactory('layout');
const LayoutSideRoot = styled.div `
    & {
        display: grid;
        flex: 1;
        height: 100%;
        grid-template-columns: 280px minmax(400px, 800px) auto;
        grid-template-rows: max-content 1fr auto;
        grid-template-areas:
"header header header"
"side main preview"
"footer footer preview";

    }

    [data-part="header"] {
        grid-area: header;
        border-bottom: 1px solid var(--fw-color-neutral-100);
        justify-content: center;
        padding: var(--layout__padding-y) var(--layout__padding-x);
        display: flex;
    }

    [data-part="footer"] {
        grid-area: footer;
        border-top: 1px solid var(--fw-color-neutral-100);
        padding: var(--layout__padding-y) var(--layout__padding-x);
        display: flex;
        justify-items: end;
    }

    *${wizardSlot.partCss('side', 'main', 'preview')} {
        padding: var(--layout__padding-x);
        overflow: auto;
    }

    [data-part="side"] {
        grid-area: side;
        border-right: 1px solid var(--fw-color-neutral-100);
    }

    [data-part="main"] {
        grid-area: main;
    }

    [data-part="preview"] {
        grid-area: preview;
        border-left: 1px solid var(--fw-color-neutral-100);
        background: var(--fw-color-neutral-50);
    }
`;
const WizardRoot = styled.div `
    & {
        display: grid;
        flex: 1;
        height: 100%;
        grid-template-rows: max-content 1fr;
        grid-template-areas:
"head"
"body"
;

        background: var(--fw-color-white);
        pointer-events: auto;

        [data-part="head"] {
            grid-area: head;
            border-bottom: 1px solid var(--fw-color-neutral-100);
            justify-content: center;
            padding: var(--layout__padding-y) var(--layout__padding-x);
            display: flex;
            justify-items: center;
        }

        ${wizardSlot.partCss('body')} {
            grid-area: body;
            min-height: 0;
            padding: 0;
        }
    }
`;
function WizardLayout(props) {
    const { header: header, footer: footer, side: side, preview: preview, children: main, } = props;
    return (_jsxs(WizardRoot, { children: [_jsx(WizardLayout.Head, { children: OrFunction(header) }), _jsx(WizardLayout.BodyWizard, { side: side && _jsx(WizardLayout.Side, { children: OrFunction(side) }), preview: preview && _jsx(WizardLayout.Preview, { children: OrFunction(preview) }), footer: footer && _jsx(WizardLayout.Footer, { children: OrFunction(footer) }), children: _jsx(WizardLayout.Main, { children: OrFunction(main) }) })] }));
}
(function (WizardLayout) {
    function Root(props) {
        return (_jsx(WizardRoot, { ...wizardSlot.props('root', props) }));
    }
    WizardLayout.Root = Root;
    function Head({ children, onHide, ...rest }) {
        return (_jsxs("header", { ...wizardSlot.props('head', rest), children: [children, _jsx(ModalClose, { onHide: onHide })] }));
    }
    WizardLayout.Head = Head;
    function BodyWizard(props) {
        const { side, footer, preview, children } = props;
        return (_jsxs(LayoutSideRoot, { ...wizardSlot.props('body', props), children: [side, children, preview, footer] }));
    }
    WizardLayout.BodyWizard = BodyWizard;
    function Body(props) {
        return (_jsx("div", { ...wizardSlot.props('body', props) }));
    }
    WizardLayout.Body = Body;
    function Side(props) {
        return (_jsx("aside", { ...wizardSlot.props('side', props) }));
    }
    WizardLayout.Side = Side;
    function Main(props) {
        return (_jsx("main", { ...wizardSlot.props('main', props) }));
    }
    WizardLayout.Main = Main;
    function Preview(props) {
        return (_jsx("aside", { ...wizardSlot.props('preview', props) }));
    }
    WizardLayout.Preview = Preview;
    function Footer(props) {
        return (_jsx("footer", { ...wizardSlot.props('footer', props) }));
    }
    WizardLayout.Footer = Footer;
})(WizardLayout || (WizardLayout = {}));
export { WizardLayout };
