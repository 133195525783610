import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from "Atoms/Badge";
import { translate } from "Services/Translator";
const HandoverStatusBadge = ({ status }) => {
    const statusCode = status >= 6
        ? 'completed'
        : 'incomplete';
    const color = status >= 6
        ? 'primary'
        : 'dark';
    return (_jsx(Badge, { "$color": color, children: status ? translate(`handovers.status.${statusCode}`) : '' }));
};
export default HandoverStatusBadge;
