import { isFunction } from 'lodash';
const maybeWindow = typeof window === 'undefined' ? undefined : window;
const KEY_PREFIX = '@fwa/';
const get_key = (key) => `${KEY_PREFIX}${key}`;
export class LocalStorage {
    constructor(key) {
        Object.defineProperty(this, 'key', {
            value: key,
            writable: false,
        });
    }
    has() {
        return LocalStorage.has(this.key);
    }
    clear() {
        this._data = undefined;
        return LocalStorage.remove(this.key);
    }
    _read(force) {
        if (force || typeof this._data === 'undefined')
            this._data = LocalStorage.get(this.key) ?? null;
        return this._data;
    }
    _write(value) {
        return this._data = LocalStorage.set(this.key, value) ?? null;
    }
    static has(key) {
        return maybeWindow?.localStorage.getItem(get_key(key)) != null;
    }
    static get(key, defaultValue) {
        const value = maybeWindow?.localStorage.getItem(get_key(key));
        if (value == null)
            return defaultValue;
        try {
            return JSON.parse(value);
        }
        catch (_) {
            return defaultValue;
        }
    }
    static set(key, value) {
        const newValue = isFunction(value) ? value(LocalStorage.get(key)) : value;
        maybeWindow?.localStorage.setItem(get_key(key), JSON.stringify(newValue));
        return newValue;
    }
    static remove(key) {
        if (LocalStorage.has(key)) {
            maybeWindow?.localStorage.removeItem(get_key(key));
            return true;
        }
    }
}
