import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { ButtonGroup } from 'Atoms/ButtonGroup';
import { Carousel } from 'Components/Carousel';
import { Button } from 'Atoms/Button';
import { trans } from 'Services/App';
import { CarouselPreview } from 'Components/Carousel/CarouselPreview';
export const ImageCarousel = ({ images, renderActions: _renderActions, fit, position, noPreview: noPreview, ...carouselProps }) => {
    const [fullScreen, setFullScreen] = useState(false);
    const carouselRef = useRef();
    const onPreview = noPreview ? undefined : () => setFullScreen(true);
    const renderActions = (index, props) => _jsx(_Fragment, { children: _jsxs(ButtonGroup, { size: 'sm', variant: 'outline', children: [_jsx(Button, { href: images[index].path, title: trans('btn.download'), download: images[index].name ?? '', icon: 'download' }), _renderActions?.(index, props)] }) });
    return _jsxs(_Fragment, { children: [_jsx(Carousel, { ref: carouselRef, ...carouselProps, interval: fullScreen ? undefined : carouselProps.interval, renderActions: renderActions, children: images.map((image, key) => (_jsx(Carousel.Item, { onPreview: onPreview, children: _jsx(Media, { fit: fit, position: position, children: _jsx("img", { src: image.path, alt: image.name ?? '' }) }) }, key))) }), _jsx(CarouselPreview, { show: fullScreen, onHide: () => setFullScreen(false), defaultActiveIndex: carouselRef.current?.manager.activeIndex, onSelect: carouselRef.current?.manager.select, renderActions: renderActions, children: images.map((image, key) => (_jsx(Carousel.Item, { children: _jsx(Media2, { children: _jsx("img", { src: image.path, alt: image.name ?? '' }) }) }, key))) })] });
};
const MediaRoot = ({}) => {
};
export const Media2 = styled.div `
    overflow: hidden;
    padding: 0;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: scale-down;
        object-position: ${p => p.position || 'center'};

        &:before {
            content: 'Keine Vorschau verfügbar';
            display: flex;
            position: absolute;
            text-align: center;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            color: var(--fw-color-dark-900);
        }
    }
`;
export const Media = styled(Media2) `
    padding: ${p => (p.fit === 'contain' ? '1px' : undefined)};
    pointer-events: none;
    border-radius: var(--fw-carousel--border-radius);

    img {
        object-fit: ${p => p.fit || 'cover'};
    }
`;
