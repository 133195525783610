import React from "react";
import { useObjectViewModal } from "Components/ObjectViewModal";
import { useRentalObjectView } from "Components/ObjectViewModal/hooks/useRentalObjectView";
import { useToastDispatcherApi } from "Components/Dispatcher";
export const RentalObjectView = ({ rentalObjectId, getRentalObjectDetails, updateRentalObject, waitingListServices, handoverId, }) => {
    const toastDispatcherApi = useToastDispatcherApi();
    const { updateRentalObjectNameForBreadcrumb, activeView } = useObjectViewModal();
    const view = useRentalObjectView(getRentalObjectDetails, updateRentalObject, waitingListServices, updateRentalObjectNameForBreadcrumb, toastDispatcherApi, handoverId);
    React.useEffect(() => {
        view.load({ id: rentalObjectId });
        if (activeView.asEdit) {
            view.openForm('generalLeft');
        }
    }, [rentalObjectId]);
    return view.render();
};
