import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NotificationState } from "Components/Notifications/types";
import { subscribeToCustomEvent, unsubscribeFromCustomEvent } from "Services/EventManager";
export default function useNotifications({ getData, updateMarkAsRead, }) {
    const [data, setData] = useState([]);
    const observerRef = useRef({});
    const unreadCount = useMemo(() => {
        return data.filter(d => d.isUnread).length;
    }, [data]);
    const inProgressCount = useMemo(() => {
        return data.filter(d => d.state === NotificationState.IN_PROGRESS).length;
    }, [data]);
    const markAsRead = useCallback((id, state = true) => {
        updateStateById(setData, id, d => {
            d.isUnread = !state;
        });
        updateMarkAsRead && updateMarkAsRead(id);
    }, [data]);
    const loadData = useCallback(() => {
        if (Array.isArray(getData)) {
            setData(getData);
        }
        else {
            getData().then(d => {
                setData(d);
            });
        }
    }, [getData]);
    useEffect(() => {
        loadData();
    }, []);
    const loadNotificationsOnCreatedEvent = () => {
        loadData();
    };
    useEffect(() => {
        subscribeToCustomEvent('fw.notificationCreated', loadNotificationsOnCreatedEvent);
        return () => {
            unsubscribeFromCustomEvent('fw.notificationCreated', loadNotificationsOnCreatedEvent);
        };
    }, []);
    return {
        data,
        unreadCount,
        inProgressCount,
        observerRef,
        loadData,
        markAsRead,
    };
}
function updateStateById(set, id, cb) {
    const ids = (id && !Array.isArray(id) ? [id] : id);
    set((oldData) => {
        return oldData.map(d => {
            if (!ids || ids.includes(d.id)) {
                cb(d);
            }
            return d;
        });
    });
}
