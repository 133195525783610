import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { DataTable } from '@f2w/data-table';
import { AccountServices } from '../services';
import { Button, ButtonToolbar, EllipsisText, FlexRow, OverlayTrigger, SafeAnchor, Table, Tbody, Td, Text, Th, Thead, Tooltip as BaseTooltip, tooltipSlotCss, Tr, } from 'Components/Atoms';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import { translate } from 'Services/Translator';
import styled from 'styled-components';
import { styledModule } from '@f2w/theme';
import { ActionsDropdown } from "@f2w/data-table/components/ActionsDropdown";
const BaseTable = DataTable.factory()(() => ({
    tableId: 'AncillaryExpenseAccounts',
    manualControl: true,
    Actions: ({ onCreateAncillaryExpenseAccount, onCreatePresetAncillaryExpenseAccount }) => (_jsxs(ButtonToolbar, { children: [_jsx(Button, { icon: 'plus', variant: 'outline', label: translate('ancillaryExpenseAccount.btn.choosePresetAccount'), title: translate('ancillaryExpenseAccount.btn.choosePresetAccount'), onClick: onCreatePresetAncillaryExpenseAccount }), _jsx(Button, { icon: "plus", label: translate('new'), title: translate('ancillaryExpenseAccount.actions.new'), onClick: () => {
                    onCreateAncillaryExpenseAccount();
                } })] })),
    RowActions: ({ row: { original: { id: propertyId } }, onDuplicateAncillaryExpenseAccount }) => (_jsx(DataTable.Actions, { id: propertyId, children: _jsx(DataTable.Actions.Link, { icon: 'copy', label: translate('ancillaryExpenseAccount.duplicate'), onClick: () => onDuplicateAncillaryExpenseAccount(propertyId) }) })),
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    columns: {
        $actions: {
            sticky: 'left',
        },
        property: {
            width: '100%',
            Header: translate('ancillaryExpenseAccount.table.property'),
            Cell: ({ value, onPropertyClick }) => (_jsx("a", { href: "#", onClick: (event => {
                    event.preventDefault();
                    onPropertyClick(value.id);
                }), children: value.name })),
        },
        $rowExpand: {},
    },
    useExpanded: {
        renderSubRow: ({ row: { original: { ancillaryExpenseAccounts } }, instance: { onEditAncillaryExpenseAccount, onDeleteClicked, refresh }, }) => _jsx(_Fragment, { children: _jsxs(Table, { variant: "content", scroll: { minWidth: 800 }, borderRadius: false, border: false, rowDivider: false, layout: "fixed", children: [_jsxs(Thead, { children: [_jsx(Th, { style: { width: 100 }, children: " " }), _jsx(Th, { children: translate('ancillaryExpenseAccount.table.name') }), _jsx(Th, { style: { width: 100 }, children: translate('ancillaryExpenseAccount.collectionType') }), _jsx(Th, { style: { width: 180 }, children: translate('ancillaryExpenseAccount.settlementType') }), _jsx(Th, { children: translate('accountingRecord.financialAccount') }), _jsx(Th, { children: translate('ancillaryExpenseAccount.table.propertyDistributionKey') }), _jsx(Th, { style: { width: 140 }, children: translate('ancillaryExpenseAccount.table.unit') })] }), _jsx(Tbody, { children: ancillaryExpenseAccounts.map(({ id, name, unit, financialAccounts, collectionType, settlementType, hasMissingRequiredData, propertyDistributionKey }) => _jsxs(Tr, { status: hasMissingRequiredData ? 'error' : undefined, children: [_jsx(Td, { children: _jsx(FlexRow, { children: _jsx(ActionsDropdown, { id: id, quickActions: [{
                                                    icon: 'edit',
                                                    title: translate('btn.edit'),
                                                    onClick: () => onEditAncillaryExpenseAccount(id),
                                                }], children: _jsx(ActionsDropdown.Link, { icon: 'trash', confirm: {
                                                    icon: 'alert-triangle',
                                                    message: translate('ancillaryExpenseAccount.message.areYouSure'),
                                                    help: translate('ancillaryExpenseAccount.message.deleteConfirmation'),
                                                    btnConfirm: translate('btn.confirm'),
                                                }, onClick: () => void onDeleteClicked(id, refresh), title: translate('btn.delete'), children: translate('btn.delete') }) }) }) }), _jsx(Td, { children: _jsx(EllipsisText, { children: _jsx(SafeAnchor, { as: "a", onClick: () => onEditAncillaryExpenseAccount(id), children: name }) }) }), _jsx(Td, { children: translate(`ancillaryExpenseAccount.collection.${collectionType}`) }), _jsx(Td, { children: collectionType === 'floating' ? translate(`ancillaryExpenseAccount.settlement.${settlementType}`) : null }), _jsx(Td, { noWrap: true, children: _jsx(FinancialAccountsInfo, { financialAccounts: financialAccounts }) }), _jsx(Td, { children: propertyDistributionKey
                                        ? propertyDistributionKey.title
                                        : (hasMissingRequiredData
                                            ? translate('ancillaryExpenseAccount.missingData')
                                            : '') }), _jsx(Td, { children: unit ? translate('unit.' + unit) : '' })] }, `row-${id}`)) })] }) }),
    },
}));
export const AncillaryExpenseAccountsDataTable = Object.assign(BaseTable, {
    use: ({ onPropertyClick, onCreateAncillaryExpenseAccount, onEditAncillaryExpenseAccount, onDuplicateAncillaryExpenseAccount, accountServices = AccountServices, onCreatePresetAncillaryExpenseAccount }) => {
        const getData = React.useCallback(({ request }) => {
            return accountServices.listAccounts(request);
        }, []);
        const { success, error } = useToastDispatcherApi();
        const onDeleteAncillaryExpenseAccount = React.useCallback((accountId, refresh) => {
            accountServices.deleteAccount(accountId)
                .then(() => {
                success({
                    timeout: 2,
                    message: translate('ancillaryExpenseAccount.delete.success')
                });
                refresh();
            })
                .catch((data) => {
                error({
                    timeout: 5,
                    message: data.response?.data?.message ?? translate('ancillaryExpenseAccount.delete.error')
                });
            });
        }, []);
        return AncillaryExpenseAccountsDataTable.useTable({
            getData,
            onPropertyClick,
            onDeleteClicked: onDeleteAncillaryExpenseAccount,
            onCreateAncillaryExpenseAccount,
            onDuplicateAncillaryExpenseAccount,
            onEditAncillaryExpenseAccount,
            onCreatePresetAncillaryExpenseAccount
        });
    }
});
const FinancialAccountsInfo = styledModule(() => {
    const TooltipStyled = styled(BaseTooltip).attrs({
        placement: 'top',
        trigger: ['hover', 'click'],
    }) `
      ${tooltipSlotCss('content')} {
        text-align: left;

        ul {
          padding-left: 20px;
          margin: 0;

          > li {
            padding: 4px 0;
          }
        }
      }
    `;
    return ({ financialAccounts }) => {
        const [firstAccount, ...rest] = financialAccounts;
        if (!firstAccount) {
            return _jsx(_Fragment, { children: "\u2014" });
        }
        return rest.length > 0 ? (_jsx(OverlayTrigger, { overlay: _jsx(TooltipStyled, { id: `tooltip-vla`, children: _jsx("ul", { children: rest.map(a => _jsx("li", { children: a.displayName }, a.id)) }) }), children: _jsxs(FlexRow, { inline: true, maxw: '100%', alignCenter: true, justifyFlexStart: true, children: [_jsx(Text, { ellipsis: true, children: firstAccount.displayName }), _jsxs(Button, { variant: 'link', style: { paddingRight: 0, paddingLeft: 4 }, children: ["+", rest.length] })] }) })) : (_jsx(Text, { ellipsis: true, children: firstAccount.displayName }));
    };
});
