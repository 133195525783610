import { translate } from "Services/Translator";
export var RentalObjectType;
(function (RentalObjectType) {
    RentalObjectType["RESIDENTIAL_APARTMENT"] = "residential-apartment";
    RentalObjectType["RESIDENTIAL_MAISONETTE"] = "residential-maisonette";
    RentalObjectType["RESIDENTIAL_ATTIC_FLAT"] = "residential-attic-flat";
    RentalObjectType["RESIDENTIAL_STEPPED_FLAT"] = "residential-stepped-flat";
    RentalObjectType["RESIDENTIAL_STUDIO"] = "residential-studio";
    RentalObjectType["RESIDENTIAL_SINGLE_ROOM"] = "residential-single-room";
    RentalObjectType["RESIDENTIAL_GRANNY_FLAT"] = "residential-granny-flat";
    RentalObjectType["RESIDENTIAL_LOFT"] = "residential-loft";
    RentalObjectType["RESIDENTIAL_FURNISHED_FLAT"] = "residential-furnished-flat";
    RentalObjectType["RESIDENTIAL_DETACHED_HOUSE"] = "residential-detached-house";
    RentalObjectType["RESIDENTIAL_ROW_HOUSE"] = "residential-row-house";
    RentalObjectType["RESIDENTIAL_DUPLEX_HOUSE"] = "residential-duplex-house";
    RentalObjectType["RESIDENTIAL_CHALET"] = "residential-chalet";
    RentalObjectType["RESIDENTIAL_RUSTICO"] = "residential-rustico";
    RentalObjectType["PARKING_SPACE_OUTSIDE"] = "parking-space-outside";
    RentalObjectType["PARKING_SPACE_OUTSIDE_E"] = "parking-space-outside-e";
    RentalObjectType["PARKING_CARPORT"] = "parking-carport";
    RentalObjectType["PARKING_CARPORT_E"] = "parking-carport-e";
    RentalObjectType["PARKING_SPACE_INSIDE"] = "parking-space-inside";
    RentalObjectType["PARKING_SPACE_INSIDE_E"] = "parking-space-inside-e";
    RentalObjectType["PARKING_GARAGE"] = "parking-garage";
    RentalObjectType["PARKING_MOTO_OUTSIDE"] = "parking-moto-outside";
    RentalObjectType["PARKING_MOTO_INSIDE"] = "parking-moto-inside";
    RentalObjectType["COMMERCIAL_OFFICE"] = "commercial-office";
    RentalObjectType["COMMERCIAL_RETAIL"] = "commercial-retail";
    RentalObjectType["COMMERCIAL_DOCTOR"] = "commercial-doctor";
    RentalObjectType["COMMERCIAL_ADVERTISING"] = "commercial-advertising";
    RentalObjectType["COMMERCIAL_WORKSHOP"] = "commercial-workshop";
    RentalObjectType["COMMERCIAL_HOBBY_ROOM"] = "commercial-hobby-room";
    RentalObjectType["COMMERCIAL_ATELIER"] = "commercial-atelier";
    RentalObjectType["COMMERCIAL_ANTENNA"] = "commercial-antenna";
    RentalObjectType["STORAGE_STORAGE"] = "storage-storage";
    RentalObjectType["GASTRONOMY_RESTAURANT"] = "gastronomy-restaurant";
    RentalObjectType["GASTRONOMY_BAR"] = "gastronomy-bar";
    RentalObjectType["GASTRONOMY_CAFE"] = "gastronomy-cafe";
    RentalObjectType["AGRICULTURAL_AGRICULTURAL"] = "agricultural-agricultural";
    RentalObjectType["INDUSTRIAL_INDUSTRIAL"] = "industrial-industrial";
    RentalObjectType["CONSTRUCTION_PLOT"] = "construction-plot";
    RentalObjectType["INVESTMENT_INVESTMENT"] = "investment-investment";
    RentalObjectType["VACATION_VACATION"] = "vacation-vacation";
    RentalObjectType["OTHER_OTHER"] = "other-other";
})(RentalObjectType || (RentalObjectType = {}));
export const rentalObjectTypeOptions = [
    {
        label: translate('rentalObjectType.residential'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('residential'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
    {
        label: translate('rentalObjectType.parking'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('parking'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
    {
        label: translate('rentalObjectType.commercial'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('commercial'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
    {
        label: translate('rentalObjectType.storage'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('storage'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
    {
        label: translate('rentalObjectType.gastronomy'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('gastronomy'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
    {
        label: translate('rentalObjectType.agricultural'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('agricultural'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
    {
        label: translate('rentalObjectType.industrial'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('industrial'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
    {
        label: translate('rentalObjectType.construction'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('construction'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
    {
        label: translate('rentalObjectType.investment'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('investment'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
    {
        label: translate('rentalObjectType.vacation'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('vacation'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
    {
        label: translate('rentalObjectType.other'),
        options: Object
            .values(RentalObjectType)
            .filter(type => type.startsWith('other'))
            .map(type => ({
            value: type,
            label: translate(`rentalObjectType.${type}`)
        }))
    },
];
