import { SimpleValue } from './SimpleValue';
import { OrFunction } from '@f2w/utils';
import * as IMask from 'imask';
import { useIMask } from 'react-imask';
export class MaskedValue extends SimpleValue {
    constructor(type, props) {
        super(type, props);
        this._props.skipUpdate = false;
    }
    static createMask(mask) {
        return IMask.createMask(OrFunction(mask));
    }
    _createMask() {
        if (!this._props.mask) {
            this._props.mask = MaskedValue.createMask(OrFunction(this._type.getMask()));
        }
        return this._props.mask;
    }
    get mask() {
        return this._props.maskInput?.maskRef?.current ?? this._createMask();
    }
    get maskRef() {
        return this._props.maskInput?.ref;
    }
    use2(props) {
        super.use(props);
        this._props.maskInput = useIMask(this._createMask(), {
            onAccept: (value, mask, e) => {
                if (this._props.skipUpdate) {
                    this._props.skipUpdate = false;
                }
                else {
                    if (this.value !== mask.unmaskedValue) {
                        super.updateValue(mask.unmaskedValue, true);
                    }
                }
            },
        });
        return this;
    }
    _setSkipUpdate(skip) {
        this._props.skipUpdate = this._props.maskInput && skip;
    }
    reset(value, shouldValidate, skipRender) {
        super.reset(value, shouldValidate, skipRender);
        this._setSkipUpdate(true);
        this.mask.value = String(this.initialValue ?? "");
    }
    setValue(value, shouldValidate, skipRender) {
        this._setSkipUpdate(true);
        this.mask.value = String(value ?? "");
        super.setValue(this.mask.unmaskedValue, shouldValidate, skipRender);
    }
    updateValue(value, shouldValidate, skipRender) {
        this._setSkipUpdate(true);
        this.mask.value = String(value ?? "");
        super.updateValue(this.mask.unmaskedValue, shouldValidate, skipRender);
    }
}
(function (MaskedValue) {
    ;
})(MaskedValue || (MaskedValue = {}));
