import { forEachObject } from '@f2w/utils';
import { ChildrenManager, IndexManager, KeyOrChild } from './descriptors';
import { BaseState } from './BaseState';
export class CompoundState extends BaseState {
    childrenMap = new Map();
    indexManager = IndexManager.create({
        onChange: (event) => {
            this._userConfig?.onIndexChange?.(event);
        },
    })
        .add('active', {
        getMetaValue: set => set.size > 0
    })
        .add('dirty', {
        getMetaValue: (set) => set.size > 0,
    })
        .add('hasError', {
        getMetaValue: (set) => set.size > 0,
    })
        .add('validated', {
        getMetaValue: (set) => set.size === this.childrenMap.size,
    })
        .add('validating', {
        getMetaValue: (set) => set.size > 0,
    })
        .add('touched', {
        getMetaValue: (set) => set.size > 0,
    });
    get indexMap() {
        return this.indexManager.data;
    }
    get gettersMap() {
        return this.childrenManager.props;
    }
    constructor(userConfig) {
        super(userConfig);
        const childrenManager = ChildrenManager.create({
            onChange: (event) => {
                this._userConfig?.onIndexChange?.(event);
            },
        });
        const propsManager = childrenManager
            .add('error', {
            getValue: (child) => child.error || false,
            set: (child, value) => child.setError(value),
        })
            .add('fields', {
            getValue: (child) => child,
        })
            .add('touched', {
            getValue: (child) => child.touched,
            set: (child, value) => child.setTouched(value, false),
        })
            .add('initialValue', {
            getValue: (child) => child.initialValue,
            set: (child, value) => child.setValue(value),
        })
            .add('value', {
            getValue: (child) => child.value,
            set: (child, value) => child.updateValue(value, true),
        })
            .add('prevValue', {
            getValue: (child) => child.data.prevValue,
        })
            .add('formattedValue', {
            getValue: (child) => child.data.formattedValue,
        })
            .add('humanValue', {
            getValue: (child) => child.data.humanValue,
        });
        const indexManager = this.indexManager;
        Object.defineProperties(this, {
            indexManager: {
                value: indexManager,
                enumerable: false,
                writable: false,
                configurable: false
            },
            childrenManager: {
                value: childrenManager,
                enumerable: false,
                writable: false,
                configurable: false
            },
        });
        const config = this._createConfig(this._configureDescriptors());
        Object.defineProperties(this, {
            _config: {
                value: config,
                writable: false,
                configurable: false,
                enumerable: false
            },
            meta: {
                value: config.meta,
                writable: false,
                configurable: false
            },
            data: {
                value: config.data,
                writable: false,
                configurable: false
            },
        });
    }
    _configureDescriptors() {
        const desc = this._userConfig.descriptors;
        return {
            ...desc,
            disabled: {
                getMetaValue: () => this._self().isDisabled,
                set: (value) => this._type().update({ disabled: value }),
                ...desc?.disabled,
            },
            visible: {
                getMetaValue: () => this._self().isVisible,
                set: (value) => this._type().update({ hidden: !value }),
                ...desc?.visible,
            },
            active: {
                getMetaValue: () => this.indexMap.active.get() && this._self().isActive,
                ...desc?.active,
            },
            dirty: {
                getMetaValue: () => this.indexMap.dirty.get(),
                ...desc?.dirty,
            },
            hasError: {
                getMetaValue: () => this.indexMap.hasError.get(),
                ...desc?.hasError,
            },
            error: {
                getValue: () => this.gettersMap.error.data,
                ...desc?.error,
            },
            valid: {
                getMetaValue: () => (this.meta.validated && !this.meta.hasError),
                ...desc?.valid,
            },
            validated: {
                getMetaValue: () => this.indexMap.validated.get(),
                ...desc?.validated,
            },
            validating: {
                getMetaValue: () => this.indexMap.validating.get(),
                ...desc?.validating,
            },
            fields: {
                ...desc?.fields,
            },
            touched: {
                getMetaValue: () => this.indexMap.touched.get(),
                ...desc?.touched,
            },
            initialValue: {
                ...desc?.initialValue,
            },
            value: {
                ...desc?.value,
            },
            prevValue: {
                ...desc?.prevValue,
            },
            formattedValue: {
                ...desc?.formattedValue,
            },
            humanValue: {
                ...desc?.humanValue,
            },
        };
    }
    getChildrenSize() {
        return this.childrenMap.size;
    }
    onAddChild(child) {
    }
    onRemoveChild(child) {
    }
    keys() {
        return [...this.childrenMap.keys()];
    }
    all() {
        return [...this.childrenMap.values()];
    }
    has(keyOrChild) {
        return this.childrenMap.has(KeyOrChild.key(keyOrChild));
    }
    get(keyOrChild) {
        return this.childrenMap.get(KeyOrChild.key(keyOrChild));
    }
    forEach(cb) {
        this.childrenMap.forEach(cb);
    }
    map(cb) {
        const items = [];
        this.childrenMap.forEach((child, key) => {
            const resolvedChild = cb(child, key);
            resolvedChild && items.push(resolvedChild);
        });
        return items;
    }
    register(key, child) {
        if (key && !this.has(key)) {
            const state = child['_state'];
            if (state['_parent'] && (state['_parent'] !== this || state.key !== key)) {
                state['_parent'].remove(child);
            }
            this.childrenMap.set(key, child);
            state.props.key = key;
            state.props.parent = this._self();
            this.childrenManager.forEach((getter, key) => {
                getter.add(child);
            });
            this.onAddChild(child);
        }
    }
    remove(keyOrChild) {
        const child = this.get(keyOrChild);
        if (child) {
            this.clearIndexes([keyOrChild]);
            this.childrenManager.forEach((getter, key) => {
                getter.remove(child);
            });
            this.onRemoveChild(child);
            this.childrenMap.delete(child.key);
            if (child['_state']['_parent'] === this) {
                child['_state'].props.parent = null;
            }
            this.updateParent();
            return true;
        }
    }
    _updateData(key, value, force) {
        return super._updateData(key, value);
    }
    clearIndexes(childrenOrKeys) {
        if (childrenOrKeys) {
            this.indexManager.update((index) => index.removeAll(childrenOrKeys));
        }
        else {
            this.indexManager.update((index) => index.clear());
        }
    }
    updateIndexes(props) {
        const {} = props;
        const updatedKeys = props.childrenOrKeys.filter(keyOrChild => {
            const child = this.get(keyOrChild);
            if (!child) {
                console.log('no child', keyOrChild, props);
                return false;
            }
            return this.indexManager.update((index, k) => {
                if (props.ignoredKeys && props.ignoredKeys.includes(k))
                    return false;
                return child && index._update(child);
            }, props.keys);
        });
        if (updatedKeys) {
            this.updateParent();
            return true;
        }
    }
    _dumpMeta() {
        const keys = {};
        Object.entries(this.indexManager.data).forEach(([key, index]) => {
            keys[key] = [...index.values()];
        });
        return keys;
    }
    _createConfig(config) {
        const metaProps = Object.create(null);
        const dataProps = Object.create(null);
        forEachObject(config, (key, configProp) => {
            const _meta = {
                get: configProp.getMetaValue,
                configurable: false,
                enumerable: true
            };
            let _data = {
                get: configProp.getValue,
                set: configProp.set,
                configurable: false,
                enumerable: true
            };
            if (this.childrenManager.hasProp(key))
                _data = {
                    get: () => this.childrenManager.getData(key),
                    configurable: false,
                    enumerable: true
                };
            if (_data.get)
                dataProps[key] = { ..._data };
            if (_meta.get)
                metaProps[key] = { ..._meta };
        });
        const data = Object.defineProperties({}, { ...metaProps, ...dataProps });
        const meta = Object.defineProperties({}, { ...metaProps });
        return {
            props: config,
            data: data,
            meta: meta,
        };
    }
}
